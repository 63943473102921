import React from 'react';
import Markdown from 'markdown-to-jsx';
import Grid from '@material-ui/core/Grid';
import { Constants } from '../constants';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Trans, useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { LoadingContent } from './../components/LoadingContent';

const styles = (theme) => ({
    listItem: {
        marginTop: theme.spacing(1),
    },
    image: {
        width: '100%'
    },
    link: {
        color: 'white',
    }
});

const options = {
    overrides: {
        img: {
            component: withStyles(styles)(({ classes, ...props }) => (
                <Grid container direction="row" justify="center">
                    <Grid item>
                        <img className={classes.image} src={props.src} />
                    </Grid>
                </Grid>
            )),
        },
        p: { component: Typography, props: { paragraph: true } },
        a: {
            component: withStyles(styles)(({ classes, ...props }) => (
                <Link color="inherit" className={classes.link} {...props} />
            )),
        },
        li: {
            component: withStyles(styles)(({ classes, ...props }) => (
                <li className={classes.listItem}>
                    <Typography component="span" {...props} />
                </li>
            )),
        },
    },
};

export const PageContent = (props) => {
    const { t, i18n } = useTranslation();

    const [content, setContent] = React.useState();
    const [latestUpdated, setLatestUpdated] = React.useState();
    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
        fetch(Constants.apiPrefix + `/api/page/${props.page}/${i18n.language}`)
            .then(response => response.json())
            .then(data => {
                setContent(data.content);
                setLatestUpdated(data.latest_updated);
                setLoaded(true);
            })
    }, [props.page, i18n.language])

    return (
        <React.Fragment>
            {loaded ? (
                <>
                    <Markdown options={options}>
                        {content}
                    </Markdown>
                    {props.showLatestUpdated === true && (
                        <Box>
                            <Typography variant='caption'>
                                <Trans>general.latest-updated</Trans>: {latestUpdated}
                            </Typography>
                        </Box>
                    )}
                </>
            ) : <LoadingContent />}
        </React.Fragment>
    );
}