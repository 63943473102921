import React, { useContext } from 'react'
import { Constants } from "../../constants";
import {
    Avatar, ButtonGroup, CircularProgress,
    Container,
    Grid, IconButton,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles, Snackbar
} from "@material-ui/core";
import { PageTitle } from "../../layout/PageTitle";
import SponsorPaper from "../../layout/components/SponsorPaper";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import { Group, Refresh } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";
import Cookies from 'universal-cookie';
import Typography from "@material-ui/core/Typography";
import { StateContext } from "../../discord/StateProvider";
import AlertDialog from "../team/components/AlertDialog";
import { Trans, useTranslation } from 'react-i18next';
import { NotificationContext } from "../../context/NotificationProvider";
import { Alert } from "@material-ui/lab";
import { RegularNotification } from "./RegularNotification";
import { InviteNotification } from "./InviteNotification";
import Divider from "@material-ui/core/Divider";

async function getNumberUnreadNotifications(accessToken) {
    const response = await fetch(new URL(Constants.apiPrefix + `/api/user/hidden/${accessToken}/unreadNotifications`).toString());
    return await response.json();
}

async function readNotifications(accessToken) {
    const response = await fetch(new URL(Constants.apiPrefix + `/api/user/hidden/${accessToken}/readNotifications`).toString());
    return await response.json();
}

async function getNotifications(accessToken) {
    const response = await fetch(new URL(Constants.apiPrefix + `/api/user/hidden/${accessToken}/notifications`).toString());
    return await response.json();
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
}));

export const Notifications = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const cookies = new Cookies();

    const [notifications, setNotifications] = React.useState([]);
    const [numberUnreadNotifications, setNumberUnreadNotifications] = React.useState();
    const [loading, setLoading] = React.useState(false);

    const update = () => {
        setLoading(true);
        getNumberUnreadNotifications(cookies.get("accessToken")).then(response => {
            setNumberUnreadNotifications(response);
            getNotifications(cookies.get("accessToken")).then(response => {
                setNotifications(response);
                setLoading(false);
            });
            readNotifications(cookies.get("accessToken")).catch(error => console.log(error));
        });
    }

    React.useEffect(() => {
        update();
    }, [])

    return (
        <>
            <PageTitle minHeight={300}><Trans>notification.title</Trans></PageTitle>
            <SponsorPaper>
                <Box p={1} />
            </SponsorPaper>
            <Box my={5} />
            <Container>
                <Grid container justify="flex-end">
                    <Grid item>
                        {loading ? (
                            <IconButton>
                                <CircularProgress style={{ color: theme.palette.primary.contrastText }} size={24} />
                            </IconButton>
                        ) : (
                            <IconButton onClick={update}>
                                <Refresh size={24} />
                            </IconButton>
                        )}

                    </Grid>
                </Grid>
                <List aria-label="main mailbox folders">
                    {notifications.length > 0 ? (
                        notifications.map((notification, index) => {
                            if (notification.inviteId === null) {
                                return (
                                    <>
                                        <RegularNotification notification={notification} update={update} />
                                        {index === numberUnreadNotifications - 1 && <Box my={3}><Divider /></Box>}
                                    </>
                                )
                            }
                            return (
                                <>
                                    <InviteNotification notification={notification} update={update} />
                                    {index === numberUnreadNotifications - 1 && notifications.length > numberUnreadNotifications && <Box my={3}><Divider /></Box>}
                                </>
                            )
                        })
                    ) : (
                        <Typography><Trans>notification.no-notifications</Trans></Typography>
                    )}
                </List>

            </Container>
            <Box my={10} />
        </>
    )

}