import React from "react";
import { Box, Container, InputAdornment } from "@material-ui/core";
import { Search } from "@material-ui/icons";

import { Constants } from "../../../../constants";
import { TextField } from "../../../../components/TextField";
import { SignedupTeamsSortableTable } from "./SignedupTeamsSortableTable";

const getSignedUpTeams = async () => {
    const r = await fetch(`${Constants.apiPrefix}/api/team/signedUpTeams`);
    return await r.json();
}

export const SignedupTeams = () => {
    const [signedUpTeams, setSignedUpTeams] = React.useState([]);
    const [searchTeams, setSearchTeams] = React.useState([]);

    React.useEffect(() => {
        getSignedUpTeams().then(signedUpTeams => {
            setSignedUpTeams(signedUpTeams);
            setSearchTeams(signedUpTeams);
        });
    }, []);

    const handleChange = (event) => {
        if (event.target.value.length > 0) {
            setSearchTeams(signedUpTeams.filter((team) => team.teamname.toLowerCase().includes(event.target.value.toLowerCase())));
        } else {
            setSearchTeams(signedUpTeams);
        }
    }

    return (
        <Container>
            <Box p={1}>
                <TextField
                    autoFocus
                    fullWidth
                    label="Sök anmälda lag"
                    variant="outlined"
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <SignedupTeamsSortableTable signedUpTeams={searchTeams} />
        </Container>
    );
}