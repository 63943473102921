import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Constants } from "../../../constants";
import { TableBody, TableCell, TableContainer, TableHead } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import {Trans, useTranslation} from "react-i18next";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Typography>{children}</Typography>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

async function getStats(playerId, divisionId) {
    const response = await fetch(new URL(`/api/player/${playerId}/division/${divisionId}/stats`, Constants.apiPrefix).toString());
    return await response.json();
}

export const StatisticsTabs = (props) => {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const { playerId, seasonTeams } = props;
    const [stats, setStats] = React.useState();
    const { t } = useTranslation();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getStatsForEachSeason = async (seasonTeams) => {
        let s = []
        for(let i = 0; i < seasonTeams.length; i++) {
            s.push(await getStats(playerId, seasonTeams[i].divisionId));
        }
        return s;
    }

    React.useEffect(() => {
        getStatsForEachSeason(seasonTeams).then(response => setStats(response));
    }, [seasonTeams])


    return (
        <>
            <Typography variant="h4">
                <Trans i18nKey="stat.header" />
            </Typography>
            <div className={classes.root}>
                <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        {seasonTeams.map((seasonTeam, index) => (
                            <Tab label={seasonTeam.season} {...a11yProps(index)} />
                        ))}
                    </Tabs>
                </AppBar>
                {stats && stats.map((stat, index) => {
                    return (
                        <TabPanel value={value} index={index} >
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                {t("stat.games.short")}
                                            </TableCell>
                                            <TableCell>
                                                {t("stat.points-average.short")}
                                            </TableCell>
                                            <TableCell>
                                                {t("stat.games.short")}
                                            </TableCell>
                                            <TableCell>
                                                {t("stat.goals-average.short")}
                                            </TableCell>
                                            <TableCell>
                                                {t("stat.assists.short")}
                                            </TableCell>
                                            <TableCell>
                                                {t("stat.assists-average.short")}
                                            </TableCell>
                                            <TableCell>
                                                {t("stat.saves.short")}
                                            </TableCell>
                                            <TableCell>
                                                {t("stat.saves-average.short")}
                                            </TableCell>
                                            <TableCell>
                                                {t("stat.shots.short")}
                                            </TableCell>
                                            <TableCell>
                                                {t("stat.shots-average.short")}
                                            </TableCell>
                                            <TableCell>
                                                {t("stat.shots-percent.short")}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                {stat.matches}
                                            </TableCell>
                                            <TableCell>
                                                {stat.matches > 0 && (stat.points / stat.matches).toFixed(2)}
                                            </TableCell>
                                            <TableCell>
                                                {stat.goals}
                                            </TableCell>
                                            <TableCell>
                                                {stat.matches > 0 && (stat.goals / stat.matches).toFixed(2)}
                                            </TableCell>
                                            <TableCell>
                                                {stat.assists}
                                            </TableCell>
                                            <TableCell>
                                                {stat.matches > 0 && (stat.assists / stat.matches).toFixed(2)}
                                            </TableCell>
                                            <TableCell>
                                                {stat.saves}
                                            </TableCell>
                                            <TableCell>
                                                {stat.matches > 0 && (stat.saves / stat.matches).toFixed(2)}
                                            </TableCell>
                                            <TableCell>
                                                {stat.shots}
                                            </TableCell>
                                            <TableCell>
                                                {stat.matches > 0 && (stat.shots / stat.matches).toFixed(2)}
                                            </TableCell>
                                            <TableCell>
                                                {stat.shots > 0 && (stat.goals / stat.shots).toFixed(2)}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                    )
                })}
            </div>
        </>
    )

}