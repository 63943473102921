import React, { useContext } from "react";
import { Avatar, Box, Container, Grid, Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Facebook, YouTube, Twitter, Instagram, LinkOutlined } from "@material-ui/icons";
import { Trans, useTranslation } from "react-i18next";

import { CustomThemeContext } from "../../theme/CustomThemeProvider";
import { PageTitle } from "../../layout/PageTitle";
import SponsorPaper from "../../layout/components/SponsorPaper";

const useStyles = makeStyles((theme) => ({
    images: {
        maxHeight: "120px",
        maxWidth: "100%",
    },
    sponsorContainer: {
        textAlign: "center",
    },
    link: {
        backgroundColor: theme.palette.secondary.main,
        color: "#000",
    },
    sponsorName: {
        paddingBottom: "10px",
    },
}));

export const Cooperation = () => {
    const classes = useStyles();
    useTranslation();
    let esportUnited = "/images/sponsor/Esport_United.png";
    let luma = "/images/sponsor/LUMA_logo_white.png";
    let coolerMaster = "/images/sponsor/coolermaster.png";
    let noble = "/images/sponsor/logo_noblechairs_wt.png";
    let streamplify = "/images/sponsor/streamplify-wh.png";

    return (
        <Box>
            <PageTitle>
                <Typography variant="h2" style={{ fontWeight: 700 }}>
                    <Trans>cooperation.title</Trans>
                </Typography>
            </PageTitle>
            <SponsorPaper>
                <Box pt={1} />
            </SponsorPaper>
            <Box my={5} />
            <Container>
                <Grid container justify="center" direction="column" alignItems="center" spacing={5}>
                    <Grid item className={classes.sponsorContainer}>
                        <img src={esportUnited} alt="Esport United" className={classes.images} />
                        <Box pt={3} />
                        <Typography>
                            <Trans>cooperation.esport-united.text</Trans>
                        </Typography>
                        <Box pt={2} />
                        <Typography variant="h5" className={classes.sponsorName}>
                            <Trans>cooperation.esport-united</Trans>
                        </Typography>
                        <Grid container justify="center" direction="row" alignItems="center" spacing={2}>
                            <Grid item>
                                <a href="https://twitter.com/esport_united" target="_blank">
                                    <Avatar className={classes.link}>
                                        <Twitter />
                                    </Avatar>
                                </a>
                            </Grid>
                            <Grid item>
                                <a href="https://www.instagram.com/esportunited/?hl=en" target="_blank">
                                    <Avatar className={classes.link}>
                                        <Instagram />
                                    </Avatar>
                                </a>
                            </Grid>
                            <Grid item>
                                <a href="https://www.facebook.com/Esportunitedswe" target="_blank">
                                    <Avatar className={classes.link}>
                                        <Facebook />
                                    </Avatar>
                                </a>
                            </Grid>
                            <Grid item>
                                <a href="https://esportunited.com/" target="_blank">
                                    <Avatar className={classes.link}>
                                        <LinkOutlined />
                                    </Avatar>
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider variant="middle" style={{ width: "80%" }} />
                    <Grid item className={classes.sponsorContainer}>
                        <img src={coolerMaster} alt="Cooler Master" className={classes.images} />
                        <Box pt={3} />
                        <Typography>
                            <Trans>cooperation.cooler-master.text</Trans>
                        </Typography>
                        <Box pt={2} />
                        <Typography variant="h5" className={classes.sponsorName}>
                            <Trans>cooperation.cooler-master</Trans>
                        </Typography>
                        <Grid container justify="center" direction="row" alignItems="center" spacing={2}>
                            <Grid item>
                                <a href="https://twitter.com/coolermasternd/" target="_blank">
                                    <Avatar className={classes.link}>
                                        <Twitter />
                                    </Avatar>
                                </a>
                            </Grid>
                            <Grid item>
                                <a href="https://www.instagram.com/coolermaster.nordics/" target="_blank">
                                    <Avatar className={classes.link}>
                                        <Instagram />
                                    </Avatar>
                                </a>
                            </Grid>
                            <Grid item>
                                <a href="https://www.facebook.com/coolermasternordics/" target="_blank">
                                    <Avatar className={classes.link}>
                                        <Facebook />
                                    </Avatar>
                                </a>
                            </Grid>
                            <Grid item>
                                <a href="https://www.coolermaster.com/" target="_blank">
                                    <Avatar className={classes.link}>
                                        <LinkOutlined />
                                    </Avatar>
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider variant="middle" style={{ width: "80%" }} />
                    <Grid item className={classes.sponsorContainer}>
                        <img src={luma} alt="YLDR" className={classes.images} />
                        <Box pt={3} />
                        <Typography>
                            <Trans>cooperation.luma.text</Trans>
                        </Typography>
                        <Box pt={2} />
                        <Typography variant="h5" className={classes.sponsorName}>
                            <Trans>cooperation.luma</Trans>
                        </Typography>
                        <Grid container justify="center" direction="row" alignItems="center" spacing={2}>
                            <Grid item>
                                <a href="https://www.facebook.com/LUMATeamwear/" target="_blank">
                                    <Avatar className={classes.link}>
                                        <Facebook />
                                    </Avatar>
                                </a>
                            </Grid>
                            <Grid item>
                                <a href="https://www.instagram.com/lumateamwear/" target="_blank">
                                    <Avatar className={classes.link}>
                                        <Instagram />
                                    </Avatar>
                                </a>
                            </Grid>
                            <Grid item>
                                <a href="https://lumateamwear.se/" target="_blank">
                                    <Avatar className={classes.link}>
                                        <LinkOutlined />
                                    </Avatar>
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider variant="middle" style={{ width: "80%" }} />
                    <Grid item className={classes.sponsorContainer}>
                        <img src={noble} alt="YLDR" className={classes.images} />
                        <Box pt={3} />
                        <Typography>
                            <Trans>cooperation.noble.text</Trans>
                        </Typography>
                        <Box pt={2} />
                        <Typography variant="h5" className={classes.sponsorName}>
                            <Trans>cooperation.noble</Trans>
                        </Typography>
                        <Grid container justify="center" direction="row" alignItems="center" spacing={2}>
                            <Grid item>
                                <a href="https://twitter.com/noblechairs" target="_blank">
                                    <Avatar className={classes.link}>
                                        <Twitter />
                                    </Avatar>
                                </a>
                            </Grid>
                            <Grid item>
                                <a href="https://www.instagram.com/noblechairs/" target="_blank">
                                    <Avatar className={classes.link}>
                                        <Instagram />
                                    </Avatar>
                                </a>
                            </Grid>
                            <Grid item>
                                <a href="https://noblechairs.com/" target="_blank">
                                    <Avatar className={classes.link}>
                                        <LinkOutlined />
                                    </Avatar>
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider variant="middle" style={{ width: "80%" }} />
                    <Grid item className={classes.sponsorContainer}>
                        <img src={streamplify} alt="YLDR" className={classes.images} />
                        <Box pt={3} />
                        <Typography>
                            <Trans>cooperation.streamplify.text</Trans>
                        </Typography>
                        <Box pt={2} />
                        <Typography variant="h5" className={classes.sponsorName}>
                            <Trans>cooperation.streamplify</Trans>
                        </Typography>
                        <Grid container justify="center" direction="row" alignItems="center" spacing={2}>
                            <Grid item>
                                <a href="https://twitter.com/streamplify" target="_blank">
                                    <Avatar className={classes.link}>
                                        <Twitter />
                                    </Avatar>
                                </a>
                            </Grid>
                            <Grid item>
                                <a href="https://www.instagram.com/streamplify_global/" target="_blank">
                                    <Avatar className={classes.link}>
                                        <Instagram />
                                    </Avatar>
                                </a>
                            </Grid>
                            <Grid item>
                                <a href="https://www.streamplify.com/" target="_blank">
                                    <Avatar className={classes.link}>
                                        <LinkOutlined />
                                    </Avatar>
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Box my={10} />
        </Box>
    );
};
