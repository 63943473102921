import { Constants } from '../constants';
export const NewsService = {
    fetchAll : () => fetch(Constants.apiPrefix + `/api/admin/news/get`).then(response => response.json()),
    
    fetch : (id) => { return fetch(Constants.apiPrefix + `/api/admin/news/get/${id}`).then(response => response.json())},
    
    delete:(accessToken,id) => fetch(Constants.apiPrefix + `/api/admin/news/delete/${id}`,{
        method:"POST",
        body:JSON.stringify({
            accessToken:accessToken
        })}).then(response => response.json()),

    update:(accessToken, news) => fetch(Constants.apiPrefix + "/api/admin/news/update", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            accessToken: accessToken,
            id: news.id,
            title: news.title,
            text: news.text,
            category: news.category,
            customUrl: news.customUrl,
            thumbnail: news.thumbnail,
            image: news.image,
            publishdate: news.publishDate

        }),
    }).then(response => response.json()),
    save:(accessToken,userId,category,thumbnail,image,customUrl,description,title,content,publishDate)=> fetch(Constants.apiPrefix + "/api/admin/news/save", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            accessToken: accessToken,
            userId: userId,
            newscategory: category,
            newsthumbnail: thumbnail,
            newsimage: image,
            newscustomUrl: customUrl,
            newsdescription: description,
            newstitle: title,
            newscontent: content,
            publishdate: publishDate
        }),
    })
    .then(response => response.json())
}
