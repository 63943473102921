import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';

import { UpperCaseText } from '../../../layout/components/UpperCaseText';
import { FaTwitch } from "react-icons/all";

const GreenCell = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.success.main,
    },
}))(TableCell);

const RedCell = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.error.main,
    },
}))(TableCell);

const YellowCell = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.warning.main,
    },
}))(TableCell);


const StyledTableRow = withStyles((theme) => ({
    root: {

    },
}))(TableRow);

export const SeriesTab = (props) => {

    useTranslation();
    let numberSkeletons = props.numberSkeletons;
    if (numberSkeletons === undefined) {
        numberSkeletons = 20;
    }
    return (
        <TableContainer {...props}>
            <Table aria-label="Divisions tabell">
                <TableHead>
                    <TableRow>
                        <TableCell align="left"><Trans>league.date</Trans></TableCell>
                        <TableCell align="center"><Trans>league.hometeam</Trans></TableCell>
                        <TableCell align="center"><Trans>league.result</Trans></TableCell>
                        <TableCell align="center"><Trans>league.awayteam</Trans></TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.series ? (props.series.map((match, index) => {
                        let matchscore;
                        let matchdate = match.matchdate;
                        let HomeCell = match.homescore > match.awayscore ? GreenCell : RedCell;
                        let AwayCell = match.homescore < match.awayscore ? GreenCell : RedCell;
                        if (match.homescore === match.awayscore) {
                            HomeCell = YellowCell;
                            AwayCell = YellowCell;
                        }
                        if (match.awayscore == null) {
                            HomeCell = TableCell;
                            AwayCell = TableCell;
                        } else {
                            matchscore = `${match.homescore} - ${match.awayscore}`;
                        }
                        if (match.matchdate == null) {
                            matchdate = (<><Trans>league.week</Trans> {match.week}</>);
                        } else {
                            matchdate = matchdate.substring(0, matchdate.length - 3);
                        }
                        return (
                            <StyledTableRow>
                                <TableCell align="left"><UpperCaseText>{matchdate}</UpperCaseText></TableCell>
                                <HomeCell align="center" component={Link} to={`/team/${match.hometeam_id}`} ><UpperCaseText>{match.hometeam}</UpperCaseText></HomeCell>
                                <TableCell align="center" component={Link} to={`/series/${match.match_id}`}><UpperCaseText>{matchscore}</UpperCaseText></TableCell>
                                <AwayCell align="center" component={Link} to={`/team/${match.awayteam_id}`}><UpperCaseText>{match.awayteam}</UpperCaseText></AwayCell>
                                <TableCell align="center" padding={"none"}>{match.match_stream === 1 && (
                                    <a style={{ color: "white" }} href={"https://twitch.tv/svenskaraketligan"}><FaTwitch size={24} /></a>
                                )}</TableCell>
                            </StyledTableRow>
                        )
                    }
                    )) : (
                        [...Array(numberSkeletons)].map((number) => {
                            const height = 20;
                            return (
                                <StyledTableRow>
                                    <TableCell><Skeleton animation="wave" height={height} /></TableCell>
                                    <TableCell><Skeleton animation="wave" height={height} /></TableCell>
                                    <TableCell><Skeleton animation="wave" height={height} /></TableCell>
                                    <TableCell><Skeleton animation="wave" height={height} /></TableCell>
                                    <TableCell><Skeleton animation="wave" height={height} /></TableCell>
                                </StyledTableRow>
                            )
                        })
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )

}
