import React from "react";
import { Box, Card, Container, Typography } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { Add, Group, Person } from "@material-ui/icons";
import { Line } from 'react-chartjs-2';

import { Constants } from "../../../../constants";
import {fade, useTheme} from "@material-ui/core/styles";

const getUserCount = async () => {
    const r = await fetch(`${Constants.apiPrefix}/api/users/allCount`);
    return await r.json();
}

const getUserConnectedWithPlayerCount = async () => {
    const r = await fetch(`${Constants.apiPrefix}/api/users/allWithPlayerCount`);
    return await r.json();
}

const getTeamCount = async () => {
    const r = await fetch(`${Constants.apiPrefix}/api/team/allCount`);
    return await r.json();
}

const getActiveTeamCount = async () => {
    const r = await fetch(`${Constants.apiPrefix}/api/team/allActiveCount`);
    return await r.json();
}

const getSignedUpTeamsCount = async () => {
    const r = await fetch(`${Constants.apiPrefix}/api/team/signedUpTeamsCount`);
    return await r.json();
}

const getPlayersPerDate = async () => {
    const r = await fetch(`${Constants.apiPrefix}/api/users/playersPerDate`);
    const apa = await r.json();

    const dates = apa.MESSAGE.map(p => p.date);
    const c = apa.MESSAGE.map(p => parseInt(p.count));

    return { dates: dates, count: c };
}

const getSignedUpTeamsPerDate = async () => {
    const r = await fetch(`${Constants.apiPrefix}/api/team/signedUpTeamsPerDate`);
    const apa = await r.json();

    const dates = apa.MESSAGE.map(p => p.date);
    const c = apa.MESSAGE.map(p => parseInt(p.count));

    return { dates: dates, count: c };
}

export const Dashboard = () => {
    const theme = useTheme();
    const [timeoutId, setTimeoutId] = React.useState();
    const [counts, setCounts] = React.useState();
    const [lineData, setLineData] = React.useState();

    let data;
    if(lineData && lineData.playersPerDateData && lineData.playersPerDateLabels) {
        data = {
            labels: lineData.playersPerDateLabels,
            datasets: [
                {
                    label: 'Användare / dag',
                    data: lineData.playersPerDateData,
                    fill: false,
                    backgroundColor: fade(theme.palette.info.main, 1),
                    borderColor: fade(theme.palette.info.main, 0.2),
                }
            ],
        };
    }
    let dataRegisterTeams;
    if(lineData && lineData.signedUpTeamsData && lineData.signedUpTeamsLabels) {
        dataRegisterTeams = {
            labels: lineData.signedUpTeamsLabels,
            datasets: [
                {
                    label: 'Registrerade lag / dag',
                    data: lineData.signedUpTeamsData,
                    fill: false,
                    backgroundColor: fade(theme.palette.success.main, 1),
                    borderColor: fade(theme.palette.success.main, 0.2),
                },
            ]
        }
    }

    const updateDashboard = () => {
       Promise.all([
            getUserCount(),
            getUserConnectedWithPlayerCount(),
            getTeamCount(),
            getActiveTeamCount(),
            getSignedUpTeamsCount(),
            getPlayersPerDate(),
            getSignedUpTeamsPerDate(),
        ]).then(([
                     userCount,
                     userWithPlayerCount,
                     teamCount,
                     activeTeamCount,
                     signedUpTeamsCount,
                     playersPerDate,
                     signedUpTeamsPerDate,
                 ]) => {
            setCounts({
                userCount: userCount.MESSAGE,
                userWithPlayerCount: userWithPlayerCount.MESSAGE,
                teamCount: teamCount.MESSAGE,
                activeTeamCount: activeTeamCount.MESSAGE,
                signedUpTeamsCount: signedUpTeamsCount.MESSAGE,
            })
           setLineData({
               playersPerDateLabels: playersPerDate.dates,
               playersPerDateData: playersPerDate.count,
               signedUpTeamsLabels: signedUpTeamsPerDate.dates,
               signedUpTeamsData: signedUpTeamsPerDate.count
           });
        });
    }

    const updateDashboardCards = () => {
        Promise.all([
            getUserCount(),
            getUserConnectedWithPlayerCount(),
            getTeamCount(),
            getActiveTeamCount(),
            getSignedUpTeamsCount(),
        ]).then(([
                     userCount,
                     userWithPlayerCount,
                     teamCount,
                     activeTeamCount,
                     signedUpTeamsCount,
        ]) => {
            setCounts({
                userCount: userCount.MESSAGE,
                userWithPlayerCount: userWithPlayerCount.MESSAGE,
                teamCount: teamCount.MESSAGE,
                activeTeamCount: activeTeamCount.MESSAGE,
                signedUpTeamsCount: signedUpTeamsCount.MESSAGE,
            });
        });
    }

    React.useEffect(() => {
        updateDashboard();
        const interval = setInterval(updateDashboardCards, 1000);
        return () => clearInterval(interval);
    }, [])


    return (
        <Container>
            <Box px={2} pt={1}>
                <Grid container direction="row" spacing={5}>
                    <Grid item>
                        <Card>
                            <CardContent>
                                <Box p={2}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Typography variant="h6" gutterBottom>
                                                Användare
                                            </Typography>
                                            <Typography>
                                                {counts && counts.userCount} Nya användare
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                {counts && counts.userWithPlayerCount} Med koppling till spelare
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Person color="secondary" fontSize="large" />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item>
                        <Card>
                            <CardContent>
                                <Box p={2}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Typography variant="h6" gutterBottom>
                                                Lag
                                            </Typography>
                                            <Typography>
                                                {counts && counts.activeTeamCount} aktiva lag
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" >
                                                {counts && counts.teamCount} lag
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Group color="secondary" fontSize="large" />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/*<Grid item>
            <Card>
              <CardContent>
                <Box p={2}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Typography variant="h6" gutterBottom>
                        Matcher
                      </Typography>
                      <Typography>
                        {2} matcher kvar att rapportera
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {5} matcher utan tid
                      </Typography>
                    </Grid>
                    <Grid item>
                      <ImportContacts color="secondary" fontSize="large"/>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>*/}


                    <Grid item>
                        <Card>
                            <CardContent>
                                <Box p={2}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Typography variant="h6" gutterBottom>
                                                Nya lag
                                            </Typography>
                                            <Typography>
                                                {counts && counts.signedUpTeamsCount} anmälda lag inför Säsong 9
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Add color="secondary" fontSize="large" />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Box pt={3} />
                <Grid container spacing={1}>
                    <Grid item md={6} xs={12}>
                        {(lineData && lineData.playersPerDateLabels && lineData.playersPerDateLabels.length > 0) &&
                        <Line data={data} style={{ background: theme.palette.background.paper }} options={{
                            animations: null,
                            scales: {
                                y: { // defining min and max so hiding the dataset does not change scale range
                                    min: 0,
                                }
                            }
                        }}/>}
                    </Grid>
                    <Grid item md={6} xs={12}>
                        {(lineData && lineData.signedUpTeamsLabels && lineData.signedUpTeamsLabels.length > 0) &&
                        <Line data={dataRegisterTeams} style={{ background: theme.palette.background.paper }} options={{
                            animations: null,
                            scales: {
                                y: { // defining min and max so hiding the dataset does not change scale range
                                    min: 0,
                                }
                            }
                        }}/>}
                    </Grid>
                </Grid>
            </Box>
        </Container>

    );
}