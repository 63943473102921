import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import swedishResources from './resources/locale/se.json';
import englishResources from './resources/locale/en.json';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            se: {
                translations: swedishResources
            },
            en: {
                translations: englishResources
            }
        },
        fallbackLng: 'se',
        debug: false,
        lng: localStorage.getItem('language') || 'se',
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ","
        },
        react: {
            wait: true
        }
    });

export default i18n;