import React from "react";
import Cookies from "universal-cookie";
import {Container} from "@material-ui/core";
import {Redirect, Route, Switch} from "react-router-dom";
import {AllMatches} from "./components/AllMatches";
import {EditMatch} from "./components/EditMatch";

//ID	Hemmalag	Bortalag	Datum	Vecka	Division	Säsong	Status
export const Matches = (props) => {

  const { url } = props.match;

  return (
    <Container>
      <Switch>
        <Route path={`${url}/all`} component={AllMatches} />
        <Route path={`${url}/edit/:id`} component={EditMatch} />
        {/* This route has to be at the bottom */}
        <Route path={`${url}`} exact>
          <Redirect to={`${url}/all`}/>
        </Route>
      </Switch>
    </Container>
  );
}