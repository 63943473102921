import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Box, Grid, Typography, withStyles } from "@material-ui/core";

import { PageTitle } from "../../layout/PageTitle";
import SponsorPaper from "../../layout/components/SponsorPaper";
import DivisionTable from "./components/DivisionTable";
import { DivisionTabs } from "./components/DivisionTabs";
import { Constants } from "../../constants";
import { Link } from "react-router-dom";

const StyledBox = withStyles((theme) => ({
    root: {
        /*boxShadow: '0px -2px 2px rgba(0, 0, 0, 0.5)',
        top: '-1rem',
        position: 'relative',
        height: '20px'*/
    },
}))(Box);

async function getDivisionId(divisionDiscriminator, seasonId) {
    const r = await fetch(`${Constants.apiPrefix}/api/divisionId/${divisionDiscriminator}/season/${seasonId}`);
    return await r.json();
}

async function getDivisionAdmin(divisionId) {
    const r = await fetch(`${Constants.apiPrefix}/api/division/${divisionId}/divisionAdmin`);
    return await r.json();
}

export const Division = (props) => {
    useTranslation();

    const [divisionAdmin, setDivisionAdmin] = React.useState("");

    React.useEffect(() => {
        getDivisionId(props.match.params.divisionDiscriminator, props.match.params.seasonId).then((r) => {
            let divisionId = r.MESSAGE.id;
            getDivisionAdmin(divisionId).then((r) => setDivisionAdmin(r.MESSAGE));
        });
    }, [props.match.params.divisionDiscriminator]);

    return (
        <React.Fragment>
            <PageTitle>
                <Grid container direction="column" alignItems="center" spacing={1}>
                    <Grid item>
                        <Trans>league.division</Trans> {props.match.params.divisionDiscriminator}
                    </Grid>
                    <Grid item>
                        <Typography variant={"subtitle1"}>
                            <Trans>league.divisionAdmin</Trans>:{" "}
                            {divisionAdmin.playerId ? (
                                <Link to={`/player/${divisionAdmin.playerId}`} className={"srl-link"}>
                                    {divisionAdmin.nickname}
                                </Link>
                            ) : (
                                divisionAdmin.nickname
                            )}
                        </Typography>
                    </Grid>
                </Grid>
            </PageTitle>
            <SponsorPaper>
                <Box pt={1} />
            </SponsorPaper>
            <Box my={5} />
            <DivisionTable seasonId={props.match.params.seasonId} divisionDiscriminator={props.match.params.divisionDiscriminator} currentSeasonId={props.currentSeasonId} />
            <Box my={5} />
            <DivisionTabs seasonId={props.match.params.seasonId} divisionDiscriminator={props.match.params.divisionDiscriminator} />
            <Box my={10} />
        </React.Fragment>
    );
};
