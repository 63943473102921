import React from "react";
import { useContext } from "react";
import Button from "@material-ui/core/Button";
import { Box, Checkbox, CircularProgress, FormControlLabel, Grid, makeStyles, MenuItem, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Trans } from "react-i18next";
import { Constants } from "../../../../../constants";
import { TextField } from "../../../../../components/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { SnackbarContext } from "../../../../../context/SnackbarProvider";
import Cookies from "universal-cookie";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default function StreamAndDateDialog(props) {
    const { seriesId, updateSeries } = props;
    const classes = useStyles();
    const cookies = new Cookies();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [stream, setStream] = React.useState(false);
    const [matchdate, setMatchdate] = React.useState();
    const { setSnackbar } = useContext(SnackbarContext);

    // Functions
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChecked = (event) => {
        setStream(event.target.checked);
    };

    const handleDateTimeChanged = (event) => {
        setMatchdate(event.target.value);
    };

    const getSeries = async (seriesId) => {
        const r = await fetch(`${Constants.apiPrefix}/api/series/${seriesId}/row`);
        return await r.json();
    };

    const saveStreamAndTime = async (seriesId, stream, matchdate) => {
        const r = await fetch(`${Constants.apiPrefix}/api/admin/series/saveStreamAndTime`, {
            method: "POST",
            body: JSON.stringify({
                accessToken: cookies.get("accessToken"),
                seriesId: seriesId,
                stream: stream,
                matchdate: matchdate,
            }),
        });
        return await r.json();
    };

    const clickSave = () => {
        setLoading(true);
        saveStreamAndTime(seriesId, stream, matchdate).then((r) => {
            updateSeries().then(() => {
                if (r.hasOwnProperty("CODE") && r.CODE === 200) {
                    setSnackbar({
                        open: true,
                        severity: "success",
                        message: r.MESSAGE,
                    });
                } else if (r.hasOwnProperty("CODE") && r.CODE !== 500) {
                    setSnackbar({
                        open: true,
                        severity: "error",
                        message: r.MESSAGE,
                    });
                } else {
                    throw new Error(r.MESSAGE);
                }
                setLoading(false);
                handleClose();
            });
        });
    };

    // Effects
    React.useEffect(() => {
        if (open) {
            getSeries(seriesId).then((r) => {
                if (r.MESSAGE.matchdate !== null) {
                    let date = new Date(r.MESSAGE.matchdate);
                    let correctDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
                    const dateString = new Date(correctDate).toISOString();
                    setMatchdate(dateString.substring(0, dateString.length - 5));
                }
                setStream(r.MESSAGE.stream === "1");
            });
        }
    }, [open]);

    return (
        <div>
            <MenuItem key={`information`} onClick={handleClickOpen}>
                <Trans>admin.matches.set-stream-and-time</Trans>
            </MenuItem>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
                <DialogTitle id="form-dialog-title">Stream och serietid</DialogTitle>
                <DialogContent>
                    <FormControlLabel control={<Checkbox checked={stream} onChange={handleChecked} name="stream" />} label="Stream" />
                    <TextField
                        id="datetime-local"
                        fullWidth
                        label="Serietid"
                        type="datetime-local"
                        onChange={handleDateTimeChanged}
                        value={matchdate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="primary">
                        Tillbaka
                    </Button>
                    <div className={classes.wrapper}>
                        <Button disabled={loading} onClick={clickSave} color="secondary" variant="contained">
                            Spara
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}
