import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "330px",
        height: "280px",
        [theme.breakpoints.up("sm")]: {
            width: "800px",
            height: "450px"
        }
    },
    wrap: {
        overflowWrap: "break-word",
        textAlign: "center",
        fontSize: "1.8em"
    },
    blockCenter: {
        display: "block",
        textAlign: "center",
        width: "150px",
        margin: "20px auto"
    }
}));

export const TwitchEmbed = (props) => {

    const classes = useStyles();

    const {twitchTitle} = props
    console.log(twitchTitle);
    return(
        <div id="twitch-embed">
            <Button color="secondary" variant="contained" className={classes.blockCenter} href="https://svenskaraketligan.se/member" target="_blank">Bli medlem</Button>
            <iframe className={classes.root}
                src="https://player.twitch.tv/?channel=svenskaraketligan&parent=svenskaraketligan.se&muted=true"
                allowfullscreen>
            </iframe>
            <h1 class="twitchTitle" className={classes.wrap}>Svenska raketligan är live!</h1>
        </div>

    );
}


  
    
