import React from "react";
import { Container, Divider, Grid,Paper,Button, FormControl,FormLabel,FormControlLabel, Radio, TextField, Box, Checkbox } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {useParams} from "react-router-dom";
import { NewsService} from "../../../../../services/NewsService";
import { Constants } from "../../../../../constants";
import ReactMde from "react-mde";
import { RadioGroup } from "./../../../../../components/RadioGroup";
import * as Showdown from "showdown";
import Cookies from 'universal-cookie';
import {useTranslation} from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import { CheckBox, Label } from "@material-ui/icons";
import { UploadImage } from "../../../../../components/UploadImage";
import { ImageService } from "../../../../../services/ImageService";


export const EditNews = () => {
    
    
    const cookies = new Cookies();
    const { t } = useTranslation();
    const { id } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [news, setNews] = React.useState();
    const [categories, setCategories] = React.useState([]);
    const [selectedTab, setSelectedTab] = React.useState("write");
    const [saved, setSaved] = React.useState(false);
    const [enableDelete, setEnableDelete] = React.useState(false);
    const [success, setSuccess] = React.useState({
        severity: undefined,
        text: undefined,
    });

    const converter = new Showdown.Converter({
        tables: true,
        simplifiedAutoLink: true,
        strikethrough: true,
        tasklists: true
    });

    const useStyles = makeStyles((theme) => ({
        resetContainer: {
            padding: theme.spacing(3),
        },
        deleteButton: {
            float: "right",
        }
    }));
    const classes = useStyles();


    const deleteNews = (event) => {
        event.preventDefault();

        NewsService.delete(cookies.get("accessToken"), id).then(response => {
            if (response.hasOwnProperty("CODE") && response.hasOwnProperty("MESSAGE") && response.CODE === 200) {
                setSuccess({
                    severity: "success",
                    text: t(response.MESSAGE)
                });
            } else if(response.hasOwnProperty("CODE") && response.CODE !== 500){
                setSuccess({
                    severity: "error",
                    text: t(response.MESSAGE)
                });
            } else {
                throw new Error("admin.news.components.AddNews.save");
            }
            setSaved(true);
        });
    }


    const save = (event) => {
        event.preventDefault();
        NewsService.update(cookies.get("accessToken"), news).then(response => {
            if (response.hasOwnProperty("CODE") && response.hasOwnProperty("MESSAGE") && response.CODE === 200) {
                setSuccess({
                    severity: "success",
                    text: t(response.MESSAGE)
                });
            } else if(response.hasOwnProperty("CODE") && response.CODE !== 500){
                setSuccess({
                    severity: "error",
                    text: t(response.MESSAGE)
                });
            } else {
                throw new Error("admin.news.components.AddNews.save");
            }
            setSaved(true);
        });
    }
    
    if(id && !news) {
            fetch(Constants.apiPrefix + `/api/admin/news/categories/get`,)
            .then(response => response.json())
            .then(response => {
                setCategories(response);
            });


            NewsService.fetch(id).then(response => {
                setNews(response);
                setLoading(false);
            });
    }

    return (
        loading ? <></> : 
        <Container>
            <Grid container>
                <Grid item xs={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Kategori:</FormLabel>
                    <RadioGroup aria-label="Kategori" name="category" value={news.category} onChange={(e) => setNews({...news, category:parseInt(e.target.value)})}>
                        {categories.map((x, index) => (
                            <FormControlLabel key={index} value={x.id} control={<Radio />} label={x.name} />
                        ))}
                    </RadioGroup>
                </FormControl>
                <Divider variant="middle" style={{width: '100%', margin: '10px 0'}} />                    
                </Grid>
                <Grid item xs={12}>
                <TextField
                        fullWidth
                        id="standard-basic"
                        label="Titel"
                        value={news.title}
                        onChange={(e) => setNews({...news, title:e.target.value})}
                        />
                <Divider variant="middle" style={{width: '100%', margin: '10px 0'}} />
                <TextField
                        fullWidth
                        id="standard-basic"
                        label="Custom URL"
                        value={news.customUrl}
                        onChange={(e) => setNews({...news,customUrl:e.target.value})}
                        helperText="t.ex ''it-avdelningen-ar-bast''"
                        />
                <Divider variant="middle" style={{width: '100%', margin: '10px 0'}} />
                <UploadImage
                    fullWidth
                    defaultImage={news.thumbnail && ImageService.transformImageUrl(news.thumbnail)}
                    croppedImageState={[news && news.thumbnail, (val)=>{
                        setNews({...news,thumbnail:val});
                    }]}
                    displayHeight={700}
                    displayWidth={1000}
                    width={1000}
                    height={700}
                    label='Thumbnail'
                    showValueAsText={true}
                />


                <Divider variant="middle" style={{width: '100%', margin: '10px 0'}} />
                <UploadImage
                    defaultImage={news.image && ImageService.transformImageUrl(news.image)}
                    croppedImageState={[news && news.image, (val)=>{
                        setNews({...news,image:val});
                    }]}
                    displayHeight={250}
                    displayWidth={1000}
                    width={1000}
                    height={250}
                    label='Bild'
                    showValueAsText={true}
                />
                <Divider variant="middle" style={{width: '100%', margin: '10px 0'}} />
                <ReactMde 
                    value={news.text}
                    onChange={(value) => setNews({...news,text:value})}
                    selectedTab={selectedTab}
                    onTabChange={setSelectedTab}
                    generateMarkdownPreview={markdown => 
                        Promise.resolve(converter.makeHtml(markdown))
                    }
                />

                <Divider variant="middle" style={{width: '100%', margin: '10px 0'}} />
                <TextField
                        fullWidth
                        id="publish-date"
                        label="Publiceringsdatum"
                        value={news.publishDate}
                        onChange={(e) => setNews({...news,publishDate:e.target.value})}
                        helperText="yyyy-mm-dd hh:mm:ss"
                        />
                </Grid>
            </Grid>
            <Box pt={2} />
            <Grid container >
                <Grid item xs={6}>
                    <Button variant="contained" color="secondary" onClick={save}>Spara nyhet</Button>
                </Grid>
                <Grid item xs={6}>
                    <Checkbox id="enableDelete" className={classes.deleteButton} value={enableDelete} onChange={setEnableDelete} />
                    
                    <Button disabled={!enableDelete} className={classes.deleteButton} variant="contained" color="secondary" onClick={deleteNews}>Ta bort nyhet</Button>
                </Grid>
            </Grid>
            {saved && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Alert severity={success.severity}>
                        {success.text}
                    </Alert>
                </Paper>
            )}
        </Container>
    );
}