import React from "react";
import { useContext } from "react";
import Button from "@material-ui/core/Button";
import { Box, CircularProgress, Grid, makeStyles, MenuItem, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Trans } from "react-i18next";
import { Constants } from "../../../../../constants";
import { TextField } from "../../../../../components/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { SnackbarContext } from "./../../../../../context/SnackbarProvider";
import Cookies from "universal-cookie";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

async function getAllDivisionAdmins() {
    const r = await fetch(`${Constants.apiPrefix}/api/users/role/2`); // 2 is division admin
    return await r.json();
}

export default function InformationFormDialog(props) {
    const { division } = props;
    const classes = useStyles();
    const cookies = new Cookies();
    const [open, setOpen] = React.useState(false);
    const [divisionName, setDivisionName] = React.useState(division.division);
    const [divisionAdmins, setDivisionAdmins] = React.useState([]);
    const [selectedDivisionAdmin, setSelectedDivisionAdmin] = React.useState({ id: division.userId, nickname: division.nickname });
    const [loading, setLoading] = React.useState(false);
    const [loadingAddRoles, setLoadingAddRoles] = React.useState(false);
    const { setSnackbar } = useContext(SnackbarContext);

    // Functions
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const saveDivisionInfo = async () => {
        const r = await fetch(`${Constants.apiPrefix}/api/division/saveDivisionInfo`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                accessToken: cookies.get("accessToken"),
                divisionId: division.divisionId,
                divisionName: divisionName,
                divisionAdminUserId: selectedDivisionAdmin ? selectedDivisionAdmin.id : null,
            }),
        });
        return await r.json();
    };

    const clickSave = () => {
        setLoading(true);
        saveDivisionInfo().then((r) => {
            if (r.hasOwnProperty("CODE") && r.CODE === 200) {
                setSnackbar({
                    open: true,
                    severity: "success",
                    message: r.MESSAGE,
                });
            } else if (r.hasOwnProperty("CODE") && r.CODE !== 500) {
                setSnackbar({
                    open: true,
                    severity: "success",
                    message: r.MESSAGE,
                });
            } else {
                throw new Error(r.MESSAGE);
            }
            setLoading(false);
            handleClose();
        });
    };

    const addRoles = async () => {
        const r = await fetch(`${Constants.apiPrefix}/api/division/addRoleToAllPlayers`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                accessToken: cookies.get("accessToken"),
                divisionId: division.divisionId,
            }),
        });
        return await r.json();
    };

    const clickAddRoles = () => {
        setLoadingAddRoles(true);
        addRoles().then((r) => {
            if (r.hasOwnProperty("CODE") && r.CODE === 200) {
                setSnackbar({
                    open: true,
                    severity: "success",
                    message: r.MESSAGE,
                });
            } else if (r.hasOwnProperty("CODE") && r.CODE !== 500) {
                setSnackbar({
                    open: true,
                    severity: "success",
                    message: r.MESSAGE,
                });
            } else {
                throw new Error(r.MESSAGE);
            }
            setLoadingAddRoles(false);
        });
    };

    // Effects
    React.useEffect(() => {
        getAllDivisionAdmins().then((r) => setDivisionAdmins(r.MESSAGE));
    }, [division]);

    return (
        <div>
            <MenuItem key={`information`} onClick={handleClickOpen}>
                <Trans>admin.division.information</Trans>
            </MenuItem>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
                <DialogTitle id="form-dialog-title">Divisionsinställningar</DialogTitle>
                <DialogContent>
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <TextField
                                fullWidth
                                label={"Divisionsnamn"}
                                defaultValue={divisionName}
                                value={divisionName}
                                onChange={(event) => setDivisionName(event.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                value={selectedDivisionAdmin}
                                id="divisionAdmin"
                                options={divisionAdmins}
                                getOptionLabel={(divisionAdmin) => divisionAdmin.nickname}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderOption={(divisionAdmin) => divisionAdmin.nickname}
                                onChange={(event, selectedDivisionAdmin) => {
                                    setSelectedDivisionAdmin(selectedDivisionAdmin);
                                }}
                                renderInput={(params) => <TextField fullWidth {...params} label={"Divisionsansvarig"} />}
                            />
                        </Grid>
                        <Grid item>
                            <Typography>Divisionsroller i Discord</Typography>
                            <Box mt={1} />
                            <div className={classes.wrapper}>
                                <Button disabled={loadingAddRoles} onClick={clickAddRoles} color="secondary" variant="contained" fullWidth>
                                    Lägg till divisionsroller till alla användare
                                </Button>
                                {loadingAddRoles && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="primary">
                        Tillbaka
                    </Button>
                    <div className={classes.wrapper}>
                        <Button disabled={loading} onClick={clickSave} color="secondary" variant="contained">
                            Spara
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}
