import React from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Toolbar,
  Typography
} from "@material-ui/core";
import {TextField} from "../../../../../components/TextField";
import Grid from "@material-ui/core/Grid";
import {Constants} from "../../../../../constants";
import {Cookies} from "react-cookie";
import {SnackbarContext} from "../../../../../context/SnackbarProvider";
import Box from "@material-ui/core/Box";
import {useTranslation} from "react-i18next";

async function getAllSeasons() {
  const r = await fetch(`${Constants.apiPrefix}/api/season/all`);
  return await r.json();
}

async function postDivision(accessToken, divisionName, seasonId) {
  const r = await fetch(`${Constants.apiPrefix}/api/division/create`, {
    method: "POST",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      accessToken: accessToken,
      divisionName: divisionName,
      seasonId: seasonId,
    }),
  });
  return await r.json();
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));


export const AddDivision = (props) => {
  const classes = useStyles();
  const cookies = new Cookies();
  const { setSnackbar } = React.useContext(SnackbarContext);
  const { t } = useTranslation();
  const [season, setSeason] = React.useState();
  const [seasons, setSeasons] = React.useState();
  const [divisionName, setDivisionName] = React.useState();

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getAllSeasons().then(r => {
      setSeasons(r);
      setSeason(r[0].id);
    });
  }, []);

  const addDivision = async () => {
    setLoading(true);
    if(divisionName === undefined || divisionName.length === 0 || season === undefined) {
      setSnackbar({
        open: true,
        severity: "info",
        message: "Fyll i fälten korrekt"
      });
      setLoading(false);
      return;
    }
    postDivision(cookies.get("accessToken"), divisionName, season).then(r => {
        if(r.hasOwnProperty("CODE") && r.CODE === 200) {
          setSnackbar({
            open: true,
            severity: "success",
            message: t(r.MESSAGE)
          });
        } else if(r.hasOwnProperty("CODE") && r.CODE !== 500) {
          setSnackbar({
            open: true,
            severity: "error",
            message: t(r.MESSAGE)
          });
        } else {
          throw new Error("admin.divisions.components.AddDivision.js");
        }
        setLoading(false);
    })
  }

  const isValid = () => {
    return divisionName !== undefined && divisionName.length > 0 && season !== undefined;
  }

  return (
    <Box marginX={10} marginTop={5}>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <Typography>
            Skapa en division med namn och vilken säsong den ska tillhöra, divisionsansvarig samt färger i tabellen ändrar man i en redan skapad division
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={divisionName}
            label={"Divisions namn"}
            onChange={(event) => {
              setDivisionName(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth style={{ minWidth: 120 }} key={season}>
            <Select
              variant="outlined"
              inputProps={{
                id: "seasonId",
                name: "seasonId",
              }}
              value={season}
              onChange={(event) => {
                setSeason(event.target.value);
              }}
            >
              {seasons && seasons.map((s) => (
                <MenuItem value={s.id}>{s.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.wrapper}>
            <Button fullWidth onClick={addDivision} disabled={loading || !isValid()} color="secondary" variant="contained">
              Skapa division
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}