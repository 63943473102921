import React, { useContext } from "react";
import {
    AppBar, Avatar,
    Box,
    Card, CardActions,
    CardContent, Chip,
    Container, Divider,
    Grid, Icon, IconButton,
    ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Paper,
    Tab,
    Tabs, TextField,
    Typography
} from "@material-ui/core";
import { PageTitle } from "../../layout/PageTitle";
import { Alert, Autocomplete } from "@material-ui/lab";
import { Constants } from "../../constants";
import { StateContext, UserContext } from "../../discord/StateProvider";
import Cookies from "universal-cookie";
import SponsorPaper from "../../layout/components/SponsorPaper";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import { useParams } from "react-router";
import { Redirect } from "react-router-dom";
import { UserPage } from "./components/UserPage";
import { UserSettings } from "./components/UserSettings";
import { InPageCircularLoader } from "../../layout/components/InPageCircularLoader";
import {Trans, useTranslation} from "react-i18next";


async function getUserWithPlayer(playerId) {
    const response = await fetch(new URL(`/api/user/${playerId}/full`, Constants.apiPrefix).toString());
    return await response.json();
}

export const User = (props) => {
    let { path, url } = useRouteMatch();
    const theme = useTheme();
    const { playerId } = useParams();
    useTranslation();

    if (playerId === "NoPlayerConnected") {
        return (
            <>
                <PageTitle />
                <SponsorPaper>
                    <Box pt={1} />
                </SponsorPaper>
                <Box my={5} />
                <Container>
                    <Typography variant="h5">
                        <Trans>
                            user.no-player-connected.title
                        </Trans>
                    </Typography>
                    <Box my={2} />
                    <Alert severity="warning" variant="outlined">
                        <Typography>
                            <Trans>
                                user.no-player-connected.text
                            </Trans>
                        </Typography>
                    </Alert>
                </Container>
                <Box my={10} />
            </>
        )
    }

    const { currentSeasonId } = props;
    if (!playerId) {
        return <Redirect to={"/"} />
    }
    const [player, setPlayer] = React.useState();
    // Cookie and user
    const cookies = new Cookies();
    const {
        currentUser,
        updateAll
    } = useContext(StateContext);

    React.useEffect(() => {
        getUserWithPlayer(playerId).then(response => setPlayer(response))
    }, [playerId])

    React.useEffect(() => {
        updateAll(cookies.get("accessToken")).catch(error => console.log(error))
    }, [])

    let displayName;
    if (player) {
        if (player.nickname !== null) {
            displayName = player.nickname;
        } else if(player.pNickname) {
            displayName = player.pNickname;
        } else if (player.discordId !== null) {
            displayName = `${player.discordUsername}#${player.discordDiscriminator}`;
        } else {
            displayName = player.discord;
        }
    }

    if (player) {
        return (
            <>
                <PageTitle shadow backgroundImage={player.banner && `${Constants.apiPrefix}/api/${player.banner}`}>
                    <Grid container alignItems={"center"} spacing={2}>
                        {(player.avatar || player.discordId) && (
                            <Grid item>
                                <Avatar
                                    alt=""
                                    style={{ height: 80, width: 80 }}
                                    src={player && `${Constants.apiPrefix}/api/${player.avatar}`}
                                />
                            </Grid>
                        )}
                        <Grid item>
                            {displayName}
                        </Grid>
                    </Grid>
                </PageTitle>
                <SponsorPaper>
                    <Box pt={1} />
                </SponsorPaper>
                <Box my={2} />

                <Container>
                    <Switch>
                        <Route path={path} exact>
                            {<UserPage url={url} player={player} currentUser={currentUser} currentSeasonId={currentSeasonId} />}
                        </Route>
                        {(currentUser && player.pId === currentUser.playerId) && (
                            <Route path={`${path}/settings`}>
                                <UserSettings url={url} />
                            </Route>
                        )}
                    </Switch>
                </Container>
                <Box my={10} />
            </>
        )
    }
    return (
        <InPageCircularLoader />
    )
}