import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';
import { Box, useTheme } from '@material-ui/core';
import { Link } from "react-router-dom";
import { ImageService } from '../../../services/ImageService';

const useStyles = makeStyles({
    card: {
        display: 'flex',
    },
    cardDetails: {
        flex: 1,
    },
    cardMedia: {
        width: 160,
    },
});

export const FeaturedPost = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const { post } = props;

    return (
        <Grid item xs={props.xs == undefined ? 12 : props.xs} md={props.md == undefined ? 6 : props.md}>
            <CardActionArea component={Link} to={`/post/${post.customUrl}`}>
                <Card className={classes.card} style={{ backgroundColor: theme.palette.primary.main }} {...props}>
                    <div className={classes.cardDetails}>
                        <CardContent>
                            <Typography variant="subtitle1">
                                {post.title}
                            </Typography>
                            <Box my={1}>
                                <Typography variant="caption" color="textSecondary">
                                    {post.datetime.length > 0 && post.datetime.substring(0, post.datetime.length - 3)}
                                </Typography>
                            </Box>
                            <Typography variant="body2" paragraph style={{textOverflow: "ellipsis", overflow: "hidden"}}>
                                {post.description}
                            </Typography>
                        </CardContent>
                    </div>
                    <Hidden xsDown>
                        <CardMedia className={classes.cardMedia} image={ImageService.transformImageUrl(post.thumbnail)} title={post.imageTitle} />
                    </Hidden>
                </Card>
            </CardActionArea>
        </Grid>
    );
}

FeaturedPost.propTypes = {
    post: PropTypes.object,
};