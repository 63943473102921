import React from "react";
import { Button, Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

import { Constants } from "./../../../../../constants";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
}));

export const NewsList = () => {
    const classes = useStyles();

    const [news, setNews] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        fetch(Constants.apiPrefix + `/api/admin/news/get`,)
        .then(response => response.json())
        .then(response => {
            setNews(response);
            setLoading(false);
        })
    }, []);

    return (
        loading ? <></> : 
        <Container>
            <Grid container justify="space-between" direction="row" alignItems="stretch" className={classes.root}>
                <Grid item>
                    <Button variant="contained" color="secondary" size="small" component={Link} to="/admin/news/add">Skapa nyhet</Button>
                </Grid>
                <Grid item>
                    <Button variant="outlined" color="secondary" size="small" component={Link} to="/admin/news/category/add">Skapa kategori</Button>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Titel</TableCell>
                            <TableCell>Skapad</TableCell>
                            <TableCell>Kategori</TableCell>
                            <TableCell>Författare</TableCell>
                            <TableCell>CustomURL</TableCell>
                            <TableCell>Publicerad</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {news.map(x => (
                            <TableRow key={x.id}>
                                <TableCell>{x.title}</TableCell>
                                <TableCell>{x.datetime}</TableCell>
                                <TableCell>{x.category}</TableCell>
                                <TableCell>{x.author}</TableCell>
                                <TableCell>{x.customUrl}</TableCell>
                                <TableCell>{x.publishDate}</TableCell>
                                <TableCell>
                                    <Button variant="contained" color="secondary" size="small" component={Link} to={`/admin/news/edit/${x.id}`}>Redigera</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}