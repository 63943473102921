import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Box, Tab, Tabs, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { AiFillFire } from "react-icons/all";
import { Link } from "react-router-dom";

import { Constants } from "../../../constants";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other}>
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
}));

const rankColorMap = {
    s: Constants.rankSColor,
    x: Constants.rankXColor,
    a: Constants.rankAColor,
    b: Constants.rankBColor,
    c: Constants.rankCColor,
    d: Constants.rankDColor,
    e: Constants.rankEColor,
};

export default function SixMansTabs(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const { leaderboards } = props;
    useTranslation();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        if (leaderboards) {
            const keys = ["s", "x", "a", "b", "c", "d", "e"];
            for (let i = 0; i < keys.length; i++) {
                if (leaderboards[keys[i]].length > 0) {
                    setValue(i);
                    break;
                }
            }
        }
    }, []);

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs value={value} onChange={handleChange} indicatorColor="secondary" variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
                    {Object.entries(leaderboards).map(([key, leaderboard], index) => (
                        <Tab
                            disabled={leaderboard.length === 0}
                            style={{ color: leaderboard.length > 0 ? rankColorMap[key] : "grey" }}
                            label={`Rank ${key.toUpperCase()}`}
                            {...a11yProps(index)}
                        />
                    ))}
                </Tabs>
            </AppBar>
            {Object.entries(leaderboards).map(([key, leaderboard], index) => (
                <TabPanel value={value} index={index}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>
                                        <Trans>league.player</Trans>
                                    </TableCell>
                                    <TableCell>
                                        <Trans>sixmans.played-matches</Trans>
                                    </TableCell>
                                    <TableCell>
                                        <Trans>sixmans.won</Trans>
                                    </TableCell>
                                    <TableCell>
                                        <Trans>sixmans.lost</Trans>
                                    </TableCell>
                                    <TableCell>
                                        <Trans>sixmans.elo</Trans>
                                    </TableCell>
                                    <TableCell>
                                        <Trans>sixmans.elo_peak</Trans>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Trans>sixmans.streak</Trans>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Trans>sixmans.best-streak</Trans>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {leaderboard.length > 0 ? (
                                    leaderboard.map((player, index) => (
                                        <TableRow>
                                            <TableCell>{index + 1}.</TableCell>
                                            <TableCell>
                                                {player.playerId !== null ? (
                                                    <Link to={`/player/${player.playerId}`} className="srl-link">
                                                        {player.nickname}
                                                    </Link>
                                                ) : (
                                                    player.nickname
                                                )}
                                            </TableCell>
                                            <TableCell>{player.games}</TableCell>
                                            <TableCell>{player.win}</TableCell>
                                            <TableCell>{player.loss}</TableCell>
                                            <TableCell>{player.elo}</TableCell>
                                            <TableCell>{player.elo_peak}</TableCell>
                                            <TableCell align="right">
                                                {parseInt(player.streak) > 0 ? (
                                                    <span style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", color: "orangered" }}>
                                                        <AiFillFire size={20} />
                                                        {player.streak}
                                                    </span>
                                                ) : (
                                                    <span style={{ color: "lightskyblue" }}>0</span>
                                                )}
                                            </TableCell>
                                            <TableCell align="right">{player.bstreak}</TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={8}>
                                            <Trans>sixmans.no-players-in-this-rank</Trans>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
            ))}
        </div>
    );
}
