import React from 'react';
import {Grid, Slider, TextField, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { useTheme } from "@material-ui/core/styles";
import AvatarEditor from 'react-avatar-editor'
import Cookies from "universal-cookie";
import Resizer from "react-image-file-resizer";
import { Trans } from "react-i18next";

const resizeImage = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1500,
            1500,
            "PNG",
            80,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

export const UploadImage = (props) => {

    const dialogId = `Dialog-${Math.random().toString(36).substring(7)}`;

    const theme = useTheme();
    const cookies = new Cookies();
    // react easy crop settings
    const [src, setSrc] = React.useState();

    const [croppedBlob, setCroppedBlob] = props.croppedImageState;
    const [croppedImage, setCroppedImage] = React.useState(null);

    const [editor, setEditor] = React.useState();

    const [open, setOpen] = React.useState(false);
    const [sliderValue, setSliderValue] = React.useState(1);

    const showValueAsText = props.showValueAsText || false;
    const label = props.label || '';


    let height = props.height ? props.height : 350;
    let width = props.width ? props.width : 350;
    let displayHeight = props.displayHeight ? props.displayHeight : 350;
    let displayWidth = props.displayWidth ? props.displayWidth : 350;
    let borderRadius = props.circle ? 1000 : 0
    let disabled = props.disabled !== undefined ? props.disabled : false;


    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                setSrc(reader.result)
            );
            reader.readAsDataURL(e.target.files[0]);
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAcceptClose = () => {
        onClickSave();
        setOpen(false);
    }

    const onClickSave = () => {
        if (editor) {
            // This returns a HTMLCanvasElement, it can be made intoURL o a data r a blob,
            // drawn on another canvas, or added to the DOM.
            editor.getImage().toBlob((blob) => {
                resizeImage(blob).then(b => setCroppedBlob(b));
                setCroppedImage(editor.getImage().toDataURL());
            });

            //setCroppedImage(editor.getImage().toDataURL('image/png'))
        }
    }

    const setEditorRef = (editor) => setEditor(editor)

    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
    };

    let border = (croppedImage === null || croppedImage === undefined) && props.defaultImage === null ? {} : {
        maxHeight: displayHeight,
        maxWidth: displayWidth,
        borderRadius: borderRadius,
    };
    return (
        <>
            <Grid container direction="column" spacing={1} alignItems={props.align === undefined ? "center" : props.align}>
                {label?<Grid item>
                    <Typography variant="h6" gutterBottom>
                        {label}
                    </Typography>
                </Grid>:''}
                <Grid item>
                    {
                        <img
                            src={croppedImage !== null ? croppedImage : props.defaultImage}
                            style={border}
                        />
                    }
                </Grid>
                {showValueAsText?
                <Grid item>
                    <TextField
                            fullWidth
                            type='text'
                            value={croppedBlob || props.defaultImage}
                            onChange={(event) => setCroppedBlob(event.target.value)}

                        />
                </Grid>
                :''}
                <Grid item>
                    <input
                        disabled={disabled}
                        accept="image/*"
                        onChange={onSelectFile}
                        onClick={(event) => {
                            event.target.value = null
                        }}
                        style={{ display: 'none' }}
                        id={`${dialogId}-raised-button-file`}
                        type="file"
                    />
                    <label htmlFor={`${dialogId}-raised-button-file`}>
                        <Button disabled={disabled} fullWidth variant="contained" color="secondary" component="span" >
                            <Trans i18nKey="image.choose" />
                        </Button>
                    </label>
                </Grid>
            </Grid>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby={dialogId}
                aria-describedby="alert-dialog-description"
                maxWidth={"lg"}
            >
                <DialogTitle id={dialogId}>{props.title}</DialogTitle>
                <DialogContent>
                    <Grid container direction="column" alignItems="center">
                        <Grid item>
                            <AvatarEditor
                                ref={setEditorRef}
                                borderRadius={borderRadius}
                                image={src}
                                width={width}
                                height={height}
                                border={5}
                                color={[255, 255, 255, 0.2]} // RGBA
                                scale={sliderValue}
                                rotate={0}
                            />
                        </Grid>
                        <Grid item>
                            <Slider
                                color="secondary"
                                value={sliderValue}
                                onChange={handleSliderChange}
                                max={4}
                                min={0.2}
                                step={0.00001}
                                style={{ minWidth: 300 }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAcceptClose} style={{ backgroundColor: theme.palette.success.main, color: "white" }}>
                        <Trans i18nKey="general.choose" />
                    </Button>
                    <Button onClick={handleClose} autoFocus style={{ backgroundColor: theme.palette.error.main, color: "white" }}>
                        <Trans i18nKey="general.back" />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}