import React, { useContext } from 'react';

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { TextField } from "../../../components/TextField";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    IconButton,
    CircularProgress
} from "@material-ui/core";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import { ArrowBack, Settings } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import { UploadImage } from "../../../components/UploadImage";
import { StateContext } from "../../../discord/StateProvider";
import { Constants } from "../../../constants";
import Cookies from "universal-cookie";
import { Alert, Autocomplete } from "@material-ui/lab";
import { isWidthUp } from "@material-ui/core/withWidth";
import { LoadingButton } from "../../../components/LoadingButton";
import { SocialLinks, TYPE_MOBILE, TYPE_DESKTOP } from 'social-links';
import { useTranslation, Trans } from "react-i18next";
import {swedishCountries} from "../../../components/swedishCountries";
import {SnackbarContext} from "../../../context/SnackbarProvider";

function checkIfValid(sociallinks, settings) {
    return true;
}

export const UserSettings = (props) => {
    const iso3311a2 = require('iso-3166-1-alpha-2');
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const url = props.url;
    const cookies = new Cookies();
    const [avatar, setAvatar] = React.useState();
    const [banner, setBanner] = React.useState();
    const { setSnackbar } = React.useContext(SnackbarContext);
    const currentUser = useContext(StateContext).currentUser;
    const updateUser = useContext(StateContext).updateUser;
    const [settings, setSettings] = React.useState({
        platform: currentUser.platform ? currentUser.platform : '',
        platformId: currentUser.platformId,
        birthdate: currentUser.birthdate,
        nationality: currentUser.nationality,
        twitter: currentUser.twitter,
        instagram: currentUser.instagram,
        twitch: currentUser.twitch,
        tiktok: currentUser.tiktok,
        youtube: currentUser.youtube
    });

    const socialLinks = new SocialLinks();

    const [loading, setLoading] = React.useState(false);

    /*if (!banner) {
      setBanner(currentUser.banner ? new URL(`/api/${currentUser.banner}`, Constants.apiPrefix) : new URL("/public/images/background.png", Constants.apiPrefix).toString())
    }
    if(!avatar) {
      setAvatar(currentUser.avatar ? new URL(`/api/${currentUser.avatar}`, Constants.apiPrefix) : "https://cdn.discordapp.com/avatars/" + currentUser.discordId + "/" + currentUser.discordAvatar)
    }*/

    /*const saveSettings = (event, callback) => {
      if(checkIfValid(socialLinks, settings)) {
        fetch(new URL(`/api/user/saveSettings`, Constants.apiPrefix).toString(), {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            accessToken: cookies.get("accessToken"),
            ...settings,
          }),
        }).then(response => response.json())
          .then(response => {
            if(response.CODE === 200) {
              setSnackbar({
                open: true,
                severity: "success",
                message: response.MESSAGE
              });
              updateUser(cookies.get("accessToken"));
            } else {
              setSnackbar({
                open: true,
                severity: "error",
                message: response.MESSAGE
              });
            }
            callback();
          })
      } else {
        console.log("is not valid");
        callback();
      }
    }*/

    const saveImageSettings = (event, callback) => {
        if (avatar || banner) {
            fetch(new URL(`/api/user/saveImageSettings`, Constants.apiPrefix).toString(), {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    accessToken: cookies.get("accessToken"),
                    avatar: avatar,
                    banner: banner,
                }),
            }).then(response => response.json())
                .then(response => {
                    if (response.hasOwnProperty("CODE") && response.CODE === 200) {
                        setSnackbar({
                            open: true,
                            severity: "success",
                            message: t(response.MESSAGE)
                        });
                        updateUser(cookies.get("accessToken"));
                    } else if(response.hasOwnProperty("CODE") && response.CODE !== 500) {
                        setSnackbar({
                            open: true,
                            severity: "error",
                            message: t(response.MESSAGE)
                        });
                    } else {
                        throw new Error("react.user.UserSettings.saveImageSettings");
                    }
                    callback();
                })
        } else {
            setSnackbar({
                open: true,
                severity: "info",
                message: t("team-settings.change-picture")
            });
            callback();
        }
    }

    //const twitterRegExp = new RegExp('^(?:https://)?(?:www\\.)?twitter\\.com/(\\w+)$', 'i');
    //const youtubeRegExp = new RegExp('(?:https?:\\/\\/)?(?:www\\.)?youtu\\.?be(?:\\.com)?\\/?.*(?:watch|embed)?(?:.*v=|v\\/|\\/)([\\w\\-_]+)\\&?', 'i');


    const handleSettingsChange = (event) => {
        const name = event.target.name;
        setSettings({
            ...settings,
            [name]: event.target.value,
        });
    };

    return (
        <>
            <Grid container direction="row-reverse">
                <Grid item>
                    <RouterLink to={`${url}`} style={{ color: theme.palette.text.primary }}>
                        <IconButton>
                            <ArrowBack />
                        </IconButton>
                    </RouterLink>
                </Grid>
            </Grid>
            <Typography variant="h4">
                <Trans>user-settings.title</Trans>
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item key={"nickname"}>
                            <TextField
                                disabled
                                margin="dense"
                                inputProps={{
                                    name: 'nickname',
                                    id: 'nickname',
                                    maxLength: 34
                                }}
                                label={t("user-settings.playername")}
                                value={currentUser.nickname}
                                fullWidth
                                onChange={() => { }}
                            />
                        </Grid>
                        <Grid item key={"platform"}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    <Trans i18nKey="login.form.platform" />
                                </InputLabel>
                                <Select
                                    disabled
                                    labelId="demo-simple-select-label"
                                    inputProps={{
                                        name: 'platform',
                                        id: 'platform',
                                    }}
                                    value={settings.platform}
                                    onChange={handleSettingsChange}>
                                    <MenuItem value={"Epic Games"}>Epic Games</MenuItem>
                                    <MenuItem value={"Steam"}>Steam</MenuItem>
                                    <MenuItem value={"Playstation Network"}>Playstation Network</MenuItem>
                                    <MenuItem value={"Xbox Live"}>Xbox Live</MenuItem>
                                    <MenuItem value={"Nintendo Switch"}>Nintendo Switch</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item key={"platformId"}>
                            <TextField
                                disabled
                                margin="dense"
                                inputProps={{
                                    name: 'platformId',
                                    id: 'platformId',
                                    maxLength: 100,
                                }}
                                label={t("login.form.platform-id")}
                                value={settings.platformId}
                                fullWidth
                                onChange={handleSettingsChange}
                            />
                        </Grid>
                        <Grid item key={"birthdate"}>
                            <TextField
                                disabled
                                fullWidth
                                label={t("login.form.birthdate")}
                                type="date"
                                value={settings.birthdate}
                                onChange={handleSettingsChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    name: 'birthdate',
                                    id: "birthdate",
                                    maxLength: 100,
                                }}
                            />
                        </Grid>
                        <Grid item key={"nationality"}>
                            <Autocomplete
                                disabled
                                id="nationalitySelect"
                                value={[settings.nationality, iso3311a2.getCountry(settings.nationality)]}
                                options={Object.entries(iso3311a2.getData())}
                                getOptionLabel={(option) => i18n.language === "en" ? option[1] : swedishCountries[option[0]]}
                                onChange={(event, country) => {
                                    setSettings({
                                        ...settings,
                                        nationality: country[0],
                                    });
                                }}
                                renderInput={(params) => <TextField {...params} label={t("login.form.nationality")} />}
                            />
                        </Grid>
                        {/*
            <Grid item key={"twitter"}>
              <TextField
                margin="dense"
                inputProps={{
                  name: 'twitter',
                  id: 'twitter',
                  maxLength: 100,
                }}
                label="Twitter"
                value={settings.twitter}
                fullWidth
                onChange={handleSettingsChange}
              />
            </Grid>
            <Grid item key={"instagram"}>
              <TextField
                margin="dense"
                inputProps={{
                  name: 'instagram',
                  id: 'instagram',
                  maxLength: 100,
                }}
                label="Instagram"
                value={settings.instagram}
                fullWidth
                onChange={handleSettingsChange}
              />
            </Grid>
            <Grid item key={"twitch"}>
              <TextField
                margin="dense"
                inputProps={{
                  name: 'twitch',
                  id: 'twitch',
                  maxLength: 100,
                }}
                label="Twitch"
                value={settings.twitch}
                fullWidth
                onChange={handleSettingsChange}
              />
            </Grid>
            <Grid item key={"tiktok"}>
              <TextField
                margin="dense"
                inputProps={{
                  name: 'tiktok',
                  id: 'tiktok',
                  maxLength: 100,
                }}
                label="Tiktok"
                value={settings.tiktok}
                fullWidth
                onChange={handleSettingsChange}
              />
            </Grid>
            <Grid item key={"youtube"}>
              <TextField
                margin="dense"
                inputProps={{
                  name: 'youtube',
                  id: 'youtube',
                  maxLength: 100,
                }}
                label="YouTube"
                value={settings.youtube}
                fullWidth
                onChange={handleSettingsChange}
              />
            </Grid>
            */}
                    </Grid>
                    {/*<Box mt={3}>
            <Grid container justify="flex-start">
              <Grid item>
                <LoadingButton onClick={saveSettings} variant="contained" color="secondary">
                  <Trans i18nKey="user-settings.save" />
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>*/}
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Box p={3}>
                        <Paper variant={"outlined"} style={{ backgroundColor: theme.palette.background.default }}>
                            <Box p={3}>
                                <Typography variant="h5">
                                    <Trans i18nKey="user-settings.change-image.profile" />
                                </Typography>
                                <Box py={3}>
                                    <UploadImage
                                        circle
                                        defaultImage={currentUser && currentUser.avatar && `${Constants.apiPrefix}/api/${currentUser.avatar}`}
                                        croppedImageState={[avatar, setAvatar]}
                                        displayHeight={150}
                                        displayWidth={150}
                                    />
                                </Box>
                                <Typography variant="h5">
                                    <Trans i18nKey="user-settings.change-image.banner" />
                                </Typography>
                                <Box py={3}>
                                    <UploadImage
                                        defaultImage={currentUser && currentUser.banner && `${Constants.apiPrefix}/api/${currentUser.banner}`}
                                        croppedImageState={[banner, setBanner]}
                                        fullScreen
                                        displayHeight={isWidthUp("lg", props.width) ? 100 : 50}
                                        displayWidth={isWidthUp("lg", props.width) ? 500 : 250}
                                        height={200}
                                        width={1000}
                                    />
                                </Box>
                                <Grid container justify="center" spacing={2}>
                                    <Grid item>
                                        <Typography>
                                            <Trans i18nKey="user-settings.change-image.text" />
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        {/*<Button variant="contained" color="secondary" onClick={saveImageSettings}>Skicka en förfrågan om att ändra bilder</Button>*/}
                                        <LoadingButton onClick={saveImageSettings} variant="contained" color="secondary">
                                            <Trans i18nKey="user-settings.change-image.button" />
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}