import React from 'react'
import {Avatar, Card, Grid, Typography} from '@material-ui/core';
import CardContent from "@material-ui/core/CardContent";
import { useTranslation } from 'react-i18next';

export const Member = (props) => {
    const { t } = useTranslation();

    let email = null;
    if (props.email !== null) {
        email = <Typography variant="body2">{props.email}</Typography>;
    }

    return (
        <Grid item xs={12} sm={6}>
            <Card>
                <CardContent>
                    <Typography variant="h6">{props.name}</Typography>
                    <Typography variant="body2" color="textSecondary">{email}</Typography>
                    <Typography variant="body2" color="textSecondary">Discord @ {props.discord}</Typography>
                    <Typography variant="body2" color="textSecondary">{props.titles.map(title => {
                        return t(`about.role.${title}`);
                    }).join(" | ")}</Typography>
                </CardContent>
            </Card>
        </Grid>
    )
}