import React from "react";
import { Box, Grid, Button, TableRow, Paper, Table, TableBody, TableCell, TableContainer, TableHead, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import { AcceptDialog } from "./AcceptDialog";

export const ExistingUserRequest = (props) => {
    const theme = useTheme();
    const request = props.request;
    const content = JSON.parse(request.content);
    const [state, setState] = React.useState({
        accepted: request.accepted === null ? undefined : request.accepted === "1",
        acceptedByNickname: request.acceptedByNickname === null ? undefined : request.acceptedByNickname,
        acceptedDate: request.acceptedDate === null ? undefined : request.acceptedDate.substr(0, request.acceptedDate.length - 3)
    });
    return (
        <Box my={2}>
            <TableContainer component={Paper} style={{ backgroundColor: theme.palette.primary.main }}>
                <Table>
                    <caption>
                        <Grid container justify="space-between">
                            <Grid item>
                                <Typography color="secondary">Spelarförfrågan</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                {state.accepted === undefined ? (
                                    <AcceptDialog setState={setState} {...props} />
                                ) : (
                                  <Button fullWidth variant="contained" disabled>
                                      {state.accepted ?
                                        `Accepterad av ${state.acceptedByNickname} (${state.acceptedDate})`
                                        : `Nekad av ${state.acceptedByNickname} (${state.acceptedDate})`}
                                  </Button>
                                )}
                            </Grid>
                        </Grid>
                    </caption>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography color="textSecondary">Skapad</Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography color="textSecondary">Discord användare</Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography color="textSecondary">Discord avatar</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {request.date.substr(0, request.date.length - 3)}
                            </TableCell>
                            <TableCell align="center">
                                Discord: {`${content.discordUsername}#${content.discordDiscriminator}`}
                            </TableCell>
                            <TableCell align="center">
                                <img
                                    style={{ maxHeight: 50 }}
                                    src={content.discordAvatar !== null ? "https://cdn.discordapp.com/avatars/" + content.discordId + "/" + content.discordAvatar : ""}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

