import React from "react";
import Cookies from "universal-cookie";
import {
    Box,
    CircularProgress,
    Container,
    IconButton,
    InputAdornment,
    makeStyles,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    useTheme,
} from "@material-ui/core";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import Table from "@material-ui/core/Table";
import { Constants } from "../../../../../constants";
import { TextField } from "../../../../../components/TextField";
import { Search } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import LastPageIcon from "@material-ui/icons/LastPage";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { TeamDialog } from "./TeamDialog";

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

async function getAllTeams() {
    const r = await fetch(`${Constants.apiPrefix}/api/team/allWithPlayers`);
    return await r.json();
}

export const AllTeams = () => {
    const theme = useTheme();

    const [teams, setTeams] = React.useState();
    const [searchTeams, setSearchTeams] = React.useState();
    const [searchQuery, setSearchQuery] = React.useState();

    const [open, setOpen] = React.useState(false);
    const [selectedTeam, setSelectedTeam] = React.useState();

    // Pagination settings
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(6);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const updateTeams = () => {
        return getAllTeams().then((r) => {
            setTeams(r.MESSAGE);
            setSearchTeams(r.MESSAGE);
        });
    }

    React.useEffect(() => {
        updateTeams();
    }, []);

    const handleChange = (event) => {
        if (event.target.value.length > 0) {
            setSearchQuery(event.target.value);
            setSearchTeams(
                teams.filter((team) => team.teamname !== null && team.teamname !== undefined && team.teamname.toLowerCase().includes(event.target.value.toLowerCase()))
            );
        } else {
            setSearchTeams(teams);
            setSearchQuery(undefined);
        }
    };

    const openDialog = (team) => {
        setSelectedTeam(team);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Box p={1}>
                <TextField
                    autoFocus
                    fullWidth
                    label="Sök på lagnamn"
                    variant="outlined"
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            {searchTeams ? (
                <>
                    <TableContainer component={Paper} style={{ backgroundColor: theme.palette.primary.main }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">ID</TableCell>
                                    <TableCell align="left">Lagnamn</TableCell>
                                    <TableCell align="left">Spelare</TableCell>
                                    <TableCell align="center">Snitt MMR</TableCell>
                                    <TableCell align="center">Aktiv</TableCell>
                                    <TableCell align="center" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0 ? searchTeams.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : searchTeams).map((team) => (
                                    <TableRow>
                                        <TableCell align="left">{team.teamId}</TableCell>
                                        <TableCell align="left">
                                            <Link className="srl-link" to={`/team/${team.teamId}`}>
                                                {searchQuery && team.teamname.toLowerCase().startsWith(searchQuery.toLowerCase()) ? (
                                                    <>
                                                        <span
                                                            style={{
                                                                backgroundColor: theme.palette.secondary.main,
                                                                color: "black",
                                                            }}
                                                        >
                                                            {team.teamname.substr(0, searchQuery.length)}
                                                        </span>
                                                        {team.teamname.substr(searchQuery.length, team.teamname.length)}
                                                    </>
                                                ) : (
                                                    team.teamname
                                                )}
                                            </Link>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Grid container spacing={1}>
                                                {team.activePlayers.map((player) => (
                                                    <Grid item>
                                                        <Link className="srl-link" to={`/player/${player.id}`}>
                                                            {player.nickname}
                                                        </Link>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </TableCell>
                                        <TableCell align="center">
                                            {Number(
                                                team.activePlayers &&
                                                    team.activePlayers.length > 0 &&
                                                    team.activePlayers.map((player) => parseFloat(player.mmr)).reduce((a, b) => a + b) / team.activePlayers.length
                                            ).toFixed(2)}
                                        </TableCell>
                                        <TableCell align="center">
                                            {team.active === 0 ? <span style={{ color: "red" }}>Falskt</span> : <span style={{ color: "green" }}>Sant</span>}
                                        </TableCell>
                                        <TableCell align={"center"}>
                                            <Button variant="contained" color="secondary" onClick={() => openDialog(team)}>
                                                Hantera
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[6, 10, 25, { label: "All", value: -1 }]}
                                        colSpan={6}
                                        count={searchTeams.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                "aria-label": "rows per page",
                                            },
                                            native: true,
                                        }}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                        onChangePage={handleChangePage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    {selectedTeam && <TeamDialog updateTeams={updateTeams} team={selectedTeam} open={open} handleClose={handleClose} key={selectedTeam.teamId} />}
                </>
            ) : (
                <Grid container justify="center">
                    <Grid item>
                        <CircularProgress color="secondary" size={24} />
                    </Grid>
                </Grid>
            )}
        </>
    );
};
