import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    AppBar,
    Box, Button,
    Container,
    Grid,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Typography
} from "@material-ui/core";

import { Constants } from "../../../../constants";
import DivisionDialog from "./components/DivisionDialog";
import {Link, Redirect, Route, Switch} from "react-router-dom";
import {NewsList} from "../news/components/NewsList";
import {EditNews} from "../news/components/EditNews";
import {AddNews} from "../news/components/AddNews";
import {AddCategory} from "../news/components/AddCategory";
import {AddDivision} from "./components/AddDivision";
import {DivisionTabs} from "./components/DivisionTabs";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    topRow: {
        padding: theme.spacing(2)
    }
}));

export const Divisions = (props) => {
    const classes = useStyles();
    const { url } = props.match;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [seasons, setSeasons] = React.useState({});

    const getDivisions = async () => {
        const r = await fetch(`${Constants.apiPrefix}/api/division/all`);
        return await r.json();
    }

    const updateDivisions = () => {
        getDivisions().then(response => {
            const divisions = response.MESSAGE;
            let seasonsTmp = {};
            divisions.forEach((division) => {
                if (seasonsTmp[division.season]) {
                    seasonsTmp[division.season].push(division);
                } else {
                    seasonsTmp[division.season] = []
                    seasonsTmp[division.season].push(division);
                }
            });
            setSeasons(seasonsTmp);
        });
    }

    React.useEffect(updateDivisions, [])


    return (
      <Container>
          <Switch>
              <Route path={`${url}/all`} component={DivisionTabs} />
              {/*<Route path={`${url}/edit/:id`} component={EditNews} />*/}
              <Route path={`${url}/add`} component={AddDivision} />
              {/* This route has to be at the bottom */}
              <Route path={`${url}`} exact>
                  <Redirect to={`${url}/all`}/>
              </Route>
          </Switch>
      </Container>
    );
}