import React from "react";
import { useContext } from "react";
import Button from "@material-ui/core/Button";
import { Box, Checkbox, CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Grid, makeStyles, MenuItem, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Trans } from "react-i18next";
import { Constants } from "../../../../../constants";
import { TextField } from "../../../../../components/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { SnackbarContext } from "../../../../../context/SnackbarProvider";
import Cookies from "universal-cookie";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default function HandleAsWODialog(props) {
    const { seriesId, updateSeries, haveMatchDate, hometeam, awayteam, hometeamid, awayteamid } = props;
    const classes = useStyles();
    const cookies = new Cookies();
    const [open, setOpen] = React.useState(false);
    const [teamWon, setTeamWon] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const { setSnackbar } = useContext(SnackbarContext);

    console.log(props);

    // Functions
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const saveWOSeries = async (seriesId, teamWon) => {
        const r = await fetch(`${Constants.apiPrefix}/api/admin/series/saveWOSeries`, {
            method: "POST",
            body: JSON.stringify({
                accessToken: cookies.get("accessToken"),
                seriesId: seriesId,
                teamWon: teamWon
            }),
        });
        return await r.json();
    };

    const clickSave = () => {
        setLoading(true);
        saveWOSeries(seriesId, teamWon).then((r) => {
            updateSeries().then(() => {
                if (r.hasOwnProperty("CODE") && r.CODE === 200) {
                    setSnackbar({
                        open: true,
                        severity: "success",
                        message: r.MESSAGE,
                    });
                    setLoading(false);
                    handleClose();
                } else if (r.hasOwnProperty("CODE") && r.CODE !== 500) {
                    setSnackbar({
                        open: true,
                        severity: "error",
                        message: r.MESSAGE,
                    });
                    setLoading(false);
                } else {
                    throw new Error(r.MESSAGE);
                }
            });
        });
    };

    const handleInput = (e, setter) => {
        e.preventDefault();

        setter(e.target.value);
    }

    return (
        <div>
            <MenuItem key={`information`} onClick={handleClickOpen} disabled={haveMatchDate === false}>
                <Trans>admin.matches.handle-as-wo</Trans>
            </MenuItem>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
                <DialogTitle id="form-dialog-title">
                    <Trans>admin.matches.handle-as-wo</Trans>
                </DialogTitle>
                <DialogContent>
                        <FormControl component="fieldset">
                        <FormLabel component="legend">Vilket lag vann?</FormLabel>
                        <RadioGroup aria-label="team" name="teamWon" value={teamWon} onChange={(e) => handleInput(e, setTeamWon)}>
                            <FormControlLabel value={'home'} control={<Radio />} label={hometeam} />
                            <FormControlLabel value={'away'} control={<Radio />} label={awayteam} />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="primary">
                        Tillbaka
                    </Button>
                    <div className={classes.wrapper}>
                        <Button disabled={loading} onClick={clickSave} color="secondary" variant="contained">
                            Spara
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}
