import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { UpperCaseText } from "../../../layout/components/UpperCaseText";
import {Trans, useTranslation} from "react-i18next";
import {Tooltip, Typography} from "@material-ui/core";

function descendingComparator(a, b, orderBy) {
    if (parseFloat(b[orderBy]) < parseFloat(a[orderBy])) {
        return -1;
    }
    if (parseFloat(b[orderBy]) > parseFloat(a[orderBy])) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

let headCells;

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
    },
}))(Tooltip);

function EnhancedTableHead(props) {
    const { classes, order, orderBy, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
      <TableHead>
          <TableRow>
              <TableCell padding="checkbox" />
              {headCells.map((headCell) => {

                  const TooltipCell = (props) => {
                      const { children } = props;
                      if(headCell.tooltip) {
                          return (
                            <HtmlTooltip title={<Typography><Trans>{headCell.tooltip}</Trans></Typography>} placement="down">
                                {children}
                            </HtmlTooltip>
                          );
                      } else {
                          return (
                            <>{children}</>
                          )
                      }
                  }

                  return (
                    <TooltipCell>
                        <TableCell
                          key={headCell.id}
                          align={headCell.numeric ? 'right' : 'left'}
                          padding={headCell.disablePadding ? 'none' : 'normal'}
                          sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {headCell.sortable ? (
                              <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                              >
                                  <UpperCaseText>{headCell.label}</UpperCaseText>
                                  {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                  ) : null}
                              </TableSortLabel>
                            ) : (
                              <UpperCaseText>{headCell.label}</UpperCaseText>
                            )}
                        </TableCell>
                    </TooltipCell>
                  );
              })}
          </TableRow>
      </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export const TeamPlayerStatisticsTable = (props) => {
    const classes = useStyles();
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('PS');
    const { stats: rows } = props;
    const { t } = useTranslation();

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    for (let i = 0; i < rows.length; i++) {
        rows[i]["PS"] = rows[i]["points"] / rows[i]["matches"];
        rows[i]["G/M"] = rows[i]["goals"] / rows[i]["matches"];
        rows[i]["A/M"] = rows[i]["assists"] / rows[i]["matches"];
        rows[i]["Sa/M"] = rows[i]["saves"] / rows[i]["matches"];
        rows[i]["S/M"] = rows[i]["shots"] / rows[i]["matches"];
        rows[i]["S%"] = rows[i]["goals"] / rows[i]["shots"];
    }

    headCells = [
        { id: 'player_name', numeric: false, disablePadding: true, label: t("stat.player"), sortable: false },
        { id: 'matches', numeric: true, disablePadding: false, label: t("stat.games.short"), sortable: true, tooltip: t("stat.games") },
        { id: 'PS', numeric: true, disablePadding: true, label: t("stat.points-average.short"), sortable: true, tooltip: t("stat.points-average") },
        { id: 'goals', numeric: true, disablePadding: true, label: t("stat.goals.short"), sortable: true, tooltip: t("stat.goals") },
        { id: 'G/M', numeric: true, disablePadding: false, label: t("stat.goals-average.short"), sortable: true, tooltip: t("stat.goals-average") },
        { id: 'assists', numeric: true, disablePadding: false, label: t("stat.assists.short"), sortable: true, tooltip: t("stat.assists") },
        { id: 'A/M', numeric: true, disablePadding: false, label: t("stat.assists-average.short"), sortable: true, tooltip: t("stat.assists-average") },
        { id: 'saves', numeric: true, disablePadding: false, label: t("stat.saves.short"), sortable: true, tooltip: t("stat.saves") },
        { id: 'Sa/M', numeric: true, disablePadding: false, label: t("stat.saves-average.short"), sortable: true, tooltip: t("stat.saves-average") },
        { id: 'shots', numeric: true, disablePadding: false, label: t("stat.shots.short"), sortable: true, tooltip: t("stat.shots") },
        { id: 'S/M', numeric: true, disablePadding: false, label: t("stat.shots-average.short"), sortable: true, tooltip: t("stat.shots-average") },
        { id: 'S%', numeric: true, disablePadding: false, label: t("stat.shots-percent.short"), sortable: true, tooltip: t("stat.shots-percent") },
    ];

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer {...props}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.id}
                                        >
                                            <TableCell padding="checkbox">

                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                                <UpperCaseText>{row.player_name}</UpperCaseText>
                                            </TableCell>
                                            <TableCell align="right"><UpperCaseText>{row.matches}</UpperCaseText></TableCell>
                                            <TableCell align="right"><UpperCaseText>{Number(row.PS).toFixed(2)}</UpperCaseText></TableCell>
                                            <TableCell align="right"><UpperCaseText>{Number(row.goals)}</UpperCaseText></TableCell>
                                            <TableCell align="right"><UpperCaseText>{Number(row["G/M"]).toFixed(2)}</UpperCaseText></TableCell>
                                            <TableCell align="right"><UpperCaseText>{Number(row.assists)}</UpperCaseText></TableCell>
                                            <TableCell align="right"><UpperCaseText>{Number(row["A/M"]).toFixed(2)}</UpperCaseText></TableCell>
                                            <TableCell align="right"><UpperCaseText>{Number(row.saves)}</UpperCaseText></TableCell>
                                            <TableCell align="right"><UpperCaseText>{Number(row["Sa/M"]).toFixed(2)}</UpperCaseText></TableCell>
                                            <TableCell align="right"><UpperCaseText>{Number(row.shots)}</UpperCaseText></TableCell>
                                            <TableCell align="right"><UpperCaseText>{Number(row["S/M"]).toFixed(2)}</UpperCaseText></TableCell>
                                            <TableCell align="right"><UpperCaseText>{Number(row["S%"]).toFixed(2)}</UpperCaseText></TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}
