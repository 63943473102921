import React from 'react';
import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";

export const SnackbarContext = React.createContext({
    snackbar: undefined,
    setSnackbar: null,
});

const SnackbarProvider = (props) => {
    const { children } = props;
    const [snackbar, setSnackbar] = React.useState({
        open: false,
    });

    const contextValue = {
        snackbar: snackbar,
        setSnackbar: setSnackbar,
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({
            open: false,
        });
    };

    return (
        <SnackbarContext.Provider value={contextValue}>
            {children}
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                {snackbar.open && (
                    <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
                        {snackbar.message}
                    </Alert>
                )}
            </Snackbar>
        </SnackbarContext.Provider>
    )
}

export default SnackbarProvider;