import React from "react";
import Cookies from "universal-cookie";
import {
    Box,
    CircularProgress,
    Container,
    IconButton,
    InputAdornment,
    makeStyles,
    Menu,
    MenuItem,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    useTheme,
} from "@material-ui/core";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import Table from "@material-ui/core/Table";
import { Constants } from "../../../../../constants";
import { TextField } from "../../../../../components/TextField";
import { MoreVert, Search } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import LastPageIcon from "@material-ui/icons/LastPage";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Trans } from "react-i18next";
import { DropdownMenu } from "./DropdownMenu";

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

async function getAllSeries() {
    const r = await fetch(`${Constants.apiPrefix}/api/series/all/get`);
    return await r.json();
}

export const AllMatches = () => {
    const theme = useTheme();

    const [series, setSeries] = React.useState();
    const [searchSeries, setSearchSeries] = React.useState();
    const [searchQuery, setSearchQuery] = React.useState();

    // Pagination settings
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(6);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const updateSeries = () => {
        return getAllSeries().then((r) => {
            setSeries(r);
            setSearchSeries(r);
        });
    };

    React.useEffect(() => {
        updateSeries().then();
    }, []);

    const handleChange = (event) => {
        if (event.target.value.length > 0) {
            setSearchQuery(event.target.value);
            setSearchSeries(
                series.filter(
                    (serie) =>
                        (serie.hometeam !== null && serie.hometeam.toLowerCase().startsWith(event.target.value.toLowerCase())) ||
                        (serie.awayteam !== null && serie.awayteam.toLowerCase().startsWith(event.target.value.toLowerCase())) ||
                        (serie.division_name !== null && serie.division_name.toLowerCase().includes(event.target.value.toLowerCase()))
                )
            );
        } else {
            setSearchSeries(series);
            setSearchQuery(undefined);
        }
    };

    return (
        <>
            <Box p={1}>
                <TextField
                    autoFocus
                    fullWidth
                    label="Sök på lagnamn"
                    variant="outlined"
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            {searchSeries ? (
                <TableContainer component={Paper} style={{ backgroundColor: theme.palette.primary.main }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">ID</TableCell>
                                <TableCell align="center">Hemmalag</TableCell>
                                <TableCell align="center">Bortalag</TableCell>
                                <TableCell align="center">Datum</TableCell>
                                <TableCell align="center">Vecka</TableCell>
                                <TableCell align="center">Division</TableCell>
                                <TableCell align="center">Säsong</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 ? searchSeries.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : searchSeries).map((serie) => (
                                <TableRow>
                                    <TableCell align="center">{serie.match_id}</TableCell>
                                    <TableCell align="center">
                                        <Link to={`/team/${serie.hometeam_id}`} className="srl-link">
                                            {searchQuery && serie.hometeam.toLowerCase().startsWith(searchQuery.toLowerCase()) ? (
                                                <>
                                                    <span style={{ backgroundColor: theme.palette.secondary.main, color: "black" }}>
                                                        {serie.hometeam.substr(0, searchQuery.length)}
                                                    </span>
                                                    {serie.hometeam.substr(searchQuery.length, serie.hometeam.length)}
                                                </>
                                            ) : (
                                                serie.hometeam
                                            )}
                                        </Link>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Link to={`/team/${serie.awayteam_id}`} className="srl-link">
                                            {searchQuery && serie.awayteam.toLowerCase().startsWith(searchQuery.toLowerCase()) ? (
                                                <>
                                                    <span style={{ backgroundColor: theme.palette.secondary.main, color: "black" }}>
                                                        {serie.awayteam.substr(0, searchQuery.length)}
                                                    </span>
                                                    {serie.awayteam.substr(searchQuery.length, serie.awayteam.length)}
                                                </>
                                            ) : (
                                                serie.awayteam
                                            )}
                                        </Link>
                                    </TableCell>
                                    <TableCell align="center">{serie.match_date}</TableCell>
                                    <TableCell align="center">{serie.match_week}</TableCell>
                                    <TableCell align="center">{serie.division_name}</TableCell>
                                    <TableCell align="center">{serie.season_name}</TableCell>
                                    <TableCell align="center">
                                        {serie.match_state === 0 ? (
                                            <Typography style={{ color: theme.palette.warning.main }}>Ej påbörjad</Typography>
                                        ) : serie.match_state === 1 ? (
                                            <Typography style={{ color: theme.palette.info.main }}>Matchtid satt</Typography>
                                        ) : (
                                            <Typography style={{ color: theme.palette.success.main }}>Inrapporterad</Typography>
                                        )}
                                    </TableCell>
                                    <TableCell align="right" padding="checkbox">
                                        <DropdownMenu 
                                            seriesId={serie.match_id} 
                                            haveMatchDate={serie.match_date !== null} 
                                            hometeam={serie.hometeam}
                                            awayteam={serie.awayteam}
                                            hometeamid={serie.hometeam_id}
                                            awayteamid={serie.awayteam_id}
                                            updateSeries={updateSeries} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[6, 10, 25, { label: "All", value: -1 }]}
                                    colSpan={9}
                                    count={searchSeries.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { "aria-label": "rows per page" },
                                        native: true,
                                    }}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                    onChangePage={handleChangePage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            ) : (
                <Grid container justify="center">
                    <Grid item>
                        <CircularProgress color="secondary" size={24} />
                    </Grid>
                </Grid>
            )}
        </>
    );
};
