import React from "react";
import { Box, Button, Container, Grid, MobileStepper, Paper, Typography, makeStyles, useTheme, Hidden } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useTranslation } from "react-i18next";

import { Constants } from "./../../../constants";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: "100%",
        height: 700,
        flexGrow: 1,
    },
    img: {
        margin: "0 auto",
        width: "300px",
        height: "300px",
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
    },
    img_small: {
        margin: "0 auto",
        width: "200px",
        height: "200px",
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
    },
    teamlogo: {
        position: "relative",
        width: "100%",
        textAlign: "center",
    },
    series: {
        overflow: "hidden",
    },
    center: {
        textAlign: "center",
    },
    center_small: {
        textAlign: "center",
        width: "100%",
    },
}));

export const SeriesSlider = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = React.useState(0);
    const [upcomingSeries, setUpcomingSeries] = React.useState([]);

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    React.useEffect(() => {
        fetch(Constants.apiPrefix + `/api/series/upcoming/division/top`)
            .then((response) => response.json())
            .then((upcomingSeries) => {
                console.log(upcomingSeries);
                setUpcomingSeries(upcomingSeries);
            });
    }, []);

    return (
        <div className={classes.root}>
            <Hidden only={['xs', 'sm']}>
                <Box p={10} />
            </Hidden>
            <AutoPlaySwipeableViews axis={"x"} index={activeStep} onChangeIndex={handleStepChange} interval={10000} enableMouseEvents>
                {upcomingSeries.map((serie, index) => (
                    <div key={index} className={classes.series}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <Container>
                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <Hidden only={['xs', 'sm']}>
                                        <Grid sm={4} className={classes.teamlogo}>
                                            <div
                                                className={classes.img}
                                                style={{
                                                    backgroundImage: `url(${
                                                        serie.home_logo.includes("discordapp") ? serie.home_logo : `${Constants.apiPrefix}/api/${serie.home_logo}`
                                                    })`,
                                                }}
                                            />
                                            <Typography variant="h4">{serie.hometeam}</Typography>
                                        </Grid>
                                        <Grid sm={4} className={classes.center}>
                                            <Typography variant="body1">{serie.division_name}</Typography>
                                            <Typography variant="h5">{serie.matchdate !== null ? serie.matchdate : `${t("league.week")} ${serie.week}`}</Typography>
                                        </Grid>
                                        <Grid sm={4} className={classes.teamlogo}>
                                            <div
                                                className={classes.img}
                                                style={{
                                                    backgroundImage: `url(${
                                                        serie.away_logo.includes("discordapp") ? serie.away_logo : `${Constants.apiPrefix}/api/${serie.away_logo}`
                                                    })`,
                                                }}
                                            />
                                            <Typography variant="h4">{serie.awayteam}</Typography>
                                        </Grid>
                                    </Hidden>
                                    <Hidden only={['md', 'lg', 'xl']}>
                                        <Grid sm={12} className={classes.center_small}>
                                            <Typography variant="body1" style={{width: '100%'}} className={classes.center}>{serie.division_name}</Typography>
                                            <Typography variant="h5" style={{width: '100%'}} className={classes.center}>{serie.matchdate !== null ? serie.matchdate : `${t("league.week")} ${serie.week}`}</Typography>
                                        </Grid>
                                        <Box pt={2} />
                                        <Grid sm={12} className={classes.teamlogo}>
                                            <div
                                                className={classes.img_small}
                                                style={{
                                                    backgroundImage: `url(${
                                                        serie.home_logo.includes("discordapp") ? serie.home_logo : `${Constants.apiPrefix}/api/${serie.home_logo}`
                                                    })`,
                                                }}
                                            />
                                            <Typography variant="h5">{serie.hometeam}</Typography>
                                        </Grid>
                                        <Grid sm={12} className={classes.center_small} style={{paddingTop: '10px', paddingBottom: '10px'}}>
                                            <Typography variant="body1" style={{width: '100%'}} className={classes.center}>vs</Typography>
                                        </Grid>
                                        <Grid sm={12} className={classes.teamlogo}>
                                            <div
                                                className={classes.img_small}
                                                style={{
                                                    backgroundImage: `url(${
                                                        serie.away_logo.includes("discordapp") ? serie.away_logo : `${Constants.apiPrefix}/api/${serie.away_logo}`
                                                    })`,
                                                }}
                                            />
                                            <Typography variant="h5">{serie.awayteam}</Typography>
                                        </Grid>
                                    </Hidden>
                                </Grid>
                            </Container>
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
        </div>
    );
};
