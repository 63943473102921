import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { TextField } from "./../../../../../components/TextField";
import { getRowEl } from "@material-ui/data-grid";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const refNames = ["teamPlayerId", "points", "goals", "assists", "saves", "shots"];

export function ReportResultTablePlayerScore(props) {
    const { players, tabindex, playerIndex, updateRow, value, result } = props;

    const [playerRow, setPlayerRow] = React.useState({
        teamPlayerId: "",
        points: null,
        goals: null,
        assists: null,
        saves: null,
        shots: null,
        name: null,
    });

    const handleChange = (event, setter) => {
        setPlayerRow({ ...playerRow, [setter]: event.target.value });
        updateRow(setter, value, event.target.value);
        if (setter === "teamPlayerId") {
            setPlayerRow({
                ...playerRow,
                teamPlayerId: event.target.value,
            });
        }
    };

    const handlePlayerLabel = () => {
        return playerRow.name ? `*${playerRow.name}*` : "Spelare";
    };

    function updateScore() {
        if (playerRow) {
            if (playerRow.teamPlayerId) {
                updateRow("teamPlayerId", value, playerRow.teamPlayerId);
            }
            updateRow("points", value, playerRow.points);
            updateRow("goals", value, playerRow.goals);
            updateRow("assists", value, playerRow.assists);
            updateRow("saves", value, playerRow.saves);
            updateRow("shots", value, playerRow.shots);
        }
    }

    React.useEffect(() => {
        try {
            if (result && Object.keys(result).length) {
                setPlayerRow((prevState) => ({
                    ...prevState,
                    ...result,
                }));
            }
        } catch (e) {
            setSnackbar({
                open: true,
                severity: "error",
                message: error.message,
            });
        }

        // if (playerRow.teamPlayerId) {
        //     setPlayer(playerRow.teamPlayerId);
        // }
        // const copyRow = { ...playerRow };
        // if (playerIndex <= players.length) {
        //     copyRow["teamPlayerId"] = players[playerIndex - 1].teamplayer_id;
        //     setPlayerRow(copyRow);
        //     updateRow(copyRow, value);
        // }
    }, [result]);

    React.useEffect(() => {
        updateScore();
    }, [playerRow]);

    return (
        <React.Fragment>
            <TableCell>
                <FormControl focused={true} variant="outlined" fullWidth size="small">
                    <InputLabel color={"secondary"} id={`player-select-${tabindex}`}>
                        {handlePlayerLabel()}
                    </InputLabel>
                    <Select
                        labelId={`player-select-${tabindex}`}
                        id={`player-select-id-${tabindex}`}
                        onChange={(e) => handleChange(e, "teamPlayerId")}
                        value={playerRow.teamPlayerId}
                        defaultValue={playerRow.teamPlayerId}
                    >
                        {players && players.map((player) => <MenuItem value={player.teamplayer_id}>{player.player_nickname}</MenuItem>)}
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell>
                <TextField
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: Infinity } }}
                    size="small"
                    fullWidth
                    value={playerRow.points}
                    tabIndex={tabindex}
                    onChange={(e) => handleChange(e, "points")}
                    variant="outlined"
                />
            </TableCell>
            <TableCell>
                <TextField
                    type="number"
                    fullWidth
                    size="small"
                    InputProps={{ inputProps: { min: 0, max: Infinity } }}
                    value={playerRow.goals}
                    tabIndex={tabindex + 1}
                    onChange={(e) => handleChange(e, "goals")}
                    variant="outlined"
                />
            </TableCell>
            <TableCell>
                <TextField
                    type="number"
                    size="small"
                    fullWidth
                    value={playerRow.assists}
                    tabIndex={tabindex + 2}
                    onChange={(e) => handleChange(e, "assists")}
                    InputProps={{ inputProps: { min: 0, max: Infinity } }}
                    variant="outlined"
                />
            </TableCell>
            <TableCell>
                <TextField
                    type="number"
                    size="small"
                    fullWidth
                    value={playerRow.saves}
                    tabIndex={tabindex + 3}
                    onChange={(e) => handleChange(e, "saves")}
                    InputProps={{ inputProps: { min: 0, max: Infinity } }}
                    variant="outlined"
                />
            </TableCell>
            <TableCell>
                <TextField
                    type="number"
                    size="small"
                    fullWidth
                    value={playerRow.shots}
                    tabIndex={tabindex + 4}
                    onChange={(e) => handleChange(e, "shots")}
                    InputProps={{ inputProps: { min: 0, max: Infinity } }}
                    variant="outlined"
                />
            </TableCell>
        </React.Fragment>
    );
}
