import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { CardActionArea } from '@material-ui/core';
import { Link } from "react-router-dom";
import { ImageService } from '../../../services/ImageService';

const useStyles = makeStyles((theme) => ({
    mainFeaturedPost: {
        position: 'relative',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,.6)',
    },
    mainFeaturedPostContent: {
        position: 'relative',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6),
            paddingRight: 0,
        },
    },
}));

export const MainFeaturedPost = (props) => {
    const classes = useStyles();
    const { post } = props;

    return (
        <CardActionArea component={Link} to={`/post/${post.customUrl}`}>
            <Paper className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${ImageService.transformImageUrl(post.image)})` }}>
                {/* Increase the priority of the hero background image */}
                {<img style={{ display: 'none' }} src={ImageService.transformImageUrl(post.image)} />}
                <div className={classes.overlay} />
                <Grid container>
                    <Grid item md={6}>
                        <div className={classes.mainFeaturedPostContent}>
                            <Typography component="h3" variant="h4" color="inherit" gutterBottom>
                                {post.title}
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary">
                                {post.datetime.length > 0 && post.datetime.substring(0, post.datetime.length - 3)}
                            </Typography>
                            <Typography variant="h5" color="inherit" paragraph>
                                {post.description}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </CardActionArea>
    );
}

MainFeaturedPost.propTypes = {
    post: PropTypes.object,
};