import React from "react";
import { Box, Button, Container, Grid, Paper, Typography, FormControl, FormGroup, FormControlLabel, Checkbox, InputLabel, Select, MenuItem } from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { PageTitle } from "../../layout/PageTitle";
import { Constants } from "./../../constants";
import { TextField } from "./../../components/TextField";
import SponsorPaper from "../../layout/components/SponsorPaper";
import { Trans, useTranslation } from "react-i18next";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

export const Member = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const initialValues = {
        fName: "",
        lName: "",
        mail: "",
        tel: "",
        ssn: "",
        address: "",
        zip: "",
        city: "",
        sex: "",
        agreejoin: false,
        agreeprivacy: false,
        agreemarketing: false,
    };
    const [formData, setFormData] = React.useState(initialValues);
    const [formErrors, setFormErrors] = React.useState(initialValues);

    const [saved, setSaved] = React.useState(false);
    const [invalidForm, setInvalidForm] = React.useState(false);
    const [success, setSuccess] = React.useState({
        severity: undefined,
        text: undefined,
    });

    const save = async (event) => {
        event.preventDefault();
        setFormErrors(validateForm(formData));
        if (Object.keys(formErrors).length > 0) {
            return;
        }

        try {
            const res = await fetch(Constants.apiPrefix + "/api/user/save-member", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            const data = await res.json();
            if (data.hasOwnProperty("CODE") && data.hasOwnProperty("MESSAGE") && data.CODE === 400) {
                console.log('## GO EXIST');
                setSuccess({
                    severity: "info",
                    text: t("member.error.exist"),
                });
            } else if (data.hasOwnProperty("CODE") && data.hasOwnProperty("MESSAGE") && data.CODE === 200) {
                setSuccess({
                    severity: "success",
                    text: t("member.success.formsave"),
                });
            } else {
                setSuccess({
                    severity: "error",
                    text: t("member.error.formsave"),
                });
            }
            setSaved(true);
            setFormData({ ...initialValues });
        } catch (e) {
            setSuccess({
                severity: "error",
                text: t("member.error.formsave"),
            });
        }
    };

    const validateForm = (values) => {
        const regexSsn = /^(19|20)?(\d{6}(-|\s)\d{4}|(?!19|20)\d{10})$/;
        const regexMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const regexTel = /^(([+]46)\s*(7)|07)[02369]\s*(\d{4})\s*(\d{3})$/;
        const errors = {};
        if (!values.fName) {
            errors.fName = t("member.error.fname");
        }
        if (!values.lName) {
            errors.lName = t("member.error.lname");
        }
        if (!values.mail || !regexMail.test(values.mail)) {
            errors.mail = t("member.error.mail");
        }
        if (!values.tel || !regexTel.test(values.tel)) {
            errors.tel = t("member.error.tel");
        }
        if (!values.ssn || !regexSsn.test(values.ssn)) {
            errors.ssn = t("member.error.ssn");
        }
        if (!values.address) {
            errors.address = t("member.error.address");
        }
        if (!values.zip) {
            errors.zip = t("member.error.zip");
        }
        if (!values.city) {
            errors.city = t("member.error.city");
        }
        if (!values.sex) {
            errors.sex = t("member.error.sex");
        }
        if (!values.agreejoin) {
            errors.agreejoin = t("member.error.agreejoin");
        }
        if (!values.agreeprivacy) {
            errors.agreeprivacy = t("member.error.agreeprivacy");
        }
        return errors;
    };

    const handleInput = (e) => {
        if (e.target.name === "agreejoin" || e.target.name === "agreeprivacy" || e.target.name === "agreemarketing") {
            const { name, checked } = e.target;
            setFormData({ ...formData, [name]: checked });
        } else {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value });
        }
    };

    return (
        <Box>
            <PageTitle>
                <Container>
                    <Grid container direction="column" spacing={5}>
                        <Grid item>
                            <Typography variant="h2" style={{ fontWeight: 700 }}>
                                <Trans>member.title</Trans>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                <Trans>member.desc.part-1</Trans>
                            </Typography>
                        </Grid>
                        <Grid item></Grid>
                    </Grid>
                </Container>
            </PageTitle>
            <SponsorPaper>
                <Box pt={1} />
            </SponsorPaper>
            <Box my={5} />
            <Container>
                <Grid container item xs={12}>
                    <TextField
                        name="fName"
                        error={formErrors.fName}
                        helperText={formErrors.fName ? formErrors.fName : ""}
                        required
                        fullWidth
                        id="standard-basic"
                        label={t("member.fname")}
                        value={formData.fName}
                        onChange={handleInput}
                    />
                </Grid>
                <Grid container item xs={12}>
                    <TextField
                        name="lName"
                        error={formErrors.lName}
                        helperText={formErrors.lName ? formErrors.lName : ""}
                        required
                        fullWidth
                        id="standard-basic"
                        label={t("member.lname")}
                        value={formData.lName}
                        onChange={handleInput}
                    />
                </Grid>
                <Grid container item xs={12}>
                    <TextField
                        name="mail"
                        error={formErrors.mail}
                        helperText={formErrors.mail ? formErrors.mail : ""}
                        required
                        type="email"
                        fullWidth
                        id="standard-basic"
                        label={t("member.mail")}
                        value={formData.mail}
                        onChange={handleInput}
                    />
                </Grid>
                <Grid container item xs={12}>
                    <TextField
                        name="tel"
                        error={formErrors.tel}
                        helperText={formErrors.tel ? formErrors.tel : ""}
                        required
                        fullWidth
                        id="standard-basic"
                        label={t("member.tel")}
                        value={formData.tel}
                        onChange={handleInput}
                    />
                </Grid>
                <Grid container item xs={12}>
                    <TextField
                        name="ssn"
                        error={formErrors.ssn}
                        helperText={formErrors.ssn ? formErrors.ssn : ""}
                        required
                        fullWidth
                        id="standard-basic"
                        label={t("member.ssn")}
                        value={formData.ssn}
                        onChange={handleInput}
                    />
                </Grid>
                <Grid container item xs={12}>
                    <TextField
                        name="address"
                        error={formErrors.address}
                        helperText={formErrors.address ? formErrors.address : ""}
                        required
                        fullWidth
                        id="standard-basic"
                        label={t("member.address")}
                        value={formData.address}
                        onChange={handleInput}
                    />
                </Grid>
                <Grid container item xs={12}>
                    <TextField
                        name="zip"
                        error={formErrors.zip}
                        helperText={formErrors.zip ? formErrors.zip : ""}
                        required
                        fullWidth
                        id="standard-basic"
                        label={t("member.zip")}
                        value={formData.zip}
                        onChange={handleInput}
                    />
                </Grid>
                <Grid container item xs={12}>
                    <TextField
                        name="city"
                        error={formErrors.city}
                        helperText={formErrors.city ? formErrors.city : ""}
                        required
                        fullWidth
                        id="standard-basic"
                        label={t("member.city")}
                        value={formData.city}
                        onChange={handleInput}
                    />
                </Grid>
                <Grid item>
                    <FormControl fullWidth>
                        <InputLabel id="label-sex" htmlFor="sex-input">
                            <Trans>member.sex</Trans>
                        </InputLabel>
                        <Select
                            required
                            labelId="label-sex"
                            name="sex"
                            value={formData.sex}
                            onChange={handleInput}
                            error={formErrors.sex}
                            helperText={formErrors.sex ? formErrors.sex : ""}
                        >
                            <MenuItem value={"m"}>
                                <Trans>member.man</Trans>
                            </MenuItem>
                            <MenuItem value={"f"}>
                                <Trans>member.woman</Trans>
                            </MenuItem>
                            <MenuItem value={"o"}>
                                <Trans>member.other</Trans>
                            </MenuItem>
                            <MenuItem value={"na"}>
                                <Trans>member.noans</Trans>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid>
                    <FormControl component="fieldset">
                        <FormGroup>
                            <FormControlLabel
                                name="agreejoin"
                                label={t("member.agreejoin")}
                                control={<Checkbox checked={formData.agreejoin} onChange={handleInput} color="secondary" />}
                                labelPlacement="end"
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid>
                    <FormControl component="fieldset">
                        <FormGroup>
                            <FormControlLabel
                                name="agreeprivacy"
                                label={t("member.agreeprivacy")}
                                control={<Checkbox checked={formData.agreeprivacy} onChange={handleInput} color="secondary" />}
                                labelPlacement="end"
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid>
                    <FormControl component="fieldset">
                        <FormGroup>
                            <FormControlLabel
                                name="agreemarketing"
                                label={t("member.agreemarketing")}
                                control={<Checkbox checked={formData.agreemarketing} onChange={handleInput} color="secondary" />}
                                labelPlacement="end"
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>

                <Box pt={2} />
                <Grid container item xs={12}>
                    <Typography>
                        <Trans>member.desc.part-2</Trans>
                    </Typography>
                </Grid>
                <Box my={3} />
                <Grid container item xs={12}>
                    <Button variant="contained" color="secondary" onClick={save}>
                        <Trans>member.send</Trans>
                    </Button>
                </Grid>
                {saved && (
                    <Paper square elevation={0} className={classes.resetContainer}>
                        <Alert severity={success.severity}>{success.text}</Alert>
                    </Paper>
                )}
            </Container>
            <Box my={10} />
        </Box>
    );
};
