import React, {useContext} from "react";
import {Route, Redirect} from "react-router-dom";

import {Constants} from "./../../constants";
import {StateContext} from "../../discord/StateProvider";
import { InPageCircularLoader } from "../../layout/components/InPageCircularLoader";

const PrivateRoute = (props) => {
  const { children, ...rest } = props;

  const acceptedRoles = rest.acceptedRoles;
  const {
    currentUser
  } = useContext(StateContext);
  let userRoles = currentUser ? currentUser.roles.map(role => parseInt(role.id)) : [];
  const isAuthenticated = userRoles.filter(value => acceptedRoles.includes(value)).length > 0;

  if (currentUser || currentUser === Constants.userLoaded) {
    if (isAuthenticated) {
      return (
        <Route {...rest} render={() => {
          children
        }}/>
      )
    }

    return (
      <Redirect to="/"/>
    );
  }
  return (
    <InPageCircularLoader/>
  );

}

export default PrivateRoute;