import React from 'react';
import { ArrowDropDown } from '@material-ui/icons';
import { Box, Button, ClickAwayListener, Divider, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { Link } from "react-router-dom";
import { Trans } from 'react-i18next';

import { Constants } from '../../constants';
import { UpperCaseText } from './UpperCaseText';

export default function LeagueMenu(props) {
    const [open, setOpen] = React.useState(false);
    const [divisions, setDivisions] = React.useState([]);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);


    React.useEffect(() => {
        fetch(Constants.apiPrefix + "/api/season/current/divisions")
            .then(response => response.json())
            .then(jsondata => {
                setDivisions(jsondata);
            });
    }, []);

    return (
        <Box component="span">
            <Button
                color="inherit"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <UpperCaseText><Trans>menu.league</Trans></UpperCaseText>
                <ArrowDropDown />
            </Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    {divisions && divisions.map((division) => {
                                        const divLink = "/division/" + division.discriminator + "/season/" + props.currentSeasonId;
                                        return (
                                            <MenuItem onClick={handleToggle} component={Link} to={divLink} key={division.discriminator}>{division.name}</MenuItem>
                                        )
                                    })}
                                    {divisions && divisions.length > 0 && (<Divider />)}
                                    <MenuItem onClick={handleToggle} component={Link} to="/seasons" key="seasons">
                                        <Trans>footer.all-seasons</Trans>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
}