import React from 'react';
import { Avatar, Box, Typography } from '@material-ui/core';
import { PageTitle } from '../../layout/PageTitle';
import SponsorPaper from '../../layout/components/SponsorPaper';

export const ErrorPage = () => {
    return (
        <React.Fragment>
            <PageTitle title="404" />
            <SponsorPaper>
                <Box pt={1} />
            </SponsorPaper>
            <Box my={5} />
            <Typography variant="body2">
                Something went wrong
            </Typography>
            <Box>
                <Avatar variant="rounded" src="https://discord.com/channels/743062631614513354/821089920533135490/833450952220475392" className="catJAM" />
            </Box>
            <Box my={10} />
        </React.Fragment>
    )
}