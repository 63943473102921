import React from 'react';
import { withStyles } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import Markdown from 'markdown-to-jsx';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";

const styles = (theme) => ({
    image: {
        maxHeight: '250px',
        maxWidth: '400px'
    },
    linkContainer: {
        marginTop: '15px'
    }
});

const textShadow = (theme) => ({
    shadow: {
        textShadow: "black 1px 1px 10px",
    }
})

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
    }
}));

const options = {
    overrides: {
        img: {
            component: withStyles(styles)(({ classes, ...props }) => (
                <Grid container direction="row" justify="center">
                    <Grid item>
                        <img className={classes.image} src={props.src} />
                    </Grid>
                </Grid>
            )),
        },
        p: { component: Typography, props: { paragraph: true } },
        a: {
            component: withStyles(styles)(({ classes, ...props }) => (
                <Grid container direction="row" justify="center" className={classes.linkContainer}>
                    <Grid item>
                        {props.href.startsWith("http") ? (
                            <Button variant="contained" color="secondary" {...props} />
                        ) : (
                            <Button component={Link} variant="contained" color="secondary" children={props.children} to={props.href} />
                        )}
                    </Grid>
                </Grid>
            )),
        },
        iframe: {
            component: withStyles(styles)(({ classes, ...props }) => (
                <iframe {...props} className="markdown-iframe" />
            ))
        },
        h1: {
            component: withStyles(textShadow)(({ classes, ...props }) => (
                <Typography variant='h2' {...props} className={classes.shadow} />
            )),
        },
        h2: {
            component: withStyles(textShadow)(({ classes, ...props }) => (
                <Typography variant='h3' {...props} className={classes.shadow} />
            )),
        },
        h3: {
            component: withStyles(textShadow)(({ classes, ...props }) => (
                <Typography variant='h4' {...props} className={classes.shadow} />
            )),
        },
        h4: {
            component: withStyles(textShadow)(({ classes, ...props }) => (
                <Typography variant='h5' {...props} className={classes.shadow} />
            )),
        },
    },
};

export const StartMarkdown = (props) => {
    const classes = useStyles();

    return (
        <Markdown options={options} className={classes.root}>
            {props.content}
        </Markdown>
    )
}