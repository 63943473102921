import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton, ListItemSecondaryAction, Menu,
    MenuItem, Typography
} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Constants } from "../../../constants";
import Cookies from "universal-cookie";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { MoreVert } from "@material-ui/icons";
import { StateContext } from "../../../discord/StateProvider";
import { getPlayers } from "../Team";
import { useHistory } from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import { SnackbarContext } from "../../../context/SnackbarProvider";
import {Alert} from "@material-ui/lab";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

async function leaveTeam(accessToken) {
    const r = await fetch(`${Constants.apiPrefix}/api/team/leaveTeam`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            accessToken: accessToken,
        }),
    });
    return await r.json();
}

async function leaveTeamWithRequest(accessToken) {
    const r = await fetch(`${Constants.apiPrefix}/api/team/leaveTeamWithRequest`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            accessToken: accessToken,
        }),
    });
    return await r.json();
}

export const LeaveTeamDialog = (props) => {
    const cookies = new Cookies();
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { updateAll } = React.useContext(StateContext);
    const { setSnackbar } = React.useContext(SnackbarContext);
    const { lastToLeave } = props;
    const { t } = useTranslation();

    // Dialog settings
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    const handleDialogOpen = () => {
        setDialogOpen(true);
    }

    // Manage player settings
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Loading button settings
    const [loading, setLoading] = React.useState(false);

    const leaveTeamClick = () => {
        setLoading(true);
        leaveTeam(cookies.get("accessToken")).then(response => {
            if(response.hasOwnProperty("CODE") && response.CODE === 200) {
                updateAll(cookies.get("accessToken")).then(() => {
                    setSnackbar({
                        open: true,
                        severity: "success",
                        message: t(response.MESSAGE)
                    });
                    setLoading(false);
                    setDialogOpen(false);
                    history.push("/");
                });
            } else if(response.hasOwnProperty("CODE") && response.CODE !== 500) {
                setSnackbar({
                    open: true,
                    severity: "error",
                    message: t(response.MESSAGE)
                });
                setLoading(false);
                setDialogOpen(false);
            } else {
                throw new Error("react.team.components.LeaveTeamDialog.leaveTeamClick");
            }
        });
    }

    const sendLeaveTeamRequest = () => {
        setLoading(true);
        leaveTeamWithRequest(cookies.get("accessToken")).then(response => {
            if (response.hasOwnProperty("CODE") && response.CODE === 200) {
                setSnackbar({
                    open: true,
                    severity: "success",
                    message: t(response.MESSAGE)
                });
            } else if(response.hasOwnProperty("CODE") && response.CODE !== 500) {
                setSnackbar({
                    open: true,
                    severity: "error",
                    message: t(response.MESSAGE)
                });
            } else {
                throw new Error("react.team.components.LeaveTeamDialog.sendLeaveTeamRequest");
            }
            setLoading(false);
            setDialogOpen(false);
        })
    }

    return (
        <>
            <IconButton
                aria-label="more"
                aria-controls={`menu-leave-icon-button`}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVert />
            </IconButton>
            <Menu
                id={`menu-leave`}
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                <MenuItem key={`item-leave`} onClick={handleDialogOpen}>
                    <Trans>team.settings.leave-dialog-button</Trans>
                </MenuItem>
            </Menu>
            <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="dialog-accept" key={`dialog-leave`}>
                <DialogTitle><Trans>team.settings.leave-dialog-title</Trans></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {lastToLeave ?
                          (
                            <>
                                <Typography>
                                    <Trans>team.settings.leave-dialog-text-last-player</Trans>
                                </Typography>
                                <Box mt={1}/>
                                <Alert severity="warning" variant="outlined">
                                    <Trans>
                                        team.settings.leave-dialog-text-last-player.warning
                                    </Trans>
                                </Alert>
                            </>
                          ) :
                          (
                            <>
                                <Typography>
                                    <Trans>team.settings.leave-dialog-text</Trans>
                                </Typography>
                                <Box mt={1}/>
                                <Alert severity="warning" variant="outlined">
                                    <Trans>team.settings.leave-dialog-text.warning</Trans>
                                </Alert>
                            </>
                          )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant="contained">
                        <Trans>general.back</Trans>
                    </Button>
                    <div className={classes.wrapper}>
                        <Button onClick={lastToLeave ? sendLeaveTeamRequest : leaveTeamClick} variant="contained" color="secondary" disabled={loading}>
                            {lastToLeave ? (<Trans>team.settings.leave-dialog-leave-button-last-player</Trans>) : (<Trans>team.settings.leave-dialog-leave-button</Trans>)}
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );

}