import React from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Badge, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip, Typography } from "@material-ui/core";

import { UpperCaseText } from "../../../../layout/components/UpperCaseText";
import Grid from "@material-ui/core/Grid";
import { useTheme, withStyles } from "@material-ui/core/styles";

function descendingComparator(a, b, orderBy) {
    if (orderBy === "date") {
        return new Date(b[orderBy]).valueOf() - new Date(a[orderBy]).valueOf();
    }

    if (parseFloat(b[orderBy]) < parseFloat(a[orderBy])) {
        return -1;
    }
    if (parseFloat(b[orderBy]) > parseFloat(a[orderBy])) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

let headCells;

function EnhancedTableHead(props) {
    const { classes, order, orderBy, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox" />
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.sortable ? (
                            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
                                <UpperCaseText>{headCell.label}</UpperCaseText>
                                {orderBy === headCell.id ? <span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span> : null}
                            </TableSortLabel>
                        ) : (
                            <UpperCaseText>{headCell.label}</UpperCaseText>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
    },
}))(Tooltip);

export const SignedupTeamsSortableTable = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState("date");
    const { signedUpTeams: rows } = props;

    headCells = [
        { id: "name", numeric: false, disablePadding: true, label: t("general.teamname"), sortable: false },
        { id: "date", numeric: false, disablePadding: true, label: t("general.date"), sortable: true },
        { id: "playersString", numeric: false, disablePadding: false, label: t("general.players"), sortable: false },
        { id: "playerCount", numeric: true, disablePadding: false, label: t("general.playerCount"), sortable: true },
        { id: "mmr", numeric: true, disablePadding: false, label: "mmr", sortable: true },
    ];

    for (let i = 0; i < rows.length; i++) {
        if (rows[i].players) {
            rows[i]["playersString"] = rows[i].players.map((player, index) =>
                index === rows[i].players.length - 1 ? `${player.nickname}` : index === 0 ? `${player.nickname} (Lagkapten), ` : `${player.nickname}, `
            );
            rows[i]["playerCount"] = rows[i].players.length;
            let mmr = 0;
            let mmrList = [];
            for (let j = 0; j < rows[i].players.length; j++) {
                if (rows[i].players[j].mmr) {
                    mmrList.push(parseInt(rows[i].players[j].mmr));
                }
            }
            mmrList.sort(function (a, b) {  return a - b;  }).reverse();
            for (let j = 0; j < Math.min(mmrList.length, 3); j++) {
                mmr += mmrList[j];
            }
            mmr /= Math.min(mmrList.length, 3);
            mmr = `${Number(mmr).toFixed(2)} (${Math.min(mmrList.length, 3)})`;
            rows[i]["mmr"] = mmr;
        }
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer {...props}>
                    <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
                        <EnhancedTableHead classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={rows.length} />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow role="checkbox" tabIndex={-1} key={index}>
                                        <TableCell padding="checkbox"></TableCell>
                                        <TableCell component="th" id={labelId} scope="row" padding="none">
                                            <Link to={`/team/${row.id}`} className="srl-link">
                                                <UpperCaseText>{row.teamname}</UpperCaseText>
                                            </Link>
                                        </TableCell>
                                        <TableCell padding="none">
                                            <UpperCaseText>{row.date}</UpperCaseText>
                                        </TableCell>
                                        <TableCell>
                                            <Grid container spacing={2} alignItems="center">
                                                {row.players.map((player, index) => {
                                                    const Wrapper = (props) => {
                                                        const { children } = props;
                                                        if (player.userId !== null) {
                                                            return <Grid item>{children}</Grid>;
                                                        }
                                                        return (
                                                            <HtmlTooltip
                                                                className={classes.tooltip}
                                                                title={
                                                                    <Typography>
                                                                        <Trans>user.not.logged.in</Trans>
                                                                    </Typography>
                                                                }
                                                            >
                                                                <Grid item>
                                                                    <Badge badgeContent={"!"} color="error">
                                                                        {children}
                                                                    </Badge>
                                                                </Grid>
                                                            </HtmlTooltip>
                                                        );
                                                    };

                                                    if (index === 0) {
                                                        return (
                                                            <Wrapper>
                                                                <UpperCaseText>{player.nickname} (Lagkapten),</UpperCaseText>
                                                            </Wrapper>
                                                        );
                                                    }
                                                    if (index === row.players.length - 1) {
                                                        return (
                                                            <Wrapper>
                                                                <UpperCaseText>{player.nickname}</UpperCaseText>
                                                            </Wrapper>
                                                        );
                                                    }
                                                    return (
                                                        <Wrapper>
                                                            <Grid item>
                                                                <UpperCaseText>{player.nickname},</UpperCaseText>
                                                            </Grid>
                                                        </Wrapper>
                                                    );
                                                })}
                                            </Grid>
                                        </TableCell>
                                        <TableCell align="right">
                                            <UpperCaseText>{row.playerCount}</UpperCaseText>
                                        </TableCell>
                                        <TableCell align="right">
                                            <UpperCaseText>{row.mmr}</UpperCaseText>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
};
