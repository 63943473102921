import React from "react";
import { PageTitle } from "../../layout/PageTitle";
import { Typography, Box, Grid, Container, withStyles, Paper } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { Constants } from "../../constants";
import { SeriesTabs } from "./components/SeriesTabs";
import SponsorPaper from "./../../layout/components/SponsorPaper";

export const Series = (props) => {
    const [series, setSeries] = React.useState();

    React.useEffect(() => {
        fetch(Constants.apiPrefix + `/api/series/${props.match.params.seriesId}`)
            .then((response) => response.json())
            .then((series) => setSeries(series));
    }, []);

    const getHomeLogo = () => {
        if (series && series.hometeam) {
            return series.hometeam.teamlogo.includes("discordapp") ? series.hometeam.teamlogo : `${Constants.apiPrefix}/api/${series.hometeam.teamlogo}`;
        }
        return null;
    };

    const getAwayLogo = () => {
        if (series && series.awayteam) {
            return series.awayteam.teamlogo.includes("discordapp") ? series.awayteam.teamlogo : `${Constants.apiPrefix}/api/${series.awayteam.teamlogo}`;
        }
        return null;
    };

    if (series && series.hometeam && series.awayteam && series.matches) {
        return (
            <>
                <PageTitle>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                        <Grid item>
                            <img height={130} src={getHomeLogo()} />
                        </Grid>
                        <Grid item>
                            <Typography variant="h2" style={{ fontWeight: 1000 }}>
                                {series && series.hometeam && series.awayteam && `${series.hometeam.score} - ${series.awayteam.score}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img height={130} src={getAwayLogo()} />
                        </Grid>
                    </Grid>
                </PageTitle>
                <SponsorPaper>
                    <Container>
                        <Box py={4}>
                            <Grid container direction="row" justify="space-around" alignItems="center">
                                <Grid item>
                                    <Typography variant="h4">{series && series.info && series.info.season}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4">{series && series.info && series.info.division}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4">{series && series.info && series.info.date.substring(0, series.info.date.length - 3)}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </SponsorPaper>
                <Box my={5} />
                <Container>
                    <SeriesTabs series={series} />
                </Container>
                <Box my={5} />
            </>
        );
    }

    return (
        <>
            <PageTitle>
                <Trans>match.could-not-find</Trans>
            </PageTitle>
            <SponsorPaper>
                <Box py={1} />
            </SponsorPaper>
        </>
    );
};
