import React from "react";
import {
    Box,
    CircularProgress,
    Container,
    Grid,
} from "@material-ui/core";
import { PageTitle } from "../PageTitle";
import SponsorPaper from "./SponsorPaper";

export const InPageCircularLoader = () => {
    return (
        <>
            <PageTitle />
            <SponsorPaper>
                <Box pt={1} />
            </SponsorPaper>
            <Container>
                <Box mt={5}>
                    <Grid container justify="center">
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Box my={35} />
        </>
    );
}