import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Trans, useTranslation } from 'react-i18next';

const StyledRow = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
    }
}))(TableRow);

const StyledCell = withStyles((theme) => ({
    root: {
        color: 'black'
    }
}))(TableCell);

export const MatchTeamTable = (props) => {
    return (
        <TableContainer component={Paper} style={{ borderRadius: 0 }}>
            <Table>
                <TableHead>
                    <StyledRow>
                        <StyledCell align="left"><Trans>league.player</Trans></StyledCell>
                        <StyledCell align="right"><Trans>league.points</Trans></StyledCell>
                        <StyledCell align="right"><Trans>league.goals</Trans></StyledCell>
                        <StyledCell align="right"><Trans>league.assists</Trans></StyledCell>
                        <StyledCell align="right"><Trans>league.saves</Trans></StyledCell>
                        <StyledCell align="right"><Trans>league.shots</Trans></StyledCell>
                    </StyledRow>
                </TableHead>
                <TableBody>
                    {props.teamMatch && props.teamMatch.map((player, index) => {
                        return (
                            <TableRow>
                                <TableCell align="left">{player.nickname}</TableCell>
                                <TableCell align="right">{player.points}</TableCell>
                                <TableCell align="right">{player.goals}</TableCell>
                                <TableCell align="right">{player.assists}</TableCell>
                                <TableCell align="right">{player.saves}</TableCell>
                                <TableCell align="right">{player.shots}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
