import React, { useContext } from "react";
import { Button, Box, Container, Divider, FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from '@material-ui/core/styles';
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import Cookies from 'universal-cookie';

import { Constants } from "./../../../../../constants";
import { TextField } from "./../../../../../components/TextField";
import { RadioGroup } from "./../../../../../components/RadioGroup";
import { StateContext } from "./../../../../../discord/StateProvider";
import {useTranslation} from "react-i18next";
import { NewsService} from "../../../../../services/NewsService";
import { ImageService } from "../../../../../services/ImageService";
import { UploadImage } from "../../../../../components/UploadImage";
import "react-mde/lib/styles/css/react-mde-all.css";

const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
});

const useStyles = makeStyles((theme) => ({
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

const getCurrentDate = () => {
    const addZeroIfNeeded = (number) => {
        return number < 10 ? `0${number}` : number;
    };
    const date = new Date();
    const year = date.getFullYear();
    const month = addZeroIfNeeded(date.getMonth() + 1);
    const day = addZeroIfNeeded(date.getDate());
    const hours = date.getHours();
    const minutes = date.getMinutes();

    

return `${year}-${month}-${day} ${hours}:${minutes}:00`;
}

export const AddNews = () => {
    const cookies = new Cookies();
    const classes = useStyles();
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(true);
    const [content, setContent] = React.useState("**Hello world!!!**");
    const [categories, setCategories] = React.useState([]);
    const [category, setCategory] = React.useState(0);
    const [thumbnail, setThumbnail] = React.useState("");
    const [image, setImage] = React.useState("");
    const [customUrl, setCustomUrl] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [title, setTitle] = React.useState("");
    const [selectedTab, setSelectedTab] = React.useState("write");
    const [saved, setSaved] = React.useState(false);
    const [publishDate, setPublishDate] = React.useState(getCurrentDate());
    const [success, setSuccess] = React.useState({
        severity: undefined,
        text: undefined,
    });
    const { currentUser } = useContext(StateContext);

    React.useEffect(() => {
        fetch(Constants.apiPrefix + `/api/admin/news/categories/get`,)
        .then(response => response.json())
        .then(response => {
            setCategories(response);
            setCategory(response[0].id);
            setLoading(false);
        })
    }, []);

    const save = (event) => {
        event.preventDefault();

        NewsService.save(cookies.get("accessToken"), parseInt(currentUser.id), category, thumbnail, image, customUrl, description, title, content, publishDate)
        .then(response => {
            if (response.hasOwnProperty("CODE") && response.hasOwnProperty("MESSAGE") && response.CODE === 200) {
                setSuccess({
                    severity: "success",
                    text: t(response.MESSAGE)
                });
            } else if(response.hasOwnProperty("CODE") && response.CODE !== 500){
                setSuccess({
                    severity: "error",
                    text: t(response.MESSAGE)
                });
            } else {
                throw new Error("admin.news.components.AddNews.save");
            }
            setSaved(true);
        });
    }

    const handleInput = (e, setter) => {
        e.preventDefault();

        setter(e.target.value);
    }

    return (
        loading ? <></> : <Container>
            <Grid container item xs={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Kategori:</FormLabel>
                    <RadioGroup aria-label="Kategori" name="category" value={category} onChange={(e) => setCategory(parseInt(e.target.value))}>
                        {categories.map((x, index) => (
                            <FormControlLabel key={index} value={x.id} control={<Radio />} label={x.name} />
                        ))}
                    </RadioGroup>
                </FormControl>
                <Divider variant="middle" style={{width: '100%', margin: '10px 0'}} />
                <TextField
                        fullWidth
                        id="standard-basic"
                        label="Titel"
                        onChange={(e) => handleInput(e, setTitle)}
                        />
                <Divider variant="middle" style={{width: '100%', margin: '10px 0'}} />
                <TextField
                        fullWidth
                        id="standard-basic"
                        label="Custom URL"
                        onChange={(e) => handleInput(e, setCustomUrl)}
                        helperText="t.ex ''it-avdelningen-ar-bast''"
                        />
                <Divider variant="middle" style={{width: '100%', margin: '10px 0'}} />
                <UploadImage
                    fullWidth
                    defaultImage={thumbnail && ImageService.transformImageUrl(thumbnail)}
                    croppedImageState={[thumbnail, (val)=>{
                        setThumbnail(val);
                    }]}
                    displayHeight={700}
                    displayWidth={1000}
                    width={1000}
                    height={700}
                    label='Thumbnail'
                    showValueAsText={true}
                />
                <Divider variant="middle" style={{width: '100%', margin: '10px 0'}} />
                <UploadImage
                    defaultImage={image && ImageService.transformImageUrl(image)}
                    croppedImageState={[image, (val)=>{
                        setImage(val);
                    }]}
                    displayHeight={250}
                    displayWidth={1000}
                    width={1000}
                    height={250}
                    label='Bild'
                    showValueAsText={true}
                />
                <Divider variant="middle" style={{width: '100%', margin: '10px 0'}} />
                <ReactMde 
                    value={content}
                    onChange={setContent}
                    selectedTab={selectedTab}
                    onTabChange={setSelectedTab}
                    generateMarkdownPreview={markdown => 
                        Promise.resolve(converter.makeHtml(markdown))
                    }
                />
                <Divider variant="middle" style={{width: '100%', margin: '10px 0'}} />
                <TextField
                        fullWidth
                        id="standard-basic"
                        label="Beskrivning"
                        onChange={(e) => handleInput(e, setDescription)}
                        />

                <Divider variant="middle" style={{width: '100%', margin: '10px 0'}} />
                <TextField 
                    fullWidth 
                    id="publish-date"
                    label="Publiceringsdatum"
                    value={publishDate}
                    onChange={(e)=> handleInput(e, setPublishDate)} />
            </Grid>
            <Box pt={2} />
            <Grid container item xs={12}>
                <Button variant="contained" color="secondary" onClick={save}>Spara nyhet</Button>
            </Grid>
            {saved && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Alert severity={success.severity}>
                        {success.text}
                    </Alert>
                </Paper>
            )}
        </Container>
    );
}
