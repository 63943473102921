import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#e3e3e3',
            contrastText: '#FFF',
            color: '#000',
            focus: '#EDA11A'
        },
        secondary: {
            main: '#EDA11A',
            contrastText: '#FFF',
            color: '#000'
        },
        text: {
            primary: '#000',
            secondary: '#282828'
        },
        type: 'light'
    },
    typography: {
        fontFamily: [
            'Oswald',
            'Arial',
            'sans-serif',
        ].join(','),
    }
});

export default theme;