import React from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { PageTitle } from '../../layout/PageTitle';
import SponsorPaper from '../../layout/components/SponsorPaper';
import { PageContent } from '../../components/PageContent';

export const Prizes = () => {
    useTranslation();

    return (
        <Box>
            <PageTitle>
                <Typography variant="h2" style={{ fontWeight: 700 }}>
                    <Trans>menu.prizes</Trans>
                </Typography>
            </PageTitle>
            <SponsorPaper>
                <Box pt={1} />
            </SponsorPaper>
            <Box my={5} />
            <Container style={{ minHeight: '500px' }}>
                <PageContent page={"prizes"} showLatestUpdated={true}></PageContent>
            </Container>
            <Box my={10} />
        </Box>
    )
}