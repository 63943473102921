import React from 'react';
import { Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { Constants } from "../../../../constants";

export const Seasons = () => {
    const [seasons, setSeasons] = React.useState([]);

    const getSeasons = async () => {
        const r = await fetch(`${Constants.apiPrefix}/api/season/all`);
        return await r.json();
    }

    React.useEffect(() => {
        getSeasons().then(r => setSeasons(r));
    }, [])

    return (
        <Container>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Namn</TableCell>
                            <TableCell>Startdatum</TableCell>
                            <TableCell>Slutdatum</TableCell>
                            <TableCell>Antalet divisioner</TableCell>
                            <TableCell>Format</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {seasons && seasons.length > 0 && seasons.map((season) => (
                            <TableRow key={season.id}>
                                <TableCell>{season.id}</TableCell>
                                <TableCell>{season.name}</TableCell>
                                <TableCell>{season.startdate}</TableCell>
                                <TableCell>{season.enddate}</TableCell>
                                <TableCell>{season.numberDivisions}</TableCell>
                                <TableCell>{season.format}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}