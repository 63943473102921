import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, Grid, Container, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import Markdown from 'markdown-to-jsx';
import { Trans, useTranslation } from 'react-i18next';

import { PageTitle } from '../../layout/PageTitle';
import SponsorPaper from '../../layout/components/SponsorPaper';
import { Constants } from '../../constants';
import { LoadingContent } from '../../components/LoadingContent';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    }
}));

export const FAQ = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const [faq, setFAQ] = React.useState();
    const [categories, setCategories] = React.useState();
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        fetch(Constants.apiPrefix + `/api/faq/` + i18n.language)
            .then(response => response.json())
            .then(data => {
                setFAQ(data);
                const cat = data.map(x => { return x.category }).filter((x, i, a) => a.indexOf(x) == i);
                setCategories(cat);
                setLoading(false);
            })
    }, [i18n.language])

    function getFAQForCategory(category) {
        return faq.filter(x => x.category === category);
    }

    return (
        <React.Fragment>
            <PageTitle>
                <Trans>menu.faq</Trans>
            </PageTitle>
            <SponsorPaper>
                <Box pt={1} />
            </SponsorPaper>
            <Box my={5} />
            {loading ? <LoadingContent /> : <Container id="faq">
                <Grid container direction="row" justify="space-evenly" alignItems="center">
                    <Grid item xs={12}>
                        {categories.map((category, categoryIndex) => {
                            return (
                                <React.Fragment key={categoryIndex}>
                                    <Typography variant="h4" className={`${categoryIndex > 0 ? "heading-margin" : ""}`}>
                                        {t(`faq.${category}`)}
                                    </Typography>
                                    <div className={classes.root}>
                                        {getFAQForCategory(category).map((faq, index) => {
                                            return (
                                                <Accordion key={index}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls={`${category}-${index}-content`}
                                                        id={`${category}-${index}-header`}>
                                                        <Typography>{faq.header}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Markdown>
                                                            {faq.content}
                                                        </Markdown>
                                                    </AccordionDetails>
                                                </Accordion>
                                            )
                                        })}
                                    </div>
                                </React.Fragment>
                            );
                        })}
                    </Grid>
                </Grid>
            </Container>}
            <Box my={10} />
        </React.Fragment>
    )
}