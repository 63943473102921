import React from "react";
import { useContext } from "react";
import Button from "@material-ui/core/Button";
import { Box, CircularProgress, Fab, Grid, IconButton, List, ListItem, makeStyles, MenuItem, Paper } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Trans } from "react-i18next";
import { Constants } from "../../../../../constants";
import { TextField } from "../../../../../components/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { SnackbarContext } from "./../../../../../context/SnackbarProvider";
import Cookies from "universal-cookie";
import { Add, Clear } from "@material-ui/icons";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

async function getTeams(divisionId) {
    const r = await fetch(`${Constants.apiPrefix}/api/division/${divisionId}/teamsWithoutPlayers`);
    return await r.json();
}

async function getAllTeams() {
    const r = await fetch(`${Constants.apiPrefix}/api/team/all`);
    return await r.json();
}

export default function TeamFormDialog(props) {
    const theme = useTheme();
    const { division, updateDivisions } = props;
    const classes = useStyles();
    const cookies = new Cookies();
    const [open, setOpen] = React.useState(false);
    const [teams, setTeams] = React.useState([]);
    const [allTeams, setAllTeams] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const { setSnackbar } = useContext(SnackbarContext);

    const [addedNew, setAddedNew] = React.useState(false);

    // Functions
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const saveTeams = async (teams) => {
        const r = await fetch(`${Constants.apiPrefix}/api/division/saveTeams`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                accessToken: cookies.get("accessToken"),
                teamIds: teams.map((team) => team.teamId),
                divisionId: division.divisionId,
            }),
        });
        return await r.json();
    };

    const clickSave = () => {
        setLoading(true);
        saveTeams(teams).then((r) => {
            updateDivisions().then(() => {
                if (r.hasOwnProperty("CODE") && r.CODE === 200) {
                    setSnackbar({
                        open: true,
                        severity: "success",
                        message: r.MESSAGE,
                    });
                } else if (r.hasOwnProperty("CODE") && r.CODE !== 500) {
                    setSnackbar({
                        open: true,
                        severity: "success",
                        message: r.MESSAGE,
                    });
                } else {
                    throw new Error(r.MESSAGE);
                }
                setLoading(false);
                handleClose();
            });
        });
    };

    const appendToList = (event) => {
        setAddedNew(true);
        setTeams((teams) => [
            ...teams,
            {
                teamId: null,
                teamname: "",
                teamlogo: "",
            },
        ]);
    };

    const removeFromList = (index) => {
        console.log(`Removing index: ${index}`);
        let tmpTeams = [...teams];
        tmpTeams.splice(index, 1);
        setTeams(tmpTeams);
    };

    // Effects
    React.useEffect(() => {
        if (open) {
            getAllTeams().then((r) => setAllTeams(r.MESSAGE));
            getTeams(division.divisionId, division.seasonId).then((r) => setTeams(r.MESSAGE));
        }
    }, [division, open]);

    return (
        <div>
            <MenuItem key={`teams`} onClick={handleClickOpen}>
                <Trans>admin.division.teams</Trans>
            </MenuItem>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth key={`dialog-${division.divisionId}`}>
                <DialogTitle id="form-dialog-title">Divisionslag</DialogTitle>
                <DialogContent>
                    <List>
                        {teams.length > 0 &&
                            teams.map((team, index) => (
                                <ListItem>
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Autocomplete
                                                key={`autocomplete-${index}`}
                                                value={teams[index]}
                                                id="all-teams"
                                                options={allTeams}
                                                getOptionLabel={(team) => (team.teamId !== -1 ? team.teamname : "")}
                                                getOptionSelected={(option, value) => option.id === value.id}
                                                renderOption={(team) => <>{team.teamname}</>}
                                                onChange={(event, selectedTeam) => {
                                                    console.log(selectedTeam);
                                                    if (selectedTeam) {
                                                        let tmpTeams = [...teams];
                                                        let team = {
                                                            ...tmpTeams[index],
                                                        };
                                                        team.teamId = selectedTeam.teamId;
                                                        team.teamname = selectedTeam.teamname;
                                                        team.teamlogo = selectedTeam.teamlogo;
                                                        tmpTeams[index] = team;
                                                        setTeams(tmpTeams);
                                                    } else {
                                                        let tmpTeams = [...teams];
                                                        let team = {
                                                            ...tmpTeams[index],
                                                        };
                                                        team.teamId = -1;
                                                        team.teamname = undefined;
                                                        team.teamlogo = undefined;
                                                        tmpTeams[index] = team;
                                                        setTeams(tmpTeams);
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField key={`textfield-${index}`} fullWidth {...params} variant="outlined" label={`Lag ${index + 1}`} />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <IconButton onClick={() => removeFromList(index)}>
                                                <Clear />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            ))}
                    </List>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Box mb={2} mr={2}>
                                <Fab color="secondary" onClick={appendToList}>
                                    <Add />
                                </Fab>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="primary">
                        Tillbaka
                    </Button>
                    <div className={classes.wrapper}>
                        <Button disabled={loading} onClick={clickSave} color="secondary" variant="contained">
                            Spara
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}
