import React from 'react';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import {Trans, useTranslation} from 'react-i18next';

import { PageTitle } from '../../layout/PageTitle';
import SponsorPaper from '../../layout/components/SponsorPaper';

export const HelpUs = () => {

    useTranslation();

    return (
        <Box>
            <PageTitle>
                <Typography variant="h2" style={{ fontWeight: 700 }}><Trans>help-us.title</Trans></Typography>
            </PageTitle>
            <SponsorPaper>
                <Box pt={1} />
            </SponsorPaper>
            <Box my={5} />
            <Container>
                <Grid container direction="column" spacing={5}>
                    <Grid item>
                        <Typography>
                            <Trans>help-us.desc.part-1 <a href="https://forms.gle/d36jA2vRf2MTUQFr6" target="blank" className="srl-link">form-link</a></Trans>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            <Trans>help-us.desc.part-2</Trans>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            <Trans>help-us.desc.part-3</Trans>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <img src="/images/swish.png" alt="Swish" />
                    </Grid>
                </Grid>
            </Container>
            <Box my={10} />
        </Box>
    )
}