import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Notifications } from "@material-ui/icons";
import { Badge, IconButton, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

import { NotificationContext } from "../../context/NotificationProvider";

export const NotificationButton = (props) => {
    const { t } = useTranslation();
    const unreadNotifications = useContext(NotificationContext).unreadNotifications
    if (props.inDrawer !== undefined && props.inDrawer) {
        return (
            <ListItem button component={Link} to='/notifications' onClick={props.onClick} key='notifications' >
                <ListItemIcon style={{ minWidth: '40px' }}>
                    <Badge badgeContent={unreadNotifications} color="error">
                        <Notifications />
                    </Badge>
                </ListItemIcon>
                <ListItemText primary={t("notification.title")} />
            </ListItem>
        )
    }
    return (
        <IconButton component={Link} to="/notifications" style={{ color: "black" }}>
            <Badge badgeContent={unreadNotifications} color="error">
                <Notifications />
            </Badge>
        </IconButton>

    )
}