import React from 'react';
import { ArrowDropDown } from '@material-ui/icons';
import { Box, Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { Link } from "react-router-dom";
import { Trans } from 'react-i18next';

import { UpperCaseText } from './UpperCaseText';

export default function LeagueMenu() {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <Box component="span">
            <Button
                color="inherit"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                style={{ fontSize: '16px' }}
            >
                <UpperCaseText>
                    <Trans>menu.information</Trans>
                </UpperCaseText>
                <ArrowDropDown />
            </Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    <MenuItem onClick={handleToggle} component={Link} to="/about"><Trans>menu.about</Trans></MenuItem>
                                    <MenuItem onClick={handleToggle} component={Link} to="/cooperation"><Trans>menu.collaborations</Trans></MenuItem>
                                    <MenuItem onClick={handleToggle} component={Link} to="/rules"><Trans>menu.rules</Trans></MenuItem>
                                    <MenuItem onClick={handleToggle} component={Link} to="/code-of-conduct"><Trans>menu.code-of-conduct</Trans></MenuItem>
                                    <MenuItem onClick={handleToggle} component={Link} to="/statutes"><Trans>menu.statutes</Trans></MenuItem>
                                    <MenuItem onClick={handleToggle} component={Link} to="/faq"><Trans>menu.faq</Trans></MenuItem>
                                    <MenuItem onClick={handleToggle} component={Link} to="/prizes"><Trans>menu.prizes</Trans></MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
}