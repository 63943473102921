import React, { useContext } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Constants } from "../constants";
import { CustomThemeContext } from "../theme/CustomThemeProvider";

const useStyles = makeStyles(() => ({
    parentSection: {
        overflow: "hidden",
    },
    section: {
        position: "relative",
        width: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    },
    pageTitle: {
        zIndex: 10,
        color: "white",
        textTransform: "uppercase",
        fontWeight: 700,
    },
}));

export const PageTitle = (props) => {
    const classes = useStyles();
    let minHeight = 400;
    if (props.minHeight !== undefined) {
        minHeight = props.minHeight;
    }
    console.log("re rendering pagetitle");
    const { currentBackground } = useContext(CustomThemeContext);

    const shadow = props.shadow !== undefined ? { textShadow: "black 2px 2px 10px" } : {};

    const hideTopBox = props.hideTopBox;

    let variant = "h2";
    if (props.variant !== undefined) {
        variant = props.variant;
    }
    return (
        <React.Fragment>
            <Grid
                container={hideTopBox !== true}
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.section}
                style={{
                    minHeight: minHeight,
                    backgroundImage: props.backgroundImage ? `url(${props.backgroundImage})` : currentBackground,
                    backgroundPosition: "center",
                }}
            >
                {!hideTopBox ? <Box p={10} /> : null}
                <Grid item container={hideTopBox !== true} direction="row" justify="center" alignItems="center">
                    <Typography variant={variant} className={classes.pageTitle} style={shadow}>
                        {props.children}
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};
