import { Constants } from '../constants';

export const ImageService = {

    transformImageUrl : (imageUrl) => {
        if(imageUrl && imageUrl.startsWith("database_images"))
            return `${Constants.apiPrefix}/api/${imageUrl}`;
        return imageUrl;
    }

}