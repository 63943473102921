import { ColorLens } from "@material-ui/icons";

require("dotenv").config();

export const Constants = {
    apiPrefix: process.env.REACT_APP_API_PREFIX,
    basePrefix: process.env.REACT_APP_BASE_PREFIX,
    imagePrefix: process.env.REACT_APP_IMAGE_PREFIX,
    googlePrivateKey:
        "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCqigz30FZZDccE\nYFAAsUmdezLbPbw9fjFV/qCxxYLeOUIRSqzM8SeJFfR3y5xrZf6H9FsjoNAVbqqE\nCUHTawsWds3zH0fD5dIG9ntL/J49GoBCfPZ1pMabsURpfdET7fNf2+J+TXP0hzve\n9NTJNeCADL3uC0dqaiGFjWDTmgKBHZDI0zeMwuR3+/cz2MVVBZKNraV4O9IY7koV\nGpfR28Sk5lThwOwemJduxSmjtH2nLoFKUSpBaCo/5NpmGoucqc7eZSg/BTCdfJ4Q\nFYw+J5WalkpcVpueZNpN+m+R2lUvLfuMqUBPqRG660ksIQacXdrZotAedT88quN/\ndbPHD0EBAgMBAAECggEAJTATYdqhaavycQbbizsdtWSPE3Xj3XjuIzVyLzHFttW/\njWu+QLZ5i5ApDhJtWpIULetgEGXZ2kKWllI6c3LNKSnhARoaGSK9aN1Ix6ofi22C\n+Hytdbvnvz2pkGNqOzFRN98IBci392Oh751t5S6FdQbSR91XxmG8S5YnvhzAXQ0M\nkbcGGBZH7zxccflGQGyPINQv+LuVC3vgS2JNYg/Af7Pb7gtO3Uz9/OubMNn4ESbe\nk8yGnm1nyzU/qNx3EmuzP5ojiU05fUvUCl1iXAtwCiSG0RK1YJl5AjT+y/ERtb4G\nqAicrOhAg47PJH5NQJ/LuUvYVUKUftzVHy+AzypWDQKBgQDtEyRXLY/yQDzJfxHV\nyMYucGOtBsJ/aRL7E5JT8dclQ7lj54u6ulsU8EBgt1jRfwRE6qth1mzylJ52F/Rm\n2q2MGSa5r41EoVwevX+2uZGed6KpHIZXNYlN+bKnxDHD8oBH7YAAvgbIXwpbDAog\nimFmSC/r81N7MMNq8dOcf2vq5wKBgQC4JzCE2bhB0MaCxVDvslEiIexAVL+1aAaF\nBy6mEdUtjMUxkyFGYAh/uB4RTZIzxuDr106b4tbZbwgS1VIxmotSrBGTBi9E2AIF\nSxJtLsjNUPqZUiEn6/x2SJnORW3/MVOALGc72fzCa7589g6FEhe/xFAFRUBLaPC7\nfsWT0c0f1wKBgCt3hwI06F+SR5LqKWr5FN0O3jerNZ4GteQzAnFBtiCu+h7HJWdn\nI6wDIMqa0z4V+8JnBGU7213ewe8FFVqGXauCMQd6mHws2acJN5FZFMnpRXmcMUVs\nG3Cgh5Ea98TlgX+um/mG3dd/FXb8Dh9JP/Jw6dhpFLVRi4LgM9xNwIuhAoGAG4GX\nCLDwibwiepP4c+Uho0SH4lAGVpVUK/iXqiyNuAEHz1DXrohXnKQmsZTxmpRBMlj1\nn+kxCEdCYHXGkiwwVUfoVBqfYtwU6S9+cBqF2c0XsWEIHMtt6UkM2Decp3p5q3J2\ndQUpcOzhBg9Gue+q5nTvMupWIS1qj8buwhabWf8CgYEAiAy+Aq3CUlwvUIA/o/zr\n9MfmTOfeZqTnD0Cb8v+FTCa8iZyLWrDVVeh2Bhgs3DdG0D5IOYcYYuRBKNFvC6eK\ntVMp1jxf5IfdUTr685LJqxUmwl/awWEGnlmU2MULwOSeJ9nEA7HZh7DUD7ZClJsd\nE5B2rqM7hApcMypchMoaIiU=\n-----END PRIVATE KEY-----\n",
    googleClientEmail: "srl-901@discord-bot-230620.iam.gserviceaccount.com",
    googleDocId: "1_ohh4KkkT0Q6SjrHjGiXzmbTEBZJc-CyB6RY5wNxoj8",
    replayApi: "https://207.154.215.26/parse-replay",
    replayApiKey: "gUj=Jt9@Mggs3NfbCJ+CCeXVf8Tpg+2a$jwzty$#?f_*q?!GR#6EkZequ7usN4=egqR$BZ",
    SvenskaRaketliganGuildId: "549361926140854322",
    loginError: 1,
    discordClientID: "859398163193659392",
    discordClientSecret: "6ggd4VUMaQUQM92INa3fvaL6vBU1EJ8b",
    userLoaded: "userLoaded",
    AdminRole: 1,
    DivisionAdmin: 2,
    rankSColor: "#FF00FF",
    rankXColor: "#00FFFF",
    rankAColor: "#1E90FF",
    rankBColor: "#228B22",
    rankCColor: "#DAA520",
    rankDColor: "#D2691E",
    rankEColor: "#DEB887",
    backgroundWhite: "/images/bg-white-desk.png",
    backgroundDark: "/images/background.png",
    roles: {
        admin: 1,
        divisionadmin: 2,
        graphics: 3,
        writer: 4,
    },
    settings: {
        currentSeason: "current_season",
        registrationOpen: "registration_open",
        transferOpen: "transfer_open",
        startContent: "start_content",
        startContentEng: "start_content_eng",
        startType: "start_type",
        startTypeValue: {
            custom: "custom",
            matches: "matches",
        },
    },
    pages: {
        prizes: "prizes",
    },
    language: {
        swedish: "se",
        english: "en",
    },
};
