export const swedishCountries = {
  AF: "Afghanistan",
  AX: "Åland",
  AL: "Albanien ",
  DZ: "Algeriet ",
  AS: "Amerikanska Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarktis",
  AG: "Antigua och Barbuda",
  AR: "Argentina",
  AM: "Armenien",
  AW: "Aruba",
  AU: "Australien",
  AT: "Österrike ",
  AZ: "Azerbajdzjan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgien",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia",
  BQ: "Bonaire, Sint Eustatius and Saba",
  BA: "Bosnien och Hercegovina",
  BW: "Botswana",
  BV: "Bouvetön",
  BR: "Brasilien",
  IO: "Brittiska territoriet i Indiska oceanen",
  BN: "Brunei",
  BG: "Bulgarien",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Kambodja",
  CM: "Kamerun",
  CA: "Kanada",
  CV: "Cap Verde",
  KY: "Caymanöarna",
  CF: "Centralafrikanska republiken",
  TD: "Tchad ",
  CL: "Chile",
  CN: "Kina",
  CX: "Julön",
  CC: "Kokosöarna",
  CO: "Colombia",
  KM: "Komorerna",
  CG: "Kongo",
  CD: "Kongo, Demokratiska republiken",
  CK: "Cooköarnas",
  CR: "Costa Rica",
  CI: "Elfenbenskusten",
  HR: "Kroatien",
  CU: "Kuba",
  CW: "Curaçao",
  CY: "Cypern",
  CZ: "Tjeckien",
  DK: "Danmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominikanska republiken",
  EC: "Ecuador",
  EG: "Egypten",
  SV: "El Salvador",
  GQ: "Ekvatorialguinea",
  ER: "Eritrea",
  EE: "Estland",
  ET: "Etiopien",
  FK: "Falklandsöarna (Malvinerna)",
  FO: "Färöarna",
  FJ: "Fiji",
  FI: "Finland",
  FR: "Frankrike",
  GF: "Franska Guyana",
  PF: "Franska Polynesien",
  TF: "Franska sydterritorierna",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgien",
  DE: "Tyskland",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Grekland",
  GL: "Grönland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard- och McDonaldöarna",
  VA: "Vatikanstaten",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Ungern",
  IS: "Island",
  IN: "Indien",
  ID: "Indonesien",
  IR: "Iran",
  IQ: "Irak",
  IE: "Irland",
  IM: "Isle of Man",
  IL: "Israel",
  IT: "Italien",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordanien",
  KZ: "Kazakstan",
  KE: "Kenya",
  KI: "Kiribati",
  XK: "Kosovo",
  KP: "Nordkorea",
  KR: "Sydkorea",
  KW: "Kuwait",
  KG: "Kirgizistan",
  LA: "Laos",
  LV: "Lettland ",
  LB: "Libanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libyen",
  LI: "Liechtenstein",
  LT: "Litauen",
  LU: "Luxemburg",
  MO: "Macao",
  MK: "Nordmakedonien",
  MG: "Madagaskar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldiverna",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshallöarna",
  MQ: "Martinique",
  MR: "Mauretanien",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexiko",
  FM: "Mikronesiska federationen",
  MD: "Moldavien",
  MC: "Monaco",
  MN: "Mongoliet",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Marocko",
  MZ: "Moçambique",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Nederländerna ",
  AN: "Nederländska Antillerna",
  NC: "Nya Kaledonien",
  NZ: "Nya Zeeland",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Nordmarianerna",
  NO: "Norge",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestina",
  PA: "Panama",
  PG: "Papua Nya Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Filippinerna",
  PN: "Pitcairnöarna",
  PL: "Polen",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RE: "Réunion",
  RO: "Rumänien",
  RU: "Ryssland",
  RW: "Rwanda",
  BL: "Saint-Barthélemy",
  SH: "Sankta Helena, Ascension och Tristan da Cunha",
  KN: "Saint Kitts och Nevis",
  LC: "Saint Lucia",
  MF: "Saint-Martin",
  PM: "Saint-Pierre och Miquelon",
  VC: "Saint Vincent och Grenadinerna",
  WS: "Samoa",
  SM: "San Marino",
  ST: "São Tomé och Príncipe",
  SA: "Saudiarabien",
  SN: "Senegal",
  RS: "Serbien",
  SC: "Seychellerna",
  SL: "Sierra Leone",
  SG: "Singapore",
  SX: "Sint Maarten",
  SK: "Slovakien",
  SI: "Slovenien",
  SB: "Salomonöarna",
  SO: "Somalia",
  ZA: "Sydafrika",
  GS: "Sydgeorgien och Sydsandwichöarna",
  SS: "Sydsudan",
  ES: "Spanien",
  LK: "Sri Lanka",
  SD: "Sudan",
  SR: "Surinam",
  SJ: "Svalbard och Jan Mayen",
  SZ: "Eswatini",
  SE: "Sverige",
  CH: "Schweiz",
  SY: "Syrien",
  TW: "Taiwan",
  TJ: "Tadzjikistan",
  TZ: "Tanzania",
  TH: "Thailand",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad och Tobago",
  TN: "Tunisien",
  TR: "Turkiet",
  TM: "Turkmenistan",
  TC: "Turks- och Caicosöarna",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraina",
  AE: "Förenade Arabemiraten",
  GB: "Storbritannien",
  US: "USA",
  UM: "Förenta staternas mindre öar i Oceanien och Västindien",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Vietnam",
  VG: "Brittiska Jungfruöarna",
  VI: "Amerikanska Jungruöarna ",
  WF: "Wallis- och Futunaöarna",
  EH: "Västsahara",
  YE: "Jemen",
  ZM: "Zambia",
  ZW: "Zimbabwe"
};
