import React from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import Cookies from "universal-cookie";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { Constants } from "../../../../../../constants";
import { TextField } from "../../../../../../components/TextField";
import {SnackbarContext} from "../../../../../../context/SnackbarProvider";
import {StateContext} from "../../../../../../discord/StateProvider";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

async function denyTransferRequest(accessToken, request, reason) {
    const r = await fetch(`${Constants.apiPrefix}/api/admin/action/denyTransferRequest`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            accessToken: accessToken,
            requestId: request.id,
            reason: reason,
        }),
    });
    return await r.json();
}

export const DenyDialog = (props) => {
    const { request, setState, updateRequestCounter } = props;
    const { setSnackbar } = React.useContext(SnackbarContext);
    const { currentUser } = React.useContext(StateContext);
    const cookies = new Cookies();
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    // Dialog settings
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [reason, setReason] = React.useState("");
    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    const handleDialogOpen = () => {
        setDialogOpen(true);
    }

    // Loading button settings
    const [loading, setLoading] = React.useState(false);

    const denyRequest = () => {
        setLoading(true);
        denyTransferRequest(cookies.get("accessToken"), request, reason).then(response => {
            if(response.hasOwnProperty("CODE") && response.CODE === 200) {
                setSnackbar({
                    open: true,
                    severity: "success",
                    message: t(response.MESSAGE)
                })
                setState({
                    accepted: false,
                    acceptedByNickname: currentUser.nickname,
                    acceptedDate: new Date().toISOString().replace("T", " ").substr(0, 16)
                });
            } else if(response.hasOwnProperty("CODE") && response.CODE !== 500) {
                setSnackbar({
                    open: true,
                    severity: "error",
                    message: t(response.MESSAGE)
                })
            } else {
                throw new Error("admin.requests.requestComponents.TransferRequest.DenyDialog.denyRequest");
            }
            updateRequestCounter();
            setLoading(false);
            setDialogOpen(false);
        })
    }

    return (
        <>
            <Button
                fullWidth
                variant="contained"
                style={{
                    color: "white",
                    backgroundColor: theme.palette.error.main,
                }}
                onClick={handleDialogOpen}
            >
                Neka
            </Button>
            <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Anledning</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <TextField
                            fullWidth
                            style={{ width: theme.spacing(50) }}
                            id="standard-multiline-static"
                            label="Anledning till att neka övergång"
                            multiline
                            onChange={(event) => setReason(event.target.value)}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant="contained">
                        Tillbaka
                    </Button>
                    <div className={classes.wrapper}>
                        <Button onClick={denyRequest} variant="contained" color="secondary" disabled={loading}>
                            Neka förfrågan
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );

}