import React from "react";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, 
    DialogTitle, FormControl, Paper, Typography } from "@material-ui/core";
import Cookies from "universal-cookie";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";

import { TextField } from "../../../../../../components/TextField";
import { Constants } from "../../../../../../constants";
import {SnackbarContext} from "../../../../../../context/SnackbarProvider";
import {useTranslation} from "react-i18next";
import {StateContext} from "../../../../../../discord/StateProvider";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

async function acceptExistingUserRequest(accessToken, request, selectedPlayer) {
    const r = await fetch(`${Constants.apiPrefix}/api/admin/action/acceptExistingUserRequest`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            accessToken: accessToken,
            requestId: request.id,
            playerId: selectedPlayer.id,
            nickname: selectedPlayer.nickname,
        }),
    });
    return await r.json();
}

export const AcceptDialog = (props) => {
    const { request, setState, updateRequestCounter } = props;
    const { setSnackbar } = React.useContext(SnackbarContext);
    const { currentUser } = React.useContext(StateContext);
    const { t } = useTranslation();
    const cookies = new Cookies();
    const classes = useStyles();
    const theme = useTheme();
    const content = JSON.parse(request.content);

    // Dialog settings
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [selectedPlayer, setSelectedPlayer] = React.useState();
    const [playersWithoutUsers, setPlayersWithoutUsers] = React.useState([]);
    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    const handleDialogOpen = () => {
        setDialogOpen(true);
    }

    // Loading button settings
    const [loading, setLoading] = React.useState(false);

    const acceptRequest = () => {
        setLoading(true);
        if (selectedPlayer === undefined || selectedPlayer === null) {
            setSnackbar({
                open: true,
                severity: "error",
                message: "Måste välja en spelare att koppla ihop med"
            })
            return;
        }
        acceptExistingUserRequest(cookies.get("accessToken"), request, selectedPlayer).then(response => {
            if(response.hasOwnProperty("CODE") && response.CODE === 200) {
                setSnackbar({
                    open: true,
                    severity: "success",
                    message: t(response.MESSAGE)
                })
                setState({
                    accepted: true,
                    acceptedByNickname: currentUser.nickname,
                    acceptedDate: new Date().toISOString().replace("T", " ").substr(0, 16)
                });
            } else if(response.hasOwnProperty("CODE") && response.CODE !== 500) {
                setSnackbar({
                    open: true,
                    severity: "error",
                    message: t(response.MESSAGE)
                })
            } else {
                throw new Error("admin.requests.requestComponents.ExistingUserRequest.AcceptDialog.acceptRequest");
            }
            updateRequestCounter();
            setLoading(false);
            setDialogOpen(false);
        })
    }

    React.useEffect(() => {
        fetch(new URL("/api/players/allWithoutUser", Constants.apiPrefix).toString())
            .then(response => response.json()).then(response => {
                setPlayersWithoutUsers(response);
            });
    }, [])


    return (
        <>
            <Button
                fullWidth
                variant="contained"
                style={{
                    color: "white",
                    backgroundColor: theme.palette.success.main,
                }}
                onClick={handleDialogOpen}
            >
                Hantera
            </Button>
            <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Hantera förfrågan</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Box mb={3}>
                            Sök efter en spelare och se nickname samt spelarens discord för att försöka koppla ihop med detta konto.
                        </Box>
                        <Paper variant="outlined" style={{ height: "100%", backgroundColor: theme.palette.primary.main }}>
                            <Box p={2}>
                                <Typography>{request.date.substr(0, request.date.length - 3)}</Typography>
                                <Typography>
                                    - Discord: {`${content.discordUsername}#${content.discordDiscriminator}`}
                                </Typography>
                                <Typography>- Skapad av {`${content.discordUsername}#${content.discordDiscriminator}`} ({request.userId})</Typography>
                                <Typography style={{ marginTop: theme.spacing(1) }}>
                                    {content.helpText}
                                </Typography>
                            </Box>
                        </Paper>
                        <Box my={2} />
                        <FormControl fullWidth>
                            <Autocomplete
                                value={selectedPlayer}
                                id="inactive-players"
                                options={playersWithoutUsers}
                                autoHighlight
                                getOptionLabel={(player) => `${player.nickname} (${player.discord})`}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderOption={(player) => (
                                    <React.Fragment>
                                        {player.nickname} ({player.discord})
                                    </React.Fragment>
                                )}
                                onChange={(event, selectedPlayer) => {
                                    setSelectedPlayer(selectedPlayer)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Sök bland spelare"
                                    />
                                )} />
                        </FormControl>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant="contained">
                        Tillbaka
                    </Button>
                    <div className={classes.wrapper}>
                        <Button
                            onClick={acceptRequest}
                            variant="contained"
                            color="secondary"
                            disabled={loading || (selectedPlayer === undefined || selectedPlayer === null || selectedPlayer.length === 0)}>
                            Godkänn förfrågan
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );

}