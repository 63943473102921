import React, { useState } from 'react';
import { Constants } from "../constants";
import Cookies from 'universal-cookie';


async function getNumberUnreadNotifications(accessToken) {
    const response = await fetch(new URL(Constants.apiPrefix + `/api/user/hidden/${accessToken}/unreadNotifications`).toString());
    return await response.json();
}

export const NotificationContext = React.createContext({
    unreadNotifications: 0,
    setUnreadNotifications: null,
    updateUnreadNotifications: null,
});

const NotificationProvider = (props) => {
    const { children } = props;
    const cookies = new Cookies();
    const [unreadNotifications, _setUnreadNotifications] = useState(undefined);
    const [intervalId, setIntervalId] = React.useState();

    const setUnreadNotifications = (unreadNotifications) => {
        _setUnreadNotifications(unreadNotifications);
    }

    const updateUnreadNotifications = (accessToken) => {
        getNumberUnreadNotifications(accessToken).then(response => setUnreadNotifications(response));
    }

    React.useEffect(() => {
        let interval;
        if(cookies.get("accessToken")) {
            interval = setInterval(() => {
                getNumberUnreadNotifications(cookies.get("accessToken")).then(unreadNotifications => setUnreadNotifications(unreadNotifications));
            }, 5000);
        }
        return () => clearInterval(interval);
    }, [])

    const contextValue = {
        unreadNotifications: unreadNotifications,
        setUnreadNotifications: setUnreadNotifications,
        updateUnreadNotifications: updateUnreadNotifications
    }

    return (
        <NotificationContext.Provider value={contextValue}>
            {children}
        </NotificationContext.Provider>
    )
}

export default NotificationProvider;