import React from "react";
import {
    AppBar,
    Box,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogContent,
    Fab,
    IconButton,
    List,
    ListItem,
    makeStyles,
    Paper,
    Slide,
    Toolbar,
    Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CloseIcon from "@material-ui/icons/Close";
import { Add, Clear } from "@material-ui/icons";
import { Alert, Autocomplete } from "@material-ui/lab";
import Cookies from "universal-cookie";

import { Constants } from "../../../../../constants";
import { TextField } from "../../../../../components/TextField";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    fab: {
        position: "absolute",
        bottom: theme.spacing(2),
        right: theme.spacing(5),
    },
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        minWidth: "100%",
        "& label.Mui-focused": {
            color: theme.palette.primary.focus,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: theme.palette.primary.focus,
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: theme.palette.primary.focus,
            },
        },
        "& .Mui-error": {
            borderColor: theme.palette.error.main,
        },
    },
}));

async function getTeams(divisionId) {
    const r = await fetch(`${Constants.apiPrefix}/api/division/${divisionId}/teamsWithoutPlayers`);
    return await r.json();
}

async function getColors(divisionId) {
    const r = await fetch(`${Constants.apiPrefix}/api/division/id/${divisionId}/colors`);
    return await r.json();
}

async function getAllTeams() {
    const r = await fetch(`${Constants.apiPrefix}/api/team/all`);
    return await r.json();
}

async function getAllDivisionAdmins() {
    const r = await fetch(`${Constants.apiPrefix}/api/users/role/2`); // 2 is division admin
    return await r.json();
}

async function getPlayingOrders() {
    const r = await fetch(`${Constants.apiPrefix}/api/playingOrder`);
    return await r.json();
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DivisionDialog(props) {
    const theme = useTheme();
    const classes = useStyles();
    const cookies = new Cookies();
    const { division, updateDivisions } = props;
    const [open, setOpen] = React.useState(false);
    const [teams, setTeams] = React.useState([]);
    const [allTeams, setAllTeams] = React.useState([]);
    const [addedNew, setAddedNew] = React.useState(false);
    const [colors, setColors] = React.useState([]);
    const [playingOrders, setPlayingOrders] = React.useState();
    const [matchesPerWeek, setMatchesPerWeek] = React.useState();
    const [allowedConfig, setAllowedConfig] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [divisionAdmins, setDivisionAdmins] = React.useState([]);
    const [selectedDivisionAdmin, setSelectedDivisionAdmin] = React.useState();
    const [divisionName, setDivisionName] = React.useState();

    const handleChange = (event) => {
        setMatchesPerWeek(event.target.value);
    };

    React.useEffect(() => {
        if (playingOrders) {
            let noOfTeams = 0;
            teams.forEach((t) => {
                if (t.teamId) {
                    noOfTeams++;
                }
            });
            let wasTrue = false;
            playingOrders.forEach((element) => {
                if (element.noOfGamesVsOther === matchesPerWeek && element.noOfTeams === noOfTeams) {
                    wasTrue = true;
                }
            });
            setAllowedConfig(wasTrue);
        }
    }, [matchesPerWeek, teams, playingOrders]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        if (open) {
            getTeams(division.divisionId, division.seasonId).then((r) => {
                setTeams(r.MESSAGE);
            });
            getAllTeams().then((r) => {
                setAllTeams(r.MESSAGE);
            });
            getColors(division.divisionId).then((r) => setColors(r.MESSAGE));
            getAllDivisionAdmins().then((r) => setDivisionAdmins(r.MESSAGE));
            if (division) {
                setSelectedDivisionAdmin({
                    id: division.userId,
                    nickname: division.nickname,
                });
                setDivisionName(division.division);
            }
            getPlayingOrders().then((r) => setPlayingOrders(r));
        }
    }, [open]);

    const appendToList = (event) => {
        setAddedNew(true);
        setTeams((teams) => [
            ...teams,
            {
                teamId: null,
                teamname: "",
                teamlogo: "",
            },
        ]);
    };

    const appendColor = (event) => {
        setColors((colors) => [
            ...colors,
            {
                hex: theme.palette.success.main,
                fromIndex: 0,
                toIndex: 0,
            },
        ]);
    };

    const removeColor = (index) => {
        let colorsCopy = [...colors];
        colorsCopy.splice(index, 1);
        setColors(colorsCopy);
    };

    const removeFromList = (index) => {
        console.log(`Removing index: ${index}`);
        let tmpTeams = [...teams];
        tmpTeams.splice(index, 1);
        setTeams(tmpTeams);
    };

    const saveColors = async (colors) => {
        const r = await fetch(`${Constants.apiPrefix}/api/division/saveColors`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                accessToken: cookies.get("accessToken"),
                colors: colors,
                divisionId: division.divisionId,
            }),
        });
        return await r.json();
    };

    const saveTeams = async (teams) => {
        const r = await fetch(`${Constants.apiPrefix}/api/division/saveTeams`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                accessToken: cookies.get("accessToken"),
                teamIds: teams.map((team) => team.teamId),
                divisionId: division.divisionId,
            }),
        });
        return await r.json();
    };

    const saveDivisionInfo = async () => {
        const r = await fetch(`${Constants.apiPrefix}/api/division/saveDivisionInfo`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                accessToken: cookies.get("accessToken"),
                divisionId: division.divisionId,
                divisionName: divisionName,
                divisionAdminUserId: selectedDivisionAdmin ? selectedDivisionAdmin.id : null,
            }),
        });
        return await r.json();
    };

    const saveAndClose = (event) => {
        setLoading(true);
        Promise.all([saveColors(colors), saveTeams(teams), saveDivisionInfo()]).then(([saveColorsResponse, saveTeamsResponse, saveDivisionInfoResponse]) => {
            updateDivisions();
            setLoading(false);
            setOpen(false);
        });
    };

    const changeColors = (event, index) => {
        event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;
        let colorsCopy = [...colors];
        let color = { ...colorsCopy[index] };
        color[name] = value;
        colorsCopy[index] = color;
        setColors(colorsCopy);
    };

    return (
        <div>
            <Button fullWidth variant="contained" color="secondary" onClick={handleClickOpen}>
                Hantera
            </Button>
            <Dialog fullScreen open={open} onClose={handleClose} aria-labelledby="form-dialog-title" TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {division.division} ({division.season})
                        </Typography>
                        <div className={classes.wrapper}>
                            <Button disabled={loading} onClick={saveAndClose} color="secondary" variant="contained">
                                Spara
                            </Button>
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Container>
                        <Grid container direction spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Box my={2}>
                                    <Typography variant="h5">Divisionsintällningar</Typography>
                                </Box>
                                <Paper
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                    }}
                                >
                                    <Box p={3}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    label={"Divisionsnamn"}
                                                    defaultValue={divisionName}
                                                    value={divisionName}
                                                    onChange={(event) => setDivisionName(event.target.value)}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Autocomplete
                                                    value={selectedDivisionAdmin}
                                                    id="divisionAdmin"
                                                    options={divisionAdmins}
                                                    getOptionLabel={(divisionAdmin) => divisionAdmin.nickname}
                                                    getOptionSelected={(option, value) => option.id === value.id}
                                                    renderOption={(divisionAdmin) => divisionAdmin.nickname}
                                                    onChange={(event, selectedDivisionAdmin) => {
                                                        setSelectedDivisionAdmin(selectedDivisionAdmin);
                                                    }}
                                                    renderInput={(params) => <TextField fullWidth {...params} variant="outlined" label={"Divisionsansvarig"} />}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                    <InputLabel id="demo-simple-select-outlined-label">Matcher per vecka</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        variant="outlined"
                                                        value={matchesPerWeek}
                                                        onChange={handleChange}
                                                        label="Matcher per vecka"
                                                    >
                                                        <MenuItem value={1}>1</MenuItem>
                                                        <MenuItem value={2}>2</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                                {allowedConfig ? (
                                                    <Alert variant="outlined" severity="success">
                                                        Matcher kommer skapas till denna division
                                                    </Alert>
                                                ) : (
                                                    <Alert variant="outlined" severity="error">
                                                        {`Matcher kommer inte skapas för denna division då antal lag eller matcher per vecka saknar konfiguration`}
                                                    </Alert>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                                <Box my={1}>
                                    <Box my={2}>
                                        <Typography variant="h5">Färger</Typography>
                                    </Box>
                                    <Paper
                                        style={{
                                            backgroundColor: theme.palette.primary.main,
                                        }}
                                    >
                                        <List>
                                            {colors &&
                                                colors.map((color, index) => (
                                                    <Box m={2}>
                                                        <Paper>
                                                            <ListItem>
                                                                <Grid container direction="row" spacing={2} alignItems="center">
                                                                    <Grid item xs>
                                                                        <div
                                                                            style={{
                                                                                backgroundColor: color.hex,
                                                                                width: "25px",
                                                                                height: "25px",
                                                                                borderRadius: "50%",
                                                                                display: "inline-block",
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={9} container spacing={1}>
                                                                        <Grid item xs={4}>
                                                                            <TextField
                                                                                inputProps={{
                                                                                    name: "hex",
                                                                                }}
                                                                                label={"Färg"}
                                                                                value={color.hex}
                                                                                defaultValue={color.hex}
                                                                                onChange={(event) => changeColors(event, index)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <TextField
                                                                                inputProps={{
                                                                                    name: "fromIndex",
                                                                                }}
                                                                                label={"Från rad (inkl)"}
                                                                                value={color.fromIndex}
                                                                                defaultValue={color.fromIndex}
                                                                                onChange={(event) => changeColors(event, index)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <TextField
                                                                                inputProps={{
                                                                                    name: "toIndex",
                                                                                }}
                                                                                label={"Till rad (inkl)"}
                                                                                value={color.toIndex}
                                                                                defaultValue={color.toIndex}
                                                                                onChange={(event) => changeColors(event, index)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <TextField
                                                                                fullWidth
                                                                                inputProps={{
                                                                                    name: "engDescription",
                                                                                }}
                                                                                label={"English"}
                                                                                value={color.engDescription}
                                                                                defaultValue={color.engDescription}
                                                                                onChange={(event) => changeColors(event, index)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <TextField
                                                                                fullWidth
                                                                                inputProps={{
                                                                                    name: "sweDescription",
                                                                                }}
                                                                                label={"Svenska"}
                                                                                value={color.sweDescription}
                                                                                defaultValue={color.sweDescription}
                                                                                onChange={(event) => changeColors(event, index)}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs>
                                                                        <IconButton onClick={() => removeColor(index)}>
                                                                            <Clear />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </ListItem>
                                                        </Paper>
                                                    </Box>
                                                ))}
                                        </List>
                                        <Grid container justify="flex-end">
                                            <Grid item>
                                                <Box mb={2} mr={2}>
                                                    <Fab color="secondary" onClick={appendColor}>
                                                        <Add />
                                                    </Fab>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box my={2}>
                                    <Typography variant="h5">Divisionslag</Typography>
                                </Box>
                                <Paper
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                    }}
                                >
                                    <List>
                                        {teams.length > 0 &&
                                            teams.map((team, index) => (
                                                <ListItem>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs>
                                                            <Autocomplete
                                                                value={teams[index]}
                                                                id="all-teams"
                                                                options={allTeams}
                                                                getOptionLabel={(team) => team.teamname}
                                                                getOptionSelected={(option, value) => option.id === value.id}
                                                                renderOption={(team) => <>{team.teamname}</>}
                                                                onChange={(event, selectedTeam) => {
                                                                    if (selectedTeam) {
                                                                        let tmpTeams = [...teams];
                                                                        let team = {
                                                                            ...tmpTeams[index],
                                                                        };
                                                                        team.teamId = selectedTeam.teamId;
                                                                        team.teamname = selectedTeam.teamname;
                                                                        team.teamlogo = selectedTeam.teamlogo;
                                                                        tmpTeams[index] = team;
                                                                        setTeams(tmpTeams);
                                                                    } else {
                                                                        let tmpTeams = [...teams];
                                                                        let team = {
                                                                            ...tmpTeams[index],
                                                                        };
                                                                        team.teamId = -1;
                                                                        team.teamname = undefined;
                                                                        team.teamlogo = undefined;
                                                                        tmpTeams[index] = team;
                                                                        setTeams(tmpTeams);
                                                                    }
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        fullWidth
                                                                        autoFocus={addedNew && index === teams.length - 1}
                                                                        {...params}
                                                                        variant="outlined"
                                                                        label={`Lag ${index + 1}`}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <IconButton onClick={() => removeFromList(index)}>
                                                                <Clear />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            ))}
                                    </List>
                                    <Grid container justify="flex-end">
                                        <Grid item>
                                            <Box mb={2} mr={2}>
                                                <Fab color="secondary" onClick={appendToList}>
                                                    <Add />
                                                </Fab>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
            </Dialog>
        </div>
    );
}
