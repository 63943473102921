import React from "react";
import { Box, Button, Container, FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from '@material-ui/core/styles';
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import Cookies from 'universal-cookie';

import "react-mde/lib/styles/css/react-mde-all.css";

import { Constants } from "./../../../../constants";

const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
});

const useStyles = makeStyles((theme) => ({
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

export const Pages = () => {
    const cookies = new Cookies();
    const classes = useStyles();

    const [page, setPage] = React.useState("");
    const [loadedPage, setLoadedPage] = React.useState(false);
    const [contentSwe, setContentSwe] = React.useState("");
    const [contentEng, setContentEng] = React.useState("");
    const [selectedTabSwe, setSelectedTabSwe] = React.useState("write");
    const [selectedTabEng, setSelectedTabEng] = React.useState("write");
    const [saved, setSaved] = React.useState(false);
    const [success, setSuccess] = React.useState({
        severity: undefined,
        text: undefined,
    });

    React.useEffect(() => {
        if (page !== "") {
            fetch(Constants.apiPrefix + `/api/admin/page/${page}/get`)
                .then(response => response.json())
                .then(response => {
                    const swedishContent = response.find(x => x.language === Constants.language.swedish);
                    const englishContent = response.find(x => x.language === Constants.language.english);
                    setContentSwe(swedishContent.content);
                    setContentEng(englishContent.content);
                    setLoadedPage(true);
                });
        }
        
    }, [page]);

    const save = (event) => {
        event.preventDefault();

        fetch(Constants.apiPrefix + `/api/admin/page/${page}/save`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                accessToken: cookies.get("accessToken"),
                contentswe: contentSwe,
                contenteng: contentEng
            }),
        })
            .then(response => response.json())
            .then(response => {
                if (response.hasOwnProperty("CODE") && response.hasOwnProperty("MESSAGE") && response.CODE === 200) {
                    setSuccess({
                        severity: "success",
                        text: response.MESSAGE
                    });
                } else if (response.hasOwnProperty("MESSAGE")) {
                    setSuccess({
                        severity: "error",
                        text: response.MESSAGE
                    });
                }
                setSaved(true);
            });
    }

    const handleInput = (e, setter) => {
        e.preventDefault();

        setLoadedPage(false);
        setter(e.target.value);
    }

    return (
        <Container>
            <Grid container item xs={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Vilken sida vill du redigera?</FormLabel>
                    <RadioGroup aria-label="page" name="page" value={page} onChange={(e) => handleInput(e, setPage)}>
                        <FormControlLabel value={Constants.pages.prizes} control={<Radio />} label="Priser" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            {loadedPage ? <React.Fragment>
                    <Typography variant="h5">Svenska</Typography>
                    <Grid container item xs={12}>
                        <ReactMde
                            value={contentSwe}
                            onChange={setContentSwe}
                            selectedTab={selectedTabSwe}
                            onTabChange={setSelectedTabSwe}
                            generateMarkdownPreview={markdown =>
                                Promise.resolve(converter.makeHtml(markdown))
                            }
                        />
                    </Grid>
                    <Box pt={2} />
                    <Typography variant="h5">Engelska</Typography>
                    <Grid container item xs={12}>
                        <ReactMde
                            value={contentEng}
                            onChange={setContentEng}
                            selectedTab={selectedTabEng}
                            onTabChange={setSelectedTabEng}
                            generateMarkdownPreview={markdown =>
                                Promise.resolve(converter.makeHtml(markdown))
                            }
                        />
                    </Grid>
                    <Box pt={2} />
                    <Grid container item xs={12}>
                        <Button variant="contained" color="secondary" onClick={save}>Spara sida ({page})</Button>
                    </Grid>
                </React.Fragment>
            : <React.Fragment></React.Fragment>
            }
            {saved && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Alert severity={success.severity}>
                        {success.text}
                    </Alert>
                </Paper>
            )}
        </Container>
    );
}