import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    text: {
        textTransform: 'uppercase',
    },
}));

export const UpperCaseText = (props) => {
    const classes = useStyles();

    let styles = {fontWeight: props.fontWeight}
    if(props.truncateWidth) {
        styles = {
            ...styles,
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: props.truncateWidth
        }
    }

    return (
        <Typography variant={props.variant} style={styles} className={classes.text}>{props.children}</Typography>
    )
}