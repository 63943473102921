import React, { useState, useRef } from 'react';
import { Box, Grid, Container } from '@material-ui/core';
import { usePdf } from '@mikecousins/react-pdf';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from "@material-ui/lab";
import { Trans, useTranslation } from 'react-i18next';

import { PageTitle } from '../../layout/PageTitle';
import SponsorPaper from '../../layout/components/SponsorPaper';

const useStyles = makeStyles((theme) => ({
    viewer: {
        width: '100&',
    }
}));

export const Rules = (props) => {
    const [page, setPage] = React.useState(1);
    const canvasRef = React.useRef(null);
    const { t, i18n } = useTranslation();

    let rulebookUrl = "/content/Regelverk_2024_autumn_SWE.pdf";

    if (i18n.language === "en") {
        rulebookUrl = "/content/Rulebook_S9_ENG.pdf"
    }

    const { pdfDocument, pdfPage } = usePdf({
        file: rulebookUrl,
        page,
        canvasRef,
    });

    const handlePage = (e, value) => {
        e.preventDefault();
        setPage(value);
    }

    return (
        <React.Fragment>
            <PageTitle>
                <Trans>menu.rules</Trans>
            </PageTitle>
            <SponsorPaper>
                <Box pt={1} />
            </SponsorPaper>
            <Box my={5} />
            <Container>
                <Box>
                    <Grid container justify="center" direction="row" alignItems="center">
                        <Grid item>
                            <a href={rulebookUrl} target="_blank" className="srl-link">
                                <Trans>rules.download-link</Trans>
                            </a>
                        </Grid>
                    </Grid>
                    {Boolean(pdfDocument && pdfDocument.numPages) && (
                        <Box pt={3}>
                            <Grid container justify="center" direction="column" alignItems="center">
                                <Grid item>
                                    <canvas ref={canvasRef} />
                                </Grid>
                                <Box pt={2} />
                                <Grid item>
                                    <Pagination count={pdfDocument.numPages} page={page} onChange={handlePage} color="secondary" />
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </Box>
            </Container>
            <Box my={10} />
        </React.Fragment>
    )

}
