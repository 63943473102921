import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Cookies from "universal-cookie";
import {
    Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel,
    FormGroup, FormLabel, Grid, IconButton, InputLabel, makeStyles, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, 
    TableFooter, TableHead, TablePagination, TableRow, Typography, useTheme
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ImCheckmark, ImCross } from "react-icons/all";
import { Link, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Constants } from "../../../../constants";
import { TextField } from "../../../../components/TextField";
import { SnackbarContext } from "../../../../context/SnackbarProvider";
import { Autocomplete } from "@material-ui/lab";
import { swedishCountries } from "../../../../components/swedishCountries";
import { StateContext } from "../../../../discord/StateProvider";
import { UploadImage } from "../../../../components/UploadImage";

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles((theme) => ({
    table: {
        minWidth: 500,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const getRoles = async (userId) => {
    const r = await fetch(`${Constants.apiPrefix}/api/user/userId/${userId}/roles`);
    return await r.json();
}

const saveSettingsAndRoles = async (accessToken, selectedUser, playerSettings, roles) => {
    const r = await fetch(`${Constants.apiPrefix}/api/admin/user/saveSettingsAndRoles`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            accessToken: accessToken,
            playerId: selectedUser.pId,
            userId: selectedUser.id,
            ...playerSettings,
            roles: roles
        }),
    });
    return await r.json();
}

export const UserTable = (props) => {
    const iso3311a2 = require('iso-3166-1-alpha-2');
    const classes = useStyles2();
    const theme = useTheme();
    const cookies = new Cookies();
    const { t, i18n } = useTranslation();
    const { setSnackbar } = React.useContext(SnackbarContext);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(6);
    const { users, updateUsers, roles: allRoles } = props;
    const { userHasAccess } = useContext(StateContext);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Dialog settings
    const [open, setOpen] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState();

    const [userRoles, setUserRoles] = React.useState();

    React.useEffect(() => {

    }, [selectedUser])

    React.useEffect(() => {
        if (allRoles && selectedUser) {
            let tmpAllRoles = {}
            allRoles.forEach(role => tmpAllRoles[role.id] = {
                description: role.description,
                engDescription: role.engDescription,
                checked: false
            });
            getRoles(selectedUser.id).then(r => {
                setUserRoles(r.MESSAGE);
                r.MESSAGE.forEach(role => tmpAllRoles[role.id].checked = true);
                setRoles(tmpAllRoles);
            });
        }
    }, [allRoles, selectedUser])

    const handleClickOpen = (player) => {
        setLoading(false);
        setOpen(true);
        setSelectedUser(player);
        setPlayerSettings({
            pNickname: player.pNickname,
            discord: player.discord,
            steam: player.steam,
            mmr: player.mmr,
            nickname: player.nickname,
            player_img: player.player_img,
            avatar: player.avatar,
            banner: player.banner,
            platformId: player.platformId,
            platform: player.platform,
            twitter: player.twitter,
            instagram: player.instagram,
            twitch: player.twitch,
            tiktok: player.tiktok,
            youtube: player.youtube,
            birthdate: player.birthdate,
            nationality: player.nationality,
            discordEmail: player.discordEmail
        })
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [playerSettings, setPlayerSettings] = React.useState({});
    const handlePlayerSettingsChange = (event) => {
        const name = event.target.name;
        setPlayerSettings({
            ...playerSettings,
            [name]: event.target.value,
        });
    };

    const [roles, setRoles] = React.useState({});

    function hasAccess(acceptedRoles) {
        return userHasAccess(acceptedRoles);
    }

    const [loading, setLoading] = React.useState(false);

    const save = (event) => {
        setLoading(true);

        saveSettingsAndRoles(cookies.get("accessToken"), selectedUser, playerSettings, roles)
        .then(response => {
            updateUsers().then(() => {
                if (response.hasOwnProperty("CODE") && response.CODE === 200) {
                    setSnackbar({
                        open: true,
                        severity: "success",
                        message: "Inställningar sparade"
                    });
                } else if(response.hasOwnProperty("CODE")) {
                    setSnackbar({
                        open: true,
                        severity: "error",
                        message: response.MESSAGE
                    });
                } else {
                throw new Error("admin.users.UserTable.js");
                }
                setLoading(false);
                handleClose();
            });
        })
    }

    return (
        <>
            <TableContainer component={Paper} style={{ backgroundColor: theme.palette.primary.main }}>
                <Table className={classes.table} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Kopplad med användare
                            </TableCell>
                            <TableCell>
                                SpelarID
                            </TableCell>
                            <TableCell>
                                AnvändarID
                            </TableCell>
                            <TableCell align="right">
                                Spelarnamn
                            </TableCell>
                            <TableCell align="right">
                                Discord
                            </TableCell>
                            <TableCell align="center" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : users
                        ).map((player) => (
                            <TableRow key={player.pId}>
                                <TableCell component="th" scope="row">
                                    {player.playerId && player.pId === player.playerId ?
                                        <ImCheckmark size={24} style={{ color: theme.palette.success.main }} /> :
                                        <ImCross size={24} style={{ color: theme.palette.error.main }} />}
                                </TableCell>
                                <TableCell>
                                    {player.pId}
                                </TableCell>
                                <TableCell>
                                    {player.id}
                                </TableCell>
                                <TableCell align="right">
                                    {player.pNickname && <Link to={`/player/${player.pId}`} className="srl-link">{player.pNickname}</Link>}
                                </TableCell>
                                <TableCell align="right">
                                    {player.discordUsername && `${player.discordUsername}#${player.discordDiscriminator}`}
                                </TableCell>
                                <TableCell align="center">
                                    <Button fullWidth variant="contained" color="secondary"
                                        onClick={() => handleClickOpen(player)}>Hantera</Button>
                                </TableCell>
                            </TableRow>
                        ))}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={8} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[6, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={9}
                                count={users.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                onChangePage={handleChangePage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"lg"}>
                <DialogTitle id="form-dialog-title">Hantera spelare</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Här visas spelarens information tillsammans med den nya tabellen för användare, i den vänstra kolumnen är
                        gamla spelarinformationen och i den högra nya.
                    </DialogContentText>
                    <Grid container direction="row" spacing={2}>
                        <Grid item container direction="column" xs={12} md={5}>
                            <Typography variant="h6">Players</Typography>
                            {selectedUser && !selectedUser.pId ? (
                                <Alert severity="info" variant="outlined">Denna användare har ingen spelare kopplad till sig</Alert>
                            ) : (
                                <>
                                    <Grid item key={selectedUser && selectedUser.pId}>
                                        <TextField
                                            disabled
                                            margin="dense"
                                            id="id"
                                            label={"id"}
                                            defaultValue={selectedUser && selectedUser.pId}
                                            type="text"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item key={selectedUser && selectedUser.pNickname}>
                                        <TextField
                                            margin="dense"
                                            inputProps={{
                                                name: 'pNickname',
                                                id: 'pNickname',
                                                maxLength: 34,
                                            }}
                                            label={"Nickname"}
                                            defaultValue={selectedUser && selectedUser.pNickname}
                                            onChange={handlePlayerSettingsChange}
                                            type="text"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item key={selectedUser && selectedUser.discord}>
                                        <TextField
                                            margin="dense"
                                            inputProps={{
                                                name: 'discord',
                                                id: 'discord',
                                                maxLength: 100,
                                            }}
                                            label={"Discord"}
                                            defaultValue={selectedUser && selectedUser.discord}
                                            onChange={handlePlayerSettingsChange}
                                            type="text"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item key={selectedUser && selectedUser.steam}>
                                        <TextField
                                            margin="dense"
                                            inputProps={{
                                                name: 'steam',
                                                id: 'steam',
                                                maxLength: 100,
                                            }}
                                            label={"Steam"}
                                            defaultValue={selectedUser && selectedUser.steam}
                                            onChange={handlePlayerSettingsChange}
                                            type="text"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item key={selectedUser && selectedUser.mmr}>
                                        <TextField
                                            margin="dense"
                                            inputProps={{
                                                name: 'mmr',
                                                id: 'mmr',
                                                maxLength: 4,
                                            }}
                                            label={"MMR"}
                                            defaultValue={selectedUser && selectedUser.mmr}
                                            onChange={handlePlayerSettingsChange}
                                            type="text"
                                            fullWidth
                                        />
                                    </Grid>
                                </>
                            )}
                            {selectedUser && selectedUser.id && (
                                <>
                                    <Grid item>
                                        <Box pt={2}>
                                            <Typography variant="h6">Socials</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item key={selectedUser && selectedUser.twitter}>
                                        <TextField
                                            margin="dense"
                                            inputProps={{
                                                name: 'twitter',
                                                id: 'twitter',
                                                maxLength: 100,
                                            }}
                                            label={"Twitter"}
                                            disabled={selectedUser && !selectedUser.twitter}
                                            defaultValue={selectedUser && selectedUser.twitter}
                                            onChange={handlePlayerSettingsChange}
                                            type="text"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item key={selectedUser && selectedUser.instagram}>
                                        <TextField
                                            margin="dense"
                                            inputProps={{
                                                name: 'instagram',
                                                id: 'instagram',
                                                maxLength: 100,
                                            }}
                                            label={"Instagram"}
                                            disabled={selectedUser && !selectedUser.instagram}
                                            defaultValue={selectedUser && selectedUser.instagram}
                                            onChange={handlePlayerSettingsChange}
                                            type="text"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item key={selectedUser && selectedUser.twitch}>
                                        <TextField
                                            margin="dense"
                                            inputProps={{
                                                name: 'twitch',
                                                id: 'twitch',
                                                maxLength: 100,
                                            }}
                                            label={"Twitch"}
                                            disabled={selectedUser && !selectedUser.twitch}
                                            defaultValue={selectedUser && selectedUser.twitch}
                                            onChange={handlePlayerSettingsChange}
                                            type="text"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item key={selectedUser && selectedUser.tiktok}>
                                        <TextField
                                            margin="dense"
                                            inputProps={{
                                                name: 'tiktok',
                                                id: 'tiktok',
                                                maxLength: 100,
                                            }}
                                            label={"Tiktok"}
                                            disabled={selectedUser && !selectedUser.tiktok}
                                            defaultValue={selectedUser && selectedUser.tiktok}
                                            onChange={handlePlayerSettingsChange}
                                            type="text"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item key={selectedUser && selectedUser.youtube}>
                                        <TextField
                                            margin="dense"
                                            inputProps={{
                                                name: 'youtube',
                                                id: 'youtube',
                                                maxLength: 100,
                                            }}
                                            label={"Youtube"}
                                            disabled={selectedUser && !selectedUser.youtube}
                                            defaultValue={selectedUser && selectedUser.youtube}
                                            onChange={handlePlayerSettingsChange}
                                            type="text"
                                            fullWidth
                                        />
                                    </Grid>
                                </>
                            )}
                            
                        </Grid>
                        <Grid item container direction="column" xs={12} md={5}>
                            <Typography variant="h6">Users</Typography>
                            {selectedUser && !selectedUser.id ? (
                                <Alert severity="info" variant="outlined">Denna spelare har ingen användare kopplad till sig</Alert>
                            ) : (
                                <>
                                    <Grid item key={selectedUser && selectedUser.id}>
                                        <TextField
                                            disabled
                                            margin="dense"
                                            label={"id"}
                                            defaultValue={selectedUser && selectedUser.id}
                                            type="text"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item key={selectedUser && selectedUser.playerId}>
                                        <TextField
                                            disabled
                                            margin="dense"
                                            label={"PlayerId"}
                                            defaultValue={selectedUser && selectedUser.playerId}
                                            type="text"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item key={selectedUser && selectedUser.nickname}>
                                        <TextField
                                            margin="dense"
                                            inputProps={{
                                                name: 'nickname',
                                                id: 'nickname',
                                                maxLength: 100,
                                            }}
                                            label={"Nickname"}
                                            defaultValue={selectedUser && selectedUser.nickname}
                                            onChange={handlePlayerSettingsChange}
                                            type="text"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item key={selectedUser && selectedUser.discordId}>
                                        <TextField
                                            margin="dense"
                                            label={"DiscordId"}
                                            disabled={selectedUser && selectedUser.discordId}
                                            defaultValue={selectedUser && selectedUser.discordId}
                                            type="text"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item key={selectedUser && selectedUser.discordUsername}>
                                        <TextField
                                            margin="dense"
                                            label={"Discord"}
                                            disabled={selectedUser && selectedUser.discordUsername}
                                            defaultValue={selectedUser && selectedUser.discordUsername && `${selectedUser.discordUsername}#${selectedUser.discordDiscriminator}`}
                                            type="text"
                                            fullWidth
                                        />
                                    </Grid>                                
                                    <Grid item key={selectedUser && selectedUser.platform}>
                                        <FormControl fullWidth>
                                            <InputLabel>Platform</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                inputProps={{
                                                    name: 'platform',
                                                    id: 'platform',
                                                }}
                                                defaultValue={selectedUser && selectedUser.platform}
                                                onChange={handlePlayerSettingsChange}
                                            >
                                                <MenuItem value={"Epic Games"}>Epic Games</MenuItem>
                                                <MenuItem value={"Steam"}>Steam</MenuItem>
                                                <MenuItem value={"Playstation Network"}>Playstation Network</MenuItem>
                                                <MenuItem value={"Xbox Live"}>Xbox Live</MenuItem>
                                                <MenuItem value={"Nintendo Switch"}>Nintendo Switch</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item key={selectedUser && selectedUser.platformId}>
                                        <TextField
                                            margin="dense"
                                            inputProps={{
                                                name: 'platformId',
                                                id: 'platformId',
                                                maxLength: 100,
                                            }}
                                            label={"Platform-ID"}
                                            defaultValue={selectedUser && selectedUser.platformId}
                                            onChange={handlePlayerSettingsChange}
                                            type="text"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item key={"birthdate"}>
                                        <TextField
                                        fullWidth
                                        label={t("login.form.birthdate")}
                                        type="date"
                                        value={playerSettings.birthdate}
                                        onChange={handlePlayerSettingsChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            name: 'birthdate',
                                            id: "birthdate",
                                            maxLength: 100,
                                        }}
                                        />
                                    </Grid>
                                    <Grid item key={"nationality"}>
                                        <Autocomplete
                                        id="nationalitySelect"
                                        value={[playerSettings.nationality, iso3311a2.getCountry(playerSettings.nationality)]}
                                        options={Object.entries(iso3311a2.getData())}
                                        getOptionLabel={(option) => i18n.language === "en" ? option[1] : swedishCountries[option[0]]}
                                        onChange={(event, country) => {
                                            setPlayerSettings({
                                                ...playerSettings,
                                                nationality: country ? country[0] : undefined,
                                            });
                                        }}
                                        renderInput={(params) => <TextField {...params} label={t("login.form.nationality")} />}
                                        />
                                    </Grid>
                                    <Grid item key={"discordEmail"}>
                                        <TextField
                                            fullWidth
                                            label={t("login.form.email")}
                                            type="email"
                                            value={playerSettings.discordEmail}
                                            onChange={handlePlayerSettingsChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                name: 'discordEmail',
                                                id: "discordEmail",
                                                maxLength: 100,
                                            }}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        {selectedUser && selectedUser.id && (
                            <Grid item md={2} xs={12}>
                                <FormControl component="fieldset" style={{ margin: theme.spacing(3) }}>
                                    <FormLabel component="legend">Roller</FormLabel>
                                    <FormGroup>
                                        {roles && Object.keys(roles).map((key) => (
                                            <FormControlLabel
                                                control={<Checkbox disabled={!hasAccess([Constants.roles.admin]) || !selectedUser.id} checked={roles[key].checked} onChange={() => {
                                                    let tmpRoles = { ...roles };
                                                    tmpRoles[key].checked = !tmpRoles[key].checked;
                                                    setRoles(tmpRoles);
                                                }} name={roles[key].engDescription} />}
                                                label={i18n.language === "en" ? roles[key].engDescription : roles[key].description}
                                            />
                                        ))}
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        )}
                        <Grid item container direction="row" xs={10}>
                            <Grid item key={selectedUser && selectedUser.player_img} xs={4}>
                                <UploadImage
                                    defaultImage={selectedUser && `${Constants.apiPrefix}/api/${selectedUser.player_img}`}
                                    croppedImageState={[selectedUser && selectedUser.player_img, (val)=>{
                                        setPlayerSettings({
                                            ...playerSettings,
                                            "player_img": val,
                                        });
                                    }]}
                                    displayHeight={50}
                                    displayWidth={50}
                                    width={1000}
                                    height={1000}
                                    label='Player Image'
                                    showValueAsText={true}
                                />
                            </Grid>
                            <Grid item key={selectedUser && selectedUser.avatar} xs={4}>
                                <UploadImage
                                    defaultImage={selectedUser && `${Constants.apiPrefix}/api/${selectedUser.avatar}`}
                                    croppedImageState={[selectedUser && selectedUser.avatar, (val)=>{
                                        setPlayerSettings({
                                            ...playerSettings,
                                            "avatar": val,
                                        });
                                    }]}
                                    displayHeight={50}
                                    displayWidth={50}
                                    label='Player Avatar'
                                    showValueAsText={true}
                                />
                            </Grid>
                            <Grid item key={selectedUser && selectedUser.banner} xs={4}>
                                <UploadImage
                                    defaultImage={selectedUser && `${Constants.apiPrefix}/api/${selectedUser.banner}`}
                                    croppedImageState={[selectedUser && selectedUser.banner, (val)=>{
                                        setPlayerSettings({
                                            ...playerSettings,
                                            "banner": val,
                                        });
                                    }]}
                                    displayHeight={50}
                                    displayWidth={50}
                                    label='Player Banner'
                                    showValueAsText={true}
                                    height={200}
                                    width={1000}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">
                        Tillbaka
                    </Button>
                    <div className={classes.wrapper}>
                        <Button onClick={save} variant="contained" color="secondary" disabled={loading}>
                            Spara
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
}