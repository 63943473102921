import { TextField as UITextField } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

export const TextField = withStyles((theme) => ({
    root: {
        '& label.Mui-focused': {
            color: theme.palette.primary.focus,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.primary.focus,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.focus,
            },
        },
        "& .Mui-error": {
            borderColor: theme.palette.error.main,
        },
    }
}))(UITextField);