import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    loader: {
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    loadingText: {
        color: '#fff',
        textAlign: 'center'
    },
    dots: {

    }
}));

export const LoadingContent = () => {
    const classes = useStyles();

    return (
        <div className={classes.loader}>
            <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
            </div>
        </div>
    )
}