import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    loader_container: {
        backgroundColor: '#0E0F0F',
        position: "absolute",
        right: 0,
        bottom: 0,
        left: 0,
        top: 0
    },
    loader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    logo: {
        maxHeight: '200px'
    },
    loadingText: {
        color: '#fff',
        textAlign: 'center'
    },
    dots: {

    }
}));

export const Loader = () => {
    const classes = useStyles();

    return (
        <div className={classes.loader_container}>
            <div className={classes.loader}>
                <img src="/images/Raketligan-White.png" className={classes.logo} />
                <div className={classes.loadingText}>
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}