import React from "react";
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    makeStyles,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import { UploadImage } from "../../../../../components/UploadImage";
import { TextField } from "../../../../../components/TextField";
import { Constants } from "../../../../../constants";
import Table from "@material-ui/core/Table";
import { useTheme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import Cookies from "universal-cookie";
import { SnackbarContext } from "../../../../../context/SnackbarProvider";
import { useTranslation } from "react-i18next";
import { isWidthUp } from "@material-ui/core/withWidth";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

const getAllPlayersInTeam = async (teamId) => {
    const r = await fetch(`${Constants.apiPrefix}/api/team/${teamId}/allPlayers`);
    return await r.json();
};

const getAllPlayers = async (teamId) => {
    const r = await fetch(`${Constants.apiPrefix}/api/team/${teamId}/allWithoutTeamAndActivePlayers`);
    return await r.json();
};

const postTeamInfo = async (accessToken, teamId, teamname, teamlogo, banner, active, players) => {
    const r = await fetch(`${Constants.apiPrefix}/api/admin/team/saveTeam`, {
        method: "POST",
        body: JSON.stringify({
            accessToken: accessToken,
            teamId: teamId,
            teamname: teamname,
            teamlogo: teamlogo,
            banner: banner,
            active: active,
            players: players,
        }),
    });
    return await r.json();
};

export const TeamDialog = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const { updateTeams, team, open, handleClose } = props;
    const cookies = new Cookies();
    const { setSnackbar } = React.useContext(SnackbarContext);
    const { t } = useTranslation();

    const [loading, setLoading] = React.useState(false);
    const [players, setPlayers] = React.useState();
    const [allPlayersInTeam, setAllPlayersInTeam] = React.useState();

    const [teamname, setTeamname] = React.useState(team.teamname);
    const [teamlogo, setTeamlogo] = React.useState(team.teamlogo || '');
    const [active, setActive] = React.useState(team.active);
    const [banner, setBanner] = React.useState(team.banner || '');
    const [activePlayers, setActivePlayers] = React.useState();

    React.useEffect(() => {
        getAllPlayersInTeam(team.teamId).then((r) => setAllPlayersInTeam(r));
        getAllPlayers(team.teamId).then((r) => setPlayers(r.MESSAGE));
        let counter = 2;
        const players = {
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
        };
        for (let i = 0; i < team.activePlayers.length; i++) {
            if (team.activePlayers[i]["sortorder"] === 1) {
                players[1] = team.activePlayers[i];
            } else {
                players[counter] = team.activePlayers[i];
                counter++;
            }
        }
        setActivePlayers(players);
    }, [team, team.activePlayers]);

    const saveTeamInfo = () => {
        setLoading(true);
        postTeamInfo(cookies.get("accessToken"), team.teamId, teamname, teamlogo, banner, active, activePlayers).then((r) => {
            updateTeams().then(() => {
                getAllPlayersInTeam(team.teamId).then((inner) => {
                    setAllPlayersInTeam(inner);
                    if (r.hasOwnProperty("CODE") && r.CODE === 200) {
                        setSnackbar({
                            open: true,
                            severity: "success",
                            message: t(r.MESSAGE),
                        });
                    } else if (r.hasOwnProperty("CODE") && r.CODE !== 500) {
                        setSnackbar({
                            open: true,
                            severity: "error",
                            message: t(r.MESSAGE),
                        });
                    } else {
                        throw new Error("admin.teams.TeamDialog.saveTeamInfo");
                    }
                    setLoading(false);
                });
            });
        });
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"lg"} key={`team-dialog-${team.teamId}`}>
            <DialogTitle id="form-dialog-title">Hantera lag: {team.teamname}</DialogTitle>
            <DialogContent>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5" gutterBottom>
                            Information
                        </Typography>
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <TextField fullWidth label={"Lagnamn"} value={teamname} onChange={(event) => setTeamname(event.target.value)} />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={active}
                                            onChange={(event) => {
                                                setActive(event.target.checked);
                                            }}
                                        />
                                    }
                                    label={"Aktiv"}
                                />
                            </Grid>
                            <Grid item>
                                <Grid container rowSpacing={2} spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <UploadImage
                                            defaultImage={teamlogo.includes("discordapp") || teamlogo.includes('data:image') ? teamlogo : `${Constants.apiPrefix}/api/${teamlogo}`}
                                            croppedImageState={[teamlogo, setTeamlogo]}
                                            displayHeight={50}
                                            displayWidth={50}
                                            label='Logotyp'
                                            showValueAsText={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <UploadImage
                                            defaultImage={banner.includes("discordapp") || teamlogo.includes('data:image') ? banner : `${Constants.apiPrefix}/api/${banner}`}
                                            croppedImageState={[banner, setBanner]}
                                            label='Banner'
                                            showValueAsText={true}
                                            fullScreen
                                            displayHeight={isWidthUp("lg", props.width) ? 100 : 50}
                                            displayWidth={isWidthUp("lg", props.width) ? 500 : 250}
                                            height={200}
                                            width={1000}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                {players && activePlayers && (
                                    <Autocomplete
                                        value={activePlayers[1]}
                                        id="teamcaptain"
                                        options={players}
                                        autoHighlight
                                        getOptionLabel={(player) => {
                                            if (player) {
                                                return `${player.nickname} (${player.id})`;
                                            }
                                            return "";
                                        }}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        renderOption={(player) => (
                                            <>
                                                {player.nickname} ({player.id})
                                            </>
                                        )}
                                        onChange={(event, selectedPlayer) => {
                                            const tmp = { ...activePlayers };
                                            tmp[1] = selectedPlayer ? selectedPlayer : false;
                                            setActivePlayers(tmp);
                                        }}
                                        renderInput={(params) => <TextField {...params} label={"Välj lagkapten"} />}
                                    />
                                )}
                            </Grid>
                            <Grid item>
                                {players && activePlayers && (
                                    <Autocomplete
                                        value={activePlayers[2]}
                                        id="player1"
                                        options={players}
                                        autoHighlight
                                        getOptionLabel={(player) => {
                                            if (player) {
                                                return `${player.nickname} (${player.id})`;
                                            }
                                            return "";
                                        }}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        renderOption={(player) => (
                                            <>
                                                {player.nickname} ({player.id})
                                            </>
                                        )}
                                        onChange={(event, selectedPlayer) => {
                                            const tmp = { ...activePlayers };
                                            tmp[2] = selectedPlayer ? selectedPlayer : false;
                                            setActivePlayers(tmp);
                                        }}
                                        renderInput={(params) => <TextField {...params} label={"Välj spelare 1"} />}
                                    />
                                )}
                            </Grid>
                            <Grid item>
                                {players && activePlayers && (
                                    <Autocomplete
                                        value={activePlayers[3]}
                                        id="player2"
                                        options={players}
                                        autoHighlight
                                        getOptionLabel={(player) => {
                                            if (player) {
                                                return `${player.nickname} (${player.id})`;
                                            }
                                            return "";
                                        }}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        renderOption={(player) => (
                                            <>
                                                {player.nickname} ({player.id})
                                            </>
                                        )}
                                        onChange={(event, selectedPlayer) => {
                                            const tmp = { ...activePlayers };
                                            tmp[3] = selectedPlayer ? selectedPlayer : false;
                                            setActivePlayers(tmp);
                                        }}
                                        renderInput={(params) => <TextField {...params} label={"Välj spelare 2"} />}
                                    />
                                )}
                            </Grid>
                            <Grid item>
                                {players && activePlayers && (
                                    <Autocomplete
                                        value={activePlayers[4]}
                                        id="player3"
                                        options={players}
                                        autoHighlight
                                        getOptionLabel={(player) => {
                                            if (player) {
                                                return `${player.nickname} (${player.id})`;
                                            }
                                            return "";
                                        }}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        renderOption={(player) => (
                                            <>
                                                {player.nickname} ({player.id})
                                            </>
                                        )}
                                        onChange={(event, selectedPlayer) => {
                                            const tmp = { ...activePlayers };
                                            tmp[4] = selectedPlayer ? selectedPlayer : false;
                                            setActivePlayers(tmp);
                                        }}
                                        renderInput={(params) => <TextField {...params} label={"Välj spelare 3"} />}
                                    />
                                )}
                            </Grid>
                            <Grid item>
                                {players && activePlayers && (
                                    <Autocomplete
                                        value={activePlayers[5]}
                                        id="player4"
                                        options={players}
                                        autoHighlight
                                        getOptionLabel={(player) => {
                                            if (player) {
                                                return `${player.nickname} (${player.id})`;
                                            }
                                            return "";
                                        }}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        renderOption={(player) => (
                                            <>
                                                {player.nickname} ({player.id})
                                            </>
                                        )}
                                        onChange={(event, selectedPlayer) => {
                                            const tmp = { ...activePlayers };
                                            tmp[5] = selectedPlayer ? selectedPlayer : false;
                                            setActivePlayers(tmp);
                                        }}
                                        renderInput={(params) => <TextField {...params} label={"Välj spelare 4"} />}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5" gutterBottom>
                            Övergångar
                        </Typography>
                        <TableContainer component={Paper} className={classes.root}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Spelare</TableCell>
                                        <TableCell>Gick med</TableCell>
                                        <TableCell>Lämnade</TableCell>
                                        <TableCell>Roll</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {allPlayersInTeam && allPlayersInTeam.length > 0 ? (
                                        allPlayersInTeam.map((player, index) => (
                                            <TableRow key={`transfer-row-${player.id}-${index}`}>
                                                <TableCell>{player.nickname}</TableCell>
                                                <TableCell>{player.valid_from}</TableCell>
                                                <TableCell>{player.valid_to}</TableCell>
                                                <TableCell>{player.sortorder === "1" ? "Lagkapten" : "Spelare"}</TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={4}>Inga spelare har varit med i laget</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="contained">
                    Tillbaka
                </Button>
                <div className={classes.wrapper}>
                    <Button variant="contained" color="secondary" disabled={loading} onClick={saveTeamInfo}>
                        Spara
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </DialogActions>
        </Dialog>
    );
};
