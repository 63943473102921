import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SeriesTab } from './SeriesTab';
import { Constants } from '../../../constants';
import { PlayerStatisticsTable } from "./PlayerStatisticsTable";
import { TeamStatisticsTable } from "./TeamStatisticsTable";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

async function getDivisionId(divisionDiscriminator, seasonId) {
    const r = await fetch(`${Constants.apiPrefix}/api/divisionId/${divisionDiscriminator}/season/${seasonId}`);
    return await r.json();
}

async function getStats(divisionId) {
    const r = await fetch(`${Constants.apiPrefix}/api/division/${divisionId}/stats`);
    return await r.json();
}

async function getTeamStats(divisionId) {
    const r = await fetch(`${Constants.apiPrefix}/api/division/${divisionId}/teamStats`);
    return await r.json();
}

async function getSeries(divisionId) {
    const r = await fetch(`${Constants.apiPrefix}/api/division/${divisionId}/matches`);
    return await r.json();
}


export const DivisionTabs = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [playerStats, setPlayerStats] = React.useState();
    const [teamStats, setTeamStats] = React.useState();
    const [series, setSeries] = React.useState();
    const [divisionId, setDivisionId] = React.useState();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        getDivisionId(props.divisionDiscriminator, props.seasonId).then(response => {
            const divisionId = response.MESSAGE.id;
            setDivisionId(divisionId);
            getStats(divisionId).then(response => setPlayerStats(response));
            getSeries(divisionId).then(response => setSeries(response));
            getTeamStats(divisionId).then(response => setTeamStats(response));
        })
    }, [props.divisionDiscriminator, props.seasonId])


    return (
        <Container>
            <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="Series and Stats tab">
                    <Tab label={t("league.tab.series")} {...a11yProps(0)} />
                    <Tab label={t("league.tab.player-stats")} {...a11yProps(1)} />
                    <Tab label={t("league.tab.team-stats")} {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <SeriesTab series={series} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <PlayerStatisticsTable stats={playerStats} includeTeam={true} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                {teamStats && <TeamStatisticsTable stats={teamStats}/>}
            </TabPanel>
            </div>
        </Container>
    );
}