import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';
import { Link, useParams, useHistory } from "react-router-dom";
import { Pagination } from "@material-ui/lab";

import { Constants } from '../../constants';
import SponsorPaper from '../../layout/components/SponsorPaper';
import { PageTitle } from '../../layout/PageTitle';
import { FeaturedPost } from './components/FeaturedPost';
import { MainFeaturedPost } from './components/MainFeaturedPost';
import { Trans, useTranslation } from 'react-i18next';

export const News = () => {
    let { page, category } = useParams();
    const { t } = useTranslation();
    const history = useHistory();

    const pageSize = 9;
    let currentPage = 1;
    if (page) {
        currentPage = parseInt(page);
    }

    const [news, setNews] = React.useState();
    const [categories, setCategories] = React.useState([]);
    const [noOfPages, setNoOfPages] = React.useState(1);
    const [loading, setLoading] = React.useState(true);

    const handlePage = (e, value) => {
        e.preventDefault();
        let selectedCategory = "";
        if (category) {
            selectedCategory = `/${category}`;
        }
        history.push(`/news/${value}${selectedCategory}`);
    }

    async function fetchAllData() {
        let selectedCategory = "";
        if (category) {
            selectedCategory = `/${category}`;
        }

        const [categoriesResponse, countResponse, newsResponse] = await Promise.all([
            fetch(`${Constants.apiPrefix}/api/admin/news/categories/get`),
            fetch(`${Constants.apiPrefix}/api/news/count${selectedCategory}`),
            fetch(`${Constants.apiPrefix}/api/news/page/${page ? page - 1 : 0}/${pageSize}${selectedCategory}`)
        ]);

        const cat = await categoriesResponse.json();
        const count = await countResponse.json();
        const allNews = await newsResponse.json();

        return [cat, count, allNews];
    }

    React.useEffect(() => {
        fetchAllData().then(([thisCategories, thisCount, thisNews]) => {
            setCategories(thisCategories);
            setNews(thisNews);
            setNoOfPages(Math.round(Math.ceil(parseFloat(parseInt(thisCount.no_of_news)) / pageSize)))
            setLoading(false);
        });
    }, [page, category])

    return (
        <React.Fragment>
            <PageTitle>
                <Trans>menu.news</Trans>
            </PageTitle>
            <SponsorPaper>
                <Box pt={1} />
            </SponsorPaper>
            <Box my={5} />
            {loading ? <></> :
                <Container>
                    <Grid container direction="row">
                        <Grid item md={10}>
                            <Box>
                                {news && news.length > 0 && <MainFeaturedPost post={news[0]} />}
                            </Box>
                            <Grid container spacing={2}>
                                {news && news.length > 1 && news.slice(1).map((x, index) => {
                                    return (
                                        <FeaturedPost key={index} post={x} style={{height: 175}}/>
                                    );
                                })}
                            </Grid>
                        </Grid>
                        <Grid item md={2}>
                            <Box p={1}>
                                <Typography>
                                    <Trans>news.category</Trans>
                                </Typography>
                                <List component="nav">
                                    <Divider />
                                    <ListItem button component={Link} to={`/news`}>
                                        {category ? (
                                            <ListItemText inset primary={t("news.all-categories")} />
                                        ) : (
                                            <>
                                                <ListItemIcon>
                                                    <ArrowRight />
                                                </ListItemIcon>
                                                <ListItemText primary={t("news.all-categories")} />
                                            </>
                                        )}
                                    </ListItem>

                                    {categories.map((x, index) => (
                                        <ListItem key={index} button component={Link} to={`/news/1/${x.id}`}>
                                            {x.id === category ? (
                                                <>
                                                    <ListItemIcon>
                                                        <ArrowRight />
                                                    </ListItemIcon>
                                                    <ListItemText primary={x.name} />
                                                </>
                                            ) : (
                                                <ListItemText inset primary={x.name} />
                                            )}

                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </Grid>
                    </Grid>
                    {noOfPages > 0 ? (<Box pt={3}>
                        <Grid container justify="center" direction="row" alignItems="center">
                            <Grid item>
                                <Pagination count={noOfPages} page={currentPage} onChange={handlePage} color="secondary" />
                            </Grid>
                        </Grid>
                    </Box>) : <></>}
                </Container>
            }
            <Box my={10} />
        </React.Fragment>
    );
}