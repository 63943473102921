import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton, ListItemSecondaryAction, Menu,
    MenuItem
} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Constants } from "../../../constants";
import Cookies from "universal-cookie";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { MoreVert } from "@material-ui/icons";
import { StateContext } from "../../../discord/StateProvider";
import { getPlayers } from "../Team";
import { useHistory } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next';
import { SnackbarContext } from "../../../context/SnackbarProvider";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

async function kickPlayer(accessToken, playerId) {
    const r = await fetch(`${Constants.apiPrefix}/api/team/kickPlayer`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            accessToken: accessToken,
            playerId: playerId,
        }),
    });
    return await r.json();
}

async function changeTeamCaptain(accessToken, playerId) {
    const r = await fetch(`${Constants.apiPrefix}/api/team/changeTeamCaptain`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            accessToken: accessToken,
            playerId: playerId,
        }),
    });
    return await r.json();
}

export const KickPlayerDialog = (props) => {
    const { playerId, nickname, teamId, setPlayers } = props;
    const cookies = new Cookies();
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { t } = useTranslation();
    const { setSnackbar } = React.useContext(SnackbarContext);

    // Dialog settings
    const [kickDialogOpen, setKickDialogOpen] = React.useState(false);
    const handleKickDialogClose = () => {
        setKickDialogOpen(false);
    };
    const handleKickDialogOpen = () => {
        setKickDialogOpen(true);
    }

    const [promoteDialogOpen, setPromoteDialogOpen] = React.useState(false);
    const handlePromoteDialogClose = () => {
        setPromoteDialogOpen(false);
    };
    const handlePromoteDialogOpen = () => {
        setPromoteDialogOpen(true);
    }

    // Manage player settings
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Loading button settings
    const [loading, setLoading] = React.useState(false);

    const kickPlayerFromTeam = () => {
        setLoading(true);
        kickPlayer(cookies.get("accessToken"), playerId).then(response => {
            if(response.hasOwnProperty("CODE") && response.CODE === 200) {
                getPlayers(teamId).then((players) => {
                    setSnackbar({
                        open: true,
                        severity: "success",
                        message: t(response.MESSAGE)
                    });
                    setPlayers(players);
                    setLoading(false);
                    setKickDialogOpen(false);
                });
            } else if(response.hasOwnProperty("CODE") && response.CODE !== 500) {
                setSnackbar({
                    open: true,
                    severity: "error",
                    message: t(response.MESSAGE)
                });
                setLoading(false);
                setKickDialogOpen(false);
            } else {
                throw new Error("react.team.components.KickPlayerDialog.kickPlayerFromTeam");
            }
        });
    }

    const changeTeamCaptainInTeam = () => {
        setLoading(true);
        changeTeamCaptain(cookies.get("accessToken"), playerId).then(response => {
            if(response.hasOwnProperty("CODE") && response.CODE === 200) {
                getPlayers(teamId).then((players) => {
                    setSnackbar({
                        open: true,
                        severity: "success",
                        message: t(response.MESSAGE)
                    });
                    setPlayers(players)
                    setLoading(false);
                    setPromoteDialogOpen(false);
                    history.push(`/team/${teamId}`);
                });
            } else if(response.hasOwnProperty("CODE") && response.CODE !== 500) {
                setSnackbar({
                    open: true,
                    severity: "error",
                    message: t(response.MESSAGE)
                });
                setLoading(false);
                setPromoteDialogOpen(false);
            } else {
                throw new Error("react.team.components.KickPlayerDialog.changeTeamCaptainInTeam");
            }
        });
    }

    return (
        <>
            <IconButton
                aria-label="more"
                aria-controls={`menu${playerId}`}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVert />
            </IconButton>
            <Menu
                id={`menu${playerId}`}
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                <MenuItem key={`promote${playerId}`} onClick={handlePromoteDialogOpen}>
                    <Trans>team.change-captain</Trans>
                </MenuItem>
                <MenuItem key={`kick${playerId}`} onClick={handleKickDialogOpen}>
                    <Trans>team.kick-player</Trans>
                </MenuItem>
            </Menu>
            <Dialog open={kickDialogOpen} onClose={handleKickDialogClose} aria-labelledby="dialog-accept" key={`kick${playerId}`}>
                <DialogTitle>
                    <Trans>team.kick-player</Trans>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Trans i18nKey="team.kick-player.confirm">
                            {{ nickname }}
                        </Trans>
                        <Alert variant="outlined" severity="warning">
                            <Trans>
                                team.kick-player.warning
                            </Trans>
                        </Alert>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleKickDialogClose} variant="contained">
                        <Trans>general.back</Trans>
                    </Button>
                    <div className={classes.wrapper}>
                        <Button onClick={kickPlayerFromTeam} variant="contained" style={{ backgroundColor: theme.palette.error.main, color: "white" }} disabled={loading}>
                            <Trans>team.kick-player</Trans>
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </DialogActions>
            </Dialog>

            <Dialog open={promoteDialogOpen} onClose={handlePromoteDialogClose} aria-labelledby="dialog-accept" key={`promote${playerId}`}>
                <DialogTitle>
                    <Trans i18nKey="team.change-captain.title" />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Trans i18nKey="team.change-captain.confirm">
                            {{ nickname }}
                        </Trans>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePromoteDialogClose} variant="contained">
                        <Trans>general.back</Trans>
                    </Button>
                    <div className={classes.wrapper}>
                        <Button onClick={changeTeamCaptainInTeam} variant="contained" style={{ backgroundColor: theme.palette.error.main, color: "white" }} disabled={loading}>
                            <Trans i18nKey="team.change-captain.title" />
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );

}