import React from "react";
import {Box, CircularProgress, Container, List, makeStyles, Snackbar, Typography} from "@material-ui/core";
import Cookies from "universal-cookie";
import { Alert } from "@material-ui/lab";

import { Constants } from "../../../../constants";
import { ChangeTeamInfoRequest } from "./requestComponents/ChangeTeamInfo/ChangeTeamInfoRequest";
import { ExistingUserRequest } from "./requestComponents/ExistingUserRequest/ExistingUserRequest";
import { LeaveTeamRequest } from "./requestComponents/leaveTeamRequest/leaveTeamRequest";
import { NewImagesRequest } from "./requestComponents/NewImagesRequest/NewImagesRequest";
import { NewTeamRequest } from "./requestComponents/NewTeamRequest/NewTeamRequest";
import { TransferRequest } from "./requestComponents/TransferRequest/TransferRequest";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
}));

export const Requests = (props) => {
    const { updateRequestCounter } = props;
    const classes = useStyles();
    const cookies = new Cookies();
    const [requests, setRequests] = React.useState();
    const [snackbar, setSnackbar] = React.useState([]);

    const setMessageSnackbar = (response) => {

        if (response.hasOwnProperty("CODE") && response.CODE === 200) {
            setSnackbar({
                open: true,
                severity: "success",
                message: response.MESSAGE,
            });
        } else {
            setSnackbar({
                open: true,
                severity: "error",
                message: response.MESSAGE,
            });
        }
    }

    function handleRemove(requestId) {
        const newRequests = requests.filter((request) => request.id !== requestId);
        setRequests(newRequests);
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({
            open: false,
        });
    };

    const updateRequests = () => {
        fetch(new URL(`/api/admin/${cookies.get("accessToken")}/requests`, Constants.apiPrefix).toString())
            .then(response => response.json())
            .then(response => {
                setRequests(response);
            });
    }

    React.useEffect(() => {
        updateRequests();
    }, [])


    return (
        <>
            <Container>
                <Box mx={2}>
                    {requests ? (
                      <List aria-label="main mailbox folders">
                          {requests.length > 0 ? requests.map((request, index) => {

                              switch (request.requestType) {
                                  case "newTeam":
                                      return <NewTeamRequest classes={classes} request={request} index={index} updateRequestCounter={updateRequestCounter} />
                                  case "existingUser":
                                      return <ExistingUserRequest classes={classes} request={request} index={index} updateRequestCounter={updateRequestCounter} />
                                  case "newImages":
                                      return <NewImagesRequest classes={classes} request={request} index={index} updateRequestCounter={updateRequestCounter} />
                                  case "transfer":
                                      return <TransferRequest classes={classes} request={request} index={index} updateRequestCounter={updateRequestCounter} />
                                  case "changeTeamInfo":
                                      return <ChangeTeamInfoRequest classes={classes} request={request} index={index} updateRequestCounter={updateRequestCounter} />
                                  case "leaveTeam":
                                      return <LeaveTeamRequest classes={classes} request={request} index={index} updateRequestCounter={updateRequestCounter} />
                              }


                          }) : (
                            <Typography>Finns inga requests.</Typography>
                          )}
                      </List>
                    ) : (
                      <Grid container justify={"center"}>
                          <Grid item>
                              <CircularProgress size={24} color="secondary"/>
                          </Grid>
                      </Grid>
                    )}
                </Box>
            </Container>
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
}