import React from "react";
import { Button, Container, FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup } from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import { makeStyles } from '@material-ui/core/styles';
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import Cookies from 'universal-cookie';
import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom";

import { Constants } from "./../../../../constants";

import { AddCategory } from "./components/AddCategory";
import { AddNews } from "./components/AddNews";
import { EditNews } from "./components/EditNews";
import { NewsList } from "./components/NewsList";

const useStyles = makeStyles((theme) => ({
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

export const News = (props) => {
    const cookies = new Cookies();
    const { url } = props.match;

    return (
        <Container>
            <Switch>
                <Route path={`${url}/all`} component={NewsList} />
                <Route path={`${url}/edit/:id`} component={EditNews} />
                <Route path={`${url}/add`} component={AddNews} />
                <Route path={`${url}/category/add`} component={AddCategory} />
                {/* This route has to be at the bottom */}
                <Route path={`${url}`} exact>
                    <Redirect to={`${url}/all`}/>
                </Route>
            </Switch>
        </Container>
    );
}