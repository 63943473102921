import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    text: {
        fontWeight: 700,
        textTransform: "uppercase"
    },
}));

export const PageText = (props) => {
    const classes = useStyles();

    return (
        <Typography className={classes.text} {...props}>{props.children}</Typography>
    )
}