import React from "react";
import Cookies from "universal-cookie";
import {
    Box,
    CircularProgress,
    Container,
    IconButton,
    InputAdornment,
    makeStyles,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    useTheme,
} from "@material-ui/core";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import Table from "@material-ui/core/Table";
import { Constants } from "../../../../../constants";
import { TextField } from "../../../../../components/TextField";
import { ArrowBack, ArrowForward, ArrowForwardIos, Backspace, KeyboardBackspace, Search, SwapHoriz } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import LastPageIcon from "@material-ui/icons/LastPage";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import PropTypes from "prop-types";
import { BsArrowLeft, BsArrowRight, IoArrowForward } from "react-icons/all";

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

async function getAllTeams() {
    const r = await fetch(`${Constants.apiPrefix}/api/players/allTransfers`);
    return await r.json();
}

export const AllTransfers = () => {
    const theme = useTheme();

    const [transfers, setTransfers] = React.useState([]);
    const [searchTransfers, setSearchTranfers] = React.useState();
    const [searchQuery, setSearchQuery] = React.useState();

    const [open, setOpen] = React.useState(false);

    // Pagination settings
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(6);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    React.useEffect(() => {
        getAllTeams().then((r) => {
            setTransfers(r.MESSAGE);
            setSearchTranfers(r.MESSAGE);
        });
    }, []);

    const handleChange = (event) => {
        if (event.target.value.length > 0) {
            setSearchQuery(event.target.value);
            setSearchTranfers(
                transfers.filter(
                    (transfer) =>
                        (transfer.nickname !== null && transfer.nickname !== undefined && transfer.nickname.toLowerCase().startsWith(event.target.value.toLowerCase())) ||
                        (transfer.teamname !== null && transfer.teamname !== undefined && transfer.teamname.toLowerCase().startsWith(event.target.value.toLowerCase())) ||
                        (transfer.previousTeamname !== null &&
                            transfer.previousTeamname !== undefined &&
                            transfer.previousTeamname.toLowerCase().startsWith(event.target.value.toLowerCase()))
                )
            );
        } else {
            setSearchTranfers(transfers);
            setSearchQuery(undefined);
        }
    };

    return (
        <>
            <Box p={1}>
                <TextField
                    autoFocus
                    fullWidth
                    label="Sök på spelare eller lagnamn"
                    variant="outlined"
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            {searchTransfers ? (
                <>
                    <TableContainer component={Paper} style={{ backgroundColor: theme.palette.primary.main }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Spelar ID</TableCell>
                                    <TableCell align="left">Spelare</TableCell>
                                    <TableCell>Från</TableCell>
                                    <TableCell>Till</TableCell>
                                    <TableCell align="left">Tidigare lag</TableCell>
                                    <TableCell align="left">Nytt Lag</TableCell>
                                    <TableCell align="center">Roll</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0 ? searchTransfers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : searchTransfers).map((transfer) => (
                                    <TableRow>
                                        <TableCell align="left">{transfer.id}</TableCell>
                                        <TableCell align="left">
                                            <Link className="srl-link" to={`/player/${transfer.id}`}>
                                                {searchQuery && transfer.nickname && transfer.nickname.toLowerCase().startsWith(searchQuery.toLowerCase()) ? (
                                                    <>
                                                        <span style={{ backgroundColor: theme.palette.secondary.main, color: "black" }}>
                                                            {transfer.nickname.substr(0, searchQuery.length)}
                                                        </span>
                                                        {transfer.nickname.substr(searchQuery.length, transfer.nickname.length)}
                                                    </>
                                                ) : (
                                                    transfer.nickname
                                                )}
                                            </Link>
                                        </TableCell>
                                        <TableCell align="left">{transfer.valid_from}</TableCell>
                                        <TableCell align="left">{transfer.valid_to /* ? transfer.valid_to : transfer.valid_from*/}</TableCell>
                                        <TableCell align="left">
                                            <Link className="srl-link" to={`/team/${transfer.previousTeamId}`}>
                                                {searchQuery && transfer.previousTeamname && transfer.previousTeamname.toLowerCase().startsWith(searchQuery.toLowerCase()) ? (
                                                    <>
                                                        <span style={{ backgroundColor: theme.palette.secondary.main, color: "black" }}>
                                                            {transfer.previousTeamname.substr(0, searchQuery.length)}
                                                        </span>
                                                        {transfer.previousTeamname.substr(searchQuery.length, transfer.previousTeamname.length)}
                                                    </>
                                                ) : (
                                                    transfer.previousTeamname
                                                )}
                                            </Link>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Link className="srl-link" to={`/team/${transfer.teamId}`}>
                                                {searchQuery && transfer.teamname && transfer.teamname.toLowerCase().startsWith(searchQuery.toLowerCase()) ? (
                                                    <>
                                                        <span style={{ backgroundColor: theme.palette.secondary.main, color: "black" }}>
                                                            {transfer.teamname.substr(0, searchQuery.length)}
                                                        </span>
                                                        {transfer.teamname.substr(searchQuery.length, transfer.teamname.length)}
                                                    </>
                                                ) : (
                                                    transfer.teamname
                                                )}
                                            </Link>
                                        </TableCell>
                                        <TableCell align="center">{transfer.sortorder === "1" ? "Lagkapten" : "Spelare"}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[6, 10, 25, { label: "All", value: -1 }]}
                                        colSpan={8}
                                        count={searchTransfers.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { "aria-label": "rows per page" },
                                            native: true,
                                        }}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                        onChangePage={handleChangePage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </>
            ) : (
                <Grid container justify="center">
                    <Grid item>
                        <CircularProgress color="secondary" size={24} />
                    </Grid>
                </Grid>
            )}
        </>
    );
};
