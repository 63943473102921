import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default function AlertDialog(props) {
    const theme = useTheme();
    const classes = useStyles();
    const { data, onClick } = props;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [loading, setLoading] = React.useState(false);
    const stopLoadingAndClose = () => {
        setLoading(false);
        setOpen(false);
    }

    const handleAcceptClose = (event) => {
        setLoading(true);
        if (onClick) {
            if (data) {
                onClick(event, stopLoadingAndClose, data);
            } else {
                onClick(event, stopLoadingAndClose);
            }
        }
    }

    const disabled = props.disabled !== undefined && props.disabled === true;
    const button = disabled ? (
        <Button disabled fullWidth onClick={handleClickOpen}>
            {props.buttonText}
        </Button>
    ) : (
        <Button fullWidth variant="contained" style={{ color: "white", backgroundColor: props.backgroundColor }} onClick={handleClickOpen}>
            {props.buttonText}
        </Button>
    );

    return (
        <div style={{ width: "100%" }}>
            {button}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.children}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">
                        {props.deny}
                    </Button>
                    <div className={classes.wrapper}>
                        <Button onClick={handleAcceptClose} color="secondary" variant="contained" disabled={loading}>
                            {props.accept}
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}