import { ButtonGroup, Grid, ListItem, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import React from "react";
import { DenyDialog } from "./components/DenyDialog";
import { AcceptTransferDialog } from "./components/AcceptTransferDialog";
import { AcceptInviteDialog } from "./components/AcceptInviteDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
}));

export const InviteNotification = (props) => {
    const { notification } = props;
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    let buttonGroup = null;

    if (notification) {
        if (notification.accepted === 0) {
            if (parseInt(notification.active) === 1) {
                buttonGroup =   <ButtonGroup fullWidth>
                                    <AcceptTransferDialog inviteId={notification.inviteId} {...props} />
                                    <DenyDialog inviteId={notification.inviteId} {...props} />
                                </ButtonGroup>;
            }
            else {
                buttonGroup =   <ButtonGroup fullWidth>
                                    <AcceptInviteDialog inviteId={notification.inviteId} {...props} />
                                    <DenyDialog inviteId={notification.inviteId} {...props} />
                                </ButtonGroup>
            }
        }
        else if (notification.accepted === "1") {
            buttonGroup = <Button fullWidth variant="contained" disabled>{t("notification.accepted")}</Button>
        }
        else if (notification.accepted === "2") {
            buttonGroup = <Button fullWidth variant="contained" disabled>{t("notification.denied")}</Button>
        }
        else if (notification.accepted === "3") {
            buttonGroup = <Button fullWidth variant="contained" disabled>{t("notification.cancelled")}</Button>
        }
    }

    return (
        <ListItem className={classes.root}>
            <Grid container direction="row" spacing={1}>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={2}>
                            <Typography color="textSecondary">{notification.date.substr(0, notification.date.length - 3)}</Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <Typography>{i18n.language === "en" ? notification.engDescription : notification.description}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    {( buttonGroup )}
                </Grid>
            </Grid>
        </ListItem>
    );
}