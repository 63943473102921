import React from 'react';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {Trans, useTranslation} from 'react-i18next';

import { PageTitle } from '../../layout/PageTitle';
import SponsorPaper from '../../layout/components/SponsorPaper';

const useStyles = makeStyles((theme) => ({
    heading: {
        paddingTop: theme.spacing(3),
    },
}));

export const CookiesAndPrivacyPolicy = () => {
    const classes = useStyles();
    useTranslation();
    return (
        <Box>
            <PageTitle>
                <Typography variant="h2" style={{ fontWeight: 700 }}>
                    <Trans>cookies.title</Trans>
                </Typography>
            </PageTitle>
            <SponsorPaper>
                <Box pt={1} />
            </SponsorPaper>
            <Box my={5} />
            <Container>
                <Grid container direction="column" spacing={5}>
                    <Grid item>
                        <Typography variant="body1" gutterBottom>
                            <Trans>cookies.part-1</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>cookies.part-2</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>cookies.part-3</Trans>
                        </Typography>
                        <Typography variant="h4" className={classes.heading}>
                            <Trans>cookies.info</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>cookies.info.part-1</Trans>
                        </Typography>
                        <Typography variant="h4" className={classes.heading}>
                            <Trans>cookies.log</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>cookies.log.part-1</Trans>
                        </Typography>
                        <Typography variant="h4" className={classes.heading}>
                            <Trans>cookies.cookies</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>cookies.cookies.part-1</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>cookies.cookies.part-2</Trans>
                        </Typography>
                        <Typography variant="h4" className={classes.heading}>
                            <Trans>cookies.service</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>cookies.service.part-1</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>cookies.service.part-2</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>cookies.service.part-3</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>cookies.service.part-4</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>cookies.service.part-5</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>cookies.service.part-6</Trans>
                        </Typography>
                        <Typography variant="h4" className={classes.heading}>
                            <Trans>cookies.security</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>cookies.security.part-1</Trans>
                        </Typography>
                        <Typography variant="h4" className={classes.heading}>
                            <Trans>cookies.links</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>cookies.links.part-1</Trans>
                        </Typography>
                        <Typography variant="h4" className={classes.heading}>
                            <Trans>cookies.children</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>cookies.children.part-1</Trans>
                        </Typography>
                        <Typography variant="h4" className={classes.heading}>
                            <Trans>cookies.privacy</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>cookies.privacy.part-1</Trans>
                        </Typography>
                        <Typography variant="h4" className={classes.heading}>
                            <Trans>cookies.contact</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>cookies.contact.part-1</Trans>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
            <Box my={10} />
        </Box>
    )
}