import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#171a21',
            contrastText: '#fff',
            color: '#fff',
            focus: '#EDA11A'
        },
        secondary: {
            main: '#EDA11A',
            contrastText: '#000',
            color: '#000',
        },
        text: {
            primary: '#FFF',
            secondary: '#cacaca'
        },
        background: {
            default: '#0E0F0F',/*'#0E0F0F',*/
            paper: '#23262d',
        },
        error: {
            main: '#692127'
        },
        success: {
            main: '#275d34'
        },
        warning: {
            main: '#735a0c'
        },
        action: {
            focus: '#000',
        },
        type: 'dark',
    },
    typography: {
        fontFamily: [
            'Oswald',
            'Arial',
            'sans-serif',
        ].join(','),
    }
});

export default theme;