import React, { useContext } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { Link } from "react-router-dom";
import { AppBar, Avatar, Badge, Box, Button, Container, Divider, Drawer, Grid, IconButton, List, ListItem, ListItemText, Toolbar, Typography } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";

import { NotificationButton } from "./components/NotificationButton";
import NotificationProvider from "../context/NotificationProvider";
import { UpperCaseText } from "./components/UpperCaseText";
import LeagueMenu from "./components/LeagueMenu";
import InformationMenu from "./components/InformationMenu";
import { ShopMenu } from "./components/ShopMenu";
import UserDropdown from "./components/UserDropdown";
import { Constants } from "../constants";
import { LoginButton } from "../discord/LoginButton";
import { StateContext } from "../discord/StateProvider";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    },
    title: {
        flexGrow: 1,
    },
    hide: {
        display: "none",
    },
    menu: {
        textAlign: "right",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-start",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
}));

const Navbar = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [divisions, setDivisions] = React.useState();

    const { currentUser, currentTeam, unreadNotifications } = useContext(StateContext);

    React.useEffect(() => {
        fetch(Constants.apiPrefix + "/api/season/current/divisions")
            .then((response) => response.json())
            .then((jsondata) => {
                setDivisions(jsondata);
            });
    }, []);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    if (isWidthUp("lg", props.width)) {
        return (
            <AppBar position="fixed" className={classes.appBar} color="secondary">
                <Container fixed>
                    <Toolbar>
                        <Box className="brand-logo">
                            <Link to="/">
                                <Avatar src="/images/Raketligan-White.png" className="navbar-logo" />
                            </Link>
                        </Box>
                        {/* <img style={{ height: '40px', marginLeft: '20px' }} src="/images/X-Gamer-Energy-Logo-Grey.png" /> */}
                        <Grid container direction="row" justify="flex-end" alignItems="center">
                            <Grid item>
                                <Button component={Link} color="inherit" to="/">
                                    <Typography>
                                        <Trans>menu.start</Trans>
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid item>
                                <InformationMenu />
                            </Grid>
                            <Grid item>
                                <LeagueMenu currentSeasonId={props.currentSeasonId} />
                            </Grid>
                            {/* <Grid item><ShopMenu /></Grid> */}
                            <Grid item>
                                <Button component={Link} color="inherit" to="/6mans">
                                    <UpperCaseText>6Mans</UpperCaseText>
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button component={Link} color="inherit" to="/news">
                                    <UpperCaseText>
                                        <Trans>menu.news</Trans>
                                    </UpperCaseText>
                                </Button>
                            </Grid>
                            <Grid item>
                            {currentUser == undefined && (
                                <Button component={Link} color="inherit" to="/member">
                                    <UpperCaseText>
                                        <Trans>menu.member</Trans>
                                    </UpperCaseText>
                                </Button>
                                )}
                            </Grid>
                            <Grid item>{currentUser !== undefined ? <UserDropdown currentUser={currentUser} currentTeam={currentTeam} /> : <LoginButton />}</Grid>
                            <Grid item>
                                {currentUser !== undefined && (
                                    <NotificationProvider>
                                        <NotificationButton unreadNotifications={unreadNotifications} />
                                    </NotificationProvider>
                                )}
                            </Grid>
                        </Grid>
                    </Toolbar>
                </Container>
            </AppBar>
        );
    }
    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar} color="secondary">
                <Toolbar>
                    <Box className="brand-logo">
                        <Link to="/">
                            <Avatar src="/images/Raketligan-White.png" className="navbar-logo" />
                        </Link>
                    </Box>
                    {/* <img style={{ height: '22px', marginLeft: '10px' }} src="/images/X-Gamer-Energy-Logo-Grey.png" /> */}
                    <Grid container direction="row" justify="flex-end" alignItems="center">
                        <Grid item>
                            <Badge badgeContent={unreadNotifications > 0 ? unreadNotifications : undefined} color="error">
                                <IconButton color="inherit" aria-label="open drawer" edge="end" onClick={handleDrawerOpen} className={clsx(open && classes.hide)}>
                                    <MenuIcon />
                                </IconButton>
                            </Badge>
                            <Box pr={5} />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>{theme.direction === "rtl" ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton>
                </div>
                <Divider />
                <List>
                    <ListItem button component={Link} to="/" onClick={handleDrawerClose} key="start">
                        <ListItemText primary={<UpperCaseText>{t("menu.start")}</UpperCaseText>} />
                    </ListItem>
                    <ListItem button component={Link} to="/seasons" onClick={handleDrawerClose} key="seasons">
                        <ListItemText primary={<UpperCaseText>{t("footer.all-seasons")}</UpperCaseText>} />
                    </ListItem>
                    <ListItem button component={Link} to="/6mans" onClick={handleDrawerClose} key="6mans">
                        <ListItemText primary={"6MANS"} />
                    </ListItem>
                    <ListItem button component={Link} to="/about" onClick={handleDrawerClose} key="about">
                        <ListItemText primary={<UpperCaseText>{t("menu.about")}</UpperCaseText>} />
                    </ListItem>
                    <ListItem button component={Link} to="/cooperation" onClick={handleDrawerClose} key="cooperation">
                        <ListItemText primary={<UpperCaseText>{t("menu.collaborations")}</UpperCaseText>} />
                    </ListItem>
                    <ListItem button component={Link} to="/rules" onClick={handleDrawerClose} key="rules">
                        <ListItemText primary={<UpperCaseText>{t("menu.rules")}</UpperCaseText>} />
                    </ListItem>
                    <ListItem button component={Link} to="/code-of-conduct" onClick={handleDrawerClose} key="code-of-conduct">
                        <ListItemText primary={<UpperCaseText>{t("menu.code-of-conduct")}</UpperCaseText>} />
                    </ListItem>
                    <ListItem button component={Link} to="/statutes" onClick={handleDrawerClose} key="statutes">
                        <ListItemText primary={<UpperCaseText>{t("menu.statutes")}</UpperCaseText>} />
                    </ListItem>
                    <ListItem button component={Link} to="/faq" onClick={handleDrawerClose} key="faq">
                        <ListItemText primary={<UpperCaseText>{t("menu.faq")}</UpperCaseText>} />
                    </ListItem>
                    <ListItem button component={Link} to="/prizes" onClick={handleDrawerClose} key="prizes">
                        <ListItemText primary={<UpperCaseText>{t("menu.prizes")}</UpperCaseText>} />
                    </ListItem>
                    <ListItem button component={Link} to="/news" onClick={handleDrawerClose} key="news">
                        <ListItemText primary={<UpperCaseText>{t("menu.news")}</UpperCaseText>} />
                    </ListItem>
                    <ListItem button component={Link} to="/member" onClick={handleDrawerClose} key="member">
                        <ListItemText primary={<UpperCaseText>{t("menu.member")}</UpperCaseText>} />
                    </ListItem>
                    {/* <ListItem button component={Link} to='/6mans' onClick={handleDrawerClose} key='6mans' >
                        <ListItemText primary='6Mans'/>
                    </ListItem> */}
                    {/* <Divider />
                    <ListItem button component={Link} to='/x-gamer-shop' onClick={handleDrawerClose} key='x-gamer' >
                        <ListItemText>
                            <UpperCaseText>X-Gamer-Shop</UpperCaseText>
                        </ListItemText>
                    </ListItem> */}
                    <Divider />
                    {currentUser !== undefined && (
                        <NotificationProvider>
                            <NotificationButton unreadNotifications={unreadNotifications} inDrawer={true} onClick={handleDrawerClose} />
                        </NotificationProvider>
                    )}
                    {currentUser !== undefined ? (
                        <UserDropdown currentUser={currentUser} currentTeam={currentTeam} inDrawer={true} onClick={handleDrawerClose} />
                    ) : (
                        <LoginButton inDrawer={true} />
                    )}
                </List>
            </Drawer>
        </div>
    );
};

export default withWidth()(Navbar);
