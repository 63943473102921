import React from 'react';
import { PageTitle } from '../../layout/PageTitle';
import { Box, Grid, Container, Typography } from '@material-ui/core';
import SponsorPaper from '../../layout/components/SponsorPaper';

export const YldrMerch = () => {

    return (
        <React.Fragment>
            <PageTitle>
                YLDR-Merch
            </PageTitle>
            <SponsorPaper>
                <Box pt={1} />
            </SponsorPaper>
            <Box my={5} />
            <Container>
                <Typography>
                    
                </Typography>
            </Container>
            <Box my={5} />
        </React.Fragment>
    )
}