import axios from "axios";
import { Constants } from "../../../../../constants";

export async function uploadReplay(file) {
    try {
        const formData = new FormData();
        formData.append("file", file, file.name);
        const res = await axios({
            method: "post",
            url: Constants.replayApi,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                "X-Cool-Code": Constants.replayApiKey,
            },
        });
        if (res.data.replay) return res.data.replay.properties;
        else throw new Error("Reprisen kunde inte analyseras");
    } catch (error) {
        throw new Error(error.response.data);
    }
}

export function formatPlayerStats(res) {
    let [team0, team1] = partition(res, (player) => player.Team === 0);

    const t1 = team0.map((player) => {
        return {
            points: player.Score,
            goals: player.Goals,
            assists: player.Assists,
            saves: player.Saves,
            shots: player.Shots,
            name: player.Name,
            team: player.Team,
            teamPlayerId: "",
        };
    });

    const t2 = team1.map((player) => {
        return {
            points: player.Score,
            goals: player.Goals,
            assists: player.Assists,
            saves: player.Saves,
            shots: player.Shots,
            name: player.Name,
            team: player.Team,
            teamPlayerId: "",
        };
    });

    return { t1, t2 };
}

export function setPlayerId(playerNames, players) {
    for (let p of players) {
        for (let player of playerNames) {
            if (p.player_nickname === player.name) {
                player.teamPlayerId = p.teamplayer_id;
            }
        }
    }
}

export function setHomeAndAway(game, homePlayers, awayPlayers) {
    const { t1, t2 } = game;
    let home, away;
    for (let p of t1) {
        for (let awayP of awayPlayers) {
            if (p.player_nickname === awayP.name) {
                home = t1;
                away = t2;
                return { home, away };
            }
        }
    }
    for (let p of t2) {
        for (let homeP of homePlayers) {
            if (p.player_nickname === homeP.name) {
                home = t1;
                away = t2;
                return { home, away };
            }
        }
    }

    home = t2;
    away = t1;
    return { home, away };
}

export function findPlayer(players, name) {
    return players.find((player) => player.name === name);
}

function partition(array, predicate) {
    return array.reduce((acc, item) => (predicate(item) ? (acc[0].push(item), acc) : (acc[1].push(item), acc)), [[], []]);
}
