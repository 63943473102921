import React, { useContext } from "react";
import { Avatar, Box, Grid, Typography, FormControlLabel, Switch, List, ListItem, ListItemText, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { FaInstagram, FaTwitch, AiOutlineTwitter, FaYoutube, IoLogoTiktok, FaDiscord, FaFacebookF } from "react-icons/all";

import { CustomThemeContext } from "../theme/CustomThemeProvider";
import { LanguageMenu } from "./components/LanguageMenu";
import { Constants } from "../constants";

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.color,
        padding: theme.spacing(5),
        position: "relative",
        right: 0,
        bottom: 0,
        left: 0,
    },
    lowerPart: {
        textAlign: "center",
        paddingTop: "50px !important",
    },
    avatar: {
        color: "#000",
        backgroundColor: theme.palette.secondary.main,
    },
}));

export const Footer = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { currentTheme, setTheme, setCurrentBackground } = useContext(CustomThemeContext);
    const isDark = Boolean(currentTheme === "dark");

    const handleThemeChange = (event) => {
        const { checked } = event.target;
        setTheme(checked ? "dark" : "normal");
    };

    // easter egg code
    const [value, setValue] = React.useState(0);
    const updateEasterEgg = (event) => {
        setValue(value + 1);
        if (value === 6) {
            setCurrentBackground("url(/images/shakingeyes.gif)");
        }
    };

    const headingVariant = "h6";

    return (
        <footer className={classes.footer}>
            <Grid container spacing={3} justify={"space-evenly"}>
                <Grid container item xs={12} sm={6} md={4} lg={3} spacing={3}>
                    <Grid container direction="column">
                        <Grid item>
                            <Typography variant={headingVariant}>
                                <Trans>footer.follow.socials</Trans>
                            </Typography>
                        </Grid>
                        <Box pt={1} />
                        <Grid item>
                            <Grid container direction="row" spacing={1}>
                                <Grid item>
                                    <a href="https://twitter.com/sv_raketligan" target="_blank">
                                        <Avatar className={classes.avatar}>
                                            <AiOutlineTwitter />
                                        </Avatar>
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a href="https://www.facebook.com/Svenska-Raketligan-2557814360914061" target="_blank">
                                        <Avatar className={classes.avatar}>
                                            <FaFacebookF />
                                        </Avatar>
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a href="https://discord.gg/svenskaraketligan" target="_blank">
                                        <Avatar className={classes.avatar}>
                                            <FaDiscord />
                                        </Avatar>
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a href="https://www.twitch.tv/svenskaraketligan" target="_blank">
                                        <Avatar className={classes.avatar}>
                                            <FaTwitch />
                                        </Avatar>
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a href="https://www.youtube.com/channel/UCGkGCNzR_zKQ-fVjmJKMFHg" target="_blank">
                                        <Avatar className={classes.avatar}>
                                            <FaYoutube />
                                        </Avatar>
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a href="https://www.instagram.com/svenskaraketligan/" target="_blank">
                                        <Avatar className={classes.avatar}>
                                            <FaInstagram />
                                        </Avatar>
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a href="https://www.tiktok.com/@svenskaraketligan" target="_blank">
                                        <Avatar className={classes.avatar}>
                                            <IoLogoTiktok />
                                        </Avatar>
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a href="https://www.challengermode.com/s/SvenskaRaketligan" target="_blank">
                                        <Avatar className={classes.avatar}>
                                        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="-150 0 1300 650"><path d="M780.775 73.78q-29.555-59.11-88.666-59.11t-88.666 59.11l-251.22 472.885h133c73.888 0 88.665-29.556 118.22-88.666l88.666-177.332 44.333 88.666C780.775 458 810.33 458 854.662 458h133Z"/><path d="M396.557 251.112q-29.555-59.11-88.665-59.11-59.112 0-88.667 59.11L12.34 635.33h132.998c73.888 0 88.665-29.555 118.221-88.666L307.892 458l44.332 88.665 101.219-190.531Z"/></svg>                                        </Avatar>
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={6} md={4} lg={3} spacing={3}>
                    <Typography variant={headingVariant}>
                        <Trans>footer.sitemap</Trans>
                    </Typography>
                    <Grid container item>
                        <List>
                            <ListItem button component={Link} to="/seasons">
                                <ListItemText primary={t("footer.all-seasons")} />
                            </ListItem>
                            <ListItem button component={Link} to="/help-us">
                                <ListItemText primary={t("help-us.title")} />
                            </ListItem>
                            <ListItem button component={Link} to="/cookies-and-privacy-policy">
                                <ListItemText primary={t("cookies.title")} />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={6} md={4} lg={3} spacing={3}>
                    <Grid container direction="column">
                        <Grid item>
                            <Typography variant={headingVariant}>
                                <Trans>footer.contact</Trans>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography paragraph>
                                <a className="srl-link" href="mailto:admin@svenskaraketligan.se">
                                    admin@svenskaraketligan.se
                                </a>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">
                                <Trans>footer.style</Trans>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography paragraph>
                                <FormControlLabel
                                    control={
                                        <a onClick={updateEasterEgg}>
                                            <Switch checked={isDark} disabled onChange={handleThemeChange} />
                                        </a>
                                    }
                                    label="Dark Mode"
                                />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">
                                <Trans>footer.language</Trans>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <LanguageMenu />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} className={classes.lowerPart}>
                    <Typography variant="body1">©2021 SVENSKARAKETLIGAN.SE | SVENSKA RAKETLIGAN EK. FÖR. | ORG.NR: 769637-6321</Typography>
                </Grid>
            </Grid>
        </footer>
    );
};
