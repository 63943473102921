import React, { useContext } from "react";
import { Box, Container } from "@material-ui/core";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";

import { Constants } from "../../constants";
import SponsorPaper from "./../../layout/components/SponsorPaper";
import { StateContext } from "../../discord/StateProvider";
import { TeamPage } from "./TeamPage";
import { TeamSettings } from "./TeamSettings";
import { TeamPageTitle } from "./components/TeamPageTitle";

export const getPlayers = async (teamId) => {
    const r = await fetch(`${Constants.apiPrefix}/api/team/${teamId}/players`);
    return await r.json();
};

export const getInvitedPlayers = async (teamId) => {
    const r = await fetch(`${Constants.apiPrefix}/api/team/${teamId}/invited-players`);
    return await r.json();
};

export const existsSignup = async (teamId) => {
    const r = await fetch(`${Constants.apiPrefix}/api/team/${teamId}/existsSignup`);
    return await r.json();
};

export const Team = (props) => {
    let { path, url } = useRouteMatch();
    const { teamId } = useParams();
    const { currentSeasonId, registrationOpen, transferOpen } = props;
    const { currentUser, currentTeam } = useContext(StateContext);

    const [teamInfo, setTeamInfo] = React.useState();
    const [invitedPlayers, setInvitedPlayers] = React.useState();
    const [players, setPlayers] = React.useState();
    const [series, setSeries] = React.useState();
    const [playedSeasons, setPlayedSeasons] = React.useState();
    const [signedUp, setSignedUp] = React.useState();

    async function fetchAllData() {
        let [team, players, invitedPlayers, series, playedSeasons, signedUp] = await Promise.all([
            fetch(`${Constants.apiPrefix}/api/team/${teamId}/info`),
            fetch(`${Constants.apiPrefix}/api/team/${teamId}/players`),
            fetch(`${Constants.apiPrefix}/api/team/${teamId}/invited-players`),
            fetch(`${Constants.apiPrefix}/api/team/${teamId}/season/${currentSeasonId}/series`),
            fetch(`${Constants.apiPrefix}/api/team/${teamId}/playedSeasons`),
            existsSignup(teamId),
        ]);
        team = await team.json();
        players = await players.json();
        invitedPlayers = await invitedPlayers.json();
        series = await series.json();
        playedSeasons = await playedSeasons.json();
        return [team, players, invitedPlayers, series, playedSeasons, signedUp];
    }

    React.useEffect(() => {
        fetchAllData().then(([team, players, invitedPlayers, series, playedSeasons, signedUp]) => {
            setTeamInfo(team);
            setPlayers(players);
            setInvitedPlayers(invitedPlayers);
            setSeries(series);
            setPlayedSeasons(playedSeasons);
            setSignedUp(signedUp.MESSAGE);
        });
    }, [teamId]);

    let teamCaptain = false;
    if (players && currentUser) {
        teamCaptain = players.some((player) => player.id === currentUser.playerId && player.sortorder === 1);
    }
    let myTeam = false;
    if (currentTeam && teamInfo) {
        myTeam = teamInfo.id === currentTeam.id;
    }
    return (
        <>
            <TeamPageTitle teamInfo={teamInfo} />
            <SponsorPaper>
                <Box pt={1} />
            </SponsorPaper>
            <Container>
                <Switch>
                    <Route path={path} exact>
                        {playedSeasons && (
                            <TeamPage
                                url={url}
                                teamInfo={teamInfo}
                                signedUp={signedUp}
                                currentUser={currentUser}
                                series={series}
                                currentSeasonId={currentSeasonId}
                                registrationOpen={registrationOpen}
                                transferOpen={transferOpen}
                                playedSeasons={playedSeasons}
                                currentPlayers={players}
                                currentTeam={currentTeam}
                            />
                        )}
                    </Route>
                    {myTeam && (
                        <Route path={`${path}/settings`}>
                            <TeamSettings
                                url={url}
                                players={players}
                                invitedPlayers={invitedPlayers}
                                setPlayers={setPlayers}
                                setInvitedPlayers={setInvitedPlayers}
                                teamCaptain={teamCaptain}
                                registrationOpen={registrationOpen}
                                transferOpen={transferOpen}
                            />
                        </Route>
                    )}
                </Switch>
            </Container>
            <Box my={10} />
        </>
    );
};
