import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { Box, Container, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { Trans } from "react-i18next";
import Cookies from "universal-cookie";
import { uploadReplay, formatPlayerStats, setHomeAndAway, setPlayerId } from "./replayUtils";

import { ReportResultTablePlayerScore } from "./ReportResultTablePlayerScore";
import { SnackbarContext } from "../../../../../context/SnackbarProvider";
import { fetchPreData, fetchRestData, updateScore } from "./MatchService";
import { Score } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    replayBtn: {
        marginLeft: theme.spacing(2),
        fontSize: "0.8rem",
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReportResultDialog(props) {
    const classes = useStyles();
    const cookies = new Cookies();
    const { seriesId, hometeam, awayteam, isOpen, onClose } = props;
    const [loaded, setLoaded] = React.useState(false);
    const [homePlayers, setHomePlayers] = React.useState([]);
    const [awayPlayers, setAwayPlayers] = React.useState([]);
    const { setSnackbar } = React.useContext(SnackbarContext);
    const [scores, setScores] = React.useState({});

    const globalStates = {};
    [1, 2, 3, 4, 5].map((matchNo) => {
        [1, 2, 3].map((playerNo) => {
            ["home", "away"].map((side) => {
                const key = `match${matchNo}_${side}_player${playerNo}`;
                globalStates[key] = React.useState({});
            });
        });
    });

    React.useEffect(() => {
        if (isOpen) {
            fetchPreData(cookies.get("accessToken"), seriesId).then((seriesInfoResult) => {
                console.log(seriesInfoResult);
                fetchRestData(cookies.get("accessToken"), seriesId, seriesInfoResult.match_hometeam_id, seriesInfoResult.match_awayteam_id).then(
                    ([homeTeamPlayersInfo, awayTeamPlayersInfo]) => {
                        setHomePlayers(homeTeamPlayersInfo);
                        setAwayPlayers(awayTeamPlayersInfo);
                        setLoaded(true);
                    }
                );
            });
        } else {
            setLoaded(false);
        }
    }, [isOpen]);

    const save = () => {
        console.log(globalStates);
        updateScore(cookies.get("accessToken"), seriesId, globalStates).then((r) => {
            if (r.hasOwnProperty("CODE") && r.CODE === 200) {
                setSnackbar({
                    open: true,
                    severity: "success",
                    message: r.MESSAGE,
                });
                onClose();
            } else if (r.hasOwnProperty("CODE") && r.CODE !== 500) {
                setSnackbar({
                    open: true,
                    severity: "error",
                    message: r.MESSAGE,
                });
            } else {
                throw new Error(r.MESSAGE);
            }
        });
        //Alla resultat finns nu i scores variabeln som inte är ett state (bara för att göra den snabbare)
        //Bug med att spelare inte syns efter den blivit vald i dropdownen
        //Spara måste göra det som den gamla gjorde på något smidigt sätt
        //Om man försöker rapportera en match som tidigare resultat så hämtas inte den datan just nu
    };

    const handleFileChange = async (e) => {
        const numFiles = e.target.files.length;
        if (numFiles === 1) {
            try {
                const res = await uploadReplay(e.target.files[0]);
                const formatedGame = await formatGame(res.PlayerStats);
                const gameNum = e.target.getAttribute("matchnum");

                setScores((prevState) => ({
                    ...prevState,
                    [`game${gameNum}`]: formatedGame,
                }));
            } catch (error) {
                setSnackbar({
                    open: true,
                    severity: "error",
                    message: error.message,
                });
            }
        } else if (numFiles === 3 || numFiles === 4 || numFiles === 5) {
            try {
                const replays = [...e.target.files];
                const sortedReplays = await handleMultipleReplays(replays);

                for (let [idx, r] of sortedReplays.entries()) {
                    let finalGameRes = await formatGame(r.stats);

                    setScores((prevState) => ({
                        ...prevState,
                        [`game${idx + 1}`]: finalGameRes,
                    }));
                }
            } catch (error) {
                setSnackbar({
                    open: true,
                    severity: "error",
                    message: error.message,
                });
            }
        } else {
            setSnackbar({
                open: true,
                severity: "error",
                message: "Ingen fil vald",
            });
        }
    };

    const formatGame = async (game) => {
        let formatedStats = formatPlayerStats(game);
        let { home, away } = await setHomeAndAway(formatedStats, homePlayers, awayPlayers);
        setPlayerId(home, homePlayers);
        setPlayerId(away, awayPlayers);
        return { home, away };
    };

    const handleMultipleReplays = async (replays) => {
        const parsedReplays = [];
        for (let r of replays) {
            const res = await uploadReplay(r);
            const playedDate = res.Date.replace(/[^|´\d]/g, "");
            parsedReplays.push({ stats: res.PlayerStats, date: playedDate });
        }
        const sortedReplays = parsedReplays.sort((a, b) => a.date - b.date);
        return sortedReplays;
    };

    const handleStatSwitch = (e) => {
        const match = e.currentTarget.getAttribute("matchnum");
        if (scores[`game${match}`] == undefined || !scores[`game${match}`] == undefined) return;
        const home = scores[`game${match}`].home;
        const away = scores[`game${match}`].away;
        if (!home || !away) return;
        setScores((prevState) => ({
            ...prevState,
            [`game${match}`]: {
                home: away,
                away: home,
            },
        }));
    };

    const getMatchObj = (match, isHomeTeam, playerIndex) => {
        return scores[`game${match}`] ? (isHomeTeam ? scores[`game${match}`].home : scores[`game${match}`].away)[playerIndex] : {};
    };

    const updateRow = (setter, key, value) => {
        const copy = globalStates[key][0];
        copy[setter] = value;
        globalStates[key][1](copy);
    };

    return (
        <div>
            <Dialog fullScreen open={isOpen} onClose={onClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Rapportera resultat ({hometeam} vs {awayteam})
                        </Typography>
                        <Button variant={"contained"} color="secondary" onClick={save}>
                            Spara
                        </Button>
                        <Button color="inherit" onClick={onClose}>
                            Stäng
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box m={3}>
                    {loaded && homePlayers && awayPlayers ? (
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={5} align="center">
                                            <Typography variant="h4">{hometeam}</Typography>
                                        </TableCell>
                                        <TableCell colSpan={2} align="center">
                                            <input onChange={handleFileChange} hidden id="allReplays" type="file" key="allReplays" multiple />
                                            <label htmlFor="allReplays">
                                                <Button className={classes.replayBtn} variant={"text"} color="secondary" size={"small"} component="span">
                                                    Fler Replays
                                                </Button>
                                            </label>
                                        </TableCell>
                                        <TableCell colSpan={5} align="center">
                                            <Typography variant="h4">{awayteam}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">Spelare</TableCell>
                                        <TableCell align="center">Poäng</TableCell>
                                        <TableCell align="center">Mål</TableCell>
                                        <TableCell align="center">Assist</TableCell>
                                        <TableCell align="center">Räddningar</TableCell>
                                        <TableCell align="center">Skott</TableCell>
                                        <TableCell align="center">Spelare</TableCell>
                                        <TableCell align="center">Poäng</TableCell>
                                        <TableCell align="center">Mål</TableCell>
                                        <TableCell align="center">Assist</TableCell>
                                        <TableCell align="center">Räddningar</TableCell>
                                        <TableCell align="center">Skott</TableCell>
                                    </TableRow>
                                </TableHead>
                                {[1, 2, 3, 4, 5].map((match) => {
                                    return (
                                        <React.Fragment key={match}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell colSpan={12} align="center">
                                                        Match {match}
                                                        <input
                                                            onChange={handleFileChange}
                                                            hidden
                                                            id={`replay-upload${match}`}
                                                            type="file"
                                                            key={`match${match}_btn`}
                                                            matchnum={`${match}`}
                                                        />
                                                        <label htmlFor={`replay-upload${match}`}>
                                                            <Button className={classes.replayBtn} variant={"text"} color="secondary" size={"small"} component="span">
                                                                Replay
                                                            </Button>
                                                        </label>
                                                        <Button
                                                            matchnum={`${match}`}
                                                            className={classes.replayBtn}
                                                            variant={"text"}
                                                            color="secondary"
                                                            size={"small"}
                                                            component="span"
                                                            onClick={handleStatSwitch}
                                                        >
                                                            Switch
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <ReportResultTablePlayerScore
                                                        key={`match${match}_home_player1`}
                                                        players={homePlayers}
                                                        tabindex={Number(`${match}${1}`)}
                                                        playerIndex={1}
                                                        result={getMatchObj(match, true, 0)}
                                                        updateRow={updateRow}
                                                        value={`match${match}_home_player1`}
                                                    />
                                                    <ReportResultTablePlayerScore
                                                        key={`match${match}_away_player1`}
                                                        players={awayPlayers}
                                                        tabindex={Number(`${match}${4}`)}
                                                        playerIndex={1}
                                                        result={getMatchObj(match, false, 0)}
                                                        updateRow={updateRow}
                                                        value={`match${match}_away_player1`}
                                                    />
                                                </TableRow>
                                                <TableRow>
                                                    <ReportResultTablePlayerScore
                                                        key={`match${match}_home_player2`}
                                                        players={homePlayers}
                                                        tabindex={Number(`${match}${2}`)}
                                                        playerIndex={2}
                                                        result={getMatchObj(match, true, 1)}
                                                        updateRow={updateRow}
                                                        value={`match${match}_home_player2`}
                                                    />
                                                    <ReportResultTablePlayerScore
                                                        key={`match${match}_away_player2`}
                                                        players={awayPlayers}
                                                        tabindex={Number(`${match}${5}`)}
                                                        playerIndex={2}
                                                        result={getMatchObj(match, false, 1)}
                                                        updateRow={updateRow}
                                                        value={`match${match}_away_player2`}
                                                    />
                                                </TableRow>
                                                <TableRow>
                                                    <ReportResultTablePlayerScore
                                                        key={`match${match}_home_player3`}
                                                        players={homePlayers}
                                                        tabindex={Number(`${match}${3}`)}
                                                        playerIndex={3}
                                                        result={getMatchObj(match, true, 2)}
                                                        updateRow={updateRow}
                                                        value={`match${match}_home_player3`}
                                                    />
                                                    <ReportResultTablePlayerScore
                                                        key={`match${match}_away_player3`}
                                                        players={awayPlayers}
                                                        tabindex={Number(`${match}${6}`)}
                                                        playerIndex={3}
                                                        result={getMatchObj(match, false, 2)}
                                                        updateRow={updateRow}
                                                        value={`match${match}_away_player3`}
                                                    />
                                                </TableRow>
                                            </TableBody>
                                        </React.Fragment>
                                    );
                                })}
                            </Table>
                        </TableContainer>
                    ) : (
                        <Container>Laddar in data</Container>
                    )}
                </Box>
            </Dialog>
        </div>
    );
}
