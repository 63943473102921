import React from "react";
import { Box, Button, ButtonGroup, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import { AcceptDialog } from "./AcceptDialog";
import { DenyDialog } from "./DenyDialog";

export const TransferRequest = (props) => {
    const theme = useTheme();
    const { request } = props;
    const content = JSON.parse(request.content);
    const [state, setState] = React.useState({
        accepted: request.accepted === null ? undefined : request.accepted === "1",
        acceptedByNickname: request.acceptedByNickname === null ? undefined : request.acceptedByNickname,
        acceptedDate: request.acceptedDate === null ? undefined : request.acceptedDate.substr(0, request.acceptedDate.length - 3)
    });

    return (
        <Box my={2}>
            <TableContainer component={Paper} style={{ backgroundColor: theme.palette.primary.main }}>
                <Table>
                    <caption>
                        <Grid container justify="space-between">
                            <Grid item>
                                <Typography color="secondary">Övergångsförfrågan</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                {state.accepted === undefined ? (
                                    <ButtonGroup fullWidth>
                                        <AcceptDialog setState={setState} {...props} />
                                        <DenyDialog setState={setState} {...props} />
                                    </ButtonGroup>
                                ) : (
                                  <Button fullWidth variant="contained" disabled>
                                      {state.accepted ?
                                        `Accepterad av ${state.acceptedByNickname} (${state.acceptedDate})`
                                        : `Nekad av ${state.acceptedByNickname} (${state.acceptedDate})`}
                                  </Button>
                                )}
                            </Grid>
                        </Grid>
                    </caption>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                <Typography color="textSecondary">Skapad</Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography color="textSecondary">Användare</Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography color="textSecondary">Nytt lag</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">
                                {request.date.substr(0, request.date.length - 3)}
                            </TableCell>
                            <TableCell align="left">
                                <Link to={`/player/${request.playerId}`} className="srl-link">
                                    {request.nickname} 
                                    {/* ({request.userId}) */}
                                </Link>
                            </TableCell>
                            <TableCell align="left">
                                <Link to={`/team/${content.teamId}`} className="srl-link">
                                    {content.teamname}
                                </Link>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

