import React, { useContext } from 'react';
import { Box, Container } from '@material-ui/core';
import { PageTitle } from '../../layout/PageTitle';
import SponsorPaper from '../../layout/components/SponsorPaper';
import CreateStepper from "./components/CreateStepper";
import { StateContext } from "../../discord/StateProvider";
import Cookies from 'universal-cookie';
import { Redirect } from "react-router-dom";
import {Trans, useTranslation} from 'react-i18next';

export const createTeam = () => {
    const cookies = new Cookies();
    const { currentUser, currentTeam } = useContext(StateContext);
    useTranslation();
    console.log(currentTeam);
    if (!cookies.get("accessToken") || currentTeam || !currentUser || currentUser.playerId === null) {
        return <Redirect to="/" />
    }

    return (
        <Box>
            <PageTitle minHeight={300}>
                <Trans>create-team.header</Trans>
            </PageTitle>
            <SponsorPaper>
                <Box pt={1} />
            </SponsorPaper>
            <Box my={5} />
            <Container>
                <CreateStepper />
            </Container>
            <Box my={10} />
        </Box>
    )
}