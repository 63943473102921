import React from "react";
import Cookies from "universal-cookie";
import {Container} from "@material-ui/core";
import {Redirect, Route, Switch} from "react-router-dom";
import {AllTeams} from "./components/AllTeams";

export const Teams = (props) => {

  const { url } = props.match;

  return (
    <Container>
      <Switch>
        <Route path={`${url}/all`} component={AllTeams} />
        {/* This route has to be at the bottom */}
        <Route path={`${url}`} exact>
          <Redirect to={`${url}/all`}/>
        </Route>
      </Switch>
    </Container>
  );
}