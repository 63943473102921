import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import { ErrorPage } from './scenes/error/error';

Bugsnag.start({
  apiKey: '624cc5d06b7dfac51451c8039c961d93',
  plugins: [new BugsnagPluginReact()]
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

ReactDOM.render(
  <ErrorBoundary FallbackComponent={ErrorPage}>
    <I18nextProvider i18n={i18n}>
        <App />
    </I18nextProvider>
  </ErrorBoundary>,
    document.getElementById('root'))