import React from 'react'
import { Button, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import {Trans, useTranslation} from 'react-i18next';

import { Constants } from '../constants';
import { UpperCaseText } from "../layout/components/UpperCaseText";

const crypto = require('crypto')

const useStyles = makeStyles(() => ({
    link: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.5,
        borderRadius: "unset"
    }
}));

export const LoginButton = (props) => {
    const classes = useStyles();
    let state = crypto.randomBytes(16).toString("hex");
    sessionStorage.setItem("state", state);
    useTranslation();

    const discordBase = "https://discord.com/oauth2/authorize?";
    const params = new URLSearchParams({
        "client_id": Constants.discordClientID,
        "redirect_uri": new URL("/callback/login", Constants.basePrefix),
        "response_type": "code",
        "scope": "identify email guilds",
        "state": state
    });
    const discordUrl = discordBase + params.toString();

    if (props.inDrawer !== undefined && props.inDrawer) {
        return (
            <ListItem button component={Button} href={discordUrl} className={classes.link}>

                <ListItemText><Trans>menu.login</Trans></ListItemText>
            </ListItem>
        )
    }

    return(
        <Button color="inherit" href={discordUrl}><UpperCaseText><Trans>menu.login</Trans></UpperCaseText></Button>
    );
}