import React from 'react';
import { Box, Grid, Container } from '@material-ui/core';

import { PageTitle } from '../../layout/PageTitle';
import SponsorPaper from '../../layout/components/SponsorPaper';
import { XGamerCard } from './components/XGamerCard';
import { Product } from './product';

const products = [
    new Product("X-Gamer Svenska Raketligan Bundle", "Inkluderar SRL-shakern, 20 X-Shotz och 3 X-Corn", "https://x-gamer.com/collections/value-bundles/products/x-gamer-svenska-raketligan-bundle", "/images/x-gamer/srl-bundle.jpg"),
    new Product("Hyperbeast Collectors Edition", "Hyperbeast samlarutgåva", "https://www.x-gamer.com/product/hyperbeast-collectors-edition-3x-x-tubz-1800g-180-servings/", "/images/x-gamer/hyperbeast_collectors_edition.png"),
    new Product("2 Tubz + 1 Shaker", "Två valfria X-Tubz och en Shaker", "https://www.x-gamer.com/product/2-tubz-1-shaker/#configuration", "/images/x-gamer/tubz_shaker.png"),
    new Product("X-Gamer Essentials Bundle 2.0", "Inkluderar shaker, 20 X-Shotz och 3 X-Corn", "https://www.x-gamer.com/product/x-gamer-essentials-bundle-2-0/", "/images/x-gamer/essential-bundle.jpg"),
    new Product("X-Gamer Starter Kit", "Inkluderar shaker, 7 X-Shotz och X-Corn Salted Caramel", "https://www.x-gamer.com/product/x-gamer-starter-kit/", "/images/x-gamer/starter-kit.jpg"),
    new Product("X-Tubz Hyperbeast", "Med smak av fruktpunch", "https://www.x-gamer.com/product/hyperbeast-flavour-x-tub/", "/images/x-gamer/hyperbeast.jpg"),
    new Product("X-Tubz Horus", "Med smak av tropisk frukt", "https://www.x-gamer.com/product/x-tubz-horus-600g-60-servings/", "/images/x-gamer/horus.png"),
    new Product("X-Tubz Bluenitro", "Med smak av hallon", "https://www.x-gamer.com/product/bluenitro-flavour-x-tub/", "/images/x-gamer/bluenitro.png"),
    new Product("X-Tubz Sakurafuri", "Med smak av körsbär", "https://www.x-gamer.com/product/sakurafuri-flavour-x-tub/", "/images/x-gamer/sakurafuri.png"),
];

export const XGamer = () => {
    return (
        <React.Fragment>
            <PageTitle>
                X-Gamer Shop
            </PageTitle>
            <SponsorPaper>
                <Box pt={1} />
            </SponsorPaper>
            <Box my={5} />
            <Container>
                <Grid container direction="row" justify="space-evenly" alignItems="center" spacing={3}>
                    {products.map((product, index) => {
                        return (
                            <Grid item xs={12} md={index == 0 ? 12 : 6} lg={index == 0 ? 12 : 6} key={index}>
                                <XGamerCard title={product.title} desc={product.desc} link={product.link} src={product.img} />
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
            <Box my={5} />
        </React.Fragment>
    )
}