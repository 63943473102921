import React from 'react';
import Markdown from 'markdown-to-jsx';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Constants } from '../../constants';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Box, Paper } from '@material-ui/core';
import {useTranslation} from "react-i18next";
import { ImageService } from "../../services/ImageService";

const styles = (theme) => ({
    listItem: {
        marginTop: theme.spacing(1),
    },
    image: {
        width: '100%'
    },
    link: {
        color: 'white',
    }
});

function Image(props) {
    return <img {...props} />;
}
const options = {
    overrides: {
        img: {
            component: withStyles(styles)(({ classes, ...props }) => (
                <Grid container direction="row" justify="center">
                    <Grid item>
                        <img className={classes.image} src={ImageService.transformImageUrl(props.src)} />
                    </Grid>
                </Grid>
            )),
        },
        p: { component: Typography, props: { paragraph: true } },
        a: {
            component: withStyles(styles)(({ classes, ...props }) => (
                <Link color="inherit" className={classes.link} {...props} />
            )),
        },
        li: {
            component: withStyles(styles)(({ classes, ...props }) => (
                <li className={classes.listItem}>
                    <Typography component="span" {...props} />
                </li>
            )),
        },
    },
};


export const Post = (props) => {

    useTranslation()
    const [post, setPost] = React.useState();

    React.useEffect(() => {
        fetch(Constants.apiPrefix + `/api/news/${props.match.params.customUrl}`)
            .then(response => response.json())
            .then(post => {
                setPost(post);
                console.log(post);
            })
    }, [props.match.params.customUrl])

    return (
        <React.Fragment>
            <Box my={20} />
            <Container>
                <Paper elevation={5}>
                    <Box padding={2}>
                        {post && (
                            <Box>
                                <img src={ImageService.transformImageUrl(post.image)} style={{ width: '100%' }} />
                                <Box py={2}>
                                    <Typography variant="h4">{post.title}</Typography>
                                </Box>
                                <Box>
                                    <Typography variant="subtitle2">{post.datetime} av {post.author_nickname}</Typography>
                                </Box>
                                <Box pt={3} />
                                <Markdown options={options}>
                                    {post.text}
                                </Markdown>
                            </Box>
                        )}
                    </Box>
                </Paper>
            </Container>
            <Box my={5} />
        </React.Fragment>
    );
}