import React from "react";
import { Box, Button, Container, Grid, Paper } from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';

import { Constants } from "./../../../../../constants";
import { TextField } from "./../../../../../components/TextField";

const useStyles = makeStyles((theme) => ({
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

export const AddCategory = () => {
    const cookies = new Cookies();
    const classes = useStyles();

    const [category, setCategory] = React.useState("");
    const [saved, setSaved] = React.useState(false);
    const [success, setSuccess] = React.useState({
        severity: undefined,
        text: undefined,
    });

    const save = (event) => {
        event.preventDefault();

        fetch(Constants.apiPrefix + "/api/admin/news/category/save", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                accessToken: cookies.get("accessToken"),
                category: category
            }),
        })
        .then(response => response.json())
        .then(response => {
            if (response.hasOwnProperty("CODE") && response.hasOwnProperty("MESSAGE") && response.CODE === 200) {
                setSuccess({
                    severity: "success",
                    text: response.MESSAGE
                });
            } else if(response.hasOwnProperty("MESSAGE")){
                setSuccess({
                    severity: "error",
                    text: response.MESSAGE
                });
            }
            setSaved(true);
        });
    }

    const handleInput = (e, setter) => {
        e.preventDefault();

        setter(e.target.value);
    }

    return (
        <Container>
            <Grid container item xs={12}>
                <TextField
                        fullWidth
                        id="standard-basic"
                        label="Kategorinamn"
                        onChange={(e) => handleInput(e, setCategory)}
                        />
            </Grid>
            <Box pt={2} />
            <Grid container item xs={12}>
                <Button variant="contained" color="secondary" onClick={save}>Spara kategori</Button>
            </Grid>
            {saved && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Alert severity={success.severity}>
                        {success.text}
                    </Alert>
                </Paper>
            )}
        </Container>
    );
}