import React from "react";
import { Box, Button, Container, FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from '@material-ui/core/styles';
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import Cookies from 'universal-cookie';

import "react-mde/lib/styles/css/react-mde-all.css";

import { Constants } from "./../../../../constants";

const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
});

const useStyles = makeStyles((theme) => ({
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

export const Start = () => {
    const cookies = new Cookies();
    const classes = useStyles();

    const [content, setContent] = React.useState("**Hello world!!!**");
    const [contentEng, setContentEng] = React.useState("**Hello world!!!**");
    const [selectedTab, setSelectedTab] = React.useState("write");
    const [selectedTabEng, setSelectedTabEng] = React.useState("write");
    const [value, setValue] = React.useState('custom');
    const [loading, setLoading] = React.useState(true);
    const [saved, setSaved] = React.useState(false);
    const [success, setSuccess] = React.useState({
        severity: undefined,
        text: undefined,
    });

    React.useEffect(() => {
        fetch(Constants.apiPrefix + "/api/settings/all")
            .then(response => response.json())
            .then(response => {
                const startContent = response.find(setting => {
                    return setting.setting_key === Constants.settings.startContent;
                });
                const startContentEng = response.find(setting => {
                    return setting.setting_key === Constants.settings.startContentEng;
                });
                const startType = response.find(setting => {
                    return setting.setting_key === Constants.settings.startType;
                });
                setContent(startContent.setting_value);
                setContentEng(startContentEng.setting_value);
                setValue(startType.setting_value);
                setLoading(false);
            })
    }, []);

    const save = (event) => {
        event.preventDefault();

        fetch(Constants.apiPrefix + "/api/settings/save", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                accessToken: cookies.get("accessToken"),
                starttype: value,
                startcontent: content,
                startcontenteng: contentEng
            }),
        })
            .then(response => response.json())
            .then(response => {
                if (response.hasOwnProperty("CODE") && response.hasOwnProperty("MESSAGE") && response.CODE === 200) {
                    setSuccess({
                        severity: "success",
                        text: response.MESSAGE
                    });
                } else if (response.hasOwnProperty("MESSAGE")) {
                    setSuccess({
                        severity: "error",
                        text: response.MESSAGE
                    });
                }
                setSaved(true);
            });
    }

    const handleInput = (e, setter) => {
        e.preventDefault();

        setter(e.target.value);
    }

    return (
        loading ? <></> : <Container>
            <Grid container item xs={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Vad ska visas på startsidan?</FormLabel>
                    <RadioGroup aria-label="starttype" name="starttype" value={value} onChange={(e) => handleInput(e, setValue)}>
                        <FormControlLabel value={Constants.settings.startTypeValue.custom} control={<Radio />} label="En custom text från nedan" />
                        <FormControlLabel value={Constants.settings.startTypeValue.matches} control={<Radio />} label="Matcher från Division 1" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Typography variant="h5">Svenska</Typography>
            <Grid container item xs={12}>
                <ReactMde
                    value={content}
                    onChange={setContent}
                    selectedTab={selectedTab}
                    onTabChange={setSelectedTab}
                    generateMarkdownPreview={markdown =>
                        Promise.resolve(converter.makeHtml(markdown))
                    }
                />
            </Grid>
            <Box pt={2} />
            <Typography variant="h5">Engelska</Typography>
            <Grid container item xs={12}>
                <ReactMde
                    value={contentEng}
                    onChange={setContentEng}
                    selectedTab={selectedTabEng}
                    onTabChange={setSelectedTabEng}
                    generateMarkdownPreview={markdown =>
                        Promise.resolve(converter.makeHtml(markdown))
                    }
                />
            </Grid>
            <Box pt={2} />
            <Grid container item xs={12}>
                <Button variant="contained" color="secondary" onClick={save}>Spara inställningar</Button>
            </Grid>
            {saved && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Alert severity={success.severity}>
                        {success.text}
                    </Alert>
                </Paper>
            )}
        </Container>
    );
}