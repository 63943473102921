import React, { useState } from "react";
import { ThemeProvider } from "@material-ui/core";
import getTheme from "./base";

export const CustomThemeContext = React.createContext({
    currentTheme: "dark",
    currentBackground: undefined,
    setTheme: null,
    setCurrentBackground: null,
});

const CustomThemeProvider = (props) => {
    const { children } = props;
    const currentTheme = localStorage.getItem("appTheme") || "dark";
    const [themeName, _setThemeName] = useState(currentTheme);
    const [currentBackground, _setCurrentBackground] = useState("url(/images/background.png)");
    const theme = getTheme(themeName);
    const setThemeName = (name) => {
        localStorage.setItem("appTheme", name);
        _setThemeName(name);
    };

    const setCurrentBackground = (bg) => {
        _setCurrentBackground(bg);
    };

    const contextValue = {
        currentTheme: themeName,
        currentBackground: currentBackground,
        setTheme: setThemeName,
        setCurrentBackground: setCurrentBackground,
    };

    return (
        <CustomThemeContext.Provider value={contextValue}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </CustomThemeContext.Provider>
    );
};

export default CustomThemeProvider;
