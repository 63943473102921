import { Button, CircularProgress } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export const LoadingButton = (props) => {
    const classes = useStyles();
    const { onClick, children, ...rest } = props;
    const [loading, setLoading] = React.useState(false);

    const stopLoading = () => {
        setLoading(false);
    }

    const overrideOnClick = (event) => {
        setLoading(true);
        onClick(event, stopLoading);
    }

    return (
        <div className={classes.wrapper}>
            <Button onClick={overrideOnClick} {...rest} disabled={loading}>
                {children}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    )
}