import React from "react";
import {
    Box,
    CircularProgress,
    Container,
    IconButton,
    InputAdornment,
    makeStyles,
    Menu,
    MenuItem,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    useTheme,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import Cookies from "universal-cookie";
import { Trans } from "react-i18next";
import { Constants } from "../../../../../constants";
import InformationFormDialog from "./InformationFormDialog";
import ColorFormDialog from "./ColorFormDialog";
import TeamFormDialog from "./TeamFormDialog";
import MatchesFormDialog from "./MatchesFormDialog";
import DiscordRoomDialog from "./DiscordRoomDialog";

export const DropdownMenu = (props) => {
    const { division, updateDivisions } = props;
    const cookies = new Cookies();

    // Dropdown
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton aria-label="more" aria-controls={`menu-leave-icon-button`} aria-haspopup="true" onClick={handleClick}>
                <MoreVert />
            </IconButton>
            <Menu id={`menu-leave`} anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                <InformationFormDialog {...props} />
                <ColorFormDialog {...props} />
                <TeamFormDialog {...props} />
                <MatchesFormDialog {...props} />
                <DiscordRoomDialog {...props} />
            </Menu>
        </>
    );
};
