import { Grid, ListItem, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
}));

export const RegularNotification = (props) => {
    const { notification } = props;
    const { i18n } = useTranslation();
    const classes = useStyles();

    return (
        <ListItem className={classes.root}>
            <Grid container direction="row" spacing={1}>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={2}>
                            <Typography color="textSecondary">{notification.date.substr(0, notification.date.length - 3)}</Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <Typography>{i18n.language === "en" ? notification.engDescription : notification.description}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ListItem>
    );
}