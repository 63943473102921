import { Constants } from "../../../../../constants";

const fetchPreData = async (accessToken, seriesId) => {
    const r = await fetch(`${Constants.apiPrefix}/api/admin/series/report/${accessToken}/${seriesId}`);

    return await r.json();
};

const fetchRestData = async (accessToken, seriesId, homeTeamId, awayTeamId) => {
    const [homeTeamPlayers, awayTeamPlayers] = await Promise.all([
        fetch(`${Constants.apiPrefix}/api/admin/series/report/players/${accessToken}/${homeTeamId}/${seriesId}`),
        fetch(`${Constants.apiPrefix}/api/admin/series/report/players/${accessToken}/${awayTeamId}/${seriesId}`),
    ]);

    return [await homeTeamPlayers.json(), await awayTeamPlayers.json()];
};

const updateScore = async (accessToken, seriesId, globalStates) => {
    const scores = {};
    [1, 2, 3, 4, 5].map((matchNo) => {
        [1, 2, 3].map((playerNo) => {
            ["home", "away"].map((side) => {
                const key = `match${matchNo}_${side}_player${playerNo}`;
                scores[key] = {
                    teamPlayerId: globalStates[key][0]["teamPlayerId"],
                    points: globalStates[key][0]["points"],
                    goals: globalStates[key][0]["goals"],
                    assists: globalStates[key][0]["assists"],
                    saves: globalStates[key][0]["saves"],
                    shots: globalStates[key][0]["shots"],
                };
            });
        });
    });

    const r = await fetch(`${Constants.apiPrefix}/api/admin/series/report`, {
        method: "POST",
        body: JSON.stringify({
            accessToken: accessToken,
            seriesId: seriesId,
            scores: scores,
        }),
    });

    return await r.json();
};

const createDiscordChannel = async (accessToken, seriesId) => {
    const r = await fetch(`${Constants.apiPrefix}/api/admin/series/createDiscordChannel`, {
        method: "POST",
        body: JSON.stringify({
            accessToken: accessToken,
            seriesId: seriesId,
        }),
    });

    return await r.json();
};

const closeDiscordChannel = async (accessToken, seriesId) => {
    const r = await fetch(`${Constants.apiPrefix}/api/admin/series/closeDiscordChannel`, {
        method: "POST",
        body: JSON.stringify({
            accessToken: accessToken,
            seriesId: seriesId,
        }),
    });

    return await r.json();
};

export { fetchPreData, fetchRestData, updateScore, createDiscordChannel, closeDiscordChannel };
