import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Container, Box, withStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Constants } from '../../../constants';
import { Link } from 'react-router-dom';
import { UpperCaseText } from '../../../layout/components/UpperCaseText';
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Trans, useTranslation } from 'react-i18next';

export const TeamDivisionTable = (props) => {
    const theme = useTheme();
    const { division, teamId } = props;
    return (
        <TableContainer {...props}>
            <Table aria-label="Divisions tabell" size="small">
                <TableHead>
                    <TableRow>

                        <TableCell align="left">
                            <Trans i18nKey="league.team" />
                        </TableCell>
                        <TableCell align="right">
                            <Trans i18nKey="league.played-series" />
                        </TableCell>
                        <TableCell align="right">
                            <Trans i18nKey="league.points" />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {division && division.teams ? (division.teams.map((team, index) => {
                        let backgroundColor = {};
                        if (teamId === team.id) {
                            backgroundColor = { backgroundColor: theme.palette.background.paper }
                        }
                        return (
                            <TableRow style={backgroundColor} key={index}>

                                <TableCell align="left"><UpperCaseText>{team.team_name}</UpperCaseText></TableCell>
                                <TableCell align="right"><UpperCaseText>{team.series_played}</UpperCaseText></TableCell>
                                <TableCell align="right"><UpperCaseText>{team.points}</UpperCaseText></TableCell>
                            </TableRow>
                        )
                    }
                    )) : (
                        [...Array(10)].map((number) => {
                            const height = 20;
                            return (
                                <TableRow>
                                    <TableCell><Skeleton animation="wave" height={height} /></TableCell>
                                    <TableCell><Skeleton animation="wave" height={height} /></TableCell>
                                    <TableCell><Skeleton animation="wave" height={height} /></TableCell>
                                </TableRow>
                            )
                        })
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
