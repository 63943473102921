import React, { useState } from 'react';
import { Constants } from "../constants";
import Cookies from 'universal-cookie';
import { logout } from "./Callback";
import Bugsnag from '@bugsnag/js'

async function getUser(accessToken) {
    const response = await fetch(new URL(`/api/user/hidden/${accessToken}/full`, Constants.apiPrefix).toString());
    return await response.json();
}

async function getTeam(accessToken) {
    const response = await fetch(new URL(`/api/team/hidden/${accessToken}/currentTeam`, Constants.apiPrefix).toString());
    return await response.json();
}

export const StateContext = React.createContext({
    currentUser: {},
    currentTeam: {},
    loggedIn: false,
    setCurrentUser: null,
    setCurrentTeam: null,
    setLoggedIn: null,
    updateAll: null,
    updateUser: null,
    updateTeam: null,
});

const StateProvider = (props) => {
    const { children } = props;
    const cookies = new Cookies();
    const [currentUser, _setCurrentUser] = useState(undefined);
    const [currentTeam, _setCurrentTeam] = useState(undefined);
    const [loggedIn, _setLoggedIn] = useState(false);

    const setCurrentUser = (currentUser) => {
        if(currentUser) {
            Bugsnag.setUser(
              currentUser.id,
              "",
              "",
            );
        }
        _setCurrentUser(currentUser);
    }

    const setCurrentTeam = (currentTeam) => {
        _setCurrentTeam(currentTeam);
    }

    const setLoggedIn = (loggedIn) => {
        _setLoggedIn(loggedIn);
    }

    const updateAll = (accessToken) => {
        return Promise.all([
            getUser(accessToken),
            getTeam(accessToken),
        ]).then((responses) => {
            const setters = [setCurrentUser, setCurrentTeam];
            let shouldLogout = false;
            responses.forEach((response, index) => {
                if (index === 0) {
                    if (response.member === 0 && response.nationality === "SE") {
                        shouldLogout = true;
                    }
                }
                if (response.hasOwnProperty("CODE") && response.CODE === 401) {
                    setters[index](undefined);
                    shouldLogout = true;
                } else {
                    setters[index](response);
                }
            });
            if (shouldLogout) {
                logout(cookies, setLoggedIn);
            } else {
                setLoggedIn(true);
            }
        })
    }

    const updateUser = (accessToken) => {
        return getUser(accessToken).then(response => setCurrentUser(response));
    }

    const updateTeam = (accessToken) => {
        return getTeam(accessToken).then(response => setCurrentTeam(response));
    }

    const userHasAccess = (acceptedRoles) => {
        let userRoles = currentUser ? currentUser.roles.map(role => parseInt(role.id)) : [];
        const hasAccess = userRoles.filter(value => acceptedRoles.includes(value)).length > 0;
        return hasAccess;
    }

    React.useEffect(() => {
        if (cookies.get("accessToken")) {
            updateAll(cookies.get("accessToken")).then();
        } else {
            setCurrentUser(undefined);
            setCurrentTeam(undefined);
        }
    }, [loggedIn]);

    const contextValue = {
        currentUser: currentUser,
        currentTeam: currentTeam,
        loggedIn: loggedIn,
        setCurrentUser: setCurrentUser,
        setCurrentTeam: setCurrentTeam,
        setLoggedIn: setLoggedIn,
        updateAll: updateAll,
        updateUser: updateUser,
        updateTeam: updateTeam,
        userHasAccess: userHasAccess
    }

    return (
        <StateContext.Provider value={contextValue}>
            {children}
        </StateContext.Provider>
    )
}

export default StateProvider;