import React from 'react';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import { ArrowDropDown } from '@material-ui/icons';
import { Avatar, Button, ClickAwayListener, Grow, ListItem, ListItemText, MenuList, Paper, Popper } from '@material-ui/core';
import { Link } from "react-router-dom";
import { Trans } from 'react-i18next';
import { useTheme } from "@material-ui/core/styles";

import { Constants } from "../../constants";
import { UpperCaseText } from './UpperCaseText';

export default function UserDropdown(props) {
    const theme = useTheme();

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const avatarHeightWidth = 30;
    let displayName;
    if (props.currentUser) {
        displayName = props.currentUser.nickname !== null ? props.currentUser.nickname :
            `${props.currentUser.discordUsername}#${props.currentUser.discordDiscriminator}`;
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    function hasAccessToAdmin() {
        const acceptedRoles = [Constants.roles.divisionadmin, Constants.roles.admin, Constants.roles.graphics, Constants.roles.writer];
        const hasAccessToAdmin = props.currentUser.roles && props.currentUser.roles.filter(value => acceptedRoles.includes(parseInt(value.id))).length > 0;

        return hasAccessToAdmin;
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    if (props.inDrawer !== undefined && props.inDrawer) {
        return (
            <React.Fragment>
                {props.currentUser && (
                    <ListItem button component={Link} to={`/player/${props.currentUser.playerId !== null ? props.currentUser.playerId : "NoPlayerConnected"}`} onClick={props.onClick}>
                        <ListItemText><Trans>menu.profile</Trans></ListItemText>
                    </ListItem>
                )}

                {props.currentTeam ? (
                    <ListItem button component={Link} to={`/team/${props.currentTeam.id}`} onClick={props.onClick}>
                        <ListItemText>{props.currentTeam.teamname}</ListItemText>
                    </ListItem>
                ) : (
                    <ListItem button component={Link} to={`/createTeam`} onClick={props.onClick}>
                        <ListItemText><Trans>menu.create-team</Trans></ListItemText>
                    </ListItem>
                )}
                {hasAccessToAdmin() && (
                    <ListItem button component={Link} to="/admin" onClick={props.onClick}>
                        <ListItemText><Trans>menu.admin</Trans></ListItemText>
                    </ListItem>
                )}
                <ListItem button component={Link} to="/callback/logout" onClick={props.onClick} >
                    <ListItemText><Trans>menu.logout</Trans></ListItemText>
                </ListItem>
            </React.Fragment>
        )
    }

    return (
        <Box>
            <Button
                color="inherit"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                style={{ fontSize: '16px' }}
            >
                {props.currentUser &&
                    <Avatar
                        alt=""
                        style={{ height: avatarHeightWidth, width: avatarHeightWidth, marginRight: theme.spacing(1) }}
                        src={props.currentUser && `${Constants.apiPrefix}/api/${props.currentUser.avatar}`}
                    />
                }
                <UpperCaseText truncateWidth={'100px'}>
                    {displayName}
                </UpperCaseText>
                <ArrowDropDown />
            </Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    {props.currentUser && (
                                        <MenuItem onClick={handleToggle} component={Link} to={`/player/${props.currentUser.playerId !== null ? props.currentUser.playerId : "NoPlayerConnected"}`}><Trans>menu.profile</Trans></MenuItem>
                                    )}
                                    {props.currentTeam ? (
                                        <MenuItem onClick={handleToggle} component={Link} to={`/team/${props.currentTeam.id}`}>
                                            {props.currentTeam.teamname}
                                        </MenuItem>
                                    ) : props.currentUser.playerId !== null && (
                                        <MenuItem onClick={handleToggle} component={Link} to={`/createTeam`}>
                                            <Trans>menu.create-team</Trans>
                                        </MenuItem>
                                    )}
                                    {hasAccessToAdmin() && (
                                        <MenuItem onClick={handleToggle} component={Link} to="/admin"><Trans>menu.admin</Trans></MenuItem>
                                    )}
                                    <MenuItem component={Link} to="/callback/logout"><Trans>menu.logout</Trans></MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
}