import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import { Trans, useTranslation } from 'react-i18next';
import { TextField } from "./../../../components/TextField";
import { Alert } from "@material-ui/lab";
import { UploadImage } from "./../../../components/UploadImage";
import { Constants } from "../../../constants";
import Cookies from 'universal-cookie';
import { StateContext } from "../../../discord/StateProvider";
import { LoadingButton } from "../../../components/LoadingButton";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

function getSteps(t) {
    return [t("create-team.teamname"), t("create-team.logo"), t("create-team.send")];
}

function getStepContent(step, states, t) {
    useTranslation();
    switch (step) {
        case 0:
            return (
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                        <Typography>
                            <Trans>create-team.teamname.text</Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <TextField
                            fullWidth
                            id="standard-basic"
                            label={states.teamnameState[0] !== null ? states.teamnameState[0] : t("team.settings.teamname")}
                            onChange={(event) => {
                                const setTeamname = states.teamnameState[1];
                                setTeamname(event.target.value);
                            }}
                        />
                    </Grid>
                </Grid>
            );
        case 1:
            return (
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                        <Typography>
                            <Trans>create-team.logo.text</Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <UploadImage align="stretch" croppedImageState={states.croppedImageState} defaultImage={states.croppedImageState[0]} />
                    </Grid>
                </Grid>
            );

        case 2:
            return (
                <>
                    <Typography>
                        <Trans>create-team.send.text</Trans>
                    </Typography>
                </>
            );
        default:
            return 'Okänt steg';
    }
}

function getImageDimensions(file) {
    return new Promise(function (resolved, rejected) {
        var i = new Image()
        i.onload = function () {
            resolved({ w: i.width, h: i.height })
        };
        i.src = file
    })
}

export default function CreateStepper() {
    const classes = useStyles();
    const { t } = useTranslation();
    const cookies = new Cookies();
    const [activeStep, setActiveStep] = React.useState(0);
    const [croppedBlob, setCroppedBlob] = React.useState(null);
    const [teamname, setTeamname] = React.useState(null);
    const [alert, setAlert] = React.useState({});
    const updateTeam = useContext(StateContext).updateTeam;

    const createTeam = (callback) => {
        fetch(new URL("/api/team/createTeamRequest", Constants.apiPrefix).toString(), {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                accessToken: cookies.get("accessToken"),
                teamname: teamname,
                teamlogo: croppedBlob
            }),
        }).then(response => response.json())
            .then(response => {
                if (response.hasOwnProperty("CODE") && response.CODE === 200) {
                    setAlert({
                        open: true,
                        severity: "success",
                        message: t(response.MESSAGE)
                    });
                    updateTeam(cookies.get("accessToken"));
                } else if (response.hasOwnProperty("CODE") && response.CODE !== 500) {
                    setAlert({
                        open: true,
                        severity: "error",
                        message: t(response.MESSAGE)
                    });
                } else {
                    throw new Error("react.createTeam.components.CreateStepper.createTeam");
                }
                callback();
            }).catch(e => callback());
    }

    const states = {
        teamnameState: [teamname, setTeamname],
        croppedImageState: [croppedBlob, setCroppedBlob],
    }

    const steps = getSteps(t);

    const handleNext = (event, index) => {
        event.preventDefault();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleFinal = (event, callback) => {
        event.preventDefault();
        createTeam(() => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            callback();
        });
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    if (croppedBlob)
        console.log(croppedBlob.length);

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                            {getStepContent(index, states, t)}
                            <div className={classes.actionsContainer}>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className={classes.button}
                                        >
                                            <Trans>login.form.back</Trans>
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        {activeStep === steps.length - 1 ? (
                                            <LoadingButton type="submit" variant="contained" color="secondary" onClick={handleFinal} className={classes.button}>
                                                <Trans>create-team.send-team</Trans>
                                            </LoadingButton>
                                        ) : (
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="secondary"
                                                onClick={handleNext}
                                                className={classes.button}
                                                disabled={index === 0 ? (teamname === null || teamname.length === 0) : false}
                                            >
                                                <Trans>create-team.next</Trans>
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer} hidden={!alert.open}>
                    <Alert severity={alert.severity}>
                        {alert.message}
                    </Alert>
                </Paper>
            )}
        </div>
    );
}
