import React from "react";
import { Box, Container, InputAdornment } from "@material-ui/core";
import Cookies from "universal-cookie";
import { Search } from "@material-ui/icons";
import { Redirect } from "react-router-dom";

import { Constants } from "../../../../constants";
import { UserTable } from "./UserTable";
import { TextField } from "../../../../components/TextField";

async function getUsersJoinedWithPlayers(accessToken) {
    const response = await fetch(`${Constants.apiPrefix}/api/admin/${accessToken}/usersOuterJoinedWithPlayers`);
    return await response.json();
}

async function getRoles() {
    const response = await fetch(`${Constants.apiPrefix}/api/users/allRoles`);
    return await response.json();
}

export const Users = () => {
    const cookies = new Cookies();
    const [users, setUsers] = React.useState([]);
    const [searchUsers, setSearchUsers] = React.useState([]);
    const [roles, setRoles] = React.useState([]);

    const updateUsers = async () => {
        if (cookies.get("accessToken")) {
            const r1 = await getUsersJoinedWithPlayers(cookies.get("accessToken"));
            setUsers(r1.MESSAGE);
            setSearchUsers(r1.MESSAGE);
        }
        const r2 = await getRoles();
        setRoles(r2.MESSAGE);
    };

    React.useEffect(() => {
        updateUsers();
    }, []);

    const handleChange = (event) => {
        if (event.target.value.length > 0) {
            setSearchUsers(
                users.filter(
                    (user) =>
                        (user.nickname !== undefined && user.nickname !== null && user.nickname.toLowerCase().includes(event.target.value.toLowerCase())) ||
                        (user.pNickname !== undefined && user.pNickname !== null && user.pNickname.toLowerCase().includes(event.target.value.toLowerCase())) ||
                        (user.discord !== undefined && user.discord !== null && user.discord.toLowerCase().includes(event.target.value.toLowerCase())) ||
                        (user.discordUsername !== undefined && user.discordUsername !== null && user.discordUsername.toLowerCase().includes(event.target.value.toLowerCase())) ||
                        (user.teamname !== undefined && user.teamname !== null && user.teamname.toLowerCase().includes(event.target.value.toLowerCase()))
                )
            );
        } else {
            setSearchUsers(users);
        }
    };

    return (
        <Container>
            <Box p={1}>
                <TextField
                    autoFocus
                    fullWidth
                    label="Sök spelarnamn"
                    variant="outlined"
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <UserTable users={searchUsers} updateUsers={updateUsers} roles={roles} />
        </Container>
    );
};
