import React from 'react';
import { PageTitle } from '../../layout/PageTitle';
import {Box, Grid, Container, Typography, Link} from '@material-ui/core';
import SponsorPaper from '../../layout/components/SponsorPaper';
import { Constants } from '../../constants';
import SixMansTabs from "./components/6mansTabs";
import {Trans, useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import {Alert} from "@material-ui/lab";

const getLeaderboards = async () => {
  const r = await fetch(`${Constants.apiPrefix}/api/6mans/leaderboard/all`);
  return await r.json();
}

export const SixMans = (props) => {

    const [leaderboards, setLeaderboards] = React.useState();
    const [loading, setLoading] = React.useState(true);

    const { t, i18n } = useTranslation();

    let rulebookUrl = "/content/Regelverk_SRL_6Mans.pdf";

    if (i18n.language === "en") {
        rulebookUrl = "/content/Rulebook_SRL_6Mans.pdf"
    }
  

    React.useEffect(() => {
        getLeaderboards().then(r => {
            setLeaderboards(r);
            setLoading(false);
        })
    }, [])

    return (
        <React.Fragment>
            <PageTitle>
                <Grid container spacing={2} justify="center" alignItems="center">
                  <Grid item>
                    <img style={{height: 200, width: "auto"}} src="/images/SRL6mansloggapng.png" alt=""/>
                  </Grid>
                  <Grid item>
                    SRL 6MANS
                  </Grid>
                </Grid>
            </PageTitle>
            <SponsorPaper>
                <Box pt={1}/>
            </SponsorPaper>
            <Box my={5}/>
            <Container>
              <Box p={1}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography>
                      <Trans>
                        sixmans.free-text.part1
                      </Trans>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      <Trans>
                        sixmans.free-text.part2
                      </Trans>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Alert severity="info" variant="outlined">
                      <Trans>
                        sixmans.free-text.part3
                      </Trans>
                    </Alert>
                  </Grid>
                  <Grid item container justify="space-between" direction="row" alignItems="stretch">
                    
                    <Grid item>
                      <Button href="https://discord.gg/ENrBFjMVNy" target="_blank" variant="contained" color="secondary">
                        <Trans>
                          sixmans.button.join-discord
                        </Trans>
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button href={rulebookUrl} target="_blank" variant="outlined" color="secondary">
                        <Trans>
                          sixmans.button.rulebook
                        </Trans>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Typography variant="h4">
                <Trans>
                  sixmans.leaderboard
                </Trans>
              </Typography>
              {leaderboards && <SixMansTabs leaderboards={leaderboards}/>}
            </Container>
            <Box my={5}/>
        </React.Fragment>
    )

}