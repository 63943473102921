import React, { useContext } from "react";
import Cookies from "universal-cookie";
import { Box, Container, makeStyles, Tabs, Tab, Grid, Badge } from "@material-ui/core";
import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";

import { StateContext } from "../../discord/StateProvider";
import { PageTitle } from "../../layout/PageTitle";
import SponsorPaper from "./../../layout/components/SponsorPaper";
import { Users } from "./scenes/users/Users";
import { Start } from "./scenes/start/Start";
import { Dashboard } from "./scenes/dashboard/Dashboard";
import { Requests } from "./scenes/requests/Requests";
import { News } from "./scenes/news/News";
import { Divisions } from "./scenes/divisions/Divisions";
import { Constants } from "../../constants";
import { Seasons } from "./scenes/seasons/Seasons";
import { SignedupTeams } from "./scenes/signupTeams/SignedupTeams";
import PrivateRoute from "./PrivateRoute";
import { Matches } from "./scenes/matches/Matches";
import { Teams } from "./scenes/teams/Teams";
import { Transfers } from "./scenes/transfers/Transfers";
import { Settings } from "./scenes/settings/Settings";
import { Pages } from "./scenes/pages/pages";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 300,
        maxWidth: 450,
        backgroundColor: theme.palette.background.paper,
    },
}));

const navItems = [
    {
        text: "Dashboard",
        urlSuffix: "dashboard",
        acceptedRoles: [Constants.roles.admin, Constants.roles.divisionadmin, Constants.roles.graphics, Constants.roles.writer],
    },
    {
        text: "Requests",
        urlSuffix: "requests",
        acceptedRoles: [Constants.roles.admin, Constants.roles.divisionadmin],
    },
    {
        text: "Matcher",
        urlSuffix: "matches",
        acceptedRoles: [Constants.roles.admin, Constants.roles.divisionadmin],
    },
    {
        text: "Nyheter",
        urlSuffix: "news",
        acceptedRoles: [Constants.roles.admin, Constants.roles.writer],
    },
    // {
    //   text: "Grafikadmin",
    //   urlSuffix: "",
    // },
    {
        text: "Anmälda lag",
        urlSuffix: "signedupTeams",
        acceptedRoles: [Constants.roles.admin, Constants.roles.divisionadmin, Constants.roles.graphics, Constants.roles.writer],
    },
    {
        text: "Användare",
        urlSuffix: "users",
        acceptedRoles: [Constants.roles.admin, Constants.roles.divisionadmin],
    },
    {
        text: "Lag",
        urlSuffix: "teams",
        acceptedRoles: [Constants.roles.admin, Constants.roles.divisionadmin],
    },
    {
        text: "Startsidan",
        urlSuffix: "start",
        acceptedRoles: [Constants.roles.admin],
    },
    {
        text: "Övergångar",
        urlSuffix: "transfers",
        acceptedRoles: [Constants.roles.admin, Constants.roles.divisionadmin, Constants.roles.graphics, Constants.roles.writer],
    },
    {
        text: "Divisioner",
        urlSuffix: "divisions",
        acceptedRoles: [Constants.roles.admin, Constants.roles.divisionadmin],
    },
    {
        text: "Säsonger",
        urlSuffix: "seasons",
        acceptedRoles: [Constants.roles.admin],
    },
    {
        text: "Ligainställningar",
        urlSuffix: "settings",
        acceptedRoles: [Constants.roles.admin],
    },
    {
        text: "Sidinställningar",
        urlSuffix: "pages",
        acceptedRoles: [Constants.roles.admin],
    }
];

const getUnprocessedRequests = async (accessToken) => {
    const r = await fetch(`${Constants.apiPrefix}/api/admin/${accessToken}/unprocessedRequests`);
    return await r.json();
};

export const Admin = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { url } = props.match;
    const location = useLocation();
    const { userHasAccess } = useContext(StateContext);

    const cookies = new Cookies();
    const [players, setPlayers] = React.useState();
    const [unprocessedRequests, setUnprocessedRequests] = React.useState(0);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [drawerOpen, setDrawerOpen] = React.useState(true);

    // This is to set correct index for Tabs when reloading page
    const splitted = location.pathname.split("/");
    const currentUrl = splitted[splitted.length - 1];
    let navIndex = navItems.findIndex((n) => {
        return n.urlSuffix.toLowerCase() === currentUrl.toLowerCase();
    });

    if (navIndex < 0) {
        navIndex = 0;
    }

    const [value, setValue] = React.useState(navIndex);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    function hasAccess(acceptedRoles) {
        return userHasAccess(acceptedRoles);
    }

    React.useEffect(() => {
        if (cookies.get("accessToken")) {
            fetch(new URL(`/api/admin/player/${cookies.get("accessToken")}/all`, Constants.apiPrefix).toString())
                .then((players) => players.json())
                .then((players) => {
                    setPlayers(players);
                    console.log(players);
                });
            updateRequestCounter();
        }
    }, []);

    const updateRequestCounter = () => {
        getUnprocessedRequests(cookies.get("accessToken")).then((r) => setUnprocessedRequests(parseInt(r)));
    };

    return (
        <>
            <PageTitle minHeight={250} variant="h4" shadow>
                Admin
            </PageTitle>
            <SponsorPaper>
                <Box pt={1} />
            </SponsorPaper>
            <Container>
                <Tabs value={value} onChange={handleChange} variant="scrollable" aria-label="simple tabs example">
                    {navItems.map(
                        (navItem, index) =>
                            hasAccess(navItem.acceptedRoles) && (
                                <Tab
                                    key={index}
                                    label={
                                        navItem.urlSuffix === "requests" ? (
                                            <Badge badgeContent={unprocessedRequests} color="secondary">
                                                {navItem.text}
                                            </Badge>
                                        ) : (
                                            navItem.text
                                        )
                                    }
                                    component={Link}
                                    to={`${url}/${navItem.urlSuffix}`}
                                    {...a11yProps(index)}
                                />
                            )
                    )}
                </Tabs>
            </Container>
            <Box my={2} />

            <Switch>
                <Route path={`${url}/dashboard`} component={Dashboard} />
                <Route path={`${url}/requests`}>
                    <Requests updateRequestCounter={updateRequestCounter} />
                </Route>
                {/* TODO: PrivateRoute verkar inte riktigt fungera i Child Routes. Inte orkat kolla varför ännu */}
                {/* <PrivateRoute path={`${url}/requests`} acceptedRoles={[Constants.roles.admin, Constants.roles.divisionadmin]} component={Requests} /> */}
                <Route path={`${url}/divisions`} component={Divisions} />
                <Route path={`${url}/start`} component={Start} />
                <Route path={`${url}/news`} component={News} />
                <Route path={`${url}/matches`} component={Matches} />
                <Route path={`${url}/users`} component={Users} />
                <Route path={`${url}/seasons`} component={Seasons} />
                <Route path={`${url}/transfers`} component={Transfers} />
                <Route path={`${url}/signedupTeams`} component={SignedupTeams} />
                <Route path={`${url}/teams`} component={Teams} />
                <Route path={`${url}/settings`} component={Settings} />
                <Route path={`${url}/pages`} component={Pages} />
                {/* This route has to be at the bottom */}
                <Route path={`${url}`} exact>
                    <Redirect to={`${url}/dashboard`} />
                </Route>
            </Switch>

            <Box my={10} />
        </>
    );
};
