import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import { UpperCaseText } from "../../../layout/components/UpperCaseText";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import Table from "@material-ui/core/Table";
import { Card, CardContent, Paper, Tooltip, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Constants } from "../../../constants";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        padding: 0,
    },
    tableCell: {
        padding: 12,
        [theme.breakpoints.up("lg")]: {
            padding: 16,
        },
    },
    disqualified: {
        color: "#d0d0d0",
        fontStyle: 'italic'
    }
}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
    },
}))(Tooltip);

const DivisionRow = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const team = props.team;
    console.log(team);

    const placement = props.placement;
    const division = props.divisionId;

    return (
        <>
            <TableRow key={team.id} style={{ backgroundColor: team.color.hex }}>
                <TableCell className={`${team.disqualified === "1" ? classes.disqualified : ""}`}>
                    <UpperCaseText>{placement}.</UpperCaseText>
                </TableCell>
                {isWidthUp("lg", props.width) && (
                    <TableCell align="center">
                        <img
                            style={{ maxWidth: 35, maxHeight: 35 }}
                            src={team.team_logo.includes("discordapp") ? team.team_logo : `${Constants.apiPrefix}/api/${team.team_logo}`}
                            alt={""}
                        />
                    </TableCell>
                )}
                {parseInt(props.seasonId) === parseInt(props.currentSeasonId) && team.activePlayers ? (
                    team.activePlayers.length > 0 ? (
                        <HtmlTooltip
                            title={
                                <Grid container direction="column" spacing={1}>
                                    {team.activePlayers.map((player) => (
                                        <Grid item>
                                            <UpperCaseText>{player.nickname}</UpperCaseText>
                                        </Grid>
                                    ))}
                                </Grid>
                            }
                            placement="down"
                        >
                            <TableCell component="th" scope="row" component={Link} to={`/team/${team.id}`} classes={classes.tableCell} className={`${team.disqualified === "1" ? classes.disqualified : ""}`}>
                                <UpperCaseText>{team.team_name} {team.disqualified === "1" ? "(DQ)" : ""}</UpperCaseText>
                            </TableCell>
                        </HtmlTooltip>
                    ) : (
                        <TableCell component="th" scope="row" component={Link} to={`/team/${team.id}`} classes={classes.tableCell} className={`${team.disqualified === "1" ? classes.disqualified : ""}`}>
                            <UpperCaseText>{team.team_name} {team.disqualified === "1" ? "(DQ)" : ""}</UpperCaseText>
                        </TableCell>
                    )
                ) : (
                    <HtmlTooltip
                        title={
                            <Grid container direction="column" spacing={1}>
                                {team.players.map((player) => (
                                    <Grid item>
                                        <UpperCaseText>{player.nickname}</UpperCaseText>
                                    </Grid>
                                ))}
                            </Grid>
                        }
                        placement="down"
                    >
                        <TableCell component="th" scope="row" component={Link} to={`/team/${team.id}`} classes={classes.tableCell}>
                            <UpperCaseText>{team.team_name}</UpperCaseText>
                        </TableCell>
                    </HtmlTooltip>
                )}
                <TableCell align="right" classes={classes.tableCell} className={`${team.disqualified === "1" ? classes.disqualified : ""}`}>
                    <UpperCaseText>{team.series_played}</UpperCaseText>
                </TableCell>
                <TableCell align="right" classes={classes.tableCell} className={`${team.disqualified === "1" ? classes.disqualified : ""}`}>
                    <UpperCaseText>{team.wins}</UpperCaseText>
                </TableCell>
                <TableCell align="right" classes={classes.tableCell} className={`${team.disqualified === "1" ? classes.disqualified : ""}`}>
                    <UpperCaseText>{team.overtime_wins}</UpperCaseText>
                </TableCell>
                <TableCell align="right" classes={classes.tableCell} className={`${team.disqualified === "1" ? classes.disqualified : ""}`}>
                    <UpperCaseText>{team.overtime_losses}</UpperCaseText>
                </TableCell>
                <TableCell align="right" classes={classes.tableCell} className={`${team.disqualified === "1" ? classes.disqualified : ""}`}>
                    <UpperCaseText>{team.losses}</UpperCaseText>
                </TableCell>
                <TableCell align="right" classes={classes.tableCell} className={`${team.disqualified === "1" ? classes.disqualified : ""}`}>
                    <UpperCaseText>{team.diff}</UpperCaseText>
                </TableCell>
                <TableCell align="center" classes={classes.tableCell} className={`${team.disqualified === "1" ? classes.disqualified : ""}`}>
                    <UpperCaseText>{team.points}</UpperCaseText>
                </TableCell>
            </TableRow>
        </>
    );
};

export default withWidth()(DivisionRow);
