import React from "react";
import Cookies from "universal-cookie";
import {Container} from "@material-ui/core";
import {Redirect, Route, Switch} from "react-router-dom";
import { AllTransfers } from "./components/AllTransfers";

export const Transfers = (props) => {

  const { url } = props.match;

  return (
    <Container>
      <Switch>
        <Route path={`${url}/all`} component={AllTransfers} />
        {/* This route has to be at the bottom */}
        <Route path={`${url}`} exact>
          <Redirect to={`${url}/all`}/>
        </Route>
      </Switch>
    </Container>
  );
}