import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Divider, List, ListItem, ListItemText } from '@material-ui/core';
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

function translateSeasonName(name){
    return name.replace('Säsong','Season');
}

export const SeasonTabs = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const { i18n } = useTranslation();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} variant="scrollable">
                    {props.seasons && props.seasons.map((season, index) => {
                         if(season.divisions && season.divisions.length > 0) {
                             return (<Tab label={i18n.language === "en" ? translateSeasonName(season.name) : season.name} {...a11yProps(index)} />);
                         }
                    })}
                </Tabs>
            </AppBar>
            {props.seasons && props.seasons.map((season, index) => {
                if(season.divisions && season.divisions.length > 0) {
                    return (
                      <TabPanel value={value} index={index}>
                          <List style={{padding: 0}}>
                              {season.divisions.map((division, index) => (
                                  <React.Fragment>
                                      <ListItem button component={Link}
                                                to={`/division/${division.discriminator[1]}/season/${season.id}`}>
                                          <ListItemText primary={division.name}/>
                                      </ListItem>
                                      {index < season.divisions.length - 1 ? <Divider/> : <React.Fragment/>}
                                  </React.Fragment>
                                )
                              )}
                          </List>
                      </TabPanel>
                    );
                }
            }
            )}

        </div>
    );
}

