import React, { useContext } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { TextField } from "../../components/TextField";
import {
    Box,
    Button,
    Paper,
    Snackbar,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    ListItemIcon,
    Avatar,
    CircularProgress,
    Tooltip,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import { UploadImage } from "../../components/UploadImage";
import { StateContext } from "../../discord/StateProvider";
import { Constants } from "../../constants";
import Cookies from "universal-cookie";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import { isWidthUp } from "@material-ui/core/withWidth";
import AlertDialog from "./components/AlertDialog";
import { Trans, useTranslation } from "react-i18next";
import { KickPlayerDialog } from "./components/KickPlayerDialog";
import { LeaveTeamDialog } from "./components/LeaveTeamDialog";
import { SnackbarContext } from "../../context/SnackbarProvider";

import { RemoveInviteDialog } from "./components/RemoveInviteDialog";
import { getInvitedPlayers } from "./Team";

function checkIfValid(settings) {
    return true;
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
    },
}))(Tooltip);

export const TeamSettings = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const { url, players, invitedPlayers, setPlayers, setInvitedPlayers, teamCaptain, registrationOpen, transferOpen } = props;
    const cookies = new Cookies();
    const [avatar, setAvatar] = React.useState();
    const [banner, setBanner] = React.useState();
    const [inactivePlayers, setInactivePlayers] = React.useState([]);
    const [selectedPlayer, setSelectedPlayer] = React.useState();
    const [isActive, setIsActive] = React.useState();
    const { currentUser, currentTeam, updateTeam } = useContext(StateContext);
    const [teamname, setTeamname] = React.useState();
    const { setSnackbar } = React.useContext(SnackbarContext);

    //Save teaminfo button settings
    const [loading, setLoading] = React.useState(false);

    console.log(invitedPlayers);

    React.useEffect(() => {
        fetch(`${Constants.apiPrefix}/api/players/inactiveWithConnection`)
            .then((response) => response.json())
            .then((response) => setInactivePlayers(response));
        if (currentTeam) {
            fetch(`${Constants.apiPrefix}/api/team/${currentTeam.id}/isActive`)
                .then((response) => response.json())
                .then((response) => setIsActive(response));

            setTeamname(currentTeam.teamname);
        }
    }, [currentTeam]);

    const sendInvite = (event, callback) => {
        if (!teamCaptain) {
            setSnackbar({
                open: true,
                severity: "error",
                message: t("team-settings.must-be-captain"),
            });
            callback();
            setLoading(false);
            return;
        }
        if (!selectedPlayer) {
            setSnackbar({
                open: true,
                severity: "error",
                message: t("team-settings.must-select-valid-player"),
            });
            callback();
            setLoading(false);
            return;
        }
        fetch(new URL("/api/team/invitePlayer", Constants.apiPrefix).toString(), {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                accessToken: cookies.get("accessToken"),
                toUserId: selectedPlayer.userId,
                toPlayerId: selectedPlayer.id,
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.hasOwnProperty("CODE") && response.CODE === 200) {
                    getInvitedPlayers(currentTeam.id).then((players) => {
                        setSnackbar({
                            open: true,
                            severity: "success",
                            message: t(response.MESSAGE),
                        });
                        setSelectedPlayer(null);
                        setInvitedPlayers(players);
                    });
                } else if (response.hasOwnProperty("CODE") && response.CODE !== 500) {
                    setSnackbar({
                        open: true,
                        severity: "error",
                        message: t(response.MESSAGE),
                    });
                } else {
                    throw new Error("react.team.TeamSettings.sendInvite");
                }
                callback();
            });
    };

    const saveImageAndNameSettings = () => {
        setLoading(true);
        if (!teamCaptain) {
            setSnackbar({
                open: true,
                severity: "error",
                message: t("team-settings.must-be-captain"),
            });
            setLoading(false);
            return;
        }
        if (avatar || banner || teamname) {
            fetch(new URL(`/api/team/saveImageAndNameSettings`, Constants.apiPrefix).toString(), {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    accessToken: cookies.get("accessToken"),
                    teamlogo: avatar,
                    banner: banner,
                    teamname: teamname,
                }),
            })
                .then((response) => response.json())
                .then((response) => {
                    updateTeam(cookies.get("accessToken")).then(() => {
                        if (response.hasOwnProperty("CODE") && response.CODE === 200) {
                            setSnackbar({
                                open: true,
                                severity: "success",
                                message: t(response.MESSAGE),
                            });
                        } else if (response.hasOwnProperty("CODE") && response.CODE !== 500) {
                            setSnackbar({
                                open: true,
                                severity: "error",
                                message: t(response.MESSAGE),
                            });
                        } else {
                            throw new Error("react.team.TeamSettings.saveImageAndNameSettings");
                        }
                        setLoading(false);
                    });
                });
        } else {
            setSnackbar({
                open: true,
                severity: "info",
                message: t("team-settings.change-picture"),
            });
            setLoading(false);
        }
    };

    const playersIncludedInvited = (players && players.length > 0 ? players.length : 0) + (invitedPlayers && invitedPlayers.length > 0 ? invitedPlayers.length : 0);

    //const twitterRegExp = new RegExp('^(?:https://)?(?:www\\.)?twitter\\.com/(\\w+)$', 'i');
    //const youtubeRegExp = new RegExp('(?:https?:\\/\\/)?(?:www\\.)?youtu\\.?be(?:\\.com)?\\/?.*(?:watch|embed)?(?:.*v=|v\\/|\\/)([\\w\\-_]+)\\&?', 'i');

    /*const handleSettingsChange = (event) => {
      const name = event.target.name;
      console.log(event);
      setSettings({
        ...settings,
        [name]: event.target.value,
      });
    };*/

    return (
        <>
            {!teamCaptain && (
                <Box p={2}>
                    <Alert severity="info" variant="outlined">
                        <AlertTitle>
                            <Trans i18nKey="team-settings.information" />
                        </AlertTitle>
                        <Trans i18nKey="team-settings.information.team-captain" />
                    </Alert>
                </Box>
            )}
            <Grid container direction="row-reverse">
                <Grid item>
                    <RouterLink to={`${url}`} style={{ color: theme.palette.text.primary }}>
                        <IconButton>
                            <ArrowBack />
                        </IconButton>
                    </RouterLink>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <Typography variant="h4">
                        <Trans i18nKey="team.settings.players" />
                    </Typography>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <List>
                                {players &&
                                    currentTeam &&
                                    players.map((player) => (
                                        <ListItem key={player.id} component={Paper} style={{ marginBottom: 4 }}>
                                            <ListItemIcon>
                                                {player.userId !== null ? (
                                                    <Avatar src={player.avatar && `${Constants.apiPrefix}/api/${player.avatar}`} />
                                                ) : (
                                                    <HtmlTooltip
                                                        title={
                                                            <Typography>
                                                                <Trans>user.not.logged.in</Trans>
                                                            </Typography>
                                                        }
                                                    >
                                                        <Avatar
                                                            src={player.avatar && `${Constants.apiPrefix}/api/${player.avatar}`}
                                                            style={{ backgroundColor: theme.palette.error.light, color: theme.palette.error.dark }}
                                                        />
                                                    </HtmlTooltip>
                                                )}
                                            </ListItemIcon>
                                            <ListItemText>
                                                {player.nickname}
                                                <Typography color="textSecondary" variant="body2">
                                                    {parseInt(player.sortorder) === 1 && "(Lagkapten)"}
                                                </Typography>
                                            </ListItemText>
                                            {player.id !== currentUser.playerId ? (
                                                <ListItemSecondaryAction>
                                                    {teamCaptain && (
                                                        <KickPlayerDialog playerId={player.id} nickname={player.nickname} teamId={currentTeam.id} setPlayers={setPlayers} />
                                                    )}
                                                </ListItemSecondaryAction>
                                            ) : (
                                                <ListItemSecondaryAction>
                                                    <LeaveTeamDialog lastToLeave={players.length === 1} />
                                                </ListItemSecondaryAction>
                                            )}
                                        </ListItem>
                                    ))}
                            </List>
                        </Grid>
                        <Grid item>
                            <Box my={3}>
                                <Typography variant="h4">
                                    <Trans i18nKey="team-settings.invite-player" />
                                </Typography>
                            </Box>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item xs={9}>
                                    <Autocomplete
                                        disabled={!teamCaptain || playersIncludedInvited >= 5 || (!registrationOpen && !transferOpen)}
                                        value={selectedPlayer}
                                        id="inactive-players"
                                        options={inactivePlayers}
                                        autoHighlight
                                        getOptionLabel={(player) => player.nickname}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        renderOption={(player) => <React.Fragment>{player.nickname}</React.Fragment>}
                                        onChange={(event, selectedPlayer) => {
                                            setSelectedPlayer(selectedPlayer);
                                        }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" label={t("team.settings.users.search")} />}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <AlertDialog
                                        onClick={sendInvite}
                                        backgroundColor={theme.palette.success.main}
                                        buttonText={t("team.settings.users.invite.button")}
                                        title={t("team.settings.users.invite.alert.title")}
                                        accept={t("general.yes")}
                                        deny={t("general.no")}
                                        disabled={!selectedPlayer || selectedPlayer === undefined || !teamCaptain}
                                    >
                                        {t("team.settings.users.invite.alert.text")} {selectedPlayer && selectedPlayer.nickname}?
                                    </AlertDialog>
                                </Grid>
                            </Grid>
                        </Grid>
                        {invitedPlayers && invitedPlayers.length > 0 && (
                            <Grid item>
                                <Box my={3}>
                                    <Typography variant="h4">
                                        <Trans i18nKey="team-settings.invited-players" />
                                    </Typography>
                                </Box>
                                <Grid container item direction="column">
                                    <List>
                                        {invitedPlayers.map((player) => (
                                            <ListItem key={player.id} component={Paper} style={{ marginBottom: 4 }}>
                                                <ListItemIcon>
                                                    <Avatar src={player.avatar && `${Constants.apiPrefix}/api/${player.avatar}`} />
                                                </ListItemIcon>
                                                <ListItemText>{player.nickname}</ListItemText>
                                                <ListItemSecondaryAction>
                                                    <RemoveInviteDialog
                                                        playerId={player.toUserId}
                                                        nickname={player.nickname}
                                                        inviteId={player.id}
                                                        teamId={currentTeam.id}
                                                        setInvitedPlayers={setInvitedPlayers}
                                                    />
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Typography variant="h4">
                        <Trans i18nKey="team-settings.team" />
                    </Typography>
                    <Box my={1} />
                    <Paper variant={"outlined"} style={{ backgroundColor: theme.palette.background.default }}>
                        <Box p={3}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <TextField
                                        variant={"outlined"}
                                        margin="dense"
                                        label="Lagnamn"
                                        disabled={!teamCaptain}
                                        value={teamname && teamname}
                                        defaultValue={teamname && teamname}
                                        fullWidth
                                        onChange={(event) => setTeamname(event.target.value)}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6">
                                        <Trans i18nKey="team-settings.change-logo" />
                                    </Typography>
                                    <Box py={1}>
                                        <UploadImage
                                            defaultImage={
                                                currentTeam &&
                                                (currentTeam.teamlogo.includes("discordapp") ? currentTeam.teamlogo : `${Constants.apiPrefix}/api/${currentTeam.teamlogo}`)
                                            }
                                            croppedImageState={[avatar, setAvatar]}
                                            displayHeight={150}
                                            displayWidth={150}
                                            disabled={!teamCaptain}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6">
                                        <Trans i18nKey="team-settings.change-banner" />
                                    </Typography>
                                    <Box py={1}>
                                        <UploadImage
                                            defaultImage={currentTeam && currentTeam.banner && `${Constants.apiPrefix}/api/${currentTeam.banner}`}
                                            croppedImageState={[banner, setBanner]}
                                            fullScreen
                                            displayHeight={isWidthUp("lg", props.width) ? 200 : 100}
                                            displayWidth={isWidthUp("lg", props.width) ? 1000 : 500}
                                            height={200}
                                            width={1000}
                                            disabled={!teamCaptain}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container justify="center" spacing={2}>
                                <Grid item>
                                    <Typography>
                                        <Trans i18nKey="team-settings.image.text" />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <div className={classes.wrapper}>
                                        <Button onClick={saveImageAndNameSettings} variant="contained" color="secondary" disabled={loading || !teamCaptain}>
                                            <Trans i18nKey="team-settings.button" />
                                        </Button>
                                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};
