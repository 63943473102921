import React from 'react';
import { Box, Container, Divider, Grid, Paper, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import {Link} from "react-router-dom";

import { PageTitle } from '../../layout/PageTitle';
import SponsorPaper from '../../layout/components/SponsorPaper';
import { Constants } from '../../constants';
import { UpcomingSeries } from './components/UpcomingSeries';
import { RecentResults } from './components/RecentResults';
import { FeaturedPost } from '../posts/components/FeaturedPost';
import { StartMarkdown } from './components/Markdown';
import { SeriesSlider } from './components/SeriesSlider';
import { SponsorRow } from './components/SponsorRow';
import { TwitchEmbed } from './components/TwitchEmbed';
import TwitchApi from "node-twitch";

export const Start = (props) => {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = React.useState(true);
    const [news, setNews] = React.useState();
    const [latestSeries, setLatestSeries] = React.useState();
    const [upcomingSeries, setUpcomingSeries] = React.useState();
    const [startContent, setStartContent] = React.useState();
    const [twitchLive, setTwitchLive] = React.useState(false);
    const [twitchTitle, setTwitchTitle] = React.useState();

    const start_type = props.settings.find(setting => {
        return setting.setting_key === Constants.settings.startType;
    })

    React.useEffect(() => {
        fetch(Constants.apiPrefix + `/api/series/upcoming/10`)
            .then(response => response.json())
            .then(upcomingSeries => setUpcomingSeries(upcomingSeries))

        fetch(Constants.apiPrefix + `/api/series/latest/10`)
            .then(response => response.json())
            .then(latestSeries => setLatestSeries(latestSeries))

        fetch(Constants.apiPrefix + `/api/news/page/0/2`)
            .then(response => response.json())
            .then(news => setNews(news))

    }, [props.seasonId])

    let hideTopBox = false;

    React.useEffect(() => {
        if (start_type.setting_value === Constants.settings.startTypeValue.custom) {
            const content = props.settings.find(setting => {
                let settingKey = Constants.settings.startContent;
                if (i18n.language === "en") {
                    settingKey = Constants.settings.startContentEng;
                }
                return setting.setting_key === settingKey;
            })
            hideTopBox = false;
            setStartContent(<StartMarkdown content={content.setting_value} />);
        }
        else if (start_type.setting_value === Constants.settings.startTypeValue.matches) {
            hideTopBox = true;
            setStartContent(<SeriesSlider />);
        }
    }, [i18n.language]);

    React.useEffect(async () => {
        const twitch = new TwitchApi({
            client_id: "h0vuavyo21z7y12truxgjhdko01knv",
            client_secret: "4tp64lm7ku6sojutajgd7j7leikvel"
        });
            const interval = setInterval(async() => {     
                try {
                    const results = await twitch.getStreams({ channel: "svenskaraketligan" });
                    const data = results.data[0];
                    const title = data.title;                 
                    setTwitchTitle(title);
                    console.log(results);
                    setTwitchLive(true);
                } catch (err) {
                    setTwitchLive(false);
                }
            }, 120000);

        
    })

    return (
        <React.Fragment>
            <PageTitle minHeight={700} hideTopBox={hideTopBox} variant="inherit" {...props}>
                {twitchLive ? <TwitchEmbed twitchTitle={twitchTitle} /> : startContent}
            </PageTitle>
            <SponsorPaper>
                <SponsorRow />
            </SponsorPaper>
            <Box my={5} />
            <Container>
                <Grid container direction="row" spacing={5}>
                    <Grid item xs={12} md={8}>
                        <Typography variant="h3"><Trans>start.upcoming-matches</Trans></Typography>
                        <Box my={1} />
                        <Paper style={{ borderRadius: 0 }}>
                            <UpcomingSeries seasonId={props.seasonId} series={upcomingSeries} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h3"><Trans>news.title</Trans></Typography>
                        <Box my={1} />
                        <Grid container direction="column" spacing={3}>
                            {news && <FeaturedPost md={12} post={news[0]} style={{height: 200}}/>}
                            {news && <FeaturedPost md={12} post={news[1]} style={{height: 200}}/>}

                        </Grid>
                        <Box my={2} />
                        <Divider />
                        <Link style={{color: "white"}} to="/news">
                            <Typography align="left" variant="h6">
                                <Trans>news.more</Trans>
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>

                <Box my={5} />
                <Typography variant="h3"><Trans>start.latest-results</Trans></Typography>
                <Box my={1} />
                <Paper style={{ borderRadius: 0 }}>
                    <RecentResults seasonId={props.seasonId} series={latestSeries} />
                </Paper>
            </Container>
            <Box my={10} />
        </React.Fragment>
    )

}