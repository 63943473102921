import React from "react";
import { Box, Container, Grid, Typography, Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import { PageTitle } from "../../layout/PageTitle";
import { Member as MemberClass } from "./member";
import { Member } from "./components/Member";
import SponsorPaper from "../../layout/components/SponsorPaper";
import { Trans, useTranslation } from "react-i18next";

const executives = [
    new MemberClass('Robert "Lem0naZ" Fridström', "Lem0naZ#9962", ["chairman","socials", "collaborations", "caster"], "lem0naz@svenskaraketligan.se"),
    new MemberClass('Simon "Jarmo" Jansson', "Jarmo#6316", ["board-member", "collaborations"], "jarmo@svenskaraketligan.se"),
    new MemberClass('Johan "Warlo" Blank', "Warlo#9542", ["finance"], "warlo@svenskaraketligan.se"),
    new MemberClass('Ante "bqobd" Persson', "bqobd#1337", ["board-member","developer"], "bq@svenskaraketligan.se"),
];

const employees = [
    new MemberClass('"Glooten"', "Glooten#2954", ["caster"], null),
    new MemberClass('"PantarN"', "PantarN#1250", ["caster"], null),
    new MemberClass('"Delloc"', "Delloc#1787", ["coordinator", "regulations"],null), //dubbelkolla roller
    new MemberClass('"Gyrox"', "Gyrox#3594", ["moderator"], null),
    new MemberClass('"Nuclear"', "Nuclear#5750", ["moderator"], null),
    new MemberClass('"Karlsson"', "Karlsson#3436", ["moderator"], null),
    new MemberClass('"Nilsson"', "NilssoN#5557", ["moderator"], null),
    new MemberClass('"Similk"', "Similk#0401", ["moderator"], null),
    new MemberClass('"Poe"', "IIRawPotatoII#6447", ["caster"], null),
    new MemberClass('"Topolinas"', "Mark#8114", ["developer"], null),
    new MemberClass('"Jakeoz"', "Jakeoz#6312", ["developer","moderator"], null),
    new MemberClass('"mr deepsound"', "mr deepsound#4371", ["graphics"], null),
    
    new MemberClass('"Drome"', "drome", ["graphics"], null),
    new MemberClass('"Poff"', "poff", ["developer"], null),
    new MemberClass('"Jiro"', "yerosan", ["developer"], null),
    new MemberClass('"Hamatzu"', "hamatzu", ["editor"], null), /// dubbelkolla rollnamn.
    new MemberClass('"Semlan"', "semlanrl", ["moderator"], null),
];

export const About = () => {
    useTranslation();
    return (
        <Box>
            <PageTitle>
                <Typography variant="h2" style={{ fontWeight: 700 }}>
                    <Trans>about.title</Trans>
                </Typography>
            </PageTitle>
            <SponsorPaper>
                <Box pt={1} />
            </SponsorPaper>
            <Box my={5} />
            <Container>
                <Grid container direction="column" spacing={5}>
                    <Grid item>
                        <Typography>
                            <Trans>about.desc.part-1</Trans>
                        </Typography>
                    </Grid>
                    <Grid item>
                    <Typography>                    
                            <Trans>about.desc.part-2-1 <a href="//www.challengermode.com/s/SvenskaRaketligan" target="blank" className="srl-link">challenger-link</a></Trans>
                            <Trans>about.desc.part-2-2 <Link to="/help-us" className="srl-link">help-link</Link></Trans>
                        </Typography>
                    </Grid>
                    <Divider />
                    <Box p={2}>
                        <Grid item>
                            <Typography variant="h4">
                                <Trans>about.executive</Trans>
                            </Typography>
                            <Box p={1} />
                            <Grid container spacing={2}>
                                {executives.map((executive, index) => {
                                    return <Member key={index} name={executive.name} email={executive.email} discord={executive.discord} titles={executive.titles} />;
                                })}
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />
                    <Box p={2}>
                        <Grid item>
                            <Typography variant="h4">
                                <Trans>about.coworker</Trans>
                            </Typography>
                            <Box p={1} />
                            <Grid container spacing={2}>
                                {employees.map((employee, index) => {
                                    return <Member key={index} name={employee.name} discord={employee.discord} titles={employee.titles} />;
                                })}
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Container>
            <Box my={10} />
        </Box>
    );
};
