import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Chip, Container, isWidthDown, Tooltip, Typography, useTheme } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { Trans, useTranslation } from "react-i18next";
import DivisionRow from "./DivisionRow";
import { Constants } from "../../../constants";
import { UpperCaseText } from "../../../layout/components/UpperCaseText";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

const SRLTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
    },
}))(TableRow);

const TableCellBlackText = withStyles((theme) => ({
    root: {
        color: "black",
        padding: 12,
        [theme.breakpoints.up("lg")]: {
            padding: 16,
        },
    },
}))(TableCell);

async function getDivisionId(divisionDiscriminator, seasonId) {
    const r = await fetch(`${Constants.apiPrefix}/api/divisionId/${divisionDiscriminator}/season/${seasonId}`);
    return await r.json();
}

async function getTeams(divisionId, seasonId) {
    const r = await fetch(`${Constants.apiPrefix}/api/division/${divisionId}/teams`);
    return await r.json();
}

async function getColors(divisionId) {
    const r = await fetch(`${Constants.apiPrefix}/api/division/id/${divisionId}/colors`);
    return await r.json();
}

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
    },
}))(Tooltip);

const DivisionTable = (props) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const [loading, setLoading] = React.useState(true);
    const [teams, setTeams] = React.useState();
    const [divisionId, setDivisionId] = React.useState();
    const [colors, setColors] = React.useState([]);

    React.useEffect(() => {
        getDivisionId(props.divisionDiscriminator, props.seasonId).then((response) => {
            const divisionId = response.MESSAGE.id;
            setDivisionId(divisionId);
            Promise.all([getColors(divisionId), getTeams(divisionId, props.seasonId)]).then(([colors, teams]) => {
                setTeams(teams);
                setColors(colors.MESSAGE);
                setLoading(false);
            });
        });
    }, [props.seasonId, props.divisionDiscriminator]);

    return (
        <Container>
            <Box mb={5}>
                <Grid container spacing={2} justify={"center"}>
                    {colors &&
                        colors.map((color) => (
                            <Grid item>
                                <Chip
                                    label={<Typography variant="body1">{i18n.language === "en" ? color.engDescription : color.sweDescription}</Typography>}
                                    style={{ backgroundColor: color.hex }}
                                />
                            </Grid>
                        ))}
                </Grid>
            </Box>
            <TableContainer component={Paper} style={{ borderRadius: 0 }}>
                <Table aria-label="Divisions tabell" size={isWidthUp("lg", props.width) ? "medium" : "small"}>
                    <TableHead>
                        <SRLTableRow>
                            <TableCellBlackText />
                            {isWidthUp("lg", props.width) && <TableCellBlackText />}
                            <TableCellBlackText color="inherit">
                                <UpperCaseText>
                                    <Trans>league.team</Trans>
                                </UpperCaseText>
                            </TableCellBlackText>
                            <HtmlTooltip
                                title={
                                    <Typography>
                                        <Trans>league.played</Trans>
                                    </Typography>
                                }
                                placement="down"
                            >
                                <TableCellBlackText align="right">
                                    <UpperCaseText>
                                        <Trans>league.played.short</Trans>
                                    </UpperCaseText>
                                </TableCellBlackText>
                            </HtmlTooltip>
                            <HtmlTooltip
                                title={
                                    <Typography>
                                        <Trans>league.wins</Trans>
                                    </Typography>
                                }
                                placement="down"
                            >
                                <TableCellBlackText align="right">
                                    <UpperCaseText>
                                        <Trans>league.wins.short</Trans>
                                    </UpperCaseText>
                                </TableCellBlackText>
                            </HtmlTooltip>
                            <HtmlTooltip
                                title={
                                    <Typography>
                                        <Trans>league.overtime-wins</Trans>
                                    </Typography>
                                }
                                placement="down"
                            >
                                <TableCellBlackText align="right">
                                    <UpperCaseText>
                                        <Trans>league.overtime-wins.short</Trans>
                                    </UpperCaseText>
                                </TableCellBlackText>
                            </HtmlTooltip>
                            <HtmlTooltip
                                title={
                                    <Typography>
                                        <Trans>league.overtime-losses</Trans>
                                    </Typography>
                                }
                                placement="down"
                            >
                                <TableCellBlackText align="right">
                                    <UpperCaseText>
                                        <Trans>league.overtime-losses.short</Trans>
                                    </UpperCaseText>
                                </TableCellBlackText>
                            </HtmlTooltip>
                            <HtmlTooltip
                                title={
                                    <Typography>
                                        <Trans>league.losses</Trans>
                                    </Typography>
                                }
                                placement="down"
                            >
                                <TableCellBlackText align="right">
                                    <UpperCaseText>
                                        <Trans>league.losses.short</Trans>
                                    </UpperCaseText>
                                </TableCellBlackText>
                            </HtmlTooltip>
                            <HtmlTooltip
                                title={
                                    <Typography>
                                        <Trans>league.difference.long</Trans>
                                    </Typography>
                                }
                                placement="down"
                            >
                                <TableCellBlackText align="right">
                                    <UpperCaseText>
                                        <Trans>league.difference</Trans>
                                    </UpperCaseText>
                                </TableCellBlackText>
                            </HtmlTooltip>
                            <TableCellBlackText align="center">
                                <UpperCaseText>
                                    <Trans>league.points</Trans>
                                </UpperCaseText>
                            </TableCellBlackText>
                        </SRLTableRow>
                    </TableHead>
                    <TableBody>
                        {!loading
                            ? teams.map((team, index) => {
                                  return (
                                      <DivisionRow team={team} placement={index + 1} divisionId={divisionId} seasonId={props.seasonId} currentSeasonId={props.currentSeasonId} />
                                  );
                              })
                            : [...Array(10)].map((number) => {
                                  const height = 20;
                                  return (
                                      <TableRow>
                                          <TableCell>
                                              <Skeleton animation="wave" height={height} />
                                          </TableCell>
                                          <TableCell>
                                              <Skeleton animation="wave" height={height} />
                                          </TableCell>
                                          <TableCell>
                                              <Skeleton animation="wave" height={height} />
                                          </TableCell>
                                          <TableCell>
                                              <Skeleton animation="wave" height={height} />
                                          </TableCell>
                                          <TableCell>
                                              <Skeleton animation="wave" height={height} />
                                          </TableCell>
                                          <TableCell>
                                              <Skeleton animation="wave" height={height} />
                                          </TableCell>
                                          <TableCell>
                                              <Skeleton animation="wave" height={height} />
                                          </TableCell>
                                          <TableCell>
                                              <Skeleton animation="wave" height={height} />
                                          </TableCell>
                                          <TableCell>
                                              <Skeleton animation="wave" height={height} />
                                          </TableCell>
                                          <TableCell>
                                              <Skeleton animation="wave" height={height} />
                                          </TableCell>
                                      </TableRow>
                                  );
                              })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default withWidth()(DivisionTable);
