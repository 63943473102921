import React from "react";
import { useContext } from "react";
import Button from "@material-ui/core/Button";
import {
    Box,
    CircularProgress,
    LinearProgress,
    makeStyles,
    Menu,
    MenuItem,
    Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { Trans } from "react-i18next";
import { Constants } from "../../../../../constants";
import { TextField } from "../../../../../components/TextField"; 
import Cookies from "universal-cookie";
import { useTheme } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { SnackbarContext } from "../../../../../context/SnackbarProvider";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

async function getPlayingOrders() {
    const r = await fetch(`${Constants.apiPrefix}/api/playingOrder`);
    return await r.json();
}

async function getWeekStatus(divisionId) {
    const r = await fetch(`${Constants.apiPrefix}/api/division/${divisionId}/weeks`);
    return await r.json();
}

function LinearProgressWithLabel(props) {
    const normalise = (value) => ((value - 0) * 100) / (props.total - 0);
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" value={normalise(props.value)} color={"secondary"} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${props.value} / ${props.total}`}</Typography>
            </Box>
        </Box>
    );
}

export default function DiscordRoomDialog(props) {
    const theme = useTheme();
    const { division, updateDivisions } = props;
    const classes = useStyles();
    const cookies = new Cookies();
    const [open, setOpen] = React.useState(false);
    const [loadingMatchRoom, setLoadingMatchRoom] = React.useState(false);
    const [progress, setProgress] = React.useState();
    const [weeks, setWeeks] = React.useState();
    const [message, setMessage] = React.useState("Välkommen till spelvecka {week}, det kommer komma ett meddelande från divisionsansvarig strax.");
    const { setSnackbar } = useContext(SnackbarContext);

    // Dropdown for discord rooms
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClickDropdown = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseDropdown = () => {
        setAnchorEl(null);
    };

    // Functions
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const createMatchRoom = async (seriesId, week) => {
        const r = await fetch(`${Constants.apiPrefix}/api/division/createMatchRoom`, {
            method: "POST",
            body: JSON.stringify({
                accessToken: cookies.get("accessToken"),
                seriesId: seriesId,
                message: message,
            }),
        });
        return await r.json();
    };

    const getSeriesWhichShouldBeCreated = async (week) => {
        const r = await fetch(`${Constants.apiPrefix}/api/division/seriesForDivisionAndWeek`, {
            method: "POST",
            body: JSON.stringify({
                accessToken: cookies.get("accessToken"),
                divisionId: division.divisionId,
                week: week,
            }),
        });
        return await r.json();
    };

    const createAllDiscordMatchRooms = async (ids, week) => {
        for (let i = 0; i < ids.length; i++) {
            const r = await createMatchRoom(ids[i], week);
            setProgress({
                value: i + 1,
                total: ids.length,
            });
        }
    };

    const createMatchRoomsClick = (event) => {
        setLoadingMatchRoom(true);
        const week = event.target.value;
        getSeriesWhichShouldBeCreated(week).then((r) => {
            const ids = r.MESSAGE;
            setProgress({
                value: 0,
                total: ids.length,
            });
            createAllDiscordMatchRooms(ids, week).then(() => {
                setSnackbar({
                    open: true,
                    severity: "success",
                    message: "Matchrum i Discord skapade",
                });
                setLoadingMatchRoom(false);
            });
        });
    };

    // Effects
    React.useEffect(() => {
        if (open) {
            getWeekStatus(division.divisionId).then((r) => {
                const weeks = r.MESSAGE.weeks;
                const weeksLeft = r.MESSAGE.weeksLeft;
                let arr = [];
                for (let i = 1; i <= weeks; i++) {
                    arr.push({
                        severity: i <= weeks - weeksLeft ? "error" : "success",
                        week: i,
                    });
                }
                setWeeks(arr);
            });
        }
    }, [open]);

    return (
        <div>
            <MenuItem key={`matches`} onClick={handleClickOpen}>
                <Trans>admin.division.create-discord-room</Trans>
            </MenuItem>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth key={`dialog-matches-${division.divisionId}`}>
                <DialogTitle id="form-dialog-title">Matchrum i Discord</DialogTitle>
                <DialogContent>
                    <Box mt={1} />
                    <TextField
                        fullWidth
                        variant={"outlined"}
                        id="standard-multiline-static"
                        label="Meddelande"
                        multiline
                        onChange={(event) => setMessage(event.target.value)}
                        rows={4}
                        value={message}
                    />
                    <Box mt={1} />
                    <div className={classes.wrapper}>
                        <Button
                            fullWidth
                            variant="contained"
                            disabled={loadingMatchRoom}
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleClickDropdown}
                            disabled={weeks && weeks.length === 0}
                        >
                            Skapa matchrum
                        </Button>
                        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseDropdown}>
                            {weeks &&
                                weeks.map((item) => (
                                    <MenuItem value={item.week} style={{ border: `2px solid ${theme.palette[item.severity].main}` }} onClick={createMatchRoomsClick}>
                                        Vecka {item.week}
                                    </MenuItem>
                                ))}
                        </Menu>
                        {loadingMatchRoom && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                    {loadingMatchRoom && progress && <LinearProgressWithLabel value={progress.value} total={progress.total} />}
                </DialogContent>
                <Box mt={3} />
            </Dialog>
        </div>
    );
}
