import {
    CircularProgress,
    Grid,
    makeStyles,
    MenuItem,
    List,
    ListItem,
    IconButton,
    Fab,
    Box,
    Paper,
    ListItemIcon, ListItemSecondaryAction
} from "@material-ui/core";
import { Add, Clear } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useContext } from "react";
import { Trans } from "react-i18next";
import Cookies from "universal-cookie";
import { TextField } from "../../../../../components/TextField";
import { Constants } from "../../../../../constants";
import { SnackbarContext } from "../../../../../context/SnackbarProvider";
import {useTheme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

async function getColors(divisionId) {
    const r = await fetch(`${Constants.apiPrefix}/api/division/id/${divisionId}/colors`);
    return await r.json();
}

export default function ColorFormDialog(props) {
    const { division } = props;
    const theme = useTheme();
    const classes = useStyles();
    const cookies = new Cookies();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { setSnackbar } = useContext(SnackbarContext);

    const [colors, setColors] = React.useState([]);

    // Functions
    const handleClickOpen = () => {
        setOpen(true);
        setColors([]);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const saveColors = async (colors) => {
        const r = await fetch(`${Constants.apiPrefix}/api/division/saveColors`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                accessToken: cookies.get("accessToken"),
                colors: colors,
                divisionId: division.divisionId,
            }),
        });
        return await r.json();
    };

    const appendColor = (event) => {
        setColors((colors) => [
            ...colors,
            {
                hex: theme.palette.success.main,
                fromIndex: 0,
                toIndex: 0,
            },
        ]);
    };

    const removeColor = (index) => {
        let colorsCopy = [...colors];
        colorsCopy.splice(index, 1);
        setColors(colorsCopy);
    };

    const changeColors = (event, index) => {
        event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;
        let colorsCopy = [...colors];
        let color = { ...colorsCopy[index] };
        color[name] = value;
        colorsCopy[index] = color;
        setColors(colorsCopy);
    };

    const clickSave = () => {
        setLoading(true);
        saveColors(colors).then((r) => {
            if (r.hasOwnProperty("CODE") && r.CODE === 200) {
                setSnackbar({
                    open: true,
                    severity: "success",
                    message: r.MESSAGE,
                });
            } else if (r.hasOwnProperty("CODE") && r.CODE !== 500) {
                setSnackbar({
                    open: true,
                    severity: "success",
                    message: r.MESSAGE,
                });
            } else {
                throw new Error(r.MESSAGE);
            }
            setLoading(false);
            handleClose();
        });
    };

    // Effects
    React.useEffect(() => {
        if(open) {
            getColors(division.divisionId).then((r) => setColors(r.MESSAGE));
        }
    }, [division, open]);

    return (
        <div>
            <MenuItem key={`color`} onClick={handleClickOpen}>
                <Trans>admin.division.color</Trans>
            </MenuItem>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
                <DialogTitle id="form-dialog-title">Divisionsfärger</DialogTitle>
                <DialogContent>
                    <List>
                        {colors &&
                            colors.map((color, index) => (
                                <Box m={2}>
                                    <Paper style={{backgroundColor: color.hex}}>
                                        <ListItem>
                                            <Grid container direction="row" spacing={1} alignItems="center">
                                                <Grid item xs container spacing={1}>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            inputProps={{
                                                                name: "hex",
                                                            }}
                                                            label={"Färg"}
                                                            value={color.hex}
                                                            defaultValue={color.hex}
                                                            onChange={(event) => changeColors(event, index)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            inputProps={{
                                                                name: "fromIndex",
                                                            }}
                                                            label={"Från rad (inkl)"}
                                                            value={color.fromIndex}
                                                            defaultValue={color.fromIndex}
                                                            onChange={(event) => changeColors(event, index)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            inputProps={{
                                                                name: "toIndex",
                                                            }}
                                                            label={"Till rad (inkl)"}
                                                            value={color.toIndex}
                                                            defaultValue={color.toIndex}
                                                            onChange={(event) => changeColors(event, index)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            inputProps={{
                                                                name: "engDescription",
                                                            }}
                                                            label={"English"}
                                                            value={color.engDescription}
                                                            defaultValue={color.engDescription}
                                                            onChange={(event) => changeColors(event, index)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            inputProps={{
                                                                name: "sweDescription",
                                                            }}
                                                            label={"Svenska"}
                                                            value={color.sweDescription}
                                                            defaultValue={color.sweDescription}
                                                            onChange={(event) => changeColors(event, index)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <ListItemSecondaryAction>
                                                <IconButton onClick={() => removeColor(index)}>
                                                    <Clear />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </Paper>
                                </Box>
                            ))}
                    </List>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Box mb={2} mr={2}>
                                <Fab color="secondary" onClick={appendColor}>
                                    <Add />
                                </Fab>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="primary">
                        Tillbaka
                    </Button>
                    <div className={classes.wrapper}>
                        <Button disabled={loading} onClick={clickSave} color="secondary" variant="contained">
                            Spara
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}
