import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import {Trans, useTranslation} from 'react-i18next';

import { UpperCaseText } from '../../../layout/components/UpperCaseText';

const SRLTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main
    }
}))(TableRow)

const TableCellBlackText = withStyles((theme) => ({
    root: {
        color: 'black'
    }
}))(TableCell)


const useStyles = makeStyles((theme) => ({
    link: {
        color: theme.palette.primary.contrastText,
    }
}));


export const UpcomingSeries = (props) => {
    const classes = useStyles();
    useTranslation();

    return (
        <TableContainer style={{ borderRadius: 0 }}>
            <Table aria-label="Divisions tabell">
                <TableHead>
                    <SRLTableRow>
                        <TableCellBlackText align="left">
                            <UpperCaseText><Trans>league.date</Trans></UpperCaseText>
                        </TableCellBlackText>
                        <TableCellBlackText align="center">
                            <UpperCaseText><Trans>league.hometeam</Trans></UpperCaseText>
                        </TableCellBlackText>
                        <TableCellBlackText align="center">
                            <UpperCaseText><Trans>league.awayteam</Trans></UpperCaseText>
                        </TableCellBlackText>
                        <TableCellBlackText align="center">
                            <UpperCaseText><Trans>league.division</Trans></UpperCaseText>
                        </TableCellBlackText>
                    </SRLTableRow>
                </TableHead>
                <TableBody>
                    {props.series ? (props.series.map((serie, index) => {
                        let date = serie.matchdate;
                        if (serie.matchdate == null) {
                            date = (<><Trans>league.week</Trans> {serie.week}</>);
                        } else {
                            date = date.substring(0, date.length - 3);
                        }
                        return (
                            <TableRow key={index}>
                                <TableCell align="left"><UpperCaseText>{date}</UpperCaseText></TableCell>
                                <TableCell align="center" >
                                    <Link className={classes.link} to={`/team/${serie.hometeam_id}`}>
                                        <UpperCaseText>{serie.hometeam}</UpperCaseText>
                                    </Link>
                                </TableCell>
                                <TableCell align="center">
                                    <Link className={classes.link} to={`/team/${serie.awayteam_id}`}>
                                        <UpperCaseText>{serie.awayteam}</UpperCaseText>
                                    </Link>
                                </TableCell>
                                <TableCell align="center">
                                    <UpperCaseText>{serie.division_name}</UpperCaseText>
                                </TableCell>
                            </TableRow>
                        )
                    }
                    )) : (
                        [...Array(10).keys()].map((number) => {
                            const height = 20;
                            return (
                                <TableRow key={number}>
                                    <TableCell><Skeleton animation="wave" height={height} /></TableCell>
                                    <TableCell><Skeleton animation="wave" height={height} /></TableCell>
                                    <TableCell><Skeleton animation="wave" height={height} /></TableCell>
                                    <TableCell><Skeleton animation="wave" height={height} /></TableCell>
                                </TableRow>
                            )
                        })
                    )}
                    {props.series && props.series.length === 0 && (
                      <TableRow key={1}>
                          <TableCell colSpan={4} align="left">
                              <UpperCaseText>
                                  <Trans>
                                      start.upcoming.no-coming
                                  </Trans>
                              </UpperCaseText>
                          </TableCell>
                      </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )

}
