import { RadioGroup as UIRadioGroup } from "@material-ui/core";
import { FormLabel as UIFormLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const RadioGroup = withStyles((theme) => ({
    root: {
        "& label.Mui-focused": {
            color: theme.palette.primary.focus,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: theme.palette.primary.focus,
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: theme.palette.primary.focus,
            },
        },
    },
}))(UIRadioGroup);

// ingen aning varför denna inte fungerar...
export const FormLabel = withStyles((theme) => ({
    root: {
        "& label.Mui-focused": {
            color: theme.palette.primary.focus,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: theme.palette.primary.focus,
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: theme.palette.primary.focus,
            },
        },
    },
}))(UIFormLabel);
