import React from 'react';
import { Avatar, Box, Typography } from '@material-ui/core';
import { PageTitle } from '../../layout/PageTitle';
import SponsorPaper from '../../layout/components/SponsorPaper';
import Grid from "@material-ui/core/Grid";
import {Trans, useTranslation} from "react-i18next";


export const NotFound = (props) => {
    useTranslation();
    return (
        <React.Fragment>
            <PageTitle title="404">
              <Trans>not-found.404</Trans>
            </PageTitle>
            <SponsorPaper>
                <Box pt={1} />
            </SponsorPaper>
            <Box my={5} />
            <Grid container justify="center">
              <Grid item>
                <img src={"/images/404.gif"} className="catJAM"  alt=""/>
              </Grid>
            </Grid>
            <Box my={10} />
        </React.Fragment>
    )
}