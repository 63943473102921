import React, { useContext } from "react";
import { Button, Box, Container, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, Typography, Grid } from "@material-ui/core";
import Cookies from 'universal-cookie';

import { Constants } from "./../../../../constants";
import { StateContext } from "./../../../../discord/StateProvider";
import { SnackbarContext } from "./../../../../context/SnackbarProvider";

export const Settings = () => {
    const cookies = new Cookies();
    const { currentUser } = useContext(StateContext);
    const { setSnackbar } = React.useContext(SnackbarContext);
    const [ settings, setSettings ] = React.useState([]);
    const [ registrationOpen, setRegistrationOpen ] = React.useState(false);
    const [ transferOpen, setTransferOpen ] = React.useState(false);

    React.useEffect(() => {
        fetch(Constants.apiPrefix + "/api/settings/all")
            .then(response => response.json())
            .then(response => {
                setSettings(response);
                const regOpen = response.find(setting => {
                    return setting.setting_key === Constants.settings.registrationOpen;
                });
                const open = regOpen.setting_value === "true";
                setRegistrationOpen(open);

                const transferWindowOpen = response.find(setting => {
                    return setting.setting_key === Constants.settings.transferOpen;
                });
                const transferWindowOpenIsOpen = transferWindowOpen.setting_value === "true";
                setTransferOpen(transferWindowOpenIsOpen);
            })
    }, []);

    const handleInput = (e, setter) => {
        e.preventDefault();
        setter(e.target.checked);
    }

    const save = (event) => {
        event.preventDefault();

        fetch(Constants.apiPrefix + "/api/admin/settings/save", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                accessToken: cookies.get("accessToken"),
                userId: parseInt(currentUser.id),
                registration: registrationOpen.toString(),
                transfer: transferOpen.toString()
            }),
        })
        .then(response => response.json())
        .then(response => {
            if (response.hasOwnProperty("CODE") && response.hasOwnProperty("MESSAGE") && response.CODE === 200) {
                setSnackbar({
                    open: true,
                    severity: "success",
                    message: "Inställningar uppdaterade"
                });
            } else if(response.hasOwnProperty("CODE") && response.CODE !== 500){
                setSnackbar({
                    open: true,
                    severity: "error",
                    message: response.MESSAGE
                });
            } else {
                throw new Error("admin.settings.save");
            }
            // setSaved(true);
        });
    }

    return (
        <Container>
            <Typography variant="h4">Ligainställningar {registrationOpen}</Typography>
            <Grid>
                <FormControl component="fieldset">
                    <FormGroup>
                        <FormControlLabel 
                            label="Registrering öppen"
                            control={<Checkbox checked={registrationOpen} onChange={(e) => handleInput(e, setRegistrationOpen)} color="secondary" />}
                            labelPlacement="end"
                        />
                    </FormGroup>
                </FormControl>
            </Grid>
            <Grid>
                <FormControl component="fieldset">
                    <FormGroup>
                        <FormControlLabel 
                            label="Transferfönster öppen"
                            control={<Checkbox checked={transferOpen} onChange={(e) => handleInput(e, setTransferOpen)} color="secondary" />}
                            labelPlacement="end"
                        />
                    </FormGroup>
                </FormControl>
            </Grid>

            <Box pt={2} />
            <Grid container item xs={12}>
                <Button variant="contained" color="secondary" onClick={save}>Spara inställningar</Button>
            </Grid>
        </Container>
    );
}