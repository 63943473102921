import React from "react";
import Button from "@material-ui/core/Button";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Constants } from "../../../constants";
import Cookies from "universal-cookie";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Trans, useTranslation } from 'react-i18next';
import {SnackbarContext} from "../../../context/SnackbarProvider";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

async function acceptInviteWithTransferRequest(accessToken, inviteId) {
    const r = await fetch(`${Constants.apiPrefix}/api/team/acceptInviteWithRequest`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            accessToken: accessToken,
            inviteId: inviteId,
        }),
    });
    return await r.json();
}

export const AcceptTransferDialog = (props) => {
    const { inviteId, update } = props;
    const { setSnackbar } = React.useContext(SnackbarContext);
    const { t } = useTranslation();
    const cookies = new Cookies();
    const classes = useStyles();
    const theme = useTheme();

    // Dialog settings
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    const handleDialogOpen = () => {
        setDialogOpen(true);
    }

    // Loading button settings
    const [loading, setLoading] = React.useState(false);

    const accept = () => {
        setLoading(true);
        acceptInviteWithTransferRequest(cookies.get("accessToken"), inviteId).then(response => {
            if(response.hasOwnProperty("CODE") && response.CODE === 200) {
                setSnackbar({
                    open: true,
                    severity: "success",
                    message: t(response.MESSAGE)
                });
                update()
            } else if(response.hasOwnProperty("CODE") && response.CODE !== 500) {
                setSnackbar({
                    open: true,
                    severity: "error",
                    message: t(response.MESSAGE)
                });
            } else {
                throw new Error("react.notifications.components.AcceptTransferDialog.accept");
            }
            setLoading(false);
            setDialogOpen(false);
        });
    }

    return (
        <>
            <Button
                fullWidth
                variant="contained"
                style={{
                    color: "white",
                    backgroundColor: theme.palette.success.main,
                }}
                onClick={handleDialogOpen}
            >
                <Trans>general.accept</Trans>
            </Button>
            <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="dialog-accept">
                <DialogTitle id="dialog-accept">
                    <Trans>notification.accept-invite.header</Trans>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Trans>notification.accept-transfer.text</Trans>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant="contained">
                        <Trans>general.back</Trans>
                    </Button>
                    <div className={classes.wrapper}>
                        <Button onClick={accept} variant="contained" color="secondary" disabled={loading}>
                            <Trans>general.accept</Trans>
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );

}