import React from "react";
import { Box, Grid } from "@material-ui/core";

import { PageText } from "./../../../layout/components/PageText";
import { PageTitle } from "./../../../layout/PageTitle";
import { Constants } from "./../../../constants";

export const TeamPageTitle = (props) => {
    const { teamInfo } = props;

    return (
        <PageTitle shadow backgroundImage={teamInfo && teamInfo.banner && `${Constants.apiPrefix}/api/${teamInfo.banner}`}>
            <Box p={2}>
                <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                    <Grid item style={{ position: "relative" }}>
                        <img
                            height={100}
                            src={teamInfo && (teamInfo.teamlogo.includes("discordapp") ? teamInfo.teamlogo : `${Constants.apiPrefix}/api/${teamInfo.teamlogo}`)}
                            alt=""
                        />
                    </Grid>
                    <Grid item>
                        <PageText variant="h2" align="center">
                            {teamInfo && teamInfo.teamname}
                        </PageText>
                    </Grid>
                </Grid>
            </Box>
        </PageTitle>
    );
};
