import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import HotelIcon from '@material-ui/icons/Hotel';
import RepeatIcon from '@material-ui/icons/Repeat';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Users } from "../../admin/scenes/users/Users";
import { FaUsers } from "react-icons/all";
import PersonIcon from "@material-ui/icons/Person";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Constants } from "../../../constants";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '6px 16px',
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
}));

async function getRating(playerId, divisionId) {
    const response = await fetch(new URL(`/api/player/${playerId}/division/${divisionId}/rating`, Constants.apiPrefix).toString());
    return await response.json();
}

function getPlacementString(placement, division, i18n) {
    if(i18n.language === "en") {
        //1st 2nd 3rd 4th 5th 6th 7th 8th 9th 10th
        switch(parseInt(placement)) {
            case 1:
                return `${placement}st ${division}`;
            case 2:
                return `${placement}nd ${division}`;
            case 3:
                return `${placement}rd ${division}`;
            default:
                return `${placement}th ${division}`;
        }
    }
    switch(parseInt(placement)) {
        case 1:
        case 2:
            return `${placement}:a plats ${division}`;
        default:
            return `${placement}:e plats ${division}`;
    }
}

async function getRatings(playerId, seasonTeams) {
  let tmp = [...seasonTeams];
  for(let i = 0; i < tmp.length; i++) {
    tmp[i]["rating"] = await getRating(playerId, tmp[i].divisionId);
  }
  return tmp;
}

export const UserTimeline = (props) => {
    const classes = useStyles();
    const { currentSeasonId, seasonTeams, playerId} = props;
    const { i18n } = useTranslation();

    const [innerSeasonTeams, setSeasonsTeams] = React.useState();

    React.useEffect(() => {
      setSeasonsTeams(seasonTeams);
    }, [seasonTeams])

    return (
        <>
            <Timeline align="alternate">
                {innerSeasonTeams && innerSeasonTeams.map((seasonTeam, index) => (
                    <TimelineItem>
                        <TimelineOppositeContent>
                            <Typography variant="body2" color="textSecondary">
                                {seasonTeam.season}
                            </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            {parseInt(seasonTeam.seasonId) === currentSeasonId ? (
                                <TimelineDot color="secondary">
                                    <PersonIcon />
                                </TimelineDot>
                            ) : (
                                <TimelineDot color="primary">
                                   <PersonIcon />
                                </TimelineDot>
                            )}
                            {index < seasonTeams.length - 1 && <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent>
                            <Paper elevation={3} className={classes.paper}>
                                <Box p={1}>
                                    <Grid container justify="space-between">
                                        <Grid item>
                                            <Typography variant="h6" component="h1" align="left">
                                                {seasonTeam.currentName === seasonTeam.teamname ? (
                                                    <Link className="srl-link" to={`/team/${seasonTeam.teamId}`}>
                                                        {seasonTeam.teamname}
                                                    </Link>
                                                ) : (
                                                    seasonTeam.teamname
                                                )}
                                            </Typography>
                                            { seasonTeam.currentName !== seasonTeam.teamname &&(
                                                <Typography color="textSecondary" align="left">
                                                    Nuvarande: <Link className="srl-link" to={`/team/${seasonTeam.teamId}`}>{seasonTeam.currentName}</Link>
                                                </Typography>
                                            )}
                                            <Typography align="left">{getPlacementString(seasonTeam.placement, seasonTeam.division, i18n)}</Typography>
                                        </Grid>
                                        {seasonTeam.rating && (
                                            <Grid item>
                                                <Typography variant="body1">
                                                    SRL Rating: {Number(seasonTeam.rating).toFixed(2)}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Box>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                ))}
          </Timeline>
        </>
    );
}