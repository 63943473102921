import React from 'react';
import { PageTitle } from '../../layout/PageTitle';
import { Box, Container } from '@material-ui/core';
import { SeasonTabs } from './components/SeasonTabs';
import { Constants } from '../../constants';
import SponsorPaper from '../../layout/components/SponsorPaper';
import { Trans, useTranslation } from 'react-i18next';

export const Seasons = (props) => {
    const [seasons, setSeasons] = React.useState();
    useTranslation();

    React.useEffect(() => {
        fetch(Constants.apiPrefix + `/api/season/all/division/all`)
            .then(response => response.json())
            .then(seasons => setSeasons(seasons.reverse()));
    }, [])

    return (
        <React.Fragment>
            <PageTitle>
                <Trans>footer.all-seasons</Trans>
            </PageTitle>
            <SponsorPaper>
                <Box pt={1} />
            </SponsorPaper>
            <Box my={5} />
            <Container>
                <SeasonTabs seasons={seasons} />
            </Container>
            <Box my={10} />
        </React.Fragment>
    )

}