import React from "react";
import { Box, Container, Grid } from "@material-ui/core";

const pathPrefix = "/images/sponsor/";
const sponsors = [
    // { logo: "X-Gamer-Energy-Logo-White.png", link: "https://x-gamer.com/?utm_source=sponsor&utm_medium=banner&utm_campaign=srl", height: 40 },
    // { logo: "Florpad_white.png", link: "https://florpad.com/?utm_source=sponsor&utm_medium=banner&utm_campaign=srl", height: 40 },
    { logo: "coolermaster.png", link: "https://coolermaster.com", height: 60 },
    { logo: "Esport_United.png", link: "https://esportunited.com", height: 60 },
    { logo: "LUMA_logo_white.png", link: "https://lumateamwear.se", height: 60 },
    { logo: "logo_noblechairs_wt.png", link: "https://noblechairs.com/", height: 60 },
    { logo: "streamplify-wh.png", link: "https://streamplify.com/sv/", height: 60 }
];

export const SponsorRow = () => {
    return (
        <Container>
            <Box p={3}>
                <Grid container direction="row" justify="space-evenly" spacing={2} alignItems="center">
                    {sponsors.map((sponsor, index) => (
                        <Grid item key={index}>
                            <a href={sponsor.link} target="_blank">
                                <img src={pathPrefix + sponsor.logo} style={{ height: sponsor.height }} alt="" />
                            </a>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Container>
    );
};
