import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CardMedia, Grid } from '@material-ui/core';
import { Link } from "react-router-dom";
import { Trans } from 'react-i18next';

const useStyles = makeStyles({
    root: {
        display: 'flex'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 16,
    },
    pos: {
        marginBottom: 12,
    },
    media: {
        width: 250,
        height: 180,
    },
    content: {
        flexGrow: 1,
        padding: 0
    },
    buttonLink: {
        textDecoration: 'none'
    }
});

export const XGamerCard = (props) => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardMedia
                className={classes.media}
                image={props.src}
                title=""
            />
            <Grid container direction="column" justify="space-between" style={{ padding: 10 }}>
                <Grid item>
                    <Typography variant="h5" component="h2">
                        {props.title}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                        {props.desc}
                    </Typography>
                </Grid>
                <Grid item>
                    <CardActions>
                        <a href={props.link} target="_blank" className={classes.buttonLink}>
                            <Button variant="contained" color="secondary"><Trans>shop.buy-now</Trans></Button>
                        </a>
                    </CardActions>
                </Grid>
            </Grid>
        </Card>
    );
}