import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Avatar, Card, CardContent, CardHeader, CardMedia, Divider, List, ListItem, ListItemText } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { render } from "react-dom";
import { MatchTeamTable } from "./MatchTeamTable";
import { Constants } from "../../../constants";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const GreenHeader = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.success.main,
    },
}))(CardHeader);

const RedHeader = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.error.main,
    },
}))(CardHeader);

const YellowHeader = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.warning.main,
    },
}))(CardHeader);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export const SeriesTabs = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    let HomeOverviewHeader =
        props.series && props.series.hometeam && props.series.awayteam ? (props.series.hometeam.score > props.series.awayteam.score ? GreenHeader : RedHeader) : CardHeader;
    let AwayOverviewHeader =
        props.series && props.series.hometeam && props.series.awayteam ? (props.series.hometeam.score < props.series.awayteam.score ? GreenHeader : RedHeader) : CardHeader;

    if (props.series && props.series.hometeam && props.series.awayteam && props.series.hometeam.score === props.series.awayteam.score) {
        HomeOverviewHeader = YellowHeader;
        AwayOverviewHeader = YellowHeader;
    }

    const getHomeLogo = () => {
        return props.series.hometeam.teamlogo.includes("discordapp") ? props.series.hometeam.teamlogo : `${Constants.apiPrefix}/api/${props.series.hometeam.teamlogo}`;
    };

    const getAwayLogo = () => {
        return props.series.awayteam.teamlogo.includes("discordapp") ? props.series.awayteam.teamlogo : `${Constants.apiPrefix}/api/${props.series.awayteam.teamlogo}`;
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label={`Översikt`} {...a11yProps(0)} />
                    {props.series && props.series.matches && props.series.matches.map((match, index) => <Tab label={`Match ${index + 1}`} {...a11yProps(index + 1)} />)}
                </Tabs>
            </AppBar>
            {props.series && (
                <TabPanel value={value} index={0}>
                    <Card style={{ borderRadius: 0 }}>
                        <HomeOverviewHeader
                            avatar={<img aria-label="Team image" className={classes.avatar} src={getHomeLogo()} style={{ height: 40 }} />}
                            title={
                                <Typography variant="h6" style={{ textTransform: "uppercase" }}>
                                    {props.series.hometeam && props.series.hometeam.name}
                                </Typography>
                            }
                        />
                        <CardContent style={{ padding: 0 }}>
                            <MatchTeamTable teamMatch={props.series.hometeam && props.series.hometeam.totals} />
                        </CardContent>
                    </Card>
                    <Card style={{ borderRadius: 0 }}>
                        <AwayOverviewHeader
                            avatar={<img aria-label="Team image" className={classes.avatar} src={getAwayLogo()} style={{ height: 40 }} />}
                            title={
                                <Typography variant="h6" style={{ textTransform: "uppercase" }}>
                                    {props.series.awayteam && props.series.awayteam.name}
                                </Typography>
                            }
                        />
                        <CardContent style={{ padding: 0 }}>
                            <MatchTeamTable teamMatch={props.series.awayteam && props.series.awayteam.totals} />
                        </CardContent>
                    </Card>
                </TabPanel>
            )}

            {props.series &&
                props.series.matches &&
                props.series.matches.map((match, index) => {
                    const HomeTeamHeader = match.result.home > match.result.away ? GreenHeader : RedHeader;
                    const AwayTeamHeader = match.result.home < match.result.away ? GreenHeader : RedHeader;
                    return (
                        <TabPanel value={value} index={index + 1}>
                            <Card style={{ borderRadius: 0 }}>
                                <HomeTeamHeader
                                    avatar={<img aria-label="Team image" className={classes.avatar} src={getHomeLogo()} style={{ height: 40 }} />}
                                    title={
                                        <Typography variant="h6" style={{ textTransform: "uppercase" }}>
                                            {props.series.hometeam.name} ({match.result.home})
                                        </Typography>
                                    }
                                />
                                <CardContent style={{ padding: 0 }}>
                                    <MatchTeamTable teamMatch={match.hometeam} />
                                </CardContent>
                            </Card>
                            <Card style={{ borderRadius: 0 }}>
                                <AwayTeamHeader
                                    avatar={<img aria-label="Team image" className={classes.avatar} src={getAwayLogo()} style={{ height: 40 }} />}
                                    title={
                                        <Typography variant="h6" style={{ textTransform: "uppercase" }}>
                                            {props.series.awayteam.name} ({match.result.away})
                                        </Typography>
                                    }
                                />
                                <CardContent style={{ padding: 0 }}>
                                    <MatchTeamTable teamMatch={match.awayteam} />
                                </CardContent>
                            </Card>
                        </TabPanel>
                    );
                })}
        </div>
    );
};
