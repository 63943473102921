import React, { useContext } from 'react';

import { useTheme } from "@material-ui/core/styles";
import {
    Box,
    Card,
    CardContent,
    Chip,
    Grid,
    Typography,
    IconButton,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { Settings } from "@material-ui/icons";
import Link from "@material-ui/core/Link";
import { FaInstagram, FaTwitch, FaTwitter, FaYoutube, IoLogoTiktok } from "react-icons/all";
import { UserTimeline } from "./UserTimeline";
import { Constants } from "../../../constants";
import { Trans } from 'react-i18next';
import { StatisticsTabs } from "./StatisticsTabs";
import ReactCountryFlag from "react-country-flag"

async function get6MansRank(playerId) {
    const response = await fetch(new URL(`/api/6mans/${playerId}/rank`, Constants.apiPrefix).toString());
    return await response.json();
}

async function getTeam(playerId) {
    const response = await fetch(new URL(`/api/player/${playerId}/currentTeam`, Constants.apiPrefix).toString());
    return await response.json();
}

async function getSeasonTeams(playerId) {
    const response = await fetch(new URL(`/api/player/${playerId}/getTeamsAtEndOfEachSeason`, Constants.apiPrefix).toString());
    return await response.json();
}

function get6MansRankChip(rank) {
    let color;
    switch (rank) {
        case "s":
            color = Constants.rankSColor;
            break;
        case "x":
            color = Constants.rankXColor;
            break;
        case "a":
            color = Constants.rankAColor;
            break;
        case "b":
            color = Constants.rankBColor;
            break;
        case "c":
            color = Constants.rankCColor;
            break;
        case "d":
            color = Constants.rankDColor;
            break;
        case "e":
            color = Constants.rankEColor;
            break;
    }

    return (
        <Chip
            style={{ borderColor: color }}
            avatar={<span style={{ width: '13px', height: '13px', background: color, borderRadius: '50%' }} />}
            label={`RANK ${rank.toUpperCase()}`}
            size="small"
            variant="outlined"
        />
    );
}

function getSocials(user, theme) {
    const socials = [
        { icon: <FaTwitter style={{ fontSize: '2.5em', color: theme.palette.primary.contrastText }} />, link: user.twitter },
        { icon: <FaInstagram style={{ fontSize: '2.5em', color: theme.palette.primary.contrastText }} />, link: user.instagram },
        { icon: <FaTwitch style={{ fontSize: '2.5em', color: theme.palette.primary.contrastText }} />, link: user.twitch },
        { icon: <IoLogoTiktok style={{ fontSize: '2.5em', color: theme.palette.primary.contrastText }} />, link: user.tiktok },
        { icon: <FaYoutube style={{ fontSize: '2.5em', color: theme.palette.primary.contrastText }} />, link: user.youtube }
    ];
    return (
        <Grid container spacing={2} justify={"center"}>
            {socials.map((social) => {
                if (social.link && social.link.length > 0) {
                    return (
                        <Grid item>
                            <Link href={social.link}>
                                {social.icon}
                            </Link>
                        </Grid>
                    )
                }
            })}
        </Grid>
    );
}

export const UserPage = (props) => {
    const theme = useTheme();
    const url = props.url;
    const { player, currentUser, currentSeasonId } = props;
    const [team, setTeam] = React.useState();
    const [sixMans, setSixMans] = React.useState();
    const [seasonTeams, setSeasonTeams] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    async function fetchAllData(playerId) {
        const [teamResponse, sixMansRankResponse, seasonTeamsResponse] = await Promise.all([
            getTeam(playerId),
            get6MansRank(playerId),
            getSeasonTeams(playerId)
        ]);

        return [teamResponse, sixMansRankResponse, seasonTeamsResponse];
    }

    React.useEffect(() => {
        fetchAllData(player.pId).then(([team, sixMans, seasonTeams]) => {
            setTeam(team);
            setSixMans(sixMans);
            setSeasonTeams(seasonTeams);
            setLoading(false);
        });
    }, [player.pId])


    return (
        loading ? <></> :
            <>
                {(currentUser && player.pId === currentUser.playerId) && (
                    <Grid container direction="row-reverse">
                        <Grid item>
                            <RouterLink to={`${url}/settings`} style={{ color: theme.palette.text.primary }}>
                                <IconButton>
                                    <Settings />
                                </IconButton>
                            </RouterLink>
                        </Grid>
                    </Grid>
                )}
                <Grid container direction="row-reverse" spacing={4}>
                    <Grid item xs={12} md={3}>
                        <Box mb={2}>
                            {getSocials(player, theme)}
                        </Box>
                        <Card >
                            <CardContent>
                                <Grid container justify="space-between">
                                    <Grid item>
                                        <Typography variant="h5" gutterBottom>
                                            <Trans>user-information</Trans>
                                        </Typography>
                                    </Grid>
                                    {player.nationality && (
                                        <Grid item>
                                            <ReactCountryFlag
                                                countryCode={player.nationality}
                                                svg
                                                style={{
                                                    width: '2em',
                                                    height: '2em',
                                                }}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                                {player.roles && player.roles.length > 0 ? <Grid container spacing={1}>
                                    <Grid item>
                                        <Typography><Trans>user.roles</Trans>:</Typography>
                                    </Grid>
                                    {player.roles && player.roles.length > 0 ? player.roles.map(role => (
                                        <Grid item><Chip key={role.id} size="small" label={role.description} color="secondary" /></Grid>
                                    )) : (
                                        <Grid item><Typography><Trans>user.no-roles</Trans></Typography></Grid>
                                    )}
                                </Grid> : <></>}
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <Typography>
                                            <Trans>league.team</Trans>: {team ? <RouterLink className="srl-link" to={`/team/${team.id}`}>{team.teamname}</RouterLink> : <Trans>user.no-team</Trans>}
                                        </Typography>
                                    </Grid>
                                    {sixMans && (
                                        <Grid item container spacing={1}>
                                            <Grid item>
                                                <Typography>
                                                    SRL 6Mans:
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                {get6MansRankChip(sixMans.rank)}
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <Typography>
                                            Discord: {player.discordUsername ? `${player.discordUsername}#${player.discordDiscriminator}` : player.discord}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    {(player && seasonTeams && seasonTeams.length > 0) ? (
                        <>
                            <Grid item xs={12} md={9}>
                                <UserTimeline playerId={player.pId} seasonTeams={seasonTeams} currentSeasonId={currentSeasonId} />
                            </Grid>
                            <Grid item xs={12}>
                                <StatisticsTabs playerId={player.pId} seasonTeams={seasonTeams} />
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} md={9}>
                            <Typography variant="h5">
                                <Trans i18nKey="user.missing-stats" />
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </>
    );
}