import React from "react";
import {
    Typography,
    Box,
    Grid,
    Container,
    Paper,
    Avatar,
    List,
    ListItem,
    ListItemText,
    IconButton,
    ListItemIcon,
    MenuItem,
    FormControl,
    FormControlLabel,
    Select,
    Divider,
} from "@material-ui/core";
import { Constants } from "../../constants";
import { UpperCaseText } from "../../layout/components/UpperCaseText";
import { SeriesTab } from "../division/components/SeriesTab";
import { TeamDivisionTable } from "./components/TeamDivisionTable";
import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import { Settings } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import { Alert } from "@material-ui/lab";
import { Trans, useTranslation } from "react-i18next";
import { TeamPlayerStatisticsTable } from "./components/TeamPlayerStatisticsTable";

async function getDivision(teamId, seasonId) {
    const response = await fetch(`${Constants.apiPrefix}/api/team/${teamId}/season/${seasonId}/division`);
    return await response.json();
}

async function getPlayers(teamId, seasonId) {
    const response = await fetch(`${Constants.apiPrefix}/api/team/${teamId}/season/${seasonId}/players`);
    return await response.json();
}

async function getActivePlayers(teamId) {
    const response = await fetch(`${Constants.apiPrefix}/api/team/${teamId}/players`);
    return await response.json();
}

async function getSeries(teamId, seasonId) {
    const response = await fetch(`${Constants.apiPrefix}/api/team/${teamId}/season/${seasonId}/series`);
    return await response.json();
}

async function getPlayerStats(teamId, seasonId) {
    const response = await fetch(`${Constants.apiPrefix}/api/team/${teamId}/season/${seasonId}/playerStats`);
    return await response.json();
}

export const TeamPage = (props) => {
    const theme = useTheme();
    const { teamInfo, currentUser, currentPlayers, url, currentSeasonId, registrationOpen, playedSeasons, currentTeam, signedUp } = props;
    const { t } = useTranslation();

    const [season, setSeason] = React.useState(currentSeasonId);
    const [players, setPlayers] = React.useState([]);
    const [activePlayers, setActivePlayers] = React.useState([]);
    const [division, setDivision] = React.useState();
    const [series, setSeries] = React.useState([]);
    const [playerStats, setPlayerStats] = React.useState([]);

    const handleChange = (event) => {
        setSeason(event.target.value);
    };

    let teamCaptain = false;
    if (currentPlayers && currentUser) {
        teamCaptain = currentPlayers.some((player) => player.id === currentUser.playerId && player.sortorder === 1);
    }

    let myTeam = false;
    if (currentTeam && teamInfo) {
        myTeam = teamInfo.id === currentTeam.id;
    }

    React.useEffect(() => {
        if (teamInfo) {
            getDivision(teamInfo.id, season).then((r) => setDivision(r));
            getPlayers(teamInfo.id, season).then((r) => setPlayers(r));
            getActivePlayers(teamInfo.id).then((r) => setActivePlayers(r));
            getSeries(teamInfo.id, season).then((r) => setSeries(r));
            getPlayerStats(teamInfo.id, season).then((r) => setPlayerStats(r));
        }
    }, [season, teamInfo]);

    return (
        <>
            <Container>
                <Grid container spacing={2} justify={"flex-end"} alignItems={"flex-end"}>
                    <Grid item xs={12} container justify="flex-end">
                        {myTeam && (
                            <Grid item>
                                <Link to={`${url}/settings`} style={{ color: theme.palette.text.primary }}>
                                    <IconButton>
                                        <Settings />
                                    </IconButton>
                                </Link>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12} md={8}>
                        {signedUp !== undefined && registrationOpen === true && (
                            <Alert
                                variant="outlined"
                                severity={signedUp ? "success" : "warning"}
                                action={
                                    !signedUp &&
                                    teamCaptain && (
                                        <Button component={Link} to="/signup">
                                            <Trans>team.information.signup.button</Trans>
                                        </Button>
                                    )
                                }
                            >
                                <Trans>{signedUp ? "team.information.isSignedUp" : "team.information.notSignedUp"}</Trans> {currentSeasonId}
                            </Alert>
                        )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h5">
                            <Trans>team.information.statistic-for-season</Trans>
                        </Typography>
                        <FormControl style={{ width: "100%" }}>
                            <Select
                                fullWidth
                                variant="outlined"
                                inputProps={{
                                    id: "season",
                                    name: "season",
                                }}
                                value={season}
                                onChange={handleChange}
                            >
                                <MenuItem value={currentSeasonId}>
                                    <Trans>team.select.current</Trans>
                                </MenuItem>
                                {playedSeasons &&
                                    playedSeasons.map(
                                        (s) =>
                                            parseInt(s.seasonId) !== parseInt(currentSeasonId) && (
                                                <MenuItem value={s.seasonId}>
                                                    {s.season} ({s.teamname})
                                                </MenuItem>
                                            )
                                    )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2} direction="row-reverse">
                    <Grid item xs={12} md={4}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                {playedSeasons && season && (
                                    <Typography variant="h5">
                                        <Trans>general.players</Trans> {`${t("general.season")} ${season}`}
                                    </Typography>
                                )}
                                <List component={Paper} style={{ backgroundColor: theme.palette.primary.main }}>
                                    {parseInt(season) === parseInt(currentSeasonId) ? (
                                        activePlayers.length > 0 ? (
                                            activePlayers.map((player) => (
                                                <ListItem button component={Link} to={`/player/${player.id}`}>
                                                    <ListItemIcon>
                                                        <Avatar style={{ height: 25, width: 25 }} src={player.avatar && `${Constants.apiPrefix}/api/${player.avatar}`} />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        <UpperCaseText>{player.nickname}</UpperCaseText>
                                                    </ListItemText>
                                                </ListItem>
                                            ))
                                        ) : (
                                            <ListItem>
                                                <ListItemText>
                                                    <Trans>general.no-active-players</Trans>
                                                </ListItemText>
                                            </ListItem>
                                        )
                                    ) : (
                                        players.map((player) => (
                                            <ListItem button component={Link} to={`/player/${player.id}`}>
                                                <ListItemIcon>
                                                    <Avatar style={{ height: 25, width: 25 }} src={player.avatar && `${Constants.apiPrefix}/api/${player.avatar}`} />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <UpperCaseText>{player.nickname}</UpperCaseText>
                                                </ListItemText>
                                            </ListItem>
                                        ))
                                    )}
                                </List>
                            </Grid>
                            <Grid item>
                                {teamInfo &&
                                    division &&
                                    !(division.hasOwnProperty("MESSAGE") && division.MESSAGE === "api.team.getDivisionForTeam.response.did-not-play-this-season") && (
                                        <>
                                            <Typography variant="h5">Division {division.divisionDiscriminator}</Typography>
                                            <TeamDivisionTable teamId={teamInfo.id} division={division} component={Paper} style={{ backgroundColor: theme.palette.primary.main }} />
                                        </>
                                    )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        {series && division && !(division.hasOwnProperty("MESSAGE") && division.MESSAGE === "api.team.getDivisionForTeam.response.did-not-play-this-season") ? (
                            <>
                                {division.MESSAGE}
                                <Typography variant="h5">Division {division.divisionDiscriminator}</Typography>
                                <SeriesTab series={series} component={Paper} style={{ backgroundColor: theme.palette.primary.main }} />
                            </>
                        ) : (
                            <Typography variant="h5">
                                <Trans>team.information.missing-stats.text</Trans>
                            </Typography>
                        )}
                    </Grid>
                    {playerStats && playerStats.length > 0 && (
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                <Trans i18nKey="stat.player-stats" />
                            </Typography>
                            <TeamPlayerStatisticsTable stats={playerStats} component={Paper} style={{ backgroundColor: theme.palette.primary.main }} />
                        </Grid>
                    )}
                </Grid>
            </Container>
        </>
    );
};
