import React from "react";
import { Card, CardContent, CircularProgress, Container, FormControl, MenuItem, Paper, Select, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Constants } from "../../../../../constants";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { TextField } from "../../../../../components/TextField";
import Button from "@material-ui/core/Button";
import { Cookies } from "react-cookie";

async function getSerie(serieId) {
    const r = await fetch(`${Constants.apiPrefix}/api/series/raw/${serieId}`);
    return await r.json();
}

/*async function postReplay(accessToken, replay) { secret code
  const formData = new FormData();
  formData.append("File", replay);
  formData.append("accessToken", accessToken);
  const r = await fetch(`${Constants.apiPrefix}/api/admin/series/replay`, {
    method: "POST",
    body: formData
  });
  return await r.json();
}*/

const MatchForm = React.forwardRef((props, ref) => {
    const theme = useTheme();
    const { name, players, rows, setRows } = props;

    return (
        <form ref={ref}>
            <Box margin={2} p={1} component={Paper}>
                <Typography variant="h6" align="center" gutterBottom>
                    {name}
                </Typography>
                <Grid container direction="row" spacing={1}>
                    {rows.map((row, key) => (
                        <>
                            <Grid item xs={2}>
                                <Select
                                    style={{ height: theme.spacing(5) }}
                                    fullWidth
                                    variant="outlined"
                                    inputProps={{
                                        id: row.reference,
                                        name: row.reference,
                                    }}
                                    value={row.team_player_id}
                                >
                                    {Object.entries(players).map(([key, val]) => (
                                        <MenuItem value={key}>{val}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={2} key={`${row.reference}-points`}>
                                <TextField type="number" size="small" variant="outlined" defaltValue={row.points} label={"points"} name={`points-${key}`} />
                            </Grid>
                            <Grid item xs={2} key={`${row.reference}-goals`}>
                                <TextField type="number" size="small" variant="outlined" defaltValue={row.goals} label={"goals"} name={`goals-${key}`} />
                            </Grid>
                            <Grid item xs={2} key={`${row.reference}-assists`}>
                                <TextField type="number" size="small" variant="outlined" defaltValue={row.assists} label={"assists"} name={`assists-${key}`} />
                            </Grid>
                            <Grid item xs={2} key={`${row.reference}-saves`}>
                                <TextField type="number" size="small" variant="outlined" defaltValue={row.saves} label={"saves"} name={`saves-${key}`} />
                            </Grid>
                            <Grid item xs={2} key={`${row.reference}-shots`}>
                                <TextField type="number" size="small" variant="outlined" defaltValue={row.shots} label={"shots"} name={`shots-${key}`} />
                            </Grid>
                        </>
                    ))}
                </Grid>
            </Box>
        </form>
    );
});

const getChangeRowsFunction = (serie, setSerie, references) => {
    return (players) => {
        const tmp = { ...serie };
        tmp.rows[references[0]] = players[0];
        tmp.rows[references[1]] = players[1];
        tmp.rows[references[2]] = players[2];
        setSerie(tmp);
    };
};

export const EditMatch = () => {
    const cookies = new Cookies();
    const theme = useTheme();
    const { id } = useParams();
    const [serie, setSerie] = React.useState();
    const elRef = React.useRef();

    /*const handleClick = (event) => {
    console.log(elRef.current)
    console.log(elRef.current["points-0"].value);
    elRef.current["points-0"].value = 583
  }*/

    /*const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      postReplay(cookies.get("accessToken"), event.target.files[0]).then(r => console.log(r));
    }
  }*/

    React.useEffect(() => {
        getSerie(id).then((r) => setSerie(r));
    }, []);

    return (
        <Box p={3}>
            {serie ? (
                <>
                    {/*<div>
            <input
              onChange={onSelectFile}
              hidden
              id="replay-upload"
              type="file"
            />
            <label htmlFor="replay-upload">
              <Button variant="contained" color="secondary" component="span" >
                Upload
              </Button>
            </label>
          </div>*/}
                    <Grid container spacing={0} component={Paper} style={{ backgroundColor: theme.palette.primary.main }}>
                        <Grid xs={12} md={6} item container direction="column">
                            <Grid item>
                                <Typography align="center" variant="h4" gutterBottom>
                                    {serie.hometeam}
                                </Typography>
                            </Grid>
                            <Grid item>
                                {serie && (
                                    <MatchForm
                                        name="Match 1"
                                        players={serie.homePlayers}
                                        rows={[serie.rows["match1_home_player1"], serie.rows["match1_home_player2"], serie.rows["match1_home_player3"]]}
                                    />
                                )}
                            </Grid>
                            <Grid item>
                                {serie && (
                                    <MatchForm
                                        name="Match 2"
                                        players={serie.homePlayers}
                                        rows={[serie.rows["match2_home_player1"], serie.rows["match2_home_player2"], serie.rows["match2_home_player3"]]}
                                    />
                                )}
                            </Grid>
                            <Grid item>
                                {serie && (
                                    <MatchForm
                                        name="Match 3"
                                        players={serie.homePlayers}
                                        rows={[serie.rows["match3_home_player1"], serie.rows["match3_home_player2"], serie.rows["match3_home_player3"]]}
                                    />
                                )}
                            </Grid>
                            <Grid item>
                                {serie && (
                                    <MatchForm
                                        name="Match 4"
                                        players={serie.homePlayers}
                                        rows={[serie.rows["match4_home_player1"], serie.rows["match4_home_player2"], serie.rows["match4_home_player3"]]}
                                    />
                                )}
                            </Grid>
                            <Grid item>
                                {serie && (
                                    <MatchForm
                                        name="Match 5"
                                        players={serie.homePlayers}
                                        rows={[serie.rows["match5_home_player1"], serie.rows["match5_home_player2"], serie.rows["match5_home_player3"]]}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Grid xs={12} md={6} item container direction="column">
                            <Grid item>
                                <Typography align="center" variant="h4" gutterBottom>
                                    {serie.awayteam}
                                </Typography>
                            </Grid>
                            <Grid item>
                                {serie && (
                                    <MatchForm
                                        ref={elRef}
                                        name="Match 1"
                                        players={serie.awayPlayers}
                                        rows={[serie.rows["match1_away_player1"], serie.rows["match1_away_player2"], serie.rows["match1_away_player3"]]}
                                        setRows={getChangeRowsFunction(serie, setSerie, ["match1_away_player1", "match1_away_player2", "match1_away_player3"])}
                                    />
                                )}
                            </Grid>
                            <Grid item>
                                {serie && (
                                    <MatchForm
                                        name="Match 2"
                                        players={serie.awayPlayers}
                                        rows={[serie.rows["match2_away_player1"], serie.rows["match2_away_player2"], serie.rows["match2_away_player3"]]}
                                        setRows={getChangeRowsFunction(serie, setSerie, ["match2_away_player1", "match2_away_player2", "match2_away_player3"])}
                                    />
                                )}
                            </Grid>
                            <Grid item>
                                {serie && (
                                    <MatchForm
                                        name="Match 3"
                                        players={serie.awayPlayers}
                                        rows={[serie.rows["match3_away_player1"], serie.rows["match3_away_player2"], serie.rows["match3_away_player3"]]}
                                        setRows={getChangeRowsFunction(serie, setSerie, ["match3_away_player1", "match3_away_player2", "match3_away_player3"])}
                                    />
                                )}
                            </Grid>
                            <Grid item>
                                {serie && (
                                    <MatchForm
                                        name="Match 4"
                                        players={serie.awayPlayers}
                                        rows={[serie.rows["match4_away_player1"], serie.rows["match4_away_player2"], serie.rows["match4_away_player3"]]}
                                        setRows={getChangeRowsFunction(serie, setSerie, ["match4_away_player1", "match4_away_player2", "match4_away_player3"])}
                                    />
                                )}
                            </Grid>
                            <Grid item>
                                {serie && (
                                    <MatchForm
                                        name="Match 5"
                                        players={serie.awayPlayers}
                                        rows={[serie.rows["match5_away_player1"], serie.rows["match5_away_player2"], serie.rows["match5_away_player3"]]}
                                        setRows={getChangeRowsFunction(serie, setSerie, ["match5_away_player1", "match5_away_player2", "match5_away_player3"])}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*<Button variant="contained" color="secondary" onClick={handleClick}>KLICK ME</Button>*/}
                </>
            ) : (
                <Grid container justify="center">
                    <Grid item>
                        <CircularProgress color="secondary" size={24} />
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};
