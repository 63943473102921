import React from "react";
import { CircularProgress, IconButton, makeStyles, Menu, MenuItem } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import Cookies from "universal-cookie";
import { Trans } from "react-i18next";
import StreamAndDateDialog from "./StreamAndDateDialog";
import ReportResultDialog from "./ReportResultDialog";
import HandleAsWODialog from "./HandleAsWODialog";
import ResetSeriesDialog from "./ResetSeriesDialog";
import { SnackbarContext } from "../../../../../context/SnackbarProvider";
import { useConfirm } from "material-ui-confirm";
import { closeDiscordChannel, createDiscordChannel } from "./MatchService";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

export const DropdownMenu = (props) => {
    const classes = useStyles();
    const confirm = useConfirm();
    const { seriesId, haveMatchDate } = props;
    const cookies = new Cookies();
    const { setSnackbar } = React.useContext(SnackbarContext);
    const [closeLoading, setCloseLoading] = React.useState(false);
    const [modalState, setModalState] = React.useState({ reportResults: false });

    // Dropdown
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const createDiscordChannelClick = () => {
        createDiscordChannel(cookies.get("accessToken"), seriesId).then((r) => {});
    };

    const closeDiscordChannelClick = () => {
        confirm({ description: `Är du säker att du vill stänga matchrummet mellan ${props.hometeam} och ${props.awayteam}?` })
            .then(() => {
                setCloseLoading(true);
                closeDiscordChannel(cookies.get("accessToken"), seriesId).then((r) => {
                    if (r.hasOwnProperty("CODE") && r.CODE === 200) {
                        setSnackbar({
                            open: true,
                            severity: "success",
                            message: r.MESSAGE,
                        });
                    } else if (r.hasOwnProperty("CODE") && r.CODE !== 500) {
                        setSnackbar({
                            open: true,
                            severity: "error",
                            message: r.MESSAGE,
                        });
                    } else {
                        throw new Error(r.MESSAGE);
                    }
                    setCloseLoading(false);
                });
            })
            .catch(() => {
                //Should we do something if user pressing no?
            });
    };

    const handleModalState = (modal, isOpen) =>
        setModalState((previousState) => ({
            ...previousState,
            [modal]: isOpen,
        }));

    return (
        <>
            <IconButton aria-label="more" aria-controls={`menu-leave-icon-button`} aria-haspopup="true" onClick={handleClick}>
                <MoreVert />
            </IconButton>
            <Menu id={`menu-leave`} anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                <MenuItem key={`create-discord`} onClick={createDiscordChannelClick} disabled={true}>
                    <Trans>admin.matches.create-discord-channel</Trans>
                </MenuItem>
                <div className={classes.wrapper}>
                    <MenuItem key={`close-discord`} onClick={closeDiscordChannelClick} disabled={closeLoading}>
                        <Trans>admin.matches.close-discord-channel</Trans>
                    </MenuItem>
                    {closeLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
                <StreamAndDateDialog {...props} />

                <div className={classes.wrapper}>
                    <MenuItem key={`report-results`} onClick={() => handleModalState("reportResults", true)} disabled={haveMatchDate === false}>
                        <Trans>admin.matches.report-result</Trans>
                    </MenuItem>
                </div>

                <HandleAsWODialog {...props} />
                <ResetSeriesDialog {...props} />
                {/* <MenuItem key={`handle-as-wo`} disabled={true}>
                    <Trans>admin.matches.handle-as-wo</Trans>
                </MenuItem> */}
            </Menu>
            <ReportResultDialog {...props} isOpen={modalState.reportResults} onClose={() => handleModalState("reportResults", false)} />
        </>
    );
};
