import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { ConfirmProvider } from "material-ui-confirm";

import { Constants } from "./constants";

import Navbar from "./layout/Navbar";
import { Loader } from "./layout/Loader";
import { Footer } from "./layout/Footer";

import { About } from "./scenes/about/About";
import { Start } from "./scenes/start/Start";
import { Team } from "./scenes/team/Team";
import { NotFound } from "./scenes/NotFound/NotFound";
import { User } from "./scenes/user/User";
import { Division } from "./scenes/division/Division";
import { Seasons } from "./scenes/seasons/Seasons";
import { Series } from "./scenes/series/Series";
import { XGamer } from "./scenes/XGamerShop/XGamer";
import { Post } from "./scenes/posts/Post";
import { News } from "./scenes/posts/News";
import { FAQ } from "./scenes/faq/FAQ";
import { HelpUs } from "./scenes/helpUs/HelpUs";
import { Member } from "./scenes/member/Member";
import PrivateRoute from "./scenes/admin/PrivateRoute";
import { Admin } from "./scenes/admin/Admin";
import { SixMans } from "./scenes/6mans/6mans";
import { createTeam } from "./scenes/createTeam/createTeam";
import { Notifications } from "./scenes/Notifications/Notifications";
import { YldrMerch } from "./scenes/YldrMerch/YldrMerch";
import { CookiesAndPrivacyPolicy } from "./scenes/CookiesAndPrivacyPolicy/CookiesAndPrivacyPolicy";
import { Prizes } from "./scenes/Prizes/Prizes";
import { Cooperation } from "./scenes/cooperation/Cooperation";
import { Rules } from "./scenes/rules/Rules";

import CustomThemeProvider from "./theme/CustomThemeProvider";
import { Callback } from "./discord/Callback";
import StateProvider from "./discord/StateProvider";
import RouteChangeTracker from "./RouteChangeTracker";
import { TeamSignup } from "./scenes/teamSignup/TeamSignup";
import SnackbarProvider from "./context/SnackbarProvider";

import Bugsnag from "@bugsnag/js";
import { CodeOfConduct } from "./scenes/code-of-conduct/CodeOfConduct";
import { Statutes } from "./scenes/statutes/Statutes";

export function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

const TRACKING_ID = "UA-160974935-1";
ReactGA.initialize(TRACKING_ID);

const App = () => {
    const [currentSeasonId, setCurrentSeasonId] = React.useState();
    const [registrationOpen, setRegistrationOpen] = React.useState(false);
    const [transferOpen, setTransferOpen] = React.useState(false);
    const [settings, setSettings] = React.useState({});
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        fetch(Constants.apiPrefix + "/api/settings/all")
            .then((response) => response.json())
            .then((response) => {
                setSettings(response);
                const currentSeason = response.find((setting) => {
                    return setting.setting_key === Constants.settings.currentSeason;
                });
                const registrationOpen = response.find((setting) => {
                    return setting.setting_key === Constants.settings.registrationOpen;
                });
                const transferOpen = response.find((setting) => {
                    return setting.setting_key === Constants.settings.transferOpen;
                });
                setRegistrationOpen(registrationOpen.setting_value === "true");
                setTransferOpen(transferOpen.setting_value === "true");
                setCurrentSeasonId(parseInt(currentSeason.setting_value));
                setTimeout(function () {
                    //Making people see the awesome loader ;)
                    setLoading(false);
                }, 750);
            });
    }, []);
    console.log("App render");
    return (
        <CustomThemeProvider>
            <SnackbarProvider>
                <StateProvider>
                    <ConfirmProvider defaultOptions={{
                        confirmationText: "Ja",
                        cancellationText: "Nej",
                        title: "Är du säker?",
                        confirmationButtonProps: { color: 'secondary'}
                    }}>
                        <CssBaseline />
                        <BrowserRouter>
                            <RouteChangeTracker />
                            <ScrollToTop />
                            {loading ? (
                                <Loader />
                            ) : (
                                <>
                                    {<Navbar currentSeasonId={currentSeasonId} />}
                                    <Switch>
                                        <Route exact path="/" render={(props) => <Start {...props} seasonId={currentSeasonId} settings={settings} />} />
                                        <Route path="/post/:customUrl" component={Post} />
                                        <Route path="/news/:page?/:category?" component={News} />
                                        <Route path="/faq" component={FAQ} />
                                        <Route path="/help-us" component={HelpUs} />
                                        <Route path="/member" component={Member} />
                                        <Route path="/about" component={About} />
                                        <Route path="/prizes" component={Prizes} />
                                        <Route path="/cooperation" component={Cooperation} />
                                        <Route path="/cookies-and-privacy-policy" component={CookiesAndPrivacyPolicy} />
                                        <Route path="/callback/:callbackCode" component={Callback} />
                                        <Route path="/seasons" component={Seasons} />
                                        {/* <Route path="/x-gamer-shop" component={XGamer} /> */}
                                        <Route path="/merch" component={YldrMerch} />
                                        <Route path="/6mans" component={SixMans} />
                                        <Route path="/rules" component={Rules} />
                                        <Route path="/code-of-conduct" component={CodeOfConduct} />
                                        <Route path="/statutes" component={Statutes} />
                                        <Route path="/player/:playerId">
                                            <User currentSeasonId={currentSeasonId} />
                                        </Route>
                                        <Route path="/team/:teamId">
                                            <Team currentSeasonId={currentSeasonId} registrationOpen={registrationOpen} transferOpen={transferOpen}/>
                                        </Route>
                                        {/*<Route path="/team/:teamId/season/:seasonId" component={Team}/>*/}
                                        <Route path="/division/:divisionDiscriminator/season/:seasonId" render={(props) => <Division {...props} currentSeasonId={currentSeasonId} />} />
                                        <Route path="/series/:seriesId" component={Series} />
                                        <Route path="/createTeam" component={createTeam} />
                                        <Route path="/notifications" component={Notifications} />
                                        <Route path="/signup" component={TeamSignup} />
                                        <PrivateRoute path="/admin" acceptedRoles={[Constants.AdminRole, Constants.DivisionAdmin]} component={Admin} />
                                        <Route path="*" component={NotFound} />
                                    </Switch>
                                    <Footer />
                                </>
                            )}
                            <CookieConsent
                                location="bottom"
                                buttonText="Accept"
                                cookieName="srl_cookie_accept2"
                                style={{ background: "#171a21", borderTop: "1px solid #0E0F0F" }}
                                buttonStyle={{ background: "#eda11a", color: "#171a21", fontSize: "13px" }}
                                expires={150}
                            >
                                This website uses cookies to improve your experience. We'll assume you're ok with this if you continue to browse the website.{" "}
                                <Link to="/cookies-and-privacy-policy" className="srl-link">
                                    More info
                                </Link>
                            </CookieConsent>
                        </BrowserRouter>
                    </ConfirmProvider>
                </StateProvider>
            </SnackbarProvider>
        </CustomThemeProvider>
    );
};
export default App;
