import React from "react";
import { useContext } from "react";
import Button from "@material-ui/core/Button";
import {
    Box,
    CircularProgress,
    Divider,
    Fab,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    makeStyles,
    Menu,
    MenuItem,
    Paper,
    Radio,
    Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Trans } from "react-i18next";
import { Constants } from "../../../../../constants";
import { TextField } from "../../../../../components/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { SnackbarContext } from "../../../../../context/SnackbarProvider";
import Cookies from "universal-cookie";
import { Add, Clear } from "@material-ui/icons";
import { useTheme } from "@material-ui/core/styles";
import { FormLabel, RadioGroup } from "../../../../../components/RadioGroup";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

async function getPlayingOrders() {
    const r = await fetch(`${Constants.apiPrefix}/api/playingOrder`);
    return await r.json();
}

async function getWeekStatus(divisionId) {
    const r = await fetch(`${Constants.apiPrefix}/api/division/${divisionId}/weeks`);
    return await r.json();
}

function LinearProgressWithLabel(props) {
    const normalise = (value) => ((value - 0) * 100) / (props.total - 0);
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" value={normalise(props.value)} color={"secondary"} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${props.value} / ${props.total}`}</Typography>
            </Box>
        </Box>
    );
}

export default function MatchesFormDialog(props) {
    const theme = useTheme();
    const { division, updateDivisions } = props;
    const classes = useStyles();
    const cookies = new Cookies();
    const [open, setOpen] = React.useState(false);
    const [createMatchesLoading, setCreateMatchesLoading] = React.useState(false);
    const [playingOrders, setPlayingOrders] = React.useState([]);
    const [allowedConfiguration, setAllowedConfiguration] = React.useState(false);
    const { setSnackbar } = useContext(SnackbarContext);

    // Radio group
    const [value, setValue] = React.useState("1");
    const handleChange = (event) => {
        setValue(event.target.value);
        isAllowed(event.target.value, playingOrders);
    };

    // Functions
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const isAllowed = (val, po) => {
        const matchesPerWeek = parseInt(val);
        const numberOfTeams = parseInt(division.numberOfTeams);
        let allowed = false;
        po.forEach((element) => {
            if (element.noOfGamesVsOther === matchesPerWeek && element.noOfTeams === numberOfTeams) {
                allowed = true;
            }
        });
        setAllowedConfiguration(allowed);
    };

    const createMatches = async () => {
        const r = await fetch(`${Constants.apiPrefix}/api/division/createAllMatches`, {
            method: "POST",
            body: JSON.stringify({
                accessToken: cookies.get("accessToken"),
                divisionId: division.divisionId,
                matchesPerWeek: value,
            }),
        });
        return await r.json();
    };

    const createMatchesClick = (event) => {
        setCreateMatchesLoading(true);
        createMatches().then((r) => {
            if (r.hasOwnProperty("CODE") && r.CODE === 200) {
                setSnackbar({
                    open: true,
                    severity: "success",
                    message: r.MESSAGE,
                });
            } else if (r.hasOwnProperty("CODE") && r.CODE !== 500) {
                setSnackbar({
                    open: true,
                    severity: "error",
                    message: r.MESSAGE,
                });
            } else {
                throw new Error(r.MESSAGE);
            }
            setCreateMatchesLoading(false);
        });
    };

    // Effects
    React.useEffect(() => {
        if (open) {
            getPlayingOrders().then((r) => {
                setPlayingOrders(r);
                isAllowed(value, r);
            });
        }
    }, [open]);

    return (
        <div>
            <MenuItem key={`matches`} onClick={handleClickOpen}>
                <Trans>admin.division.match-schema</Trans>
            </MenuItem>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth key={`dialog-matches-${division.divisionId}`}>
                <DialogTitle id="form-dialog-title">Hantera serier och schema</DialogTitle>
                <DialogContent>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Antal serier per vecka</FormLabel>
                        <RadioGroup aria-label="matches-per-week" name="matches-per-week" value={value} onChange={handleChange}>
                            <FormControlLabel value={"1"} control={<Radio />} label="1" />
                            <FormControlLabel value={"2"} control={<Radio />} label="2" />
                        </RadioGroup>
                        <Grid container justify={"center"} spacing={1}>
                            {!allowedConfiguration && (
                                <Grid item xs={12}>
                                    <Alert variant="outlined" severity="error">
                                        Hittade inget matchschema för {division.numberOfTeams} lag med {value} matcher per vecka
                                    </Alert>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Alert variant="outlined" severity="warning">
                                    Vid skapande av serier då det redan finns så kommer alla serier för denna division <strong>försvinna</strong> och bli ersatta av de nya
                                </Alert>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.wrapper}>
                                    <Button fullWidth disabled={createMatchesLoading || !allowedConfiguration} onClick={createMatchesClick} color="secondary" variant="contained">
                                        Skapa serier
                                    </Button>
                                    {createMatchesLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContent>
                <Box mt={3} />
            </Dialog>
        </div>
    );
}
