import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Box, Button, Container, Grid, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from "@material-ui/core";

import { Constants } from "../../../../../constants";
import DivisionDialog from "./DivisionDialog";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { DropdownMenu } from "./DropdownMenu";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    topRow: {
        padding: theme.spacing(2),
    },
}));

export const DivisionTabs = (props) => {
    const classes = useStyles();
    const { url } = props.match;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [seasons, setSeasons] = React.useState({});

    const getDivisions = async () => {
        const r = await fetch(`${Constants.apiPrefix}/api/division/all`);
        return await r.json();
    };

    const updateDivisions = () => {
        return getDivisions().then((response) => {
            const divisions = response.MESSAGE;
            let seasonsTmp = {};
            divisions.forEach((division) => {
                if (seasonsTmp[division.season]) {
                    seasonsTmp[division.season].push(division);
                } else {
                    seasonsTmp[division.season] = [];
                    seasonsTmp[division.season].push(division);
                }
            });
            setSeasons(seasonsTmp);
        });
    };

    React.useEffect(updateDivisions, []);

    return (
        <>
            <Grid container justify="space-between" direction="row" alignItems="stretch" className={classes.topRow}>
                <Grid item>
                    <Button variant="contained" color="secondary" size="small" component={Link} to={`/admin/divisions/add`}>
                        Skapa division
                    </Button>
                </Grid>
            </Grid>
            <div className={classes.root}>
                <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        {seasons &&
                            Object.keys(seasons)
                                .sort()
                                .map((key, index) => <Tab label={key} {...a11yProps(index)} />)}
                    </Tabs>
                </AppBar>
                {seasons &&
                    Object.keys(seasons)
                        .sort()
                        .map((key, index) => (
                            <TabPanel value={value} index={index}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Namn</TableCell>
                                                <TableCell align="right">Säsong</TableCell>
                                                <TableCell align="right">Divisionsansvarig</TableCell>
                                                <TableCell align="right">Antal lag</TableCell>
                                                <TableCell align="right">Antal skapade serier</TableCell>
                                                <TableCell />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {seasons[key].map((division) => (
                                                <TableRow>
                                                    <TableCell>{division.division}</TableCell>
                                                    <TableCell align="right">{division.season}</TableCell>
                                                    <TableCell align="right">{division.nickname ? division.nickname : "-"}</TableCell>
                                                    <TableCell align="right">{division.numberOfTeams}</TableCell>
                                                    <TableCell align="right">{division.numberOfSeries}</TableCell>
                                                    {/*<TableCell align="right">
                          <DivisionDialog division={division} updateDivisions={updateDivisions} />
                        </TableCell>*/}
                                                    <TableCell align="right" padding="checkbox">
                                                        <DropdownMenu division={division} updateDivisions={updateDivisions} />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TabPanel>
                        ))}
            </div>
        </>
    );
};
